import { WhatsAppIcon } from "@assets/icons"
import logoSmall from "@assets/images/logo-brand.svg"
import BrandImage from "@components/brand-image"
import { CommunicationItem } from "@components/communication-item"
import FullLoading from "@components/full-loading"
import { RoutesConstants as routes } from "@constants/routes"
import { AbilityContext } from "@context/ability.provider"
import { IProfile } from "@features/profile"
import { faCircle, faEye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useAuth } from "@hooks/use-auth"
import { useNotifications } from "@hooks/use-notifications"
import { useRole } from "@hooks/use-role"
import { useScrollDirection } from "@hooks/use-scroll-direction"
import { requestForToken } from "@services/firebase-cm.service"
import NotificationService from "@services/notification.service"
import { ProfileService } from "@services/profile.service"
import { Message } from "@type/messages.types"
import { Notification, NotificationStatus } from "@type/notification.types"
import AuthHelper from "@utils/helpers/auth.helper"
import classNames from "classnames"
import { Avatar, Button, Dropdown, Navbar } from "flowbite-react"
import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  HiBell,
  HiChatAlt2,
  HiChevronDown,
  HiChevronUp,
  HiMail,
  HiOutlineLogout,
  HiOutlinePlus,
  HiUser,
  HiMenu,
} from "react-icons/hi"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
const { VITE_CONTACT_WHATSAPP, VITE_CDN_URL } = import.meta.env

const DefaultLayout: React.FC = () => {
  const [showAdminMenu, setShowAdminMenu] = useState(true)
  const { notifications, changeNotificationStatus } = useNotifications()
  const [messages] = useState<Message[]>([])
  const [showAllMessages, setShowAllMessages] = useState(false)

  const { checkAuthenticated, updateRules, signout } = useAuth()
  const { scrollableDivRef } = useScrollDirection()
  const navigate = useNavigate()
  const location = useLocation()
  const [imageProfileError, setImageProfileError] = useState(false)
  const { user } = useAuth()
  const [showLoading, setShowLoading] = useState(true)
  const ability = useContext(AbilityContext)
  const { isAdmin, isUser } = useRole()
  const [profile, setProfile] = useState<IProfile | null>(null)

  const displayedMessages = useMemo(() => {
    return showAllMessages ? messages : messages.slice(0, 10)
  }, [messages, showAllMessages])

  const initPush = () => {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          if (registration.active) {
            requestForToken().then((token) => {
              if (token) {
                NotificationService.sendToken(token)
              }
            })
          }
        })
    }
  }
  useEffect(() => {
    checkAuthenticated((error) => {
      if (error) {
        navigate(routes.signIn)
      } else {
        initPush()
        updateRules(ability)
        setTimeout(() => {
          setShowLoading(false)
        }, 2000)
      }
    })
  }, [])

  useEffect(() => {
    ProfileService.get().then((data) => {
      if (data) {
        setProfile(data)
      }
    })
  }, []);

  const handleSignout = () => {
    signout(() => {
      AuthHelper.redirectToLogin()
    })
  }

  useEffect(() => {
    //TODO falta o endpoint no backend
    //MessageService.findAll().then((data) => setMessages(data))
  }, [])

  return (
    <div className="relative w-full h-full bg-gray-50 dark:bg-gray-900">
      <Navbar fluid={true} rounded={true} className="px-4">
        <Navbar.Brand href={routes.home}>
          <BrandImage src={logoSmall} className="mr-4 h-8" />
        </Navbar.Brand>
        <Navbar.Collapse className="hidden lg:flex grow">
          <Navbar.Link
            className="cursor-pointer"
            onClick={() => navigate(routes.home)}
            active={
              location.pathname === routes.home ||
              location.pathname.startsWith(routes.conciliations)
            }
          >
            Processos
          </Navbar.Link>
          <Navbar.Link
            active={location.pathname === routes.payment}
            className="cursor-pointer"
            onClick={() => navigate(routes.payment)}
          >
            Pagamentos
          </Navbar.Link>
          {!isUser && (
            <Navbar.Link
              active={location.pathname === routes.pendencies}
              className="cursor-pointer"
              onClick={() => navigate(routes.pendencies)}
            >
              Pendências
            </Navbar.Link>
          )}
          {isAdmin && (
            <div
              className={`${
                location.pathname.startsWith(routes.admin)
                  ? "md:bg-transparent md:text-primary-700 md:border-b-2 md:border-primary-600"
                  : ""
              } block py-2 pr-4 pl-3 md:p-0 text-gray-700 md:hover:bg-transparent md:hover:text-primary-700`}
            >
              <Dropdown inline label="Administração" className="min-w-[125px]">
                <Dropdown.Item
                  className="cursor-pointer my-1 w-full"
                  onClick={() => navigate(routes.adminUsers)}
                >
                  Usuários
                </Dropdown.Item>
                <Dropdown.Item
                  className="cursor-pointer my-1 w-full"
                  onClick={() => navigate(routes.adminModels)}
                >
                  Modelos
                </Dropdown.Item>
              </Dropdown>
            </div>
          )}
        </Navbar.Collapse>
        {!showLoading && (
          <>
            <div className="flex md:order-2">
              <Dropdown
                arrowIcon={false}
                inline={true}
                label={
                  <div className="w-8 relative text-gray-500">
                    <HiBell className="h-[24px] w-[24px]" />
                    {notifications?.some(
                      (not) => not.status === NotificationStatus.UNREAD
                    ) && (
                      <FontAwesomeIcon
                        className="absolute top-0 left-3 border-2 border-white rounded-full text-red-600 width-[10px] h-[10px]"
                        icon={faCircle}
                      />
                    )}
                  </div>
                }
              >
                <div className="px-4 py-2 bg-gray-100 block text-base font-medium text-left">
                  Notificações
                </div>
                {notifications && notifications?.length > 0 ? (
                  notifications?.map((item: Notification) => (
                    <div
                      key={`notification_${item.notificationId}`}
                      className="border-b"
                    >
                      <Dropdown.Item>
                        <div className="grow py-2">
                          <CommunicationItem
                            avatar={item.agentAvatar}
                            name={item.agentName}
                            body={item.body}
                            createdAt={item.createdAt}
                            status={item.status}
                            onChangeStatus={() =>
                              changeNotificationStatus(
                                item.notificationId,
                                item.status
                              )
                            }
                          />
                        </div>
                      </Dropdown.Item>
                    </div>
                  ))
                ) : (
                  <Dropdown.Item className="border-b text-gray-900 leading-tight text-sm font-medium">
                    <HiMail size={16} className="mr-2" /> Nenhuma notificação
                    por enquanto.
                  </Dropdown.Item>
                )}
                {notifications && notifications?.length > 10 && (
                  <Dropdown.Item
                    className={`justify-center ${
                      notifications?.length === 0
                        ? "text-gray-400 cursor-not-allowed hover:bg-transparent"
                        : ""
                    }`}
                    onClick={() => navigate(routes.notifications)}
                  >
                    <div>
                      <FontAwesomeIcon icon={faEye} />
                      <span className="ml-2">Ver mais</span>
                    </div>
                  </Dropdown.Item>
                )}
              </Dropdown>
              <Dropdown
                arrowIcon={false}
                inline={true}
                label={
                  <HiChatAlt2 className="text-gray-500 h-[24px] w-[24px]" />
                }
              >
                <div className="px-4 py-2 bg-gray-100 block text-base font-medium text-left">
                  Mensagens
                </div>
                {displayedMessages && displayedMessages?.length > 0 ? (
                  displayedMessages?.map((item) => (
                    <div key={`messages_${item.id}`} className="border-b">
                      <Dropdown.Item>
                        <div className="grow py-2">
                          <CommunicationItem
                            avatar={item.avatar}
                            name={item.name}
                            body={item.description}
                            message={item.message}
                          />
                        </div>
                      </Dropdown.Item>
                    </div>
                  ))
                ) : (
                  <Dropdown.Item className="border-b text-gray-900 leading-tight text-sm font-medium">
                    <HiMail size={16} className="mr-2" /> Nenhuma notificação
                    por enquanto.
                  </Dropdown.Item>
                )}
                {messages && messages?.length > 10 && (
                  <Dropdown.Item
                    className={`justify-center ${
                      messages?.length === 0
                        ? "text-gray-400 cursor-not-allowed hover:bg-transparent"
                        : ""
                    }`}
                    onClick={() => setShowAllMessages((prev) => !prev)}
                  >
                    <div>
                      <FontAwesomeIcon icon={faEye} />
                      <span className="ml-2">Ver mais</span>
                    </div>
                  </Dropdown.Item>
                )}
              </Dropdown>
              <div className="ml-4 mr-4 border-r-2 border-gray-200" />
              <span className="md:inline-flex lg:inline-flex sm:hidden hidden items-center justify-center text-base font-medium rounded-lg text-gray-500 hover:text-gray-900 hover:bg-gray-100">
                <WhatsAppIcon />
                <Link
                  target="_blank"
                  to={VITE_CONTACT_WHATSAPP}
                  rel="noreferrer"
                  className="ml-1.5"
                >
                  <span className="w-full ml-1.5">Fale conosco</span>
                </Link>
              </span>
              <Button
                color="primary"
                onClick={() => navigate(routes.conciliationsCreate)}
                className="ml-4 mr-4 md:inline-flex lg:inline-flex sm:hidden hidden"
              >
                <HiOutlinePlus className="mr-1" />
                Criar novo processo
              </Button>
              <div className="mr-4 md:mx-0">
                <Dropdown
                  className="rounded-lg min-w-[224px]"
                  arrowIcon={false}
                  inline={true}
                  label={
                    <Avatar
                      rounded={true}
                      img={
                        imageProfileError
                          ? `https://ui-avatars.com/api/?name=${encodeURI(
                              profile?.fullName ?? ""
                            )}`
                          : VITE_CDN_URL + `/profiles/profile_${user?.id}.png`
                      }
                      onError={() => setImageProfileError(true)}
                    />
                  }
                >
                  <div className="leading-tight py-2 px-4 truncate text-sm border-b border-gray-200">
                    {profile?.fullName && (
                      <p className="leading-tight font-semibold">
                        {profile?.fullName}
                      </p>
                    )}
                    {profile?.email && (
                      <p className="leading-tight font-normal text-gray-500">
                        {profile?.email}
                      </p>
                    )}
                  </div>
                  <Dropdown.Item
                    className={`${
                      location.pathname === routes.profile
                        ? "text-primary-600 underline"
                        : "text-gray-700"
                    } text-sm font-normal`}
                    onClick={() => navigate(routes.profile)}
                  >
                    <HiUser
                      className={`${
                        location.pathname === routes.profile
                          ? "text-primary-600"
                          : "text-gray-700"
                      } mr-2`}
                    />{" "}
                    Meu perfil
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="border-t border-gray-200 text-primary-900"
                    onClick={handleSignout}
                  >
                    <HiOutlineLogout className="mr-2 text-primary-900" />
                    Sair
                  </Dropdown.Item>
                </Dropdown>
              </div>
              <Dropdown
                arrowIcon={false}
                inline={true}
                label={<HiMenu className="h-6 w-6 shrink-0 md:hidden" />}
                className="rounded-lg md:hidden lg:hidden"
              >
                <Dropdown.Item
                  className={`${
                    (location.pathname === routes.home ||
                      location.pathname.startsWith(routes.conciliations)) &&
                    location.pathname !== routes.conciliationsCreate
                      ? "text-primary-600 underline"
                      : "text-gray-700"
                  } text-sm font-normal`}
                  onClick={() => navigate(routes.home)}
                >
                  Processos
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${
                    location.pathname === routes.payment
                      ? "text-primary-600 underline"
                      : "text-gray-700"
                  } text-sm font-normal`}
                  onClick={() => navigate(routes.payment)}
                >
                  Pagamentos
                </Dropdown.Item>
                {isAdmin && (
                  <>
                    <div
                      onClick={() => setShowAdminMenu((prev) => !prev)}
                      className="py-2 px-4 cursor-pointer w-full focus:outline-none hover:bg-gray-100"
                    >
                      <p
                        className={`${
                          location.pathname.startsWith(routes.admin)
                            ? "text-primary-600 underline"
                            : "text-gray-700"
                        } flex items-center justify-start text-sm font-normal`}
                      >
                        Administração{" "}
                        {showAdminMenu ? (
                          <HiChevronUp className="ml-1" />
                        ) : (
                          <HiChevronDown className="ml-1" />
                        )}
                      </p>
                    </div>
                    {showAdminMenu && (
                      <>
                        <Dropdown.Item
                          className="pl-8 text-gray-700 text-sm font-normal"
                          onClick={() => navigate(routes.adminUsers)}
                        >
                          Usuários
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="pl-8 text-gray-700 text-sm font-normal"
                          onClick={() => navigate(routes.adminModels)}
                        >
                          Modelos
                        </Dropdown.Item>
                      </>
                    )}
                  </>
                )}
                <Dropdown.Item
                  as={Link}
                  target="_blank"
                  to={VITE_CONTACT_WHATSAPP}
                  rel="noreferrer"
                  className="text-gray-700 text-sm font-normal"
                >
                  Fale conosco
                </Dropdown.Item>
                <Dropdown.Item
                  className={`${
                    location.pathname === routes.conciliationsCreate
                      ? "text-primary-600 underline"
                      : "text-gray-700"
                  } text-sm font-normal border-t border-gray-200`}
                  onClick={() => navigate(routes.conciliationsCreate)}
                >
                  <HiOutlinePlus className="mr-1" size={10} /> Criar novo
                  processo
                </Dropdown.Item>
              </Dropdown>
            </div>
          </>
        )}
      </Navbar>
      <main
        ref={scrollableDivRef}
        className={classNames(
          "overflow-y-scroll relative w-full h-[calc(100%-4rem)]",
          "lg:px-8 md:px-8 sm:pt-8 pt-8"
        )}
      >
        {showLoading ? <FullLoading /> : <Outlet />}
      </main>
    </div>
  )
}

export default DefaultLayout
