import { AdminModels } from "@type/admin.type"

export enum Constants {
  "ALL" = "Todos",
  "INTERNAL" = "Interno",
  "DRAFT" = "Rascunho",
  "PUBLISH_ALL" = "Publicar para todos",
  "PUBLISH_INTERNAL" = "Publicar internamente",
  "SAVED_DRAFT" = "Salvar como rascunho",
}

export const commonStatusOptions = [
  {
    value: AdminModels.Status.PUBLISH_INTERNAL,
    label: Constants.PUBLISH_INTERNAL,
  },
  {
    value: AdminModels.Status.DRAFT,
    label: Constants.SAVED_DRAFT,
  },
]

export enum ProcessActions {
  "DEFINE_RESPONSIBLE" = "define_responsible",
  "ADD_UPDATE" = "add_update",
  "NOTIFY_REQUIRED" = "notify_required",
  "DEFINE_SPECIALIST" = "define_specialist",
  "FINISH_PROCESS" = "finish_process",
}

export const toIgnore = [
  "hasClausula",
  "tipoPessoa",
  "pedido",
  "papelProcesso",
  "id",
]

export enum EditProcessKeys {
  RELATO = "relato",
  VALOR = "valor",
  DOCS = "docs",
  RITO_PROCESSO = "ritoProcesso",
  METODO_SOLUCAO = "metodoSolucao",
  NOME_COMPLETO = "nomeCompleto",
  CPF_CNPJ = "cpfCnpj",
  EMAIL = "email",
  TEL = "tel",
  PAPEL_PROCESSO = "papelProcesso",
  OAB = "oab",
}

export const editProcessTitles: Record<EditProcessKeys, string> = {
  [EditProcessKeys.RELATO]: "Resumo do processo",
  [EditProcessKeys.VALOR]: "Valor da causa",
  [EditProcessKeys.DOCS]: "Documentos importantes para este processo",
  [EditProcessKeys.RITO_PROCESSO]: "Rito de processo",
  [EditProcessKeys.METODO_SOLUCAO]: "Método de solução",
  [EditProcessKeys.NOME_COMPLETO]: "Nome completo",
  [EditProcessKeys.CPF_CNPJ]: "CPF/CNPJ",
  [EditProcessKeys.EMAIL]: "E-mail",
  [EditProcessKeys.TEL]: "Telefone/Whatsapp",
  [EditProcessKeys.PAPEL_PROCESSO]: "Papel no processo",
  [EditProcessKeys.OAB]: "OAB",
}

export enum PROCESS_ROLES {
  Lawyer = "LAWYER",
  Representative = "REPRESENTATIVE",
  Claimant = "CLAIMANT",
  Required = "REQUIRED",
}

export enum PERSON_TYPE {
  PF = "PF",
  PJ = "PJ",
}
