import { ClipIcon, PencilIcon } from "@assets/icons"
import {
  Claimant,
  Conciliation,
} from "@features/conciliation/types/conciliation.type"
import {
  EditProcessKeys,
  PROCESS_ROLES,
  editProcessTitles,
  toIgnore,
} from "@utils/helpers/constants"
import { formatCurrency } from "@utils/helpers/formatCurrency"
import { Button } from "flowbite-react"
import IMask from "imask/holder"
import React, { useEffect, useMemo, useRef, useState } from "react"
/* eslint-disable @typescript-eslint/no-explicit-any */

interface EditInfoProps {
  data: Conciliation | Claimant[]
  handleSet: React.Dispatch<React.SetStateAction<any>>
  countInfoEdit: number
  setCountInfoEdit: React.Dispatch<React.SetStateAction<number>>
  formStep: number
  fetchData: (key: string, id?: number) => Array<any> | any | null
}

export default function EditInfoProcess({
  data,
  handleSet,
  countInfoEdit,
  setCountInfoEdit,
  formStep,
  fetchData,
}: EditInfoProps) {
  const refPhone = useRef<HTMLInputElement | null>(null)
  const [editStates, setEditStates] = useState<{
    [key: number]: { [innerKey: string]: boolean }
  }>({})

  const startEditing = (index: number, key: string) => {
    setCountInfoEdit(countInfoEdit + 1)
    setEditStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [key]: true,
      },
    }))
  }

  const stopEditing = (index: number, key: string) => {
    setCountInfoEdit(countInfoEdit - 1)
    setEditStates((prevState) => ({
      ...prevState,
      [index]: {
        ...prevState[index],
        [key]: false,
      },
    }))
  }

  const handleSubmit = (
    index: number,
    key: string,
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()
    stopEditing(index, key)
  }

  const handleChange = (index: number, key: string, value: string) => {
    if (Array.isArray(data)) {
      const updatedRequerentes = [...data]
      updatedRequerentes[index] = { ...updatedRequerentes[index], [key]: value }
      handleSet(updatedRequerentes)
    } else {
      handleSet({ ...data, [key]: value })
    }
  }

  const sectionTitle = (key: string): string => {
    return editProcessTitles[key as EditProcessKeys] || "Opção"
  }

  const dataArray = useMemo(() => (Array.isArray(data) ? data : [data]), [data])

  useEffect(() => {
    if (refPhone.current) {
      IMask(refPhone.current!, {
        mask: "(00) 00000-0000",
      })
    }
  }, [dataArray])

  return (
    <div className="mt-8">
      {dataArray.map((objeto, index) => (
        <React.Fragment key={`${objeto.papelProcesso}_${index}`}>
          {dataArray &&
          dataArray[0] &&
          dataArray[0].papelProcesso &&
          dataArray[0].papelProcesso !== PROCESS_ROLES.Claimant &&
          index == 0 &&
          formStep == 2 ? (
            <p className="mb-4 text-gray-700 leading-none text-base font-semibold">
              Parte representada
            </p>
          ) : null}
          {formStep == 3 && index == 0 ? (
            <p className="mb-4 text-gray-700 leading-none text-base font-semibold">
              Parte acusada
            </p>
          ) : null}
          <div key={index}>
            {Object.entries(objeto).map(([key, value]) =>
              toIgnore.includes(key) ? null : (
                <div key={key}>
                  {[
                    EditProcessKeys.METODO_SOLUCAO,
                    EditProcessKeys.RITO_PROCESSO,
                  ].includes(key as EditProcessKeys) ? (
                    editStates[index] && editStates[index][key] ? (
                      <>
                        <form onSubmit={(e) => handleSubmit(index, key, e)}>
                          <div className="flex gap-2 mb-2.5">
                            <p className="font-semibold text-gray-700 text-sm flex items-center">
                              {sectionTitle(key)}
                            </p>
                          </div>
                          <select
                            required
                            name={key}
                            className={`w-full rounded-md border-gray-300 bg-gray-50 mb-2.5`}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              handleChange(
                                index,
                                key,
                                fetchData(key)[e.target.value].id
                              )
                            }}
                          >
                            {fetchData(key).map(
                              (item: any, indexData: number) => (
                                <option
                                  key={indexData}
                                  selected={
                                    item.id === data[key as keyof typeof data]
                                  }
                                  value={indexData}
                                >
                                  {item.name}
                                </option>
                              )
                            )}
                          </select>
                          <div className="w-full flex">
                            <Button
                              type="submit"
                              className="mb-4"
                              color="primary"
                            >
                              Confirmar
                            </Button>
                          </div>
                        </form>
                      </>
                    ) : (
                      <>
                        <div className="flex gap-2 mb-4">
                          <p className="font-semibold text-gray-700 text-sm flex items-center">
                            {sectionTitle(key)}
                          </p>
                          <button
                            type="button"
                            onClick={() => startEditing(index, key)}
                            className="flex w-8 h-8 justify-center items-center gap-2.5 shrink-0 rounded-[50px] border border-gray-500 hover:scale-110 transition-[2s]"
                          >
                            <PencilIcon />
                          </button>
                        </div>
                        <h6 className="mb-4 font-normal text-gray-700 text-sm">
                          {fetchData(key, parseInt(value))?.name}
                        </h6>
                      </>
                    )
                  ) : key == EditProcessKeys.DOCS ? (
                    <>
                      {value.length > 0 && Array.isArray(value)
                        ? value.map((item, itemIndex) => (
                            <div
                              className="flex flex-col gap-4 mt-5 mb-5"
                              key={`${item.name}_${itemIndex}`}
                            >
                              <p className="font-semibold text-gray-700 text-sm flex items-center">
                                {sectionTitle(key)}
                              </p>
                              <div
                                className={`flex items-center  ${
                                  itemIndex === value.length - 1
                                    ? "mb-0"
                                    : "mb-4"
                                }`}
                              >
                                <ClipIcon />
                                <h6 className="text-primary-400">
                                  {item.name}
                                </h6>
                              </div>
                            </div>
                          ))
                        : null}
                    </>
                  ) : editStates[index] && editStates[index][key] ? (
                    <>
                      <form onSubmit={(e) => handleSubmit(index, key, e)}>
                        <div className="flex gap-2 mb-2.5">
                          <p className="font-semibold text-gray-700 text-sm flex items-center">
                            {sectionTitle(key)}
                          </p>
                        </div>
                        {key == EditProcessKeys.VALOR && (
                          <input
                            name="value"
                            type="text"
                            value={value}
                            className="w-full rounded-md border border-gray-300 bg-gray-50 p-2 h-[38px] mb-3.5 mt-1 text-sm text-gray-900"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const numericValue = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              )
                              const formattedValue =
                                formatCurrency(numericValue)
                              handleChange(index, key, formattedValue)
                            }}
                          />
                        )}
                        {[
                          EditProcessKeys.EMAIL,
                          EditProcessKeys.NOME_COMPLETO,
                          EditProcessKeys.OAB,
                        ].includes(key as EditProcessKeys) && (
                          <input
                            className="w-full rounded-md border border-gray-300 bg-gray-50 p-2 h-[38px] mb-3.5 mt-1 text-sm text-gray-900"
                            id={`${index}-${key}`}
                            value={String(value)}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleChange(index, key, e.target.value)}
                            required
                          />
                        )}
                        {key == EditProcessKeys.TEL && (
                          <input
                            id="phone"
                            name="phone"
                            minLength={16}
                            className="w-full rounded-md border border-gray-300 bg-gray-50 p-2 h-[38px] mb-3.5 mt-1 text-sm text-gray-900"
                            ref={(ref) => (refPhone.current = ref)}
                            value={value}
                            placeholder="(00) 00000-0000"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              if (/[a-zA-Z]/.test(e.target.value)) {
                                return
                              }
                              handleChange(index, key, e.target.value)
                            }}
                          />
                        )}
                        {[EditProcessKeys.RELATO].includes(
                          key as EditProcessKeys
                        ) && (
                          <textarea
                            className="w-full h-[154px] rounded-md border border-gray-300 bg-gray-50 my-2.5 px-3 text-sm text-gray-900"
                            id={`${index}-${key}`}
                            value={String(value)}
                            onChange={(
                              e: React.ChangeEvent<HTMLTextAreaElement>
                            ) => handleChange(index, key, e.target.value)}
                            required
                          />
                        )}
                        <div className="w-full flex">
                          <Button
                            type="submit"
                            className="mb-4"
                            color="primary"
                          >
                            Confirmar
                          </Button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <div className="flex gap-2 mb-4">
                        <p className="font-semibold text-gray-700 text-sm flex items-center">
                          {sectionTitle(key)}
                        </p>
                        {[
                          EditProcessKeys.PAPEL_PROCESSO,
                          EditProcessKeys.CPF_CNPJ,
                        ].includes(key as EditProcessKeys) ? null : (
                          <button
                            type="button"
                            onClick={() => startEditing(index, key)}
                            className="flex w-8 h-8 justify-center items-center gap-2.5 shrink-0 rounded-[50px] border border-gray-500 hover:scale-[110%] transition-[2s]"
                          >
                            <PencilIcon />
                          </button>
                        )}
                      </div>
                      <h6 className="mb-4 font-normal text-gray-700 text-sm">
                        {String(value)}
                      </h6>
                    </>
                  )}
                </div>
              )
            )}
          </div>
        </React.Fragment>
      ))}
    </div>
  )
}
