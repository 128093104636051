import { RoutesConstants as routes } from "@constants/routes"
import { AbilityContext } from "@context/ability.provider"
import ConciliationStatusService from "@services/conciliation-status.service"
import PaymentService from "@services/payment.service"
import { ConciliationStatus } from "@type/conciliation-status.type"
import { Payment, PaymentContextType } from "@type/payment.types"
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

const PaymentsContext = createContext<PaymentContextType>(null!)

export const notPaid = (element: Payment.Item) => {
  return element.status?.code !== "PAID" && element.id !== -1
}

export function PaymentsProvider(props: PropsWithChildren) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const ability = useContext(AbilityContext)
  const isReadPay = ability.can("read", "payments pay")
  const isReadReceive = ability.can("read", "payments receive")

  const [error, setError] = useState({
    post: false,
    get: false,
  })
  const [activeTab, setActiveTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [checkListDownload, setCheckListDownload] = useState<number[]>([])
  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  )

  const [paymentPage, setPaymentPage] = useState<Payment.Paginated>()
  const [receivePage, setReceivePage] = useState<Payment.Paginated>()

  const [filteredPaymentData, setFilteredPaymentData] = useState<
    Payment.Item[]
  >([])
  const [filteredReceiveData, setFilteredReceiveData] = useState<
    Payment.Item[]
  >([])

  const [paymentStatusId, setPaymentStatusId] = useState<number[]>([])
  const [processStatusId, setProcessStatusId] = useState<number[]>([])
  const [paymentStatusList, setPaymentStatusList] = useState<
    Payment.PaymentStatus[]
  >([])
  const [processStatusList, setProcessStatusList] = useState<
    ConciliationStatus[]
  >([])

  const qtdListWithoutPaymentPaid = useMemo(
    () => filteredPaymentData?.filter((x: Payment.Item) => notPaid(x)) || [],
    [filteredPaymentData]
  )

  const qtdListWithoutReceivePaid = useMemo(
    () => filteredReceiveData?.filter((x: Payment.Item) => notPaid(x)) || [],
    [filteredReceiveData]
  )

  const handleFilterProcessStatus = useCallback((selectedOptions: number[]) => {
    setProcessStatusId(selectedOptions)
  }, [])

  const handleFilterPaymentStatus = useCallback((selectedOptions: number[]) => {
    setPaymentStatusId(selectedOptions)
  }, [])

  const fetchData = useCallback(async (page: number) => {
    setLoading(true)

    const paymentStatus = paymentStatusList
      .filter(({ id }) => paymentStatusId.includes(id))
      .map(({ code }) => code)

    const processStatus = processStatusList
      .filter(({ id }) => processStatusId.includes(id))
      .map(({ id }) => id)

    if (isReadReceive) {
      PaymentService.findAllReceives(
        page,
        paymentStatusId.length > 0 ? paymentStatus : undefined,
        processStatusId.length > 0 ? processStatus : undefined
      )
        .then((data) => {
          setReceivePage(data)
          setFilteredReceiveData(data?.items)
        })
        .catch((err) => {
          console.error("findAllReceipts", err)
          setError((prev) => ({ ...prev, get: true }))
        })
        .finally(() => setLoading(false))
    }

    if (isReadPay) {
      PaymentService.findAllPayments(
        page,
        paymentStatusId.length > 0 ? paymentStatus : undefined
      )
        .then((data) => {
          setPaymentPage(data)
          setFilteredPaymentData(data?.items)
        })
        .catch((err) => {
          console.error("findAllPayments", err)
          setError((prev) => ({ ...prev, get: true }))
        })
        .finally(() => setLoading(false))
    }
  }, [])

  const fetchRequest = useCallback(async () => {
    try {
      if (isReadReceive) {
        const receiptList =
          await PaymentService.fetchReceiveList(checkListDownload)

        window.open(receiptList, "_blank")
      }

      if (isReadPay) {
        const paymentList =
          await PaymentService.fetchPaymentList(checkListDownload)
        window.open(paymentList, "_blank")
      }
    } catch (err) {
      console.error("Error fetching request:", err)
      setError((prev) => ({ ...prev, post: true }))
    }
  }, [])

  const fetchStatus = useCallback(async () => {
    try {
      if (isReadReceive) {
        const receiveStatusData = await ConciliationStatusService.findAll()
        setProcessStatusList(receiveStatusData)
      }

      const paymentStatusData = await PaymentService.fetchPaymentsStatus()
      setPaymentStatusList(paymentStatusData)
    } catch (err) {
      console.error("Error fetching status:", err)
      setError((prev) => ({ ...prev, get: true }))
    }
  }, [])

  const checkAllItems = (
    list: Payment.Item[] | undefined,
    condition: (item: Payment.Item) => boolean,
    setList: React.Dispatch<React.SetStateAction<number[]>>
  ) => {
    if (!list) {
      return
    }

    if (checkListDownload.length === list.filter(condition).length) {
      setList([])
      return
    }

    const newList: number[] = []
    list.forEach((element) => {
      if (notPaid(element)) {
        newList.push(element.id)
      }
    })

    setList(newList)
  }

  const checkAllPayment = () => {
    checkAllItems(filteredPaymentData, notPaid, setCheckListDownload)
  }

  const checkAllReceive = () => {
    checkAllItems(filteredReceiveData, notPaid, setCheckListDownload)
  }

  const isSemiChecked = (list: Payment.Item[]) => {
    return (
      !!checkListDownload.length && checkListDownload.length !== list?.length
    )
  }

  const isSemiPaymentChecked = () => isSemiChecked(qtdListWithoutPaymentPaid)
  const isSemiReceiveChecked = () => isSemiChecked(qtdListWithoutReceivePaid)

  const onCheck = (item: Payment.Item) => {
    const insertItemCheck = () => {
      return [...checkListDownload, item.id]
    }

    const removeItemCheck = () => {
      return checkListDownload.filter((x) => x !== item.id)
    }

    if (checkListDownload.includes(item.id)) {
      return setCheckListDownload(removeItemCheck())
    }

    return setCheckListDownload(insertItemCheck())
  }

  const onNavigatePage = useCallback((toPage: number) => {
    setCurrentPage(toPage)
    navigate({ pathname: routes.payment, search: `?page=${toPage}` })
    fetchData(toPage)
  }, [])

  const updateFilteredData = (
    page: Payment.Paginated | undefined,
    tab: number,
    setFilteredData: React.Dispatch<React.SetStateAction<Payment.Item[]>>,
    sumAmount: number
  ) => {
    if (page?.items && page.items.length && activeTab === tab) {
      const sumRow = {
        id: -1,
        amount: sumAmount,
        conciliation: {
          code: `Total (${checkListDownload.length || 0} ${
            page.items.length > 1 ? "processos" : "processo"
          })`,
          defendant: "",
        },
      } as Payment.Item

      setFilteredData(sumAmount ? [...page.items, sumRow] : [...page.items])
    }
  }

  const value = {
    updateFilteredData,
    onNavigatePage,
    onCheck,
    isSemiReceiveChecked,
    isSemiPaymentChecked,
    checkAllReceive,
    checkAllPayment,
    fetchStatus,
    fetchRequest,
    fetchData,
    handleFilterProcessStatus,
    handleFilterPaymentStatus,
    error,
    setActiveTab,
    loading,
    currentPage,
    paymentPage,
    receivePage,
    checkListDownload,
    setFilteredPaymentData,
    setFilteredReceiveData,
    activeTab,
    paymentStatusList,
    paymentStatusId,
    processStatusList,
    processStatusId,
    setCheckListDownload,
    filteredPaymentData,
    filteredReceiveData,
  }

  return (
    <PaymentsContext.Provider value={value}>
      {props.children}
    </PaymentsContext.Provider>
  )
}

export function usePayments() {
  return useContext(PaymentsContext)
}
