import React from "react"

export default function DatabaseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M3.6001 14.4V18C3.6001 19.9884 7.3609 21.6 12.0001 21.6C16.6393 21.6 20.4001 19.9884 20.4001 18V14.4C20.4001 16.3884 16.6393 18 12.0001 18C7.3609 18 3.6001 16.3884 3.6001 14.4Z"
        fill="#6B7280"
      />
      <path
        d="M3.6001 8.40002V12C3.6001 13.9884 7.3609 15.6 12.0001 15.6C16.6393 15.6 20.4001 13.9884 20.4001 12V8.40002C20.4001 10.3884 16.6393 12 12.0001 12C7.3609 12 3.6001 10.3884 3.6001 8.40002Z"
        fill="#6B7280"
      />
      <path
        d="M20.4001 6.00002C20.4001 7.98842 16.6393 9.60002 12.0001 9.60002C7.3609 9.60002 3.6001 7.98842 3.6001 6.00002C3.6001 4.01162 7.3609 2.40002 12.0001 2.40002C16.6393 2.40002 20.4001 4.01162 20.4001 6.00002Z"
        fill="#111928"
      />
    </svg>
  )
}
