import loadingAnimation from "@assets/animations/loading_animation.json"
import Lottie from "lottie-react"

function FullLoading() {
  return (
    <div className="h-full flex flex-col justify-center items-center bg-logo-loading bg-center bg-no-repeat">
      <Lottie
        autoplay={true}
        className="h-20	w-32 justify-center"
        animationData={loadingAnimation}
        loop={true}
      />
      <p className="text-sm font-bold">Por favor, aguarde...</p>
    </div>
  )
}

export default FullLoading
