import { ConciliationDetail } from "@features/conciliation/types/conciliation-detail.type"
import {
  BuildingLibraryIcon,
  NewspaperIcon,
  ShieldCheckIcon,
  UsersIcon,
} from "@heroicons/react/24/solid"
import { ConciliationChatStates } from "@type/conciliation-chat-message.type"
import format from "date-fns/format"
import { pt } from "date-fns/locale"
import { ReactNode } from "react"
import tailwindColors from "tailwindcss/colors"
import ConciliationPartsRender from "../../components/render-conciliation-parts/render.conciliation.parts"

type ConciliationInformationType = {
  conciliationData: ConciliationDetail
  chatStates: ConciliationChatStates
  includeClaimants?: ReactNode
  claimantsModal?: ReactNode
  includeRequireds?: ReactNode
  includeDefineResponsible?: ReactNode
  includeSpecialist?: ReactNode
  requiredsModal?: ReactNode
}

export default function ConciliationInformation({
  conciliationData,
  chatStates,
  includeClaimants,
  claimantsModal,
  includeRequireds,
  includeDefineResponsible,
  includeSpecialist,
  requiredsModal,
}: ConciliationInformationType) {
  return (
    <>
      <p className="text-2xl text-gray-600 font-semibold">Informações gerais</p>
      <div className="flex items-center gap-2">
        <NewspaperIcon
          height={20}
          width={20}
          color={tailwindColors.gray[400]}
        />
        <p className="text-gray-600 my-6 text-lg">Sobre o processo</p>
      </div>
      <div className="text-gray-600 mr-6 ml-7 mb-8">
        <p className="mb-2">
          <strong>Início: </strong>
          {format(
            new Date(conciliationData.beginning),
            "eeee, dd 'de' MMMM 'de' yyyy, HH'h'mm",
            { locale: pt }
          )}
        </p>
        {conciliationData.ending && (
          <p className="mb-6">
            <strong>Encerramento: </strong>
            {conciliationData.ending
              ? format(
                  new Date(conciliationData.ending),
                  "eeee, dd 'de' MMMM 'de' yyyy, HH'h'mm",
                  { locale: pt }
                )
              : ""}
          </p>
        )}
        <p className="mb-2">
          <strong>Valor: </strong>
          {conciliationData && conciliationData.value}
        </p>
        <p>
          <strong>Resumo do processo: </strong>
          {conciliationData && conciliationData.processSummary}
        </p>
      </div>
      <div className="border-b my-8"></div>
      <p className="text-2xl text-gray-600 mb-8 font-semibold">
        Partes envolvidas
      </p>
      <div>
        <div
          className={`flex lg:flex-row md:flex-row sm:flex-col flex-col ${
            chatStates.desktopOpen ? "" : "w-2/3"
          } justify-between text-gray-600`}
        >
          <div className="w-80">
            <div className="flex items-center gap-2">
              <UsersIcon
                width={20}
                height={20}
                color={tailwindColors.gray[400]}
              />
              <p className="text-lg text-gray-600">
                <strong>Requerentes</strong>
              </p>
              {includeClaimants}
            </div>
            {claimantsModal}
            <div className="ml-7">
              <ConciliationPartsRender
                data={conciliationData && conciliationData.claimants}
              />
            </div>
          </div>
          <div className="w-80">
            <div className="flex items-center gap-2 lg:mt-0 md:mt-0 sm:mt-6 mt-6">
              <UsersIcon
                width={20}
                height={20}
                color={tailwindColors.gray[400]}
              />
              <p className="text-lg text-gray-600">
                <strong>Parte requerida</strong>
              </p>
              {includeRequireds}
            </div>
            {requiredsModal}
            <div className="ml-7">
              <ConciliationPartsRender
                data={conciliationData && conciliationData.requireds}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border-b my-8"></div>
      <p className="text-2xl text-gray-600 mb-8 font-semibold">
        Responsáveis e especialistas
      </p>
      <div>
        <div
          className={`flex ${
            chatStates.desktopOpen ? "" : "w-2/3"
          } justify-between text-gray-600 lg:flex-row md:flex-row sm:flex-col flex-col`}
        >
          <div className="w-80">
            <div className="flex items-center gap-2">
              <BuildingLibraryIcon
                width={20}
                height={20}
                color={tailwindColors.gray[400]}
                className="pb-1"
              />
              <p className="text-lg text-gray-600">
                <strong>Responsáveis da secretaria</strong>
              </p>
              {includeDefineResponsible}
            </div>
            <div className="ml-7">
              {conciliationData &&
              conciliationData.secretary &&
              conciliationData.secretary.length > 0 ? (
                <ConciliationPartsRender
                  data={conciliationData && conciliationData.secretary}
                />
              ) : (
                <p className="text-sm text-gray-600 mt-6 mb-2">
                  Assim que a pessoa responsável der início à triagem do seu
                  processo, as informações dela serão mostradas aqui.
                </p>
              )}
            </div>
          </div>
          <div className="w-80">
            <div className="flex items-center gap-2 lg:mt-0 md:mt-0 sm:mt-6 mt-6">
              <ShieldCheckIcon
                width={20}
                height={20}
                color={tailwindColors.gray[400]}
              />
              <p className="text-lg text-gray-600">
                <strong>Especialistas</strong>
              </p>
              {includeSpecialist}
            </div>
            <div className="ml-7">
              {conciliationData &&
              conciliationData.specialists &&
              conciliationData.specialists.length > 0 ? (
                <ConciliationPartsRender
                  data={conciliationData && conciliationData.specialists}
                />
              ) : (
                <p className="text-sm text-gray-600 mt-6 mb-2">
                  Especialistas são os árbitros que agem como juízes e definem a
                  sentença. Quando essa pessoa for sorteada para o processo, os
                  dados dela aparecerão aqui.
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
