export function formatPostalCode(string: string): string {
  if (!string) {
    return ""
  }

  string = string.replace(/\D/g, "")

  if (string.length === 8) {
    string = string.replace(/(\d{5})(\d{3})/, "$1-$2")
  }

  return string
}
