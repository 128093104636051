import { Assignable, Conciliation } from "@type/conciliation.type"
import axios, { AxiosError, AxiosResponse } from "axios"
import axiosBase from "./base.service"

const CONCILIATION_PATH = "/conciliation"

const ConciliationService = {
  findAll: async (
    page: number = 1,
    allFilter: string,
    statusId: number[] | undefined = undefined,
    search: string | undefined = undefined,
    startCreatedAt?: Date,
    endCreatedAt?: Date,
    startSpecialistAppointmentDate?: Date,
    endSpecialistAppointmentDate?: Date
  ): Promise<Conciliation.Paginated> => {
    const response = await axiosBase.get(CONCILIATION_PATH, {
      params: {
        page,
        filter: allFilter,
        status: statusId?.toString(),
        search: search,
        startCreatedAt,
        endCreatedAt,
        startSpecialistAppointmentDate,
        endSpecialistAppointmentDate,
      },
    })
    return response.data.data
  },
  getAssignable: async (type: Assignable.Type): Promise<Assignable.Item[]> => {
    const response = await axiosBase.get(`/users/assignable?type=${type}`)
    return response.data.data
  },
  accessPermission: async (code: string) => {
    try {
      const response: AxiosResponse = await axiosBase.get(
        `/conciliation/${code}`
      )

      if (response.status === 403) {
        throw new Error("Access not permitted")
      }

      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError: AxiosError = error
        if (axiosError.response && axiosError.response.status === 403) {
          throw new Error("Access not permitted")
        }
        throw axiosError
      }
      throw error
    }
  },
  getReasons: async (): Promise<Conciliation.Reason[]> => {
    const response = await axiosBase.get(`${CONCILIATION_PATH}/reason/complete`)
    return response.data.data
  },
  postConciliation: async ({
    applicants,
    applicantsAccused,
    generalInfo,
  }: Conciliation.Payload): Promise<void> => {
    const response = await axiosBase.post("/conciliation", {
      applicants,
      applicantsAccused,
      generalInfo,
    })
    return response.data.data
  },
}

export default ConciliationService
