import { ReactNode } from "react"

type CardComponentProps = {
  header: ReactNode
  body: ReactNode
  footer: ReactNode
  bodyWithPadding?: boolean
}

export const CardComponent = ({
  header,
  body,
  footer,
  bodyWithPadding,
}: CardComponentProps) => {
  return (
    <div className="my-8 h-fit bg-white min-h-[80vh] border border-gray-200 md:rounded-lg shadow py-4 flex flex-col">
      <div className="px-4">{header}</div>
      <div
        className={`relative overflow-x-auto pb-4 grow ${
          bodyWithPadding ? "mx-4" : "mx-0 md:mx-4"
        }`}
      >
        {body}
      </div>
      <div className="px-4">{footer}</div>
    </div>
  )
}
