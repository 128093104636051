export const modalConfirmTheme = (isMobile: boolean, width?: string) => ({
  overlay: {
    background: "rgba(0, 0, 0, 0.20)",
    zIndex: 9999,
  },
  content: {
    zIndex: 9999,
    width: isMobile ? "auto" : width ?? "514px",
    inset: "1rem",
    margin: "auto",
    padding: "0px",
    height: "fit-content",
    border: "1px solid #E5E7EB",
    borderRadius: "8px",
    boxShadow:
      "0px 4px 6px 0px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
  },
})
