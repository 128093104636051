import { ExclamationCircleIcon } from "@assets/icons"
const { VITE_CONTACT_WHATSAPP } = import.meta.env

const ErrorComponent = () => {
  return (
    <div className="py-16 w-full flex justify-center items-center flex-col">
      <div className="flex items-center justify-center bg-red-50 h-[48px] w-[48px] rounded-full mb-4">
        <ExclamationCircleIcon />
      </div>
      <p className="text-sm text-center text-primary-900 font-medium line-h[2rem]">
        Desculpe, aconteceu um erro no nosso sistema. <br />
        Por favor, tente novamente dentro de alguns minutos. <br />
        Caso o erro persista,{" "}
        <a
          className="underline cursor-pointer"
          target="_blank"
          href={VITE_CONTACT_WHATSAPP}
          rel="noreferrer"
        >
          fale conosco
        </a>
        .
      </p>
    </div>
  )
}

export default ErrorComponent
