import AuthService from "@services/auth.service"
import { SignupDto } from "@type/signup.type"
import { UserAuthenticated } from "@type/user.type"
import { ValidatorError } from "@type/validator-error.type"
import { AxiosError } from "axios"
import TokenStorage from "../data/token.storage"
import { mapToValidateErrors } from "../utils/helpers/mapper-errors.helper"

const authProvider = {
  isAuthenticated() {
    return TokenStorage.getJWTToken() !== null
  },
  getUserDecoded(): UserAuthenticated | null {
    const token = TokenStorage.getJWTToken()
    if (token !== null) {
      return TokenStorage.parseJWTToken(token)
    }
    return null
  },
  async requestToken(
    username: string,
    callback: (error: boolean, message: string | null) => void
  ) {
    try {
      await AuthService.requestToken(username)
      callback(false, null)
    } catch (error) {
      if (error instanceof AxiosError) {
        callback(true, error.response?.data?.message)
      } else {
        callback(true, null)
      }
    }
  },
  async verifyToken(
    token: string,
    callback: (error: boolean, userAuthenticated?: UserAuthenticated) => void
  ) {
    try {
      const resp = await AuthService.verifyToken(token)
      const accessToken = resp.accessToken
      const refreshToken = resp.refreshToken
      TokenStorage.setJWTToken(
        accessToken.token,
        parseInt(accessToken.expiresIn)
      )
      TokenStorage.setJWTRefreshToken(
        refreshToken.token,
        parseInt(refreshToken.expiresIn)
      )
      const userAuthenticated = this.getUserDecoded()
      callback(false, userAuthenticated as UserAuthenticated)
    } catch (error) {
      callback(true)
    }
  },
  async verifyEmail(
    token: string,
    callback: (error: boolean, userAuth?: UserAuthenticated) => void
  ) {
    try {
      const resp = await AuthService.verifyEmail(token)
      const accessToken = resp.accessToken
      const refreshToken = resp.refreshToken
      TokenStorage.setJWTToken(
        accessToken.token,
        parseInt(accessToken.expiresIn)
      )
      TokenStorage.setJWTRefreshToken(
        refreshToken.token,
        parseInt(refreshToken.expiresIn)
      )
      const userAuthenticated = this.getUserDecoded()
      callback(false, userAuthenticated as UserAuthenticated)
    } catch (error) {
      callback(true)
    }
  },
  signout(callback: VoidFunction) {
    TokenStorage.clearTokens()
    setTimeout(callback, 100)
  },
  async signup(
    data: SignupDto,
    callback: (error: boolean, errors: Array<ValidatorError>) => void
  ) {
    try {
      await AuthService.signUp(data)
      callback(false, [])
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.response?.data?.message) {
        callback(true, mapToValidateErrors(error.response.data.message))
      } else {
        callback(true, [])
      }
    }
  },
  async checkAuthenticated(callback: (error: boolean) => void) {
    try {
      await AuthService.isAuthenticated()
      callback(false)
    } catch (error) {
      callback(true)
    }
  },
}

export { authProvider }
