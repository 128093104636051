import React from "react"

type TabProps = {
  number: string
  title: string
}

function Tab(props: TabProps) {
  return (
    <>
      <div
        className={`lg:mb-0 mb-4 flex w-8 h-8 justify-center items-center gap-2.5 shrink-0 rounded-[50px] border border-gray-500 mr-2.5`}
      >
        {props.number}
      </div>
      <p
        className={`lg:mb-0 mb-4 lg:w-[130px] w-[110px] text-sm lg:text-base text-left font-medium`}
      >
        {props.title}
      </p>
    </>
  )
}

export default Tab
