import React from "react"

type ClipBoardCheckIconType = {
  width?: string
  height?: string
  first_color?: string
  second_color?: string
}

export default function ClipBoardCheckIcon({
  first_color = "#31246D",
  second_color = "#111928",
  height = "17",
  width = "16",
}: ClipBoardCheckIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        d="M7.1999 2.09998C6.98773 2.09998 6.78425 2.18426 6.63422 2.33429C6.48419 2.48432 6.3999 2.6878 6.3999 2.89998C6.3999 3.11215 6.48419 3.31563 6.63422 3.46566C6.78425 3.61569 6.98773 3.69998 7.1999 3.69998H8.7999C9.01208 3.69998 9.21556 3.61569 9.36559 3.46566C9.51562 3.31563 9.5999 3.11215 9.5999 2.89998C9.5999 2.6878 9.51562 2.48432 9.36559 2.33429C9.21556 2.18426 9.01208 2.09998 8.7999 2.09998H7.1999Z"
        fill={first_color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19995 4.50002C3.19995 4.07568 3.36852 3.66871 3.66858 3.36865C3.96864 3.0686 4.3756 2.90002 4.79995 2.90002C4.79995 3.53654 5.05281 4.14699 5.5029 4.59708C5.95298 5.04717 6.56343 5.30002 7.19995 5.30002H8.79995C9.43647 5.30002 10.0469 5.04717 10.497 4.59708C10.9471 4.14699 11.2 3.53654 11.2 2.90002C11.6243 2.90002 12.0313 3.0686 12.3313 3.36865C12.6314 3.66871 12.8 4.07568 12.8 4.50002V13.3C12.8 13.7244 12.6314 14.1313 12.3313 14.4314C12.0313 14.7315 11.6243 14.9 11.2 14.9H4.79995C4.3756 14.9 3.96864 14.7315 3.66858 14.4314C3.36852 14.1313 3.19995 13.7244 3.19995 13.3V4.50002ZM10.9656 9.06562C11.1113 8.91474 11.1919 8.71266 11.1901 8.5029C11.1883 8.29315 11.1041 8.0925 10.9558 7.94417C10.8075 7.79584 10.6068 7.71171 10.3971 7.70989C10.1873 7.70806 9.98523 7.7887 9.83435 7.93442L7.19995 10.5688L6.16555 9.53442C6.01467 9.3887 5.81259 9.30806 5.60283 9.30989C5.39307 9.31171 5.19242 9.39584 5.0441 9.54417C4.89577 9.6925 4.81163 9.89315 4.80981 10.1029C4.80799 10.3127 4.88862 10.5147 5.03435 10.6656L6.63435 12.2656C6.78437 12.4156 6.98782 12.4999 7.19995 12.4999C7.41208 12.4999 7.61553 12.4156 7.76555 12.2656L10.9656 9.06562Z"
        fill={second_color}
      />
    </svg>
  )
}
