import FullSuccessModal from "@components/full-success-modal"
import InputMaskCustom from "@components/input-mask-custom"
import { RoutesConstants as routes } from "@constants/routes"
import { useAuth } from "@hooks/use-auth"
import PersonService from "@services/person.service"
import { cpf } from "cpf-cnpj-validator"
import {
  Alert,
  Button,
  Checkbox,
  Label,
  Spinner,
  TextInput,
} from "flowbite-react"
import { useFormik } from "formik"
import Handlebars from "handlebars"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import * as Yup from "yup"

interface SignupFormValues {
  fullName: string
  document: string
  phone: string
  email: string
  agree: boolean
}
const phoneRegExp = /(\(\d{2}\)\s)(\d{4,5}-\d{4})/g
const textValidationDefault =
  "É necessário preencher este campo para continuar."
const cpfRequiredMessage =
  "O CPF é um dado essencial para nossos processos, assim como no tribunal de justiça. Não se preocupe, nunca iremos compartilhar seus dados."
const SignupSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Digite o nome completo")
    .required("É necessário preencher este campo para continuar.")
    .test(
      "test-invalid-full-name",
      "O campo deve conter apenas letras e espaços.",
      (fullName) => /^[\p{L}\s]+$/u.test(fullName)
    ),
  email: Yup.string()
    .email("Digite um e-mail válido")
    .required(textValidationDefault),
  document: Yup.string()
    .required(cpfRequiredMessage)
    .test(
      "test-invalid-cpf",
      "Algo parece estar errado. Por favor, verifique o número de CPF e tente novamente.",
      (cpfValue) => cpf.isValid(cpfValue)
    ),
  phone: Yup.string()
    .matches(phoneRegExp, "Digite um telefone válido")
    .required(textValidationDefault),
  agree: Yup.boolean().oneOf([true], "Você precisa aceitar os termos de uso"),
})

const SignupProperties: { [index: string]: string } = {
  fullName: "Nome completo",
  document: "CPF",
  phone: "Telefone",
  email: "e-mail",
  agree: "Aceite",
}

const MASK_DOCUMENT = "999.999.999-99"
const MASK_PHONE = "(99) 99999-9999"

interface TemplateValidateProperties {
  property: string
  linkSignin: string
}

function SignUpPage() {
  const [isLoadingSignup, setLoadingSignup] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [alreadyTrySubmit, setAlreadyTrySubmit] = useState(false)
  const { signup } = useAuth()
  const initialValues: SignupFormValues = {
    fullName: "",
    document: "",
    phone: "",
    email: "",
    agree: false,
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: alreadyTrySubmit,
    validateOnChange: alreadyTrySubmit,
    validationSchema: SignupSchema,
    onSubmit: (values, { setFieldError }) => {
      setLoadingSignup(true)
      signup(
        {
          document: values.document,
          email: values.email,
          name: values.fullName,
          phone: values.phone,
        },
        (error, errors) => {
          errors.forEach((e) => {
            setFieldError(e.property, e.message)
          })
          setLoadingSignup(false)
          setShowAlertError(error && errors.length === 0)
          setShowSuccess(!error)
        }
      )
    },
  })

  const bindFieldErrorFromTemplate = (
    property: string,
    templateText: string
  ) => {
    const template =
      Handlebars.compile<TemplateValidateProperties>(templateText)
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: template({
            property: SignupProperties[property],
            linkSignin: `<a class='underline hover:no-underline' href='${routes.signIn}'>Acesse sua conta aqui.</a>`,
          }),
        }}
      ></div>
    )
  }

  useEffect(() => {
    const fetchDocument = async () => {
      const cpfValueLength = formik.values.document.replace(/_/g, "")
      if (cpfValueLength.length === 14 && formik.values.fullName.length === 0) {
        if (cpf.isValid(formik.values.document)) {
          PersonService.findNameByCpf(formik.values.document).then(
            (personName) => {
              if (!personName) {
                formik.setFieldError("document", "CPF inválido ou incorreto")
                return
              }
              formik.setFieldError("document", undefined)

              formik.setFieldValue("fullName", personName, false)
            }
          )
        } else {
          SignupSchema.validateAt("document", {
            document: formik.values.document,
          }).catch((err) => {
            formik.setFieldError(err.path, err.message)
          })
        }
      }
    }
    fetchDocument()
  }, [formik.values.document])

  return (
    <div className="relative">
      <FullSuccessModal
        title="Cadastro efetuado com sucesso!"
        description="Enviamos um link para o seu e-mail para um acesso mais seguro e sem necessidade de senha."
        show={showSuccess}
      />

      <form
        onSubmit={formik.handleSubmit}
        onReset={formik.handleReset}
        autoComplete="off"
        className="space-y-4 p-1 md:space-y-6 md:p-2 md:max-w-[560px] sm:w-full"
      >
        <h1 className="text-xl font-bold text-gray-900 dark:text-white flex items-center flex-row">
          Crie uma conta
        </h1>
        {showAlertError && (
          <Alert
            color="failure"
            onDismiss={() => {
              setShowAlertError(false)
            }}
          >
            <span>Ocorreu um erro, por favor tente novamente mais tarde.</span>
          </Alert>
        )}

        <div>
          <Label
            color={formik.errors.document ? "failure" : ""}
            htmlFor="document"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            CPF*
          </Label>

          <InputMaskCustom
            mask={MASK_DOCUMENT}
            disabled={isLoadingSignup}
            {...formik.getFieldProps("document")}
          >
            {() => (
              <TextInput
                contentEditable={true}
                color={formik.errors.document ? "failure" : ""}
                disabled={isLoadingSignup}
                helperText={
                  formik.errors.document
                    ? bindFieldErrorFromTemplate(
                        "document",
                        formik.errors.document
                      )
                    : cpfRequiredMessage
                }
                type="text"
                id="document"
                placeholder="000.000.000-00"
                {...formik.getFieldProps("document")}
              />
            )}
          </InputMaskCustom>
        </div>

        {(formik.values.fullName.length > 0 ||
          formik.touched["fullName"] === true) && (
          <div>
            <Label
              color={formik.errors.fullName ? "failure" : ""}
              htmlFor="fullName"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Nome completo
            </Label>
            <TextInput
              color={formik.errors.fullName ? "failure" : ""}
              disabled={isLoadingSignup}
              type="text"
              helperText={formik.errors.fullName ? formik.errors.fullName : ""}
              id="fullName"
              placeholder="Seu nome completo"
              {...formik.getFieldProps("fullName")}
            />
          </div>
        )}

        <div>
          <Label
            color={formik.errors.email ? "failure" : ""}
            htmlFor="email"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            E-mail*
          </Label>
          <TextInput
            color={formik.errors.email ? "failure" : ""}
            type="email"
            disabled={isLoadingSignup}
            id="email"
            helperText={
              formik.errors.email
                ? bindFieldErrorFromTemplate("email", formik.errors.email)
                : ""
            }
            placeholder="nome@exemplo.com"
            {...formik.getFieldProps("email")}
          />
        </div>

        <div>
          <Label
            htmlFor="phone"
            color={formik.errors.phone ? "failure" : ""}
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          >
            Celular/Whatsapp*
          </Label>
          <InputMaskCustom
            mask={MASK_PHONE}
            disabled={isLoadingSignup}
            {...formik.getFieldProps("phone")}
          >
            {() => (
              <TextInput
                color={formik.errors.phone ? "failure" : ""}
                type="phone"
                id="phone"
                disabled={isLoadingSignup}
                helperText={formik.errors.phone ? formik.errors.phone : ""}
                placeholder="(DDD) + número"
                {...formik.getFieldProps("phone")}
              />
            )}
          </InputMaskCustom>
        </div>

        <>
          <div className="flex items-center gap-2">
            <Checkbox
              {...formik.getFieldProps("agree")}
              id="accept"
              disabled={isLoadingSignup}
              defaultChecked={formik.initialValues.agree}
            />
            <Label
              color={formik.errors.agree ? "failure" : "gray-500"}
              htmlFor="accept"
            >
              Li e aceito os{" "}
              <a href="#" className="underline hover:no-underline">
                Termos gerais de uso
              </a>
            </Label>
          </div>
          {formik.errors.agree && (
            <small className="text-sm text-red-600 dark:text-red-500">
              {formik.errors.agree}
            </small>
          )}
        </>
        <Button
          color="primary"
          onClick={() => {
            setAlreadyTrySubmit(true)
          }}
          className="w-full"
          type="submit"
          disabled={isLoadingSignup}
        >
          {isLoadingSignup ? (
            <>
              <Spinner />
              <span className="pl-3">Aguarde...</span>
            </>
          ) : (
            `Confirmar e-mail`
          )}
        </Button>
        <div className="text-sm font-light text-gray-500 dark:text-gray-400">
          Já possui uma conta?{" "}
          <Link
            to={routes.signIn}
            className="font-medium text-primary-600 hover:underline dark:text-primary-500"
          >
            Entre aqui
          </Link>
        </div>
      </form>
    </div>
  )
}

export default SignUpPage
