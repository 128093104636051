import React from "react"

type DocumentIconType = {
  size?: string
  color?: string
}
export default function DocumentIcon({
  color = "#233876",
  size = "24",
}: DocumentIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.79999 4.80002C4.79999 4.1635 5.05284 3.55306 5.50293 3.10297C5.95302 2.65288 6.56347 2.40002 7.19999 2.40002H12.7032C13.3397 2.40016 13.95 2.65311 14.4 3.10322L18.4968 7.20002C18.9469 7.65001 19.1999 8.26036 19.2 8.89682V19.2C19.2 19.8365 18.9471 20.447 18.497 20.8971C18.047 21.3472 17.4365 21.6 16.8 21.6H7.19999C6.56347 21.6 5.95302 21.3472 5.50293 20.8971C5.05284 20.447 4.79999 19.8365 4.79999 19.2V4.80002ZM7.19999 12C7.19999 11.6818 7.32642 11.3765 7.55146 11.1515C7.7765 10.9265 8.08173 10.8 8.39999 10.8H15.6C15.9182 10.8 16.2235 10.9265 16.4485 11.1515C16.6736 11.3765 16.8 11.6818 16.8 12C16.8 12.3183 16.6736 12.6235 16.4485 12.8486C16.2235 13.0736 15.9182 13.2 15.6 13.2H8.39999C8.08173 13.2 7.7765 13.0736 7.55146 12.8486C7.32642 12.6235 7.19999 12.3183 7.19999 12ZM8.39999 15.6C8.08173 15.6 7.7765 15.7265 7.55146 15.9515C7.32642 16.1765 7.19999 16.4818 7.19999 16.8C7.19999 17.1183 7.32642 17.4235 7.55146 17.6486C7.7765 17.8736 8.08173 18 8.39999 18H15.6C15.9182 18 16.2235 17.8736 16.4485 17.6486C16.6736 17.4235 16.8 17.1183 16.8 16.8C16.8 16.4818 16.6736 16.1765 16.4485 15.9515C16.2235 15.7265 15.9182 15.6 15.6 15.6H8.39999Z"
        fill={color}
      />
    </svg>
  )
}
