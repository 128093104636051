export namespace AdminUser {
  export interface Item {
    id: string
    fullName: string
    document: string
    type: AdminUser.Type
    email: string
    phone: string
    licenseNumber: string
    kindOfPerson: AdminUser.KindPerson
    profile: AdminUser.Profile
    status: AdminUser.Status
    address: AdminUser.Address
  }

  export interface Params {
    type?: AdminUser.Type
    status?: AdminUser.Status
    profile?: string
    search?: string
    page?: number
  }

  export enum Profile {
    SPECIALIST = "SPECIALIST",
    SECRETARY = "SECRETARY",
  }

  export enum All {
    ALL = "ALL",
  }

  export enum Status {
    ENABLE = "ENABLE",
    DISABLE = "DISABLE",
  }

  export enum KindPerson {
    PF = "PF",
    PJ = "PJ",
  }

  export enum Type {
    END = "END",
    INTERNAL = "INTERNAL",
  }

  export type PaginatedAdminUsers = {
    currentPage: number
    pages: number
    count: number
    items: AdminUser.Item[]
    itemsPerPage: number
  }

  export type Address = {
    complement: string
    country: string
    neighborhood: string
    number: string
    postalCode: string
    state: string
    streetName: string
    city: string
  }
}

export namespace AdminModels {
  export enum CategoryCodes {
    CONCILIATION = "CONCILIATION",
    PARTS = "PARTS",
    INFORMATION = "INFO",
  }

  export interface VariableCodes {
    code: string
    variableName: string
    category: CategoryCodes
  }

  export interface Item {
    id: string
    title: string
    status: AdminModels.Status
    template: string
  }

  export interface Params {
    status?: AdminModels.Status
    search?: string
    page?: number
    all?: boolean
  }

  export enum Status {
    PUBLISH_ALL = "PUBLISH_ALL",
    PUBLISH_INTERNAL = "PUBLISH_INTERNAL",
    DRAFT = "DRAFT",
  }

  export type PaginatedAdminModels = {
    currentPage: number
    pages: number
    count: number
    items: AdminModels.Item[]
    itemsPerPage: number
  }

  export enum All {
    ALL = "ALL",
  }
}
