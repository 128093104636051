import React from "react"
type UserCircleIconType = {
  width?: string
  height?: string
  color?: string
}

export default function UserCircleIcon({
  color = "#111928",
  height = "15",
  width = "15",
}: UserCircleIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4858 7.37606C13.4858 8.92837 12.8691 10.4171 11.7715 11.5147C10.6738 12.6124 9.18508 13.229 7.63277 13.229C6.08046 13.229 4.59173 12.6124 3.49409 11.5147C2.39644 10.4171 1.77979 8.92837 1.77979 7.37606C1.77979 5.82375 2.39644 4.33502 3.49409 3.23737C4.59173 2.13972 6.08046 1.52307 7.63277 1.52307C9.18508 1.52307 10.6738 2.13972 11.7715 3.23737C12.8691 4.33502 13.4858 5.82375 13.4858 7.37606ZM9.09602 5.18119C9.09602 5.56927 8.94186 5.94145 8.66744 6.21586C8.39303 6.49027 8.02085 6.64443 7.63277 6.64443C7.2447 6.64443 6.87251 6.49027 6.5981 6.21586C6.32369 5.94145 6.16953 5.56927 6.16953 5.18119C6.16953 4.79311 6.32369 4.42093 6.5981 4.14652C6.87251 3.8721 7.2447 3.71794 7.63277 3.71794C8.02085 3.71794 8.39303 3.8721 8.66744 4.14652C8.94186 4.42093 9.09602 4.79311 9.09602 5.18119ZM7.63277 8.10768C6.93232 8.10754 6.24655 8.3085 5.65696 8.68669C5.06738 9.06488 4.59876 9.60438 4.30681 10.2411C4.71848 10.72 5.22886 11.1042 5.80294 11.3674C6.37703 11.6306 7.00124 11.7665 7.63277 11.7658C8.26431 11.7665 8.88852 11.6306 9.4626 11.3674C10.0367 11.1042 10.5471 10.72 10.9587 10.2411C10.6668 9.60438 10.1982 9.06488 9.60858 8.68669C9.019 8.3085 8.33323 8.10754 7.63277 8.10768Z"
        fill={color}
      />
    </svg>
  )
}
