import React from "react"

export default function CheckIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="check">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7069 5.29279C16.8944 5.48031 16.9997 5.73462 16.9997 5.99979C16.9997 6.26495 16.8944 6.51926 16.7069 6.70679L8.70692 14.7068C8.51939 14.8943 8.26508 14.9996 7.99992 14.9996C7.73475 14.9996 7.48045 14.8943 7.29292 14.7068L3.29292 10.7068C3.11076 10.5182 3.00997 10.2656 3.01224 10.0034C3.01452 9.74119 3.11969 9.49038 3.3051 9.30497C3.49051 9.11956 3.74132 9.01439 4.00352 9.01211C4.26571 9.00983 4.51832 9.11063 4.70692 9.29279L7.99992 12.5858L15.2929 5.29279C15.4804 5.10532 15.7348 5 15.9999 5C16.2651 5 16.5194 5.10532 16.7069 5.29279Z"
          fill="#03543F"
        />
      </g>
    </svg>
  )
}
