import { ReactNode } from "react"

type ModalConfirmType = {
  buttonInput?: ReactNode
  title?: string | ReactNode
  description?: string | ReactNode
}

const ModalConfirm = ({
  title = "Ao sair sem salvar, todas as informações editadas serão perdidas.",
  description = "Ao sair sem salvar, todas as informações editadas serão perdidas.",
  buttonInput,
}: ModalConfirmType) => {
  return (
    <div className="p-5">
      {typeof title === "string" ? (
        <h5 className="text-gray-900 text-lg font-semibold border-b pb-4 mb-5">
          {title}
        </h5>
      ) : (
        title
      )}
      {typeof description === "string" ? (
        <p className="text-gray-500 text-sm font-normal mb-5">{description}</p>
      ) : (
        description
      )}
      {buttonInput ? buttonInput : null}
    </div>
  )
}

export default ModalConfirm
