import { CommunicationItem } from "@components/communication-item"
import Error from "@components/error"
import ToastComponent from "@components/toast"
import { useNotifications } from "@hooks/use-notifications"
import { Notification, NotificationStatus } from "@type/notification.types"
import { Tabs } from "flowbite-react"
import { Fragment, useState } from "react"

function NotificationsMainPage() {
  const { notifications, changeNotificationStatus, errors } = useNotifications()
  const [filterByUnread, setFilterByUnread] = useState(false)

  return (
    <Fragment>
      {errors.put && <ToastComponent />}
      <h1 className="text-3xl font-bold lg:ml-0 md:ml-0 sm:ml-8 ml-8 ">
        Notificações
      </h1>
      <div className="mt-8 h-fit bg-white border border-gray-200 lg:rounded-lg md:rounded-lg shadow p-6 flex flex-col">
        <Tabs.Group
          aria-label="Tab Notifications"
          style="underline"
          className="gap-8"
        >
          <Tabs.Item
            active
            title="Todas"
            onClick={() => setFilterByUnread(false)}
          >
            {errors.get ? (
              <Error />
            ) : (
              <NotificationSection
                notifications={notifications}
                changeNotificationStatus={changeNotificationStatus}
                filterByUnread={filterByUnread}
              />
            )}
          </Tabs.Item>
          <Tabs.Item title="Não lidas" onClick={() => setFilterByUnread(true)}>
            {errors.get ? (
              <Error />
            ) : (
              <NotificationSection
                notifications={notifications}
                changeNotificationStatus={changeNotificationStatus}
                filterByUnread={true}
              />
            )}
          </Tabs.Item>
        </Tabs.Group>
      </div>
    </Fragment>
  )
}

type NotificationSectionProps = {
  notifications: Notification[] | undefined
  changeNotificationStatus: (
    notificationId: number,
    status: NotificationStatus
  ) => void
  filterByUnread: boolean
}

function NotificationSection({
  notifications,
  changeNotificationStatus,
  filterByUnread,
}: NotificationSectionProps) {
  return (
    <div className="relative overflow-x-auto pb-4 mt-4 grow">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <tbody>
          {notifications
            ?.filter(
              (item) =>
                !filterByUnread || item.status === NotificationStatus.UNREAD
            )
            .map((item) => (
              <div key={item.notificationId}>
                <div>
                  <CommunicationItem
                    avatar={item.agentAvatar}
                    name={item.agentName}
                    body={item.body}
                    createdAt={item.createdAt}
                    status={item.status}
                    onChangeStatus={() =>
                      changeNotificationStatus(item.notificationId, item.status)
                    }
                  />
                </div>
                <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
              </div>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default NotificationsMainPage
