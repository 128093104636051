import React from "react"

type ClockIconType = {
  width?: string
  height?: string
  color?: string
}

export default function ClockIcon({
  color = "#111928",
  height = "15",
  width = "15",
}: ClockIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63277 13.4768C9.18508 13.4768 10.6738 12.8602 11.7715 11.7625C12.8691 10.6649 13.4858 9.17617 13.4858 7.62386C13.4858 6.07155 12.8691 4.58282 11.7715 3.48517C10.6738 2.38753 9.18508 1.77087 7.63277 1.77087C6.08046 1.77087 4.59173 2.38753 3.49409 3.48517C2.39644 4.58282 1.77979 6.07155 1.77979 7.62386C1.77979 9.17617 2.39644 10.6649 3.49409 11.7625C4.59173 12.8602 6.08046 13.4768 7.63277 13.4768ZM8.3644 4.69737C8.3644 4.50333 8.28731 4.31724 8.15011 4.18003C8.0129 4.04283 7.82681 3.96574 7.63277 3.96574C7.43873 3.96574 7.25264 4.04283 7.11544 4.18003C6.97823 4.31724 6.90115 4.50333 6.90115 4.69737V7.62386C6.90119 7.81788 6.9783 8.00394 7.11551 8.14112L9.18455 10.2109C9.25252 10.2789 9.33322 10.3328 9.42203 10.3696C9.51085 10.4064 9.60604 10.4253 9.70217 10.4253C9.7983 10.4253 9.89349 10.4064 9.98231 10.3696C10.0711 10.3328 10.1518 10.2789 10.2198 10.2109C10.2878 10.1429 10.3417 10.0622 10.3785 9.97339C10.4153 9.88458 10.4342 9.78939 10.4342 9.69326C10.4342 9.59713 10.4153 9.50194 10.3785 9.41312C10.3417 9.32431 10.2878 9.24361 10.2198 9.17563L8.3644 7.32097V4.69737Z"
        fill={color}
      />
    </svg>
  )
}
