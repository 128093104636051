import { RoutesConstants as routes } from "@constants/routes"
import {
  onMessageListener,
  requestForToken,
} from "@services/firebase-cm.service"
import NotificationService from "@services/notification.service"
import {
  Notification,
  NotificationContextType,
  NotificationStatus,
} from "@type/notification.types"
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react"
import { useLocation } from "react-router-dom"
import { useAuth } from "./use-auth"

const NotificationsContext = createContext<NotificationContextType>(null!)

onMessageListener().then((payload) => {
  console.error("FRONT NOTIFICATION", payload)
})

export function NotificationsProvider(props: PropsWithChildren) {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const [notifications, setNotifications] = useState<
    Notification[] | undefined
  >([])
  const [errors, setErrors] = useState({
    get: false,
    put: false,
  })

  const getNotifications = () => {
    NotificationService.findAll()
      .then((response: Notification[]) => setNotifications(response))
      .catch((err) => {
        console.error("getNotifications", err)
        setErrors((prev) => ({ ...prev, get: true }))
      })
  }

  const changeNotificationStatus = (
    notificationId: number,
    status: NotificationStatus
  ) => {
    const newStatus: NotificationStatus =
      status === NotificationStatus.READ
        ? NotificationStatus.UNREAD
        : NotificationStatus.READ

    NotificationService.put(notificationId, newStatus)
      .then(() => getNotifications())
      .catch((err) => {
        console.error("changeNotificationStatus", err)
        setErrors((prev) => ({ ...prev, put: true }))
      })
  }

  useEffect(() => {
    if (
      isAuthenticated() &&
      location.pathname &&
      ![routes.page500, routes.page404].includes(location.pathname)
    ) {
      requestForToken().then((token) => {
        NotificationService.sendToken(token)
        getNotifications()
      })
      getNotifications()
    }
  }, [isAuthenticated(), location.pathname])

  const value = {
    notifications: notifications ?? [],
    changeNotificationStatus,
    errors,
  }

  return (
    <NotificationsContext.Provider value={value}>
      {props.children}
    </NotificationsContext.Provider>
  )
}

export function useNotifications() {
  return useContext(NotificationsContext)
}
