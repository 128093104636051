import { NextIcon, PreviousIcon } from "@assets/icons"
import { Button } from "flowbite-react"
import { HTMLProps, useCallback } from "react"
import { HiChevronLeft, HiChevronRight } from "react-icons/hi"

interface Props extends HTMLProps<HTMLDivElement> {
  pages: number
  currentPage: number
  itemsPerPage: number
  total: number
  currentTotal: number
  onNavigatePage: (toPage: number) => void
}

export const Pagination = (props: Props) => {
  const pages = Array.from({ length: props.pages }, (_, i) => i + 1)
  const paginateCallback = useCallback((toPage: number) => {
    props.onNavigatePage(toPage)
  }, [])

  return (
    <div className="flex-none">
      <nav
        className={`flex items-center justify-between pt-4 mx-4`}
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          Mostrando{" "}
          <span className="font-semibold text-gray-900 dark:text-white">
            {props.currentPage * props.itemsPerPage - props.itemsPerPage + 1}-
            {props.total >= props.itemsPerPage
              ? props.currentPage * props.itemsPerPage
              : props.currentTotal}
          </span>{" "}
          de{" "}
          <span className="font-semibold text-gray-900 dark:text-white">
            {props.total >= props.itemsPerPage
              ? props?.total
              : props.currentTotal}
          </span>
        </span>
        <ul className="items-center -space-x-px hidden md:inline-flex">
          <li>
            <a
              href="#"
              onClick={() => {
                if (props.currentPage > 1 && pages.length > 1)
                  paginateCallback(props.currentPage - 1)
              }}
              className={`block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                props.currentPage === 1
                  ? "cursor-not-allowed disabled:opacity-75"
                  : ""
              }`}
            >
              <span className="sr-only">Anterior</span>
              <PreviousIcon className="w-5 h-5" />
            </a>
          </li>
          {pages.map((page, index) =>
            page === props.currentPage ? (
              <li key={index}>
                <a
                  href="#"
                  onClick={() => paginateCallback(page)}
                  aria-current="page"
                  className="z-10 px-3 py-2 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                >
                  {page}
                </a>
              </li>
            ) : (
              <li key={index}>
                <a
                  onClick={() => paginateCallback(page)}
                  href="#"
                  className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  {page}
                </a>
              </li>
            )
          )}
          <li>
            <a
              href="#"
              onClick={() => {
                if (props.currentPage < pages.length)
                  paginateCallback(props.currentPage + 1)
              }}
              className={`block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                pages.length === props.currentPage
                  ? "cursor-not-allowed disabled:opacity-75"
                  : ""
              }`}
            >
              <span className="sr-only">Próxima</span>
              <NextIcon className="w-5 h-5" />
            </a>
          </li>
        </ul>
      </nav>
      {props.pages > 1 && (
        <div className="grid grid-cols-2 gap-2 mt-4 md:hidden">
          <Button
            onClick={() => {
              if (props.currentPage > 1 && pages.length > 1)
                paginateCallback(props.currentPage - 1)
            }}
            className={`${
              props.currentPage === 1
                ? "cursor-not-allowed disabled:opacity-75"
                : ""
            }`}
            color="gray"
          >
            <HiChevronLeft className="mr-2 h-5 w-5" />
            <p>Anterior</p>
          </Button>
          <Button
            color="primary"
            className={`${
              pages.length === props.currentPage
                ? "cursor-not-allowed disabled:opacity-75"
                : ""
            }`}
            onClick={() => {
              if (props.currentPage < pages.length)
                paginateCallback(props.currentPage + 1)
            }}
          >
            <p>Próxima</p>
            <HiChevronRight className="ml-2 h-5 w-5" />
          </Button>
        </div>
      )}
    </div>
  )
}
