import { Payment } from "@type/payment.types"
import axiosBase from "./base.service"
const PAYMENT_STATUS_PATH = `/bills/status`
const PAYMENT_PAY = `/bills/pay`
const PAYMENT_RECEIVE = `/bills/receive`

const PaymentService = {
  findAllPayments: async (
    page: number = 1,
    status: string[] | undefined = undefined
  ): Promise<Payment.Paginated> => {
    try {
      const { data } = await axiosBase.get(PAYMENT_PAY, {
        params: {
          page,
          status,
        },
      })

      return (
        data?.data ?? {
          pages: 0,
          items: [],
          count: 0,
          currentPage: 0,
          itemsPerPage: 0,
        }
      )
    } catch (error) {
      return { pages: 0, items: [], count: 0, currentPage: 0, itemsPerPage: 0 }
    }
  },
  findAllReceives: async (
    page: number = 1,
    status: string[] | undefined = undefined,
    conciliation_status: number[] | undefined = undefined
  ): Promise<Payment.Paginated> => {
    try {
      const { data } = await axiosBase.get(PAYMENT_RECEIVE, {
        params: {
          page,
          status,
          conciliation_status,
        },
      })

      return (
        data?.data ?? {
          pages: 0,
          items: [],
          count: 0,
          currentPage: 0,
          itemsPerPage: 0,
        }
      )
    } catch (error) {
      return { pages: 0, items: [], count: 0, currentPage: 0, itemsPerPage: 0 }
    }
  },
  fetchPaymentsStatus: async (): Promise<Payment.PaymentStatus[]> => {
    try {
      const { data } = await axiosBase.get(PAYMENT_STATUS_PATH)

      return data?.data
    } catch (error) {
      return []
    }
  },
  fetchPaymentList: async (idList: number[]): Promise<string> => {
    try {
      const { data } = await axiosBase.post(
        `${PAYMENT_PAY}/generate-bank-slip`,
        {
          ids: idList,
        }
      )

      return data?.data || ""
    } catch (error) {
      return ""
    }
  },
  fetchReceiveList: async (idList: number[]): Promise<string> => {
    try {
      const { data } = await axiosBase.post(
        `${PAYMENT_RECEIVE}/generate-bank-slip`,
        {
          ids: idList,
        }
      )
      return data?.data || ""
    } catch (error) {
      return ""
    }
  },
}

export default PaymentService
