import PlusIcon from "@assets/icons/plus"
import ModalConfirm from "@components/modal-confirm"
import { modalConfirmTheme } from "@components/modal-confirm/style"
import { Pagination } from "@components/pagination"
import RadioFilter from "@components/radio-filter"
import SearchInput from "@components/search-input"
import Search from "@components/search/search"
import ToastComponent from "@components/toast"
import { RoutesConstants as routes } from "@constants/routes"
import { useScreenDetect } from "@hooks/use-screen-detect"
import AdminModelService from "@services/admin.models.service"
import { AdminModels } from "@type/admin.type"
import { Breadcrumb, Button, Flowbite } from "flowbite-react"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { HiNewspaper } from "react-icons/hi"
import Modal from "react-modal"
import { useNavigate, useSearchParams } from "react-router-dom"

import { CardComponent } from "@components/card"
import ErrorComponent from "@components/error"
import { customButton } from "@theme/custom"
import { Constants } from "@utils/helpers/constants"
import AdminModelsTable from "./components/table"

const statusOptions = [
  {
    label: Constants.ALL,
    value: AdminModels.All.ALL,
    name: AdminModels.All.ALL,
  },
  {
    label: Constants.INTERNAL,
    value: AdminModels.Status.PUBLISH_INTERNAL,
    name: AdminModels.Status.PUBLISH_INTERNAL,
  },
  {
    label: Constants.DRAFT,
    value: AdminModels.Status.DRAFT,
    name: AdminModels.Status.DRAFT,
  },
]

Modal.setAppElement("#root")

export default function AdminModelsMainPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [errors, setErrors] = useState({
    get: false,
    remove: false,
  })
  const [initialModels, setInitialModels] = useState<AdminModels.Item[]>([])
  const [filtered, setFiltered] = useState<AdminModels.Item[]>([])
  const [status, setStatus] = useState<
    AdminModels.Status | AdminModels.All.ALL
  >(AdminModels.All.ALL)
  const [searchText, setSearchText] = useState("")
  const [ascending, setAscending] = useState(0)
  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  )
  const [modelsPaginated, setModelsPaginated] =
    useState<AdminModels.PaginatedAdminModels>()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const { isMobile } = useScreenDetect()
  const [modelIdToBeRemove, setModelIdToBeRemoved] = useState<
    string | undefined
  >()

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value as AdminModels.Status)
  }

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleSearch = () => {
    fetchModels(currentPage)
    setSearchText("")
  }

  const toggleSortingOrder = () => {
    setAscending((prev) => prev + 1)
    setFiltered((prev) => {
      if (ascending === 2) {
        setAscending(0)
        return initialModels
      }
      const prevUsers = [...prev]
      return prevUsers.sort((a, b) => {
        if (ascending === 0) {
          return b.status.localeCompare(a.status)
        } else {
          return a.status.localeCompare(b.status)
        }
      })
    })
  }

  const fetchModels = async (page: number) => {
    const params = {
      status: status === AdminModels.All.ALL ? undefined : status,
      search: searchText === "" ? undefined : searchText,
      page,
    }

    AdminModelService.findAllModels(params)
      .then((data) => {
        setModelsPaginated(data)
        setFiltered(data.items)
        setInitialModels(data.items)
      })
      .catch(() =>
        setErrors((prev) => ({
          ...prev,
          get: true,
        }))
      )
  }

  const onNavigatePage = useCallback(
    (toPage: number) => {
      setCurrentPage(toPage)
      navigate({ pathname: routes.adminModels, search: `?page=${toPage}` })
      fetchModels(toPage)
    },
    [navigate]
  )

  useEffect(() => {
    fetchModels(currentPage)
  }, [status])

  const addModel = useCallback(() => {
    navigate(`${routes.adminModels}/create`)
  }, [])

  const editModel = useCallback((id: string) => {
    navigate(`${routes.adminModels}/${id}`)
  }, [])

  const removeModel = useCallback((id: string) => {
    AdminModelService.removeModel(id)
      .then(() => {
        fetchModels(currentPage)
        setIsConfirmationModalOpen(false)
      })
      .catch(() =>
        setErrors((prev) => ({
          ...prev,
          remove: true,
        }))
      )
  }, [])

  return (
    <>
      {errors.remove && <ToastComponent />}
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col">
            <h1 className="text-3xl font-bold lg:ml-0 md:ml-0 sm:ml-8 ml-8">
              Modelos de documentos
            </h1>
          </div>
          <Breadcrumb
            aria-label="Default breadcrumb example"
            className=" items-center lg:flex hidden"
          >
            <Breadcrumb.Item icon={HiNewspaper}>
              <p>Administração</p>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Modelos</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <CardComponent
        header={
          <Search
            searchInput={
              <SearchInput
                placeholder="Título ou nº do documento"
                handleSearch={handleSearch}
                handleSearchText={handleSearchText}
                searchText={searchText}
              />
            }
            radioInput={
              <>
                <p className="text-gray-900 font-medium text-sm">Mostrar</p>{" "}
                <RadioFilter
                  onChange={handleStatusChange}
                  value={status}
                  options={statusOptions}
                />
              </>
            }
            buttonInput={
              <Flowbite theme={{ theme: customButton }}>
                <Button size="xs" onClick={addModel} color="primary">
                  <span className="mr-1">
                    <PlusIcon color="white" hoverColor="white" />
                  </span>
                  Criar modelo
                </Button>
              </Flowbite>
            }
          />
        }
        body={
          errors.get ? (
            <ErrorComponent />
          ) : (
            <AdminModelsTable
              removeModel={(id: string) => {
                setModelIdToBeRemoved(id)
                setIsConfirmationModalOpen(true)
              }}
              editModel={editModel}
              filtered={filtered}
              toggleSortingOrder={toggleSortingOrder}
            />
          )
        }
        footer={
          <Pagination
            onNavigatePage={onNavigatePage}
            currentPage={currentPage}
            currentTotal={modelsPaginated?.items.length ?? 0}
            itemsPerPage={modelsPaginated?.itemsPerPage ?? 10}
            pages={modelsPaginated?.pages ?? 0}
            total={modelsPaginated?.count ?? 0}
          />
        }
      />
      <Modal
        isOpen={isConfirmationModalOpen}
        onRequestClose={() => setIsConfirmationModalOpen(false)}
        style={modalConfirmTheme(isMobile)}
      >
        <ModalConfirm
          title="Tem certeza que deseja excluir este modelo?"
          description=""
          buttonInput={
            <div className="flex md:col-span-2 gap-4">
              <Button
                color="gray"
                className="w-fit text-sm font-medium"
                onClick={() => removeModel(modelIdToBeRemove!)}
              >
                Sim, excluir
              </Button>
              <Button
                color="gray"
                className="w-fit text-sm font-medium"
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                Cancelar
              </Button>
            </div>
          }
        />
      </Modal>
    </>
  )
}
