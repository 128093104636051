import React from "react"

export default function BtnMessageIcon() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_1039_1246)">
        <circle cx="40" cy="36" r="34.5" fill="#F9FAFB" stroke="#E5E7EB" />
        <path
          fillRule="evenodd"
          d="M34.2928 29.5753C34.4803 29.3755 34.7346 29.2633 34.9998 29.2633C35.265 29.2633 35.5193 29.3755 35.7068 29.5753L39.9998 34.1498L44.2928 29.5753C44.385 29.4735 44.4954 29.3924 44.6174 29.3365C44.7394 29.2807 44.8706 29.2513 45.0034 29.25C45.1362 29.2488 45.2678 29.2758 45.3907 29.3293C45.5136 29.3829 45.6253 29.462 45.7192 29.5621C45.8131 29.6621 45.8873 29.7811 45.9376 29.9121C45.9879 30.043 46.0132 30.1833 46.012 30.3248C46.0109 30.4663 45.9833 30.6061 45.9309 30.7361C45.8785 30.8662 45.8023 30.9837 45.7068 31.082L41.4138 35.6565L45.7068 40.231C45.8889 40.432 45.9897 40.7012 45.9875 40.9806C45.9852 41.26 45.88 41.5272 45.6946 41.7248C45.5092 41.9224 45.2584 42.0344 44.9962 42.0368C44.734 42.0393 44.4814 41.9319 44.2928 41.7378L39.9998 37.1633L35.7068 41.7378C35.5182 41.9319 35.2656 42.0393 35.0034 42.0368C34.7412 42.0344 34.4904 41.9224 34.305 41.7248C34.1196 41.5272 34.0144 41.26 34.0121 40.9806C34.0098 40.7012 34.1106 40.432 34.2928 40.231L38.5858 35.6565L34.2928 31.082C34.1053 30.8822 34 30.6112 34 30.3287C34 30.0461 34.1053 29.7751 34.2928 29.5753Z"
          fill="#9CA3AF"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_1039_1246"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1039_1246"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1039_1246"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1039_1246"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1039_1246"
            result="effect2_dropShadow_1039_1246"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1039_1246"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
