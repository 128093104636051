import React from "react"

export default function ClipIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="text-primary-400 mr-2"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M15.172 7.50016L8.58602 14.0862C8.395 14.2707 8.24264 14.4913 8.13782 14.7354C8.033 14.9794 7.97783 15.2418 7.97552 15.5074C7.97321 15.7729 8.02381 16.0363 8.12438 16.2821C8.22494 16.5279 8.37344 16.7512 8.56123 16.939C8.74902 17.1267 8.97232 17.2752 9.21811 17.3758C9.4639 17.4764 9.72726 17.527 9.99282 17.5247C10.2584 17.5224 10.5208 17.4672 10.7648 17.3624C11.0088 17.2575 11.2295 17.1052 11.414 16.9142L17.828 10.3282C18.5567 9.57376 18.9598 8.56335 18.9507 7.51456C18.9416 6.46577 18.5209 5.46252 17.7793 4.72089C17.0377 3.97926 16.0344 3.55858 14.9856 3.54947C13.9368 3.54035 12.9264 3.94353 12.172 4.67216L5.75702 11.2572C4.63171 12.3825 3.99951 13.9087 3.99951 15.5002C3.99951 17.0916 4.63171 18.6179 5.75702 19.7432C6.88233 20.8685 8.40859 21.5007 10 21.5007C11.5915 21.5007 13.1177 20.8685 14.243 19.7432L20.5 13.5002"
        stroke="#31246D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
