import { NotificationStatus } from "@type/notification.types"
import { Tooltip } from "flowbite-react"
import { useMemo } from "react"

interface CommunicationItem {
  avatar: string
  name: string
  body: string
  status?: string
  createdAt?: string
  onChangeStatus?: () => void
  message?: string
}

export const CommunicationItem = ({
  avatar,
  name,
  body,
  createdAt,
  status,
  onChangeStatus,
  message,
}: CommunicationItem) => {
  const notificationStatus = useMemo(
    () =>
      status === NotificationStatus.UNREAD
        ? { color: "bg-red-600", tooltipTextSuffix: "" }
        : { color: "bg-blue-200", tooltipTextSuffix: "não" },
    []
  )

  return (
    <div className="flex justify-between max-w-[384px]">
      <div className="flex items-start">
        <img
          src={
            avatar
              ? avatar
              : `https://ui-avatars.com/api/?name=${encodeURI(name ?? "")}`
          }
          alt={`${name} Avatar`}
          className="w-10 h-10 rounded-full"
        />
        <div className="ml-3 text-left whitespace-normal">
          <p className="text-gray-900 space-y-1 leading-tight font-medium text-sm dark:text-white">
            {name}{" "}
            <span className="text-gray-500 leading-tight font-normal">
              {body}
            </span>
          </p>
          {createdAt && (
            <p className="leading-tight text-xs font-normal text-primary-600">
              {createdAt}
            </p>
          )}
          {message && (
            <p className="mt-1 whitespace-normal leading-tight text-xs font-normal text-gray-700">
              {message}
            </p>
          )}
        </div>
      </div>
      {status && (
        <div className="flex items-center mr-2 ml-4">
          <Tooltip
            className="min-w-[100px]"
            placement="top"
            content={`Marcar como ${notificationStatus.tooltipTextSuffix} lida`}
          >
            <div
              className={`h-3 w-3 ${notificationStatus.color} rounded-full cursor-pointer`}
              onClick={onChangeStatus}
            />
          </Tooltip>
        </div>
      )}
    </div>
  )
}
