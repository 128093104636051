import { CheckIcon, InfoIcon, TrashCan } from "@assets/icons"
import ErrorComponent from "@components/error"
import FullLoading from "@components/full-loading"
import { RoutesConstants as routes } from "@constants/routes"
import {
  Claimant,
  Conciliation,
  ResolutionMethod,
  Rite,
} from "@features/conciliation/types/conciliation.type"
import axiosBase from "@services/base.service"
import ConciliationService from "@services/conciliation.service"
import { Conciliation as ConciliationType } from "@type/conciliation.type"
import { PROCESS_ROLES } from "@utils/helpers/constants"
import { formatCurrency } from "@utils/helpers/formatCurrency"
import { TERMOS_DE_USO_URL } from "@utils/helpers/termsURL"
import axios, { AxiosResponse } from "axios"
import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  FileInput,
  Label,
  Modal,
  Radio,
  Tabs,
  Toast,
  Tooltip,
  type TabsRef,
} from "flowbite-react"
import { ErrorMessage, Field, Formik } from "formik"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { HiOfficeBuilding } from "react-icons/hi"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import EditInfoProcess from "../../components/edit-info-process/edit.info.process"
import CreateProcessForm from "../../components/form/create.process.form"
import ModalComponent from "../../components/modal-form-new-process/modal.form.new.process"
import Tab from "../../components/tab-form-steps/tab"

interface TabStatus {
  [tabName: string]: boolean
}

enum Status {
  "loading" = "loading",
  "default" = "default",
  "error" = "error",
  "done" = "done",
}

function CreateProcess() {
  const [infoGerais, setInfoGerais] = useState<Conciliation>({
    relato: "",
    pedido: "",
    valor: "",
    docs: [],
    ritoProcesso: undefined,
    metodoSolucao: undefined,
    hasClausula: false,
  })
  const [requerentes, setRequerentes] = useState<Claimant[]>([])
  const [requerentesAcusados, setRequerentesAcusados] = useState<Claimant[]>([])
  const [status, setStatus] = useState<Status>(Status.default)
  const [openModal, setOpenModal] = useState<string | undefined>()
  const [openModal2, setOpenModal2] = useState<string | undefined>()
  const [cancelModal, setCancelModal] = useState<string | undefined>()
  const [tabsStatus, setTabsStatus] = useState({
    tab0: false,
    tab1: true,
    tab2: true,
    tab3: true,
  })
  const [activeTab, setActiveTab] = useState<number>(0)
  const tabsRef = useRef<TabsRef>(null)
  const [countInfoEdit, setcountInfoEdit] = useState(0)
  const [methods, setMethods] = useState<ResolutionMethod[]>([])
  const [rites, setRites] = useState<Rite[]>([])
  const navigate = useNavigate()
  const [checked, setChecked] = useState(false)
  const [documentError, setDocumentError] = useState(false)
  const inputRefs = useRef<HTMLParagraphElement[] | null[]>([])
  const legendRefs = useRef<HTMLParagraphElement[] | null[]>([])
  const warningRefs = useRef<HTMLParagraphElement[] | null[]>([])
  const termsRef = useRef<HTMLParagraphElement | null>(null)
  const topRef = useRef<HTMLParagraphElement | null>(null)
  const handleCheckboxChange = () => {
    setChecked(!checked)
  }

  const validateDocumentsInputs = () => {
    let error = false
    const emptyInputsIndex = infoGerais.docs
      .map((doc, index) => (!doc.detail ? index : null))
      .filter((index) => index !== null)

    const filledInputsIndex = infoGerais.docs
      .map((doc, index) => (doc.detail ? index : null))
      .filter((index) => index !== null)

    if (infoGerais.docs.length === 0 || emptyInputsIndex) {
      error = true
      setDocumentError((prev) => !prev)
    }

    function updateStyles(
      index: number | null,
      color: string,
      display: string
    ) {
      if (index !== null) {
        legendRefs.current[index]!.style.color = color
        warningRefs.current[index]!.style.display = display
      }
    }

    for (let i = 0; i < emptyInputsIndex.length; i++) {
      updateStyles(emptyInputsIndex[i], "red", "block")
    }

    for (let i = 0; i < filledInputsIndex.length; i++) {
      updateStyles(filledInputsIndex[i], "black", "none")
    }

    return error
  }

  function scrollTop() {
    topRef.current!.scrollIntoView({ behavior: "smooth" })
  }

  const validationSchema = yup.object({
    relato: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    pedido: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    valor: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    ritoProcesso: yup.number().required("É necessário selecionar uma opção."),
    metodoSolucao: yup.number().required("É necessário selecionar uma opção."),
    hasClausula: yup.boolean().required("É necessário selecionar uma opção."),
  })

  useEffect(() => {
    setStatus(Status.loading)
    Promise.all([
      axiosBase.get<AxiosResponse<Rite[]>>("/rite"),
      axiosBase.get<AxiosResponse<ResolutionMethod[]>>("/resolution-method"),
    ])
      .then(([ritesRes, methodsRes]) => {
        const ritesData = ritesRes.data.data
        const methodsData = methodsRes.data.data
        setInfoGerais((prev) => ({
          ...prev,
          ritoProcesso: ritesData?.find((rite) => rite.isDefault)?.id || 8,
          metodoSolucao:
            methodsData?.find((method) => method.isDefault)?.id || 5,
        }))

        setRites(ritesData)
        setMethods(methodsData)
      })
      .catch(() => setStatus(Status.error))
      .finally(() => setStatus(Status.default))
  }, [])

  const formSubmit = (tab: string, state: boolean, tabToGo: number) => {
    if (infoGerais?.docs.length === 0) {
      return setDocumentError(true)
    }
    setTabsStatus({ ...tabsStatus, [tab]: state })
    tabsRef.current?.setActiveTab(tabToGo)
    scrollTop()
  }

  const onFormSubmitCreate = async () => {
    setStatus(Status.loading)
    try {
      const docs = infoGerais.docs
      const attachs: Array<number> = []

      for (let i = 0; i < docs.length; i++) {
        const name = docs[i].name
        const mimeType = docs[i].type
        const body = { name, mimeType, fileName: docs[i].detail }

        const resUrl = await axiosBase.post("/attachment/url-upload", body)
        const urlForUpload = resUrl.data.data.url

        await axios.put(urlForUpload, docs[i].file).catch((error) => {
          console.error(error)
          setStatus(Status.error)
        })

        const res = await axiosBase
          .post("/attachment", body)
          .then((res) => res.data)
          .catch((error) => {
            console.error(error)
            setStatus(Status.error)
          })

        attachs.push(res.data.id)
      }

      const applicants: ConciliationType.Applicant[] = []
      const applicantsAccused: ConciliationType.Applicant[] = []

      for (let index = 0; index < requerentesAcusados.length; index++) {
        const req = requerentesAcusados[index]
        if (req.id) {
          applicantsAccused.push({
            type: PROCESS_ROLES.Required,
            id: req.id,
            fullName: req.nomeCompleto,
          })
        } else {
          applicantsAccused.push({
            type: PROCESS_ROLES.Required,
            cpfCnpj: req.cpfCnpj,
            personType: req.tipoPessoa,
            fullName: req.nomeCompleto,
            email: req.email,
            phone: req.tel,
          })
        }
      }

      for (let index = 0; index < requerentes.length; index++) {
        const req = requerentes[index]
        if (req.id) {
          applicants.push({
            type: req.papelProcesso,
            id: req.id,
            fullName: req.nomeCompleto,
          })
        } else {
          applicants.push({
            type: req.papelProcesso,
            lawNumber: req.oab,
            cpfCnpj: req.cpfCnpj,
            personType: req.tipoPessoa,
            fullName: req.nomeCompleto,
            email: req.email,
            phone: req.tel,
          })
        }
      }

      const generalInfo = {
        summary: infoGerais.relato,
        expectedOutcome: infoGerais.pedido,
        amountClaim: Number(infoGerais.valor.replace(/[^0-9]/g, "")),
        attachments: attachs.map((attach) => ({ id: attach })),
        rite: infoGerais.ritoProcesso,
        resolutionMethod: infoGerais.metodoSolucao,
        hasClause: infoGerais.hasClausula,
      } as ConciliationType.GeneralInfo

      ConciliationService.postConciliation({
        generalInfo,
        applicants,
        applicantsAccused,
      })
        .catch(() => setStatus(Status.error))
        .finally(() => {
          setStatus(Status.done)
          setTimeout(() => {
            navigate(routes.conciliations)
          }, 2000)
        })
    } catch (error) {
      console.error(error)
      setStatus(Status.error)
    } finally {
      setStatus(Status.default)
    }
  }

  const handleReplaceFile = (index: number) => {
    const hiddenFileInput = document.createElement("input")
    hiddenFileInput.type = "file"
    hiddenFileInput.addEventListener("change", (event) => {
      const newFile = (event.target as HTMLInputElement).files?.[0]
      if (newFile) {
        const newFilesInfo = {
          name: newFile.name,
          size: newFile.size,
          type: newFile.type,
          file: newFile,
          detail: newFile.name,
        }

        setInfoGerais((prevInfoGerais: Conciliation) => {
          const updatedDocs = [...prevInfoGerais.docs]
          updatedDocs[index] = newFilesInfo
          return { ...prevInfoGerais, docs: updatedDocs }
        })
      }
    })
    hiddenFileInput.click()
  }

  const handleDeleteFile = (index: number) => {
    setInfoGerais((prevInfoGerais: Conciliation) => {
      const updatedDocs = [...prevInfoGerais.docs]
      updatedDocs.splice(index, 1)
      return { ...prevInfoGerais, docs: updatedDocs }
    })
  }

  const fetchDataFromStates = useCallback(
    (
      key: string,
      id?: number
    ): Array<Rite | ResolutionMethod> | Rite | ResolutionMethod | null => {
      if (key === "ritoProcesso") {
        if (id) {
          return rites.find((rite) => rite.id === id) ?? null
        }
        return rites
      } else if (key === "metodoSolucao") {
        if (id) {
          return methods.find((method) => method.id === id) ?? null
        }
        return methods
      }
      return []
    },
    [rites, methods]
  )

  function tabCheck(number: number) {
    const keys = Object.keys(tabsStatus)
    const startIndex = keys.findIndex((key) => key.includes(number.toString()))
    const subsequentTabs = keys.slice(startIndex + 1)
    const updatedTabsStatus = subsequentTabs.reduce((result, key) => {
      result[key] = true
      return result
    }, {} as TabStatus)

    setTabsStatus((prevState) => ({ ...prevState, ...updatedTabsStatus }))
  }

  useEffect(() => {
    if (Object.values(infoGerais).includes("")) {
      const number = activeTab
      const keys = Object.keys(tabsStatus)
      const startIndex = keys.findIndex((key) =>
        key.includes(number.toString())
      )
      const subsequentTabs = keys.slice(startIndex + 1)
      const updatedTabsStatus = subsequentTabs.reduce((result, key) => {
        result[key] = true
        return result
      }, {} as TabStatus)
      setTabsStatus((prevState) => ({ ...prevState, ...updatedTabsStatus }))
    }
  }, [infoGerais])

  if (status === "loading") {
    return <FullLoading />
  }

  return (
    <>
      {status === "error" && <ErrorComponent />}
      <div className="w-full flex justify-between">
        <h1
          ref={topRef}
          className="text-3xl font-bold lg:ml-0 md:ml-0 sm:ml-8 ml-8 "
        >
          Novo processo
        </h1>
        <Breadcrumb
          aria-label="Default breadcrumb example"
          className=" items-center lg:flex hidden"
        >
          <Breadcrumb.Item
            className="cursor-pointer"
            onClick={() => navigate(routes.conciliations)}
            icon={HiOfficeBuilding}
          >
            <p>Processos</p>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Novo processo</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <Card className="mt-8 flex flex-col items-center py-6">
        <div className="lg:w-[800px] w-100%">
          <Tabs.Group
            aria-label="Pills"
            style="pills"
            className="flex justify-between w-full"
            ref={tabsRef}
            onActiveTabChange={(tab) => {
              if (activeTab > 0 && tab === 0) {
                setDocumentError(false)
              }
              setActiveTab(tab)
            }}
          >
            <Tabs.Item
              className="pl-1"
              disabled={tabsStatus.tab0}
              title={<Tab title={"Informações gerais"} number={"1"} />}
            >
              <h5 className="text-xl font-bold	mt-5 font-sans leading-5">
                Informações gerais
              </h5>
              <Formik
                initialValues={infoGerais}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
                onSubmit={() => {
                  formSubmit("tab1", false, 1)
                }}
              >
                {({
                  errors,
                  values,
                  setFieldValue,
                  handleSubmit,
                  setFieldError,
                }) => (
                  <form
                    onSubmit={handleSubmit}
                    onChange={() =>
                      Object.keys(errors).length > 0 ? tabCheck(activeTab) : ""
                    }
                  >
                    <div className="" id="textarea">
                      <div className="mb-2 mt-8 flex">
                        <Label
                          className={`${
                            errors.relato ? "text-red-600" : ""
                          } mr-4`}
                          htmlFor="comment"
                          value="Faça um breve relato sobre a sua situação e apresente uma proposta de acordo, se for o caso."
                        />
                        <Tooltip
                          className="max-w-[255px]"
                          content="Este é o resumo do conflito e a principal mensagem que aparecerá para os réus. Já a proposta é aplicável somente para o caso de você desejar tentar um acordo. Essas informações não poderão ser modificadas durante o processo."
                          style="light"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </div>
                      <Field
                        as="textarea"
                        className={`w-full h-[154px] rounded-md border ${
                          errors.relato
                            ? "border-red-600 bg-red-50"
                            : "border-gray-300 bg-gray-50"
                        } px-3 text-sm text-gray-900`}
                        name="relato"
                        placeholder="Ex.: X e Y celebraram contrato de locação de imóvel. Após tentativa de conciliação, as partes não chegaram em um consenso, não havendo outro caminho senão o de instaurar o presente requerimento de arbitragem."
                        value={values.relato}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("relato", e.target.value)
                          setFieldError("relato", "")
                          setInfoGerais((prev) => ({
                            ...prev,
                            relato: e.target.value,
                          }))
                        }}
                      />
                      {errors.relato && (
                        <ErrorMessage
                          component="div"
                          className="text-red-600 text-sm"
                          name="relato"
                        />
                      )}
                      <div className="mb-2 flex mt-8">
                        <Label
                          className={`${
                            errors.pedido ? "text-red-600" : ""
                          } mr-4`}
                          htmlFor="pedido"
                          value="Descreva seu pedido para o árbitro."
                        />
                        <Tooltip
                          className="max-w-[255px]"
                          content="Esse é o seu pedido que o árbitro irá considerar. Em virtude do princípio da congruência, o juiz não poderá conceder mais do que for pedido. Por exemplo, se a sua intenção é tanto a quitação da dívida quanto o despejo do devedor em caso de não pagamento, certifique-se de mencionar ambos."
                          style="light"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </div>
                      <Field
                        id="pedido"
                        name="pedido"
                        as="textarea"
                        className={`w-full h-[154px] rounded-md border !mb-1.5 ${
                          errors.pedido
                            ? "border-red-600 bg-red-50"
                            : "border-gray-300 bg-gray-50"
                        } px-3 text-sm text-gray-900`}
                        placeholder="Ex.: Devido ao não pagamento, desejo a instauração da arbitragem para a ação de despejo."
                        value={values.pedido}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue("pedido", e.target.value)
                          setFieldError("pedido", "")
                          setInfoGerais((prev) => ({
                            ...prev,
                            pedido: e.target.value,
                          }))
                        }}
                      />
                      {errors.pedido && (
                        <ErrorMessage
                          component="div"
                          className="text-red-600 text-sm"
                          name="pedido"
                        />
                      )}
                    </div>
                    <div className="mt-10">
                      <Label
                        htmlFor="price"
                        className={`flex lg:w-1/4 w-full text-sm font-medium leading-6 justify-between ${
                          errors.valor ? "text-red-600" : "text-gray-900"
                        }`}
                      >
                        Qual o valor da causa?
                        <Tooltip
                          className="max-w-[255px]"
                          content="Todos os custos envolvidos no processo, tais como: honorários, juros, multas, custos diretos e indiretos."
                          style="light"
                        >
                          <InfoIcon />
                        </Tooltip>
                      </Label>
                      <div className="relative mt-2 mb-2">
                        <input
                          name="valor"
                          type="text"
                          className={`lg:w-1/4 w-full rounded-md border py-1.5 ${
                            errors.valor
                              ? "border-red-600 bg-red-50"
                              : "border-gray-300 bg-gray-50"
                          } text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                          value={values.valor}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            )
                            const formattedValue = formatCurrency(numericValue)
                            setFieldValue("valor", formattedValue)
                            setFieldError("valor", undefined)
                            setInfoGerais((prev) => ({
                              ...prev,
                              valor: formattedValue,
                            }))
                          }}
                        />
                      </div>
                      {errors.valor && (
                        <ErrorMessage
                          component="p"
                          className="mt-1 text-red-600 text-sm"
                          name="valor"
                        />
                      )}
                    </div>
                    <div className="mt-11">
                      <p
                        className={
                          documentError ? "text-red-600 mb-2.5" : "mb-2.5"
                        }
                      >
                        Adicione os documentos importantes para este processo.
                      </p>
                      <li className="text-gray-500 ml-1.5">
                        Documento de identidade das partes (para PF) ou contrato
                        social (para PJ);
                      </li>
                      <li className="text-gray-500 ml-1.5">
                        Contrato com a cláusula de arbitragem (opcional)
                      </li>
                      <div className=" mt-4 flex flex-col" id="fileUpload">
                        {Array.isArray(infoGerais.docs)
                          ? infoGerais.docs.map((item, index) => (
                              <React.Fragment
                                key={`docs_${item.name?.trim()}_${index}`}
                              >
                                <div className="flex mb-4">
                                  <button
                                    type="button"
                                    className="rounded-l-lg bg-primary-500 px-5 py-2.5 text-white"
                                    onClick={() => handleReplaceFile(index)}
                                  >
                                    Procurar
                                  </button>
                                  <p className=" w-full lg:text-base text-base bg-gray-50 border-gray-300 border rounded-r-lg flex items-center py-3 px-4">
                                    {item.name}
                                  </p>
                                  <button
                                    type="button"
                                    className="border py-3 px-4 rounded-lg text-primary-800 ml-2.5"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    Excluir
                                  </button>
                                </div>
                                <legend
                                  ref={(el) => (legendRefs.current[index] = el)}
                                >
                                  Nome detalhado do arquivo
                                </legend>
                                <input
                                  type="text"
                                  className="w-full h-[42px] my-4 inline-flex items-center rounded-md border border-gray-300 bg-gray-50 px-3 text-sm text-gray-900"
                                  ref={(el) => (inputRefs.current[index] = el)}
                                  onChange={(e) => {
                                    setDocumentError(false)
                                    legendRefs.current[index]!.style.color =
                                      "black"
                                    warningRefs.current[index]!.style.display =
                                      "none"
                                    setInfoGerais(
                                      (prevInfoGerais: Conciliation) => {
                                        const updatedDocs = [
                                          ...prevInfoGerais.docs,
                                        ]
                                        updatedDocs[index] = {
                                          ...updatedDocs[index],
                                          detail: e.target.value,
                                        }
                                        return {
                                          ...prevInfoGerais,
                                          docs: updatedDocs,
                                        }
                                      }
                                    )
                                  }}
                                />
                                <legend
                                  className="hidden text-red-600 text-sm mb-4"
                                  ref={(el) =>
                                    (warningRefs.current[index] = el)
                                  }
                                >
                                  É necessário preencher este campo para
                                  continuar.{" "}
                                </legend>
                              </React.Fragment>
                            ))
                          : null}
                        <label
                          htmlFor="file"
                          className={`lg:w-[192px] flex hover:bg-gray-100 justify-center mt-4 w-full hover:cursor-pointer border py-2 px-3 ${
                            documentError
                              ? "text-red-600 border-red-600"
                              : "text-primary-800 border-gray-200"
                          } rounded-lg  text-sm not-italic font-medium`}
                        >
                          + Adicionar documento
                        </label>
                        <FileInput
                          className="hidden"
                          id="file"
                          onChange={(e) => {
                            if (
                              e.target.files !== null &&
                              e.target.files.length > 0
                            ) {
                              setDocumentError(false)
                              const filesArray = Array.from(e.target.files)
                              const newFilesInfo = filesArray.map((file) => ({
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                detail: "",
                                file,
                              }))
                              setInfoGerais((prevInfoGerais: Conciliation) => {
                                return {
                                  ...prevInfoGerais,
                                  docs: Array.isArray(prevInfoGerais.docs)
                                    ? [...prevInfoGerais.docs, ...newFilesInfo]
                                    : newFilesInfo,
                                }
                              })
                            }
                          }}
                          multiple
                        />
                        <div></div>
                      </div>
                    </div>
                    <div className="w-full flex mt-8 gap-3 lg:flex-row flex-col">
                      <div className="flex flex-col lg:w-1/2 w-full">
                        <div className="mb-2 flex ">
                          <Label
                            className={`${
                              errors.ritoProcesso ? "text-red-600" : ""
                            } mr-2.5`}
                            htmlFor="rito-processo"
                            value="Informe o rito de processo"
                          />
                          <Tooltip
                            className="max-w-[255px]"
                            content="O Rito do Processo é o conjunto de normas que orientam a tramitação de um processo judicial, definindo as etapas e procedimentos necessários para sua resolução. Os custos do processo variam de acordo com o rito selecionado, devido aos recursos que cada rito demanda."
                            style="light"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </div>
                        <Field
                          as="select"
                          id="rito-processo"
                          name="ritoProcesso"
                          value={values.ritoProcesso}
                          className={`w-full rounded-md ${
                            errors.ritoProcesso
                              ? "border-red-600 bg-red-50"
                              : "border-gray-300 bg-gray-50"
                          }`}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue("ritoProcesso", e.target.value)
                            setFieldError("ritoProcesso", undefined)
                            setInfoGerais((prev) => ({
                              ...prev,
                              ritoProcesso: Number(e.target.value),
                            }))
                          }}
                        >
                          {rites.length > 0
                            ? rites.map((rite) => {
                                return (
                                  <option
                                    key={`${rite.name}_${rite.id}`}
                                    value={rite.id}
                                    defaultChecked={rite.isDefault}
                                  >
                                    {rite.name}
                                  </option>
                                )
                              })
                            : ""}
                        </Field>
                        <p
                          className="mb-2 text-primary-600 font-light underline text-sm mt-2 hover:cursor-pointer"
                          onClick={() => setOpenModal2("default")}
                        >
                          Saiba mais sobre os ritos.
                        </p>
                        {errors.ritoProcesso && (
                          <ErrorMessage
                            component="div"
                            className="text-red-600 text-sm"
                            name="ritoProcesso"
                          />
                        )}
                        <ModalComponent
                          openModal={openModal2}
                          setOpenModal={setOpenModal2}
                          modalTitle={"Saiba mais sobre os ritos de processo"}
                          optionDescription={[
                            "É o mais simples e rápido dentre os três. Ideal para qualquer tipo de conflito no qual a arbitragem é aplicável. Alguns casos típicos seriam atrasos no pagamento de aluguéis ou indenização e ações de cobrança de dívidas.",
                            "Aplicando a casos de complexidade moderadas. Um exemplo comum seria o desvio de finalidade de imóvel. Por exemplo, alguém aluga um imóvel para fins residenciasi mas utiliza para fins comerciais. È comum a realização de audiências de conciiação e testemunhas para prestar depoimentos.",
                            "É o  processo mais complexo e demorado, que inclui ações de indenização por falha na prestação de um serviço técnico. Um exemplo seria um erro médico, onde é necessário convocar um perito da área para confimar o erro técnico.",
                          ]}
                          optionTitle={[
                            "Sumaríssimo:",
                            "Sumário:",
                            "Ordinário:",
                          ]}
                        />
                      </div>

                      <div className="flex flex-col lg:w-1/2 w-full">
                        <div className="mb-2 flex">
                          <Label
                            htmlFor="metodo-solucao"
                            value="Informe o método de solução"
                            className={`${
                              errors.metodoSolucao ? "text-red-600" : ""
                            } mr-2.5`}
                          />
                          <Tooltip
                            className="max-w-[255px]"
                            content="Trabalhamos com 3 métodos de solução: arbitragem, dispute board e mediação. Ao escolher o método, ele não poderá ser alterado durante o processo."
                            style="light"
                          >
                            <InfoIcon />
                          </Tooltip>
                        </div>
                        <Field
                          as="select"
                          id="metodo-solucao"
                          name="metodoSolucao"
                          defaultChecked={values.metodoSolucao}
                          className={`w-full rounded-md ${
                            errors.metodoSolucao
                              ? "!border-red-600 !bg-red-50"
                              : "border-gray-300 bg-gray-50"
                          }`}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFieldValue("metodoSolucao", e.target.value)
                            setFieldError("metodoSolucao", undefined)
                            setInfoGerais((prev) => ({
                              ...prev,
                              metodoSolucao: Number(e.target.value),
                            }))
                          }}
                        >
                          {methods.length > 0
                            ? methods.map((method: ResolutionMethod) => {
                                return (
                                  <option
                                    key={`${method.name}_${method.id}`}
                                    value={method.id}
                                    defaultChecked={method.isDefault}
                                  >
                                    {method.name}
                                  </option>
                                )
                              })
                            : ""}
                        </Field>
                        <p
                          className="mb-2 text-primary-600 font-light underline text-sm mt-2 hover:cursor-pointer"
                          onClick={() => setOpenModal("default")}
                        >
                          Saiba mais sobre os métodos
                        </p>
                        <ModalComponent
                          openModal={openModal}
                          setOpenModal={setOpenModal}
                          modalTitle={"Saiba mais sobre os métodos de solução"}
                          optionDescription={[
                            "Um árbitro, que é um terceiro neutro, atua como juiz e é responsável pela resolução da disputa. Sua decisão é vinculante, ou seja, não depende de acordo entre as partes para solucionar o problema.",
                            "Envolve um comitê de especialistas que monitora e fiscaliza o cumprimento do contrato e auxilia na prevenção e resolução de eventuais disputas",
                            "Um mediador imparcial facilita a comunicação e ajuda as partes a chegarem a um acordo aceitável entre elas",
                          ]}
                          optionTitle={[
                            "Arbitragem:",
                            "Dispute Board:",
                            "Mediação:",
                          ]}
                        />
                        {errors.metodoSolucao && (
                          <ErrorMessage
                            component="div"
                            className="text-red-600 text-sm"
                            name="metodoSolucao"
                          />
                        )}
                      </div>
                    </div>

                    <Field
                      as="fieldset"
                      className="flex max-w-md flex-col gap-4 mt-[30px]"
                      id="radio"
                      name="hasClausula"
                    >
                      <Label
                        className={`${
                          errors.hasClausula ? "text-red-600" : ""
                        }`}
                      >
                        O contrato possui a cláusula de arbitragem da
                        Arbitralis?
                      </Label>
                      <div className="flex gap-8">
                        <div className="flex items-center gap-2">
                          <Radio
                            className={`checked:bg-primary-600 ${
                              errors.hasClausula ? "bg-red-50" : ""
                            }`}
                            id="sim"
                            value={"Sim"}
                            checked={infoGerais.hasClausula === true}
                            onChange={() => {
                              setFieldValue("hasClausula", true)
                              setFieldError("hasClausula", undefined)
                              setInfoGerais((prev) => ({
                                ...prev,
                                hasClausula: true,
                              }))
                            }}
                          />
                          <Label htmlFor="sim">Sim</Label>
                        </div>
                        <div className="flex items-center gap-2">
                          <Radio
                            className={`checked:bg-primary-600 ${
                              errors.hasClausula ? "bg-red-50 text-red-600" : ""
                            }`}
                            id="nao"
                            value="Não"
                            checked={infoGerais.hasClausula === false}
                            onChange={() => {
                              setFieldValue("hasClausula", false)
                              setFieldError("hasClausula", undefined)
                              setInfoGerais((prev) => ({
                                ...prev,
                                hasClausula: false,
                              }))
                            }}
                          />
                          <Label htmlFor="nao">Não</Label>
                        </div>
                      </div>
                      {errors.hasClausula && (
                        <ErrorMessage
                          component="div"
                          className="text-red-600 text-sm"
                          name="hasClausula"
                        />
                      )}
                    </Field>
                    <Button
                      className="mt-14"
                      type={
                        infoGerais.docs.every((doc) => Boolean(doc.detail))
                          ? "submit"
                          : "button"
                      }
                      color="primary"
                      onClick={() => {
                        const error = validateDocumentsInputs()
                        if (!error) {
                          handleSubmit()
                        }
                      }}
                    >
                      Continuar
                    </Button>
                  </form>
                )}
              </Formik>
            </Tabs.Item>
            <Tabs.Item
              disabled={tabsStatus.tab1}
              title={<Tab title={"Dados do requerente"} number={"2"} />}
            >
              <CreateProcessForm
                formSubmit={formSubmit}
                secondStep={true}
                stepToGoInfo={{ tab: "tab2", state: false, tabToGo: 2 }}
                requerentesAnteriores={[]}
                requerentes={requerentes}
                papelProcessoDefault={PROCESS_ROLES.Lawyer}
                setRequerentes={setRequerentes}
                btnText={"+ Adicionar requerente"}
                primaryBtnText={"Continuar"}
                sectionTitle={"Insira os dados do requerente"}
              />
            </Tabs.Item>
            <Tabs.Item
              title={<Tab title={"Dados da pessoa acusada"} number={"3"} />}
              disabled={tabsStatus.tab2}
            >
              <h5 className="text-xl font-bold	mt-5 font-sans leading-5 mb-8">
                Dados da parte acusada
              </h5>
              <CreateProcessForm
                formSubmit={formSubmit}
                secondStep={false}
                stepToGoInfo={{ tab: "tab3", state: false, tabToGo: 3 }}
                papelProcessoDefault={PROCESS_ROLES.Required}
                requerentesAnteriores={requerentes}
                requerentes={requerentesAcusados}
                setRequerentes={setRequerentesAcusados}
                btnText={"+ Adicionar outra pessoa"}
                primaryBtnText={"Continuar para revisão"}
                sectionTitle={"Insira os dados da parte acusada"}
              />
            </Tabs.Item>
            <Tabs.Item
              title={<Tab title={"Revisão dos dados"} number={"4"} />}
              disabled={tabsStatus.tab3}
            >
              <h5 className="text-xl mt-4 font-bold font-sans leading-5">
                Informações gerais
              </h5>
              <EditInfoProcess
                data={infoGerais}
                handleSet={setInfoGerais}
                formStep={1}
                countInfoEdit={countInfoEdit}
                setCountInfoEdit={setcountInfoEdit}
                fetchData={fetchDataFromStates}
              />
              <h5 className="text-xl mt-10 font-bold font-sans leading-5">
                Partes envolvidas
              </h5>
              <EditInfoProcess
                data={requerentes}
                formStep={2}
                handleSet={setRequerentes}
                countInfoEdit={countInfoEdit}
                setCountInfoEdit={setcountInfoEdit}
                fetchData={fetchDataFromStates}
              />
              <EditInfoProcess
                data={requerentesAcusados}
                formStep={3}
                handleSet={setRequerentesAcusados}
                countInfoEdit={countInfoEdit}
                setCountInfoEdit={setcountInfoEdit}
                fetchData={fetchDataFromStates}
              />
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  onFormSubmitCreate()
                }}
              >
                <div className="relative flex flex-col justify-center mb-10 mt-10">
                  <div className="flex">
                    <Checkbox
                      required
                      id="accept"
                      className="mr-1.5 checked:bg-primary-600"
                      checked={checked}
                      onChange={handleCheckboxChange}
                    />
                    <Label
                      className="flex flex-col items-center"
                      htmlFor="agree"
                    >
                      <div className="flex">
                        <p>Li e aceito os &nbsp;</p>
                        <a
                          className="text-cyan-600 underline"
                          target="_blank"
                          rel="noreferrer"
                          href={TERMOS_DE_USO_URL}
                        >
                          Termos de uso
                        </a>
                      </div>
                      <legend
                        className="hidden text-red-600 text-sm mt-2"
                        ref={(el) => (termsRef.current = el)}
                      >
                        Por favor, confirme que concorda com nossos Termos de
                        Uso clicando no quadrado acima.
                      </legend>
                    </Label>
                  </div>
                  <Modal
                    show={cancelModal === "default"}
                    size="md"
                    popup
                    onClose={() => setCancelModal(undefined)}
                  >
                    <Modal.Header />
                    <Modal.Body>
                      <div className="text-center">
                        <TrashCan />
                        <h3 className="mb-5 text-center text-lg font-normal text-gray-500 dark:text-gray-400">
                          Todas as informações serão perdidas.
                          <br />
                          Tem certeza que deseja cancelar o processo?
                        </h3>
                        <div className="flex justify-center gap-4">
                          <button
                            className="bg-white text-black rounded-lg border-gray-200 hover:bg-gray-100 border py-2 px-3"
                            onClick={() => setCancelModal(undefined)}
                          >
                            Continuar editando
                          </button>
                          <Button
                            color="failure"
                            onClick={() => window.location.reload()}
                          >
                            Sim, cancelar
                          </Button>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  {status === Status.done && (
                    <Toast className="absolute right-0 bg-green-50 w-[90%]">
                      <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                        <CheckIcon />
                      </div>
                      <div className="ml-3 text-sm font-normal">
                        Processo enviado com sucesso. Em até 1 dia útil, nossos
                        especialistas irão avaliar as informações e dar
                        andamento ao processo.
                      </div>
                      <Toast.Toggle onClick={() => setStatus(Status.default)} />
                    </Toast>
                  )}
                </div>
                <div className="flex gap-5">
                  <Button
                    type={checked ? "submit" : "button"}
                    className="w-[147px] h-[41px]"
                    disabled={
                      countInfoEdit !== 0 || (activeTab === 3 && !checked)
                        ? true
                        : false
                    }
                    color="primary"
                    onClick={
                      !checked
                        ? () => {
                            termsRef.current!.style.display = "block"
                          }
                        : () => {
                            termsRef.current!.style.display = "none"
                          }
                    }
                  >
                    Enviar processo
                  </Button>
                  <button
                    type="button"
                    onClick={() => setCancelModal("default")}
                    className=" w-[83px] h-[41px] text-sm not-italic font-medium bg-white text-primary-800 rounded-lg border-gray-200 hover:bg-gray-100 border py-2 px-3"
                  >
                    Cancelar
                  </button>
                </div>
              </form>
            </Tabs.Item>
          </Tabs.Group>
        </div>
      </Card>
    </>
  )
}

export default CreateProcess
