import React from "react"

type PhotographIconType = {
  width?: string
  height?: string
  color?: string
}

export default function PhotographIcon({
  color = "#6B7280",
  height = "17",
  width = "16",
}: PhotographIconType) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19998 2.90002C2.77563 2.90002 2.36866 3.0686 2.0686 3.36865C1.76855 3.66871 1.59998 4.07568 1.59998 4.50002V12.5C1.59998 12.9244 1.76855 13.3313 2.0686 13.6314C2.36866 13.9315 2.77563 14.1 3.19998 14.1H12.8C13.2243 14.1 13.6313 13.9315 13.9313 13.6314C14.2314 13.3313 14.4 12.9244 14.4 12.5V4.50002C14.4 4.07568 14.2314 3.66871 13.9313 3.36865C13.6313 3.0686 13.2243 2.90002 12.8 2.90002H3.19998ZM12.8 12.5H3.19998L6.39998 6.10002L8.79998 10.9L10.4 7.70002L12.8 12.5Z"
        fill={color}
      />
    </svg>
  )
}
