import type { CustomFlowbiteTheme } from "flowbite-react"

export const flowbiteTheme: CustomFlowbiteTheme = {
  badge: {
    root: {
      base: "flex h-fit items-center gap-1 font-medium",
      color: {
        info: "bg-cyan-100 text-cyan-800 dark:bg-cyan-200 dark:text-cyan-800 group-hover:bg-cyan-200 dark:group-hover:bg-cyan-300",
        gray: "bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300 group-hover:bg-gray-200 dark:group-hover:bg-gray-600",
        failure:
          "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300",
        success:
          "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300",
        warning:
          "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300",
        indigo:
          "bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900 group-hover:bg-indigo-200 dark:group-hover:bg-indigo-300",
        purple:
          "bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900 group-hover:bg-purple-200 dark:group-hover:bg-purple-300",
        pink: "bg-pink-100 text-pink-800 dark:bg-pink-200 dark:text-pink-900 group-hover:bg-pink-200 dark:group-hover:bg-pink-300",
        blue: "bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-900 group-hover:bg-blue-200 dark:group-hover:bg-blue-300",
        cyan: "bg-cyan-100 text-cyan-800 dark:bg-cyan-200 dark:text-cyan-900 group-hover:bg-cyan-200 dark:group-hover:bg-cyan-300",
        dark: "bg-gray-600 text-gray-100 dark:bg-gray-900 dark:text-gray-200 group-hover:bg-gray-500 dark:group-hover:bg-gray-700",
        light:
          "bg-gray-200 text-gray-800 dark:bg-gray-400 dark:text-gray-900 group-hover:bg-gray-300 dark:group-hover:bg-gray-500",
        green:
          "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900 group-hover:bg-green-200 dark:group-hover:bg-green-300",
        lime: "bg-lime-100 text-lime-800 dark:bg-lime-200 dark:text-lime-900 group-hover:bg-lime-200 dark:group-hover:bg-lime-300",
        red: "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900 group-hover:bg-red-200 dark:group-hover:bg-red-300",
        teal: "bg-teal-100 text-teal-800 dark:bg-teal-200 dark:text-teal-900 group-hover:bg-teal-200 dark:group-hover:bg-teal-300",
        yellow:
          "bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900 group-hover:bg-yellow-200 dark:group-hover:bg-yellow-300",
      },
      href: "group",
      size: {
        xs: "p-1 text-xs h-[22px]",
        sm: "p-1.5 text-sm",
      },
    },
    icon: {
      off: "rounded-full px-2 py-1",
    },
  },
  card: {
    root: {
      base: "mt-8 p-0 md:p-8 py-4 flex lg:rounded-lg md:rounded-lg border border-gray-200 bg-white shadow-md mb-4",
      children: "flex h-full flex-col flex-1 justify-between",
      horizontal: {
        off: "flex-col",
        on: "flex-col md:max-w-xl md:flex-row",
      },
      href: "hover:bg-gray-100",
    },
  },
  checkbox: {
    root: {
      base: "h-4 w-4 text-primary-500 rounded border border-gray-300 bg-gray-100 focus:ring-2 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-primary-600",
    },
  },
  tooltip: {
    target: "w-fit",
    animation: "transition-opacity",
    arrow: {
      base: "absolute z-10 h-2 w-2 rotate-45 ",
      style: {
        light: "bg-white border-r border-b border-solid ",
        auto: "bg-white",
      },
      placement: "-4px",
    },
    base: "absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm",
    hidden: "invisible opacity-0",
    style: {
      dark: "bg-gray-900 text-white",
      light: "border border-gray-200 bg-white text-gray-900",
      auto: "border border-gray-200 bg-white text-gray-900",
    },
    content: "relative z-20",
  },
  tab: {
    base: "flex flex-col px-0 border-none mb-4",
    tabitemcontainer: {
      base: "mb-0",
    },
    tablist: {
      base: "flex text-center gap-8 border-transparent md:border-b md:border-gray-200 md:dark:border-gray-700 mb-2",
      styles: { pills: "grid grid-cols-2 sm:grid-cols-4 md:pb-8" },
      tabitem: {
        base: "flex items-center pl-1.5 justify-center rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-500",
        styles: {
          pills: {
            active: {
              on: "rounded-lg text-primary-600",
              off: "rounded-lg hover:text-gray-900 hover:bg-gray-100",
            },
          },
          underline: {
            base: "rounded-t-lg font-sans",
            active: {
              on: "text-primary-600 rounded-t-lg border-b-2 border-primary-600 pb-[7px] active",
              off: "hover:border-transparent pb-2 md:border-transparent md:border-transparent border-b border-gray-200 dark:border-gray-700 text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300",
            },
          },
        },
        icon: "mr-2 h-5 w-5",
      },
    },
    tabpanel: "py-0",
  },
  button: {
    base: "bg-primary-700 focus:ring-primary-300 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10",
    color: {
      primary:
        "text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800",
      secondary: "border bg-white hover:bg-gray-100 sm:w-full w-full",
    },

    outline: {
      on: "transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit",
    },
    size: {
      md: "text-sm px-3 py-2",
    },
  },
  textInput: {
    field: {
      input: {
        base: "flex box-border w-full border disabled:cursor-not-allowed disabled:opacity-50 focus:border-primary-300",
      },
    },
  },
  dropdown: {
    floating: {
      base: "z-10 w-fit rounded-xl divide-y divide-gray-100 shadow",
      content: "rounded-xl text-sm text-gray-700 dark:text-gray-200",
      target: "lg:w-fit md:w-fit sm:w-full w-full dark:text-white",
    },
    content: "",
    arrowIcon: "ml-2 h-4 w-4",
  },
  modal: {
    content: {
      inner: "relative rounded-lg bg-white shadow dark:bg-gray-800",
    },
    header: {
      base: "flex items-start justify-between rounded-t px-5 pt-5",
    },
  },
  navbar: {
    link: {
      active: {
        on: "block py-2 pr-4 pl-3 md:p-0 bg-primary-700 text-white dark:text-white md:bg-transparent md:text-primary-700 md:border-b-2 md:border-primary-600",
      },
      base: "block py-2 pr-4 pl-3 md:p-0 border-b border-gray-100 text-gray-700 hover:bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:border-0 md:hover:bg-transparent md:hover:text-primary-700 md:dark:hover:bg-transparent md:dark:hover:text-white",
    },
  },
  sidebar: {},
  textarea: {
    base: "block w-full text-sm p-4 rounded-lg border disabled:cursor-not-allowed disabled:opacity-50",
  },
  toggleSwitch: {
    toggle: {
      checked: {
        off: "!border-gray-200 !bg-gray-200 dark:!border-gray-600 dark:!bg-gray-700",
      },
    },
  },
  radio: {
    root: {
      base: "h-4 w-4 border border-gray-300 focus:ring-2 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:focus:bg-primary-600 dark:focus:ring-primary-600",
    },
  },
  pagination: {
    pages: {
      selector: {
        active:
          "z-10 px-3 py-2 leading-tight text-primary-600 border border-primary-300 bg-primary-50 hover:bg-primary-100 hover:text-primary-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white",
      },
    },
  },
}
