import BtnLoadingIcon from "@assets/icons/btnLoading"
import DatabaseIcon from "@assets/icons/database"
import NewspaperIcon from "@assets/icons/newspapper"
import UserIcon from "@assets/icons/user"
import UserAddIcon from "@assets/icons/user-add"
import { CardComponent } from "@components/card"
import ErrorComponent from "@components/error"
import ModalConfirm from "@components/modal-confirm"
import { modalConfirmTheme } from "@components/modal-confirm/style"
import QuillToolbar, { formats, modules } from "@components/rich-text/rich-text"
import ToastComponent from "@components/toast"
import { useScreenDetect } from "@hooks/use-screen-detect"
import AdminModelService from "@services/admin.models.service"
import { AdminModels } from "@type/admin.type"
import { Constants, commonStatusOptions } from "@utils/helpers/constants"
import {
  addSpanTags,
  sanitizeAndSaveContent,
} from "@utils/helpers/react-quill.helpers"
import axios from "axios"
import { Breadcrumb, Button, Label, Radio, TextInput } from "flowbite-react"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { HiChevronDown, HiChevronUp, HiNewspaper } from "react-icons/hi"
import Modal from "react-modal"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { useNavigate, useParams } from "react-router-dom"
import "./style/rich-text.css"

const categoryOrder = {
  [AdminModels.CategoryCodes.CONCILIATION]: {
    label: "Processo",
    icon: <NewspaperIcon />,
  },
  [AdminModels.CategoryCodes.PARTS]: {
    label: "Partes",
    icon: <UserAddIcon />,
  },
  [AdminModels.CategoryCodes.INFORMATION]: {
    label: "Informações",
    icon: <UserIcon />,
  },
}

Modal.setAppElement("#root")

export default function AdminModelMainPage() {
  const navigate = useNavigate()
  const { modelId } = useParams()
  const [errors, setErrors] = useState({
    codes: false,
    get: false,
    create: false,
  })
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const { isMobile } = useScreenDetect()
  const [title, setTitle] = useState("")
  const [variableCodes, setVariableCodes] = useState<
    undefined | AdminModels.VariableCodes[]
  >(undefined)
  const [status, setStatus] = useState(AdminModels.Status.PUBLISH_ALL)
  const [accordionOptionsOpened, setAccordionOptionsOpened] = useState<
    string[]
  >([])
  const [richText, setRichText] = useState("")
  const quillRef = useRef<ReactQuill | null>(null)
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({
    title: false,
    template: false,
    status: false,
  })

  useEffect(() => {
    AdminModelService.getVariableCodes()
      .then((data) => {
        setVariableCodes(data)
      })
      .catch(() =>
        setErrors((prev) => ({
          ...prev,
          codes: true,
        }))
      )
  }, [])

  useEffect(() => {
    if (modelId && modelId !== "create") {
      AdminModelService.findModel(modelId)
        .then((data) => {
          setTitle(data.title)
          const sanitizedHtml = sanitizeAndSaveContent(data.template)
          console.log(sanitizedHtml)
          setRichText(addSpanTags(sanitizedHtml))
          console.log(richText)
          setStatus(data.status)
        })
        .catch(() =>
          setErrors((prev) => ({
            ...prev,
            get: true,
          }))
        )
        .finally(() => setLoading(false))
    }
  }, [modelId])

  const groupedData: { [key: string]: AdminModels.VariableCodes[] } =
    useMemo(() => {
      if (variableCodes && variableCodes.length > 0) {
        return variableCodes?.reduce(
          (acc, item) => {
            const category = item.category

            if (!acc[category]) {
              acc[category] = []
            }

            acc[category].push(item)

            return acc
          },
          {} as Record<AdminModels.CategoryCodes, AdminModels.VariableCodes[]>
        )
      }
      return {}
    }, [variableCodes])

  const statusOptions = useMemo(() => {
    if (modelId === "create") {
      return commonStatusOptions
    }

    return [
      {
        value: AdminModels.Status.PUBLISH_ALL,
        label: Constants.PUBLISH_ALL,
      },
      ...commonStatusOptions,
    ]
  }, [modelId])

  const handleAddText = (string: string) => {
    const quill = quillRef.current?.getEditor()
    if (!quill) return
    const selection = quill.getSelection()

    if (selection) {
      const cursorPosition = selection.index
      quill.clipboard.dangerouslyPasteHTML(
        cursorPosition,
        `<span class="user-added-text"> {{${string}}} </span>`,
        "api"
      )

      quill.formatText(cursorPosition, string.length + 5, "color", "blue")
      const newHtml = quill.root.innerHTML
      setRichText(newHtml)
    }
  }

  const handleStatusChange = (selectedStatus: AdminModels.Status) => {
    setStatus(selectedStatus)
  }

  const handleCreateModel = () => {
    setLoading(true)
    const template = sanitizeAndSaveContent(richText)
    const newErrors = {
      title: title === "",
      template: template === "",
      status: !status,
    }

    setFormErrors(newErrors)

    if (Object.values(newErrors).some((item) => item === true)) {
      return
    }
    if (modelId !== "create" && modelId !== undefined) {
      AdminModelService.updateModel(modelId, {
        title,
        template,
        status,
      })
        .then(() => navigate("/admin/models"))
        .catch(() =>
          setErrors((prev) => ({
            ...prev,
            create: true,
          }))
        )
        .finally(() => setLoading(false))
    } else {
      AdminModelService.createModel({
        title,
        template,
        status,
      })
        .then(() => navigate("/admin/models"))
        .catch(() =>
          setErrors((prev) => ({
            ...prev,
            create: true,
          }))
        )
        .finally(() => setLoading(false))
    }
  }

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      try {
        const formData = new FormData()
        formData.append("image", file)

        // TBD implement the route to upload the image
        const response = await axios.post("/api/upload", formData)
        const quill = quillRef.current?.getEditor()

        if (response.data.url && quill) {
          const imageUrl = response.data.url

          const range = quill.getSelection()
          if (range) {
            quill.insertEmbed(range.index, "image", imageUrl)
          }
        }
      } catch (error) {
        console.error("Error uploading image:", error)
      }
    }
  }

  const renderTitleInput = () => (
    <>
      <Label value="Título" htmlFor="title" className="text-sm font-semibold" />
      <TextInput
        name="title"
        id="title"
        className="mt-2"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value)
          setFormErrors((prev) => ({ ...prev, title: false }))
        }}
        placeholder="Título"
        color={formErrors.title ? "failure" : ""}
      />
      {formErrors.title ? (
        <div className="text-red-600 text-sm mt-2">
          É necessário preencher este campo para continuar.
        </div>
      ) : null}
    </>
  )

  return (
    <>
      {errors.create && <ToastComponent />}
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col">
            <h1 className="text-3xl font-bold lg:ml-0 md:ml-0 sm:ml-8 ml-8">
              Modelos de documentos
            </h1>
          </div>
          <Breadcrumb
            aria-label="Default breadcrumb example"
            className=" items-center lg:flex hidden"
          >
            <Breadcrumb.Item icon={HiNewspaper}>
              <p>Administração</p>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="cursor-pointer"
              onClick={() => navigate("/admin/models")}
            >
              <p className="text-gray-500">Modelos</p>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {`${modelId === "create" ? "Novo" : "Editar"}`} Modelo
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <CardComponent
        bodyWithPadding
        header={
          <div className="mb-8 hidden md:block">{renderTitleInput()}</div>
        }
        body={
          errors.codes || errors.get ? (
            <ErrorComponent />
          ) : (
            <>
              <div className="flex md:flex-row flex-col-reverse relative h-auto">
                <div className="flex flex-col w-auto md:w-full mx-4 p-4 md:p-0 md:mx-0 bg-white shadow-lg rounded-lg">
                  <div className="mb-8 md:hidden block">
                    {renderTitleInput()}
                  </div>
                  <QuillToolbar handleImageUpload={handleImageUpload} />
                  <ReactQuill
                    ref={quillRef}
                    value={richText}
                    modules={modules}
                    formats={formats}
                    onChange={(value) => {
                      setRichText(value)
                      setFormErrors((prev) => ({ ...prev, template: false }))
                    }}
                  />
                </div>
                <aside className="mb-4 md:mb-0 md:min-w-[254px] mx-4 md:mx-0 w-auto border-gray-300 bg-white shadow-md px-4 pt-4 rounded-lg">
                  <div className="flex items-center justify-center mb-4">
                    <DatabaseIcon />
                    <h5 className="text-base font-medium py-1.5 px-2">
                      Dados variáveis
                    </h5>
                  </div>
                  {Object.entries(groupedData).map(([key, value]) => {
                    const category =
                      categoryOrder[key as keyof typeof categoryOrder]
                    return (
                      <div key={`model_category_${key}`}>
                        <button
                          className={`bg-gray-100 py-1.5 px-2 rounded-lg h-[40px] flex items-center justify-between cursor-pointer w-full ${
                            accordionOptionsOpened.includes(category.label)
                              ? "mb-6"
                              : "mb-0"
                          }`}
                          onClick={() => {
                            setAccordionOptionsOpened((prev) => {
                              const prevValues = [...prev]
                              const index = prevValues.find(
                                (item) => item === category.label
                              )
                              if (index) {
                                return prevValues.filter(
                                  (item) => item !== category.label
                                )
                              }
                              return prevValues.concat(category.label)
                            })
                          }}
                        >
                          <div className="flex items-center gap-2">
                            {category.icon}
                            <p className="text-base font-medium flex items-center">
                              {category.label}{" "}
                            </p>
                          </div>
                          <span>
                            {accordionOptionsOpened.includes(category.label) ? (
                              <HiChevronDown />
                            ) : (
                              <HiChevronUp />
                            )}
                          </span>
                        </button>
                        {!accordionOptionsOpened.includes(category.label) && (
                          <ul className="ml-12 mb-4">
                            {value.map((item) => (
                              <button
                                key={`category_${item.code}_${item.variableName}`}
                                className="flex gap-2.5 py-1.5 px-2 h-[42px] items-center text-base/font-medium"
                                onClick={() => handleAddText(item.code)}
                              >
                                {item.variableName}
                              </button>
                            ))}
                          </ul>
                        )}
                      </div>
                    )
                  })}
                </aside>
              </div>
              {formErrors.template ? (
                <div className="text-red-600 text-sm mt-2 hidden md:block">
                  É necessário preencher este campo para continuar.
                </div>
              ) : null}
              <div className="mt-8 mb-4 hidden md:flex">
                <div className="flex gap-4" role="group">
                  {statusOptions.map((option) => (
                    <div
                      className="flex items-center gap-2"
                      key={`hidden_md:flex_${option.value}`}
                    >
                      <Radio
                        className="checked:bg-primary-600"
                        checked={status === option.value}
                        onChange={() => handleStatusChange(option.value)}
                      />
                      <label
                        htmlFor="status"
                        className="text-xs font-normal text-gray-500"
                      >
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )
        }
        footer={
          <div className="flex md:col-span-2 gap-4 md:flex">
            <Button
              className="md:min-w-[100px] w-fit"
              onClick={handleCreateModel}
              color="primary"
            >
              {loading ? <BtnLoadingIcon /> : null}
              {status === AdminModels.Status.DRAFT
                ? "Salvar rascunho"
                : "Publicar documento"}
            </Button>
            <Button
              color="gray"
              className="md:min-w-[100px] w-fit"
              onClick={() => setIsConfirmationModalOpen(true)}
            >
              Cancelar
            </Button>
          </div>
        }
      />
      <Modal
        isOpen={isConfirmationModalOpen}
        onRequestClose={() => setIsConfirmationModalOpen(false)}
        style={modalConfirmTheme(isMobile)}
      >
        <ModalConfirm
          description=""
          buttonInput={
            <div className="flex md:col-span-2 gap-4">
              <Button
                color="gray"
                className="w-fit text-sm font-medium"
                onClick={() => navigate("/admin/models")}
              >
                Sair sem salvar
              </Button>
              <Button
                color="gray"
                className="w-fit text-primary-700 text-sm font-medium border-primary-700"
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                Continuar editando
              </Button>
            </div>
          }
        />
      </Modal>
    </>
  )
}
