import { BtnLoadingIcon, PlusIcon } from "@assets/icons"
import { CardComponent } from "@components/card"
import ErrorComponent from "@components/error"
import ModalConfirm from "@components/modal-confirm"
import { modalConfirmTheme } from "@components/modal-confirm/style"
import { Pagination } from "@components/pagination"
import RadioFilter from "@components/radio-filter"
import SearchInput from "@components/search-input"
import Search from "@components/search/search"
import SelectFilter from "@components/select-filter"
import ToastComponent from "@components/toast"
import { RoutesConstants as routes } from "@constants/routes"
import { useScreenDetect } from "@hooks/use-screen-detect"
import AdminService from "@services/admin.service"
import { customButton, modalFormTheme } from "@theme/custom"
import { AdminUser } from "@type/admin.type"
import { formatCpfCnpj } from "@utils/helpers/formatCpfCnpj.helper"
import { formatPhoneNumber } from "@utils/helpers/formatPhoneNumber.helper"
import { formatPostalCode } from "@utils/helpers/formatPostalCode.helper"
import { states } from "@utils/helpers/states.list"
import { cpf as cpfValidator } from "cpf-cnpj-validator"
import {
  Breadcrumb,
  Button,
  Flowbite,
  Label,
  Radio,
  Select,
  Tabs,
  TextInput,
} from "flowbite-react"
import { useFormik } from "formik"
import { isEqual } from "lodash"
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { HiNewspaper, HiOutlineX, HiUser, HiUserGroup } from "react-icons/hi"
import Modal from "react-modal"
import { useNavigate, useSearchParams } from "react-router-dom"
import * as yup from "yup"
import AdminUsersTable from "./components/table"
import * as theme from "./styles"

const validationSchema = yup
  .object({
    fullName: yup
      .string()
      .min(2, "Digite o nome completo")
      .required("É necessário preencher este campo para continuar.")
      .test(
        "test-invalid-full-name",
        "O campo deve conter apenas letras e espaços.",
        (fullName) => /^[\p{L}\s]+$/u.test(fullName)
      ),
    document: yup
      .string()
      .required("É necessário preencher este campo para continuar.")
      .test(
        "test-invalid-cpf-cnpj",
        "Algo parece estar errado. Por favor, verifique o número e tente novamente.",
        (cpfValue: string) => cpfValidator.isValid(cpfValue)
      ),
    kindOfPerson: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    email: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    phone: yup
      .string()
      .matches(/(\(\d{2}\)\s)(\d{4,5}-\d{4})/g, "Digite um telefone válido")
      .required("É necessário preencher este campo para continuar."),
    licenseNumber: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    profile: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    status: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    type: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    address: yup.object({
      postalCode: yup
        .string()
        .matches(/^\d{5}-\d{3}$/, "CEP deve estar no formato XXXXX-XXX")
        .required("É necessário preencher este campo para continuar."),
      streetName: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      number: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      complement: yup.string(),
      neighborhood: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      state: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      country: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      city: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
    }),
  })
  .required()

export type AdminUsersForm = yup.InferType<typeof validationSchema>

const initialState: AdminUsersForm = {
  fullName: "",
  document: "",
  kindOfPerson: "",
  email: "",
  phone: "",
  licenseNumber: "",
  profile: "",
  status: "",
  type: AdminUser.Type.INTERNAL,
  address: {
    postalCode: "",
    streetName: "",
    number: "",
    complement: "",
    neighborhood: "",
    state: "",
    country: "Brasil",
    city: "",
  },
}

const profileOptions = [
  { value: "ALL", label: "Tipo de perfil" },
  { value: "SPECIALIST", label: "Especialista" },
  { value: "SECRETARY", label: "Responsável" },
]

const statusOptions = [
  {
    label: "Todos",
    value: AdminUser.All.ALL,
    name: AdminUser.All.ALL,
  },
  {
    label: "Ativos",
    value: AdminUser.Status.ENABLE,
    name: AdminUser.Status.ENABLE,
  },
  {
    label: "Inativos",
    value: AdminUser.Status.DISABLE,
    name: AdminUser.Status.DISABLE,
  },
]

Modal.setAppElement("#root")

export default function AdminUsersMainPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [errors, setErrors] = useState({
    get: false,
    getOne: false,
    put: false,
    post: false,
  })
  const [loadingSubmission, setLoadingSubmission] = useState(false)
  const [initialUsers, setInitialUsers] = useState<AdminUser.Item[]>([])
  const [filtered, setFiltered] = useState<AdminUser.Item[]>([])
  const [status, setStatus] = useState<AdminUser.Status | AdminUser.All>(
    AdminUser.All.ALL
  )
  const [profile, setProfile] = useState<AdminUser.Profile | AdminUser.All>(
    AdminUser.All.ALL
  )
  const [searchText, setSearchText] = useState("")
  const [activeTab, setActiveTab] = useState(0)
  const [ascending, setAscending] = useState(0)
  const [editUser, setEditUser] = useState<AdminUser.Item | undefined>()
  const [currentPage, setCurrentPage] = useState<number>(
    searchParams.get("page") ? Number(searchParams.get("page")) : 1
  )
  const [adminUsersPage, setAdminUsersPage] =
    useState<AdminUser.PaginatedAdminUsers>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const { isMobile } = useScreenDetect()

  const type = useMemo(
    () => (activeTab === 1 ? AdminUser.Type.END : AdminUser.Type.INTERNAL),
    [activeTab]
  )

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    setStatus(event.target.value as AdminUser.Status)
  }

  const handleProfileChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setProfile(event.target.value as AdminUser.Profile)
  }

  const closeModal = () => {
    formik.resetForm()
    setIsModalOpen(false)
    setIsConfirmationModalOpen(false)
  }

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleSearch = () => {
    fetchUsers(currentPage)
    setSearchText("")
  }

  const toggleSortingOrder = () => {
    setAscending((prev) => prev + 1)
    setFiltered((prev) => {
      if (ascending === 2) {
        setAscending(0)
        return initialUsers
      }
      const prevUsers = [...prev]
      return prevUsers.sort((a, b) => {
        if (ascending === 0) {
          return b.status.localeCompare(a.status)
        } else {
          return a.status.localeCompare(b.status)
        }
      })
    })
  }

  const fetchUsers = async (page: number) => {
    const isEndUsers = activeTab === 1
    const isProfileAll = profile === AdminUser.All.ALL

    const params = {
      type: isEndUsers ? AdminUser.Type.END : AdminUser.Type.INTERNAL,
      status: status === AdminUser.All.ALL ? undefined : status,
      profile: isProfileAll || isEndUsers ? undefined : profile,
      search: searchText === "" ? undefined : searchText,
      page,
    }

    AdminService.findAllUsers(params)
      .then((data) => {
        setAdminUsersPage(data)
        setFiltered(data.items)
        setInitialUsers(data.items)
      })
      .catch((err) => {
        console.error(err)
        setErrors((prev) => ({ ...prev, get: true }))
      })
  }

  const getUser = useCallback((id: string) => {
    AdminService.findUser(id)
      .then((data) => {
        setEditUser(data)
        const { ...form } = data
        form.document = formatCpfCnpj(data.document, "cpf") as string
        if (form?.address?.postalCode) {
          form.address.postalCode = formatPostalCode(data.address.postalCode)
        }
        if (form.phone) {
          form.phone = formatPhoneNumber(data.phone.replace(/[^0-9]/g, ""))
        }
        formik.setValues(form)
        setIsModalOpen(true)
      })
      .catch((err) => {
        console.error(err)
        setEditUser(undefined)
        setErrors((prev) => ({ ...prev, getOne: true }))
        closeModal()
      })
  }, [])

  const createUser = (form: Omit<AdminUser.Item, "id">) => {
    AdminService.postUser(form)
      .then(() => {
        fetchUsers(currentPage)
      })
      .catch((err) => {
        console.error(err)
        setErrors((prev) => ({ ...prev, post: true }))
      })
      .finally(() => {
        closeModal()
        setLoadingSubmission(false)
      })
  }

  const updateUser = (form: Omit<AdminUser.Item, "id">) => {
    if (editUser?.id === undefined) return
    AdminService.putUser(form, editUser?.id)
      .then(() => {
        fetchUsers(currentPage)
      })
      .catch((err) => {
        console.error(err)
        setErrors((prev) => ({ ...prev, put: true }))
      })
      .finally(() => {
        closeModal()
        setLoadingSubmission(false)
      })
  }

  const onNavigatePage = useCallback(
    (toPage: number) => {
      setCurrentPage(toPage)
      navigate({
        pathname: routes.adminUsers,
        search: `?page=${toPage}`,
      })
      fetchUsers(toPage)
    },
    [navigate]
  )

  useEffect(() => {
    fetchUsers(currentPage)
  }, [activeTab, status, profile])

  const formik = useFormik<AdminUsersForm>({
    initialValues: initialState,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      values.type = type
      onSubmit(values)
    },
  })

  const onSubmit = (values: AdminUsersForm) => {
    setLoadingSubmission(true)
    const form = values as Omit<AdminUser.Item, "id">

    if (editUser) {
      return updateUser(form)
    }
    return createUser(form)
  }

  const addUser = () => {
    setEditUser(undefined)
    formik.resetForm()
    setIsModalOpen(true)
  }

  const KindOfPersonRadioWithType = ({
    formik,
    kindOfPerson,
    label,
    type,
  }: {
    formik: ReturnType<typeof useFormik<AdminUsersForm>>
    kindOfPerson: string
    label: string
    type?: string
  }) => {
    return (
      <div className="flex items-center gap-2">
        <Radio
          className="checked:bg-primary-600"
          onChange={() => formik.setFieldValue("kindOfPerson", kindOfPerson)}
          checked={formik.values.kindOfPerson === kindOfPerson}
          disabled={type ? type === AdminUser.Type.END : false}
        />
        <Label
          htmlFor="kindOfPerson"
          color={formik.errors.status ? "failure" : ""}
          className="text-xs font-normal text-gray-500"
        >
          {label}
        </Label>
      </div>
    )
  }

  return (
    <>
      {(errors.getOne || errors.post || errors.put) && <ToastComponent />}
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col">
            <h1 className="text-3xl font-bold lg:ml-0 md:ml-0 sm:ml-8 ml-8">
              Usuários
            </h1>
          </div>
          <Breadcrumb
            aria-label="Default breadcrumb example"
            className=" items-center lg:flex hidden"
          >
            <Breadcrumb.Item icon={HiNewspaper}>
              <p>Administração</p>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Usuários</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <CardComponent
        header={
          <>
            <Tabs.Group
              style="underline"
              onActiveTabChange={(tab) => setActiveTab(tab)}
            >
              <Tabs.Item active title="Usuários internos" icon={HiUserGroup} />
              <Tabs.Item title="Usuários finais" icon={HiUser} />
            </Tabs.Group>
            <Search
              searchInput={
                <SearchInput
                  placeholder="Busque por nome, CPF/CNPJ ou email"
                  handleSearch={handleSearch}
                  handleSearchText={handleSearchText}
                  searchText={searchText}
                />
              }
              selectInput={
                type !== AdminUser.Type.END && (
                  <SelectFilter
                    onChange={handleProfileChange}
                    value={profile}
                    options={profileOptions}
                  />
                )
              }
              radioInput={
                <>
                  <p className="text-gray-900 font-medium text-sm">Mostrar</p>{" "}
                  <RadioFilter
                    onChange={handleStatusChange}
                    value={status}
                    options={statusOptions}
                  />
                </>
              }
              buttonInput={
                type !== AdminUser.Type.END && (
                  <Flowbite theme={{ theme: customButton }}>
                    <Button size="xs" onClick={addUser} color="primary">
                      <span className="mr-1">
                        <PlusIcon color="white" hoverColor="white" />
                      </span>
                      Adicionar usuário
                    </Button>
                  </Flowbite>
                )
              }
            />
          </>
        }
        body={
          errors.get ? (
            <ErrorComponent />
          ) : (
            <AdminUsersTable
              getUser={getUser}
              filtered={filtered}
              toggleSortingOrder={toggleSortingOrder}
              type={type}
            />
          )
        }
        footer={
          <Pagination
            onNavigatePage={onNavigatePage}
            currentPage={currentPage}
            currentTotal={adminUsersPage?.items.length ?? 0}
            itemsPerPage={adminUsersPage?.itemsPerPage ?? 10}
            pages={adminUsersPage?.pages ?? 0}
            total={adminUsersPage?.count ?? 0}
          />
        }
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          if (type === AdminUser.Type.END) {
            return closeModal()
          }

          if (isEqual(formik.values, editUser)) {
            return closeModal()
          }

          setIsConfirmationModalOpen(true)
        }}
        style={{ ...modalFormTheme(isMobile) }}
      >
        <div className="flex justify-between">
          <h5 className="mb-4 uppercase text-gray-500 font-semibold">
            {type === AdminUser.Type.END
              ? "Visualizar dados de usuários finais"
              : `${editUser ? "Edição" : "Adicionar"} usuários internos`}
          </h5>
          <HiOutlineX
            size={18}
            color="#6B7280"
            className="cursor-pointer"
            onClick={() => {
              if (type === AdminUser.Type.END) {
                return closeModal()
              }
              setIsConfirmationModalOpen(true)
            }}
          />
        </div>
        <form
          className="w-full grid grid-cols-2 gap-4"
          onSubmit={formik.handleSubmit}
        >
          <div className="col-span-2">
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="fullName"
                value="Nome completo"
              />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">{formik.values.fullName}</p>
            ) : (
              <>
                <TextInput
                  id="fullName"
                  placeholder="Nome Completo"
                  color={formik.errors.fullName ? "failure" : ""}
                  {...formik.getFieldProps("fullName")}
                />
                {formik.touched.fullName && formik.errors.fullName ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.fullName}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div
            className={`${
              type === AdminUser.Type.END
                ? "col-span-2"
                : "col-span-2 md:col-span-1"
            }`}
          >
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="document"
                value="CPF/CNPJ"
              />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">
                {formatCpfCnpj(formik.values.document)}
              </p>
            ) : (
              <>
                <TextInput
                  id="document"
                  name="document"
                  color={formik.errors.document ? "failure" : ""}
                  onChange={(e) => {
                    formik.setFieldValue("document", e.target.value)
                    formatCpfCnpj(e.target.value)
                  }}
                  maxLength={18}
                  placeholder="Número de CPF/CNPJ"
                  value={formatCpfCnpj(formik.values.document) || ""}
                />
                {formik.touched.document && formik.errors.document ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.document}
                  </div>
                ) : null}
              </>
            )}
          </div>
          {!(type === AdminUser.Type.END) && (
            <div className="col-span-2 md:col-span-1">
              <div className="flex items-center mb-2">
                <Label htmlFor="kindOfPerson" value="Tipo de Pessoa" />
              </div>
              <div className="flex gap-4" role="group">
                <KindOfPersonRadioWithType
                  formik={formik}
                  kindOfPerson={AdminUser.KindPerson.PF}
                  label="Física"
                />
                <KindOfPersonRadioWithType
                  formik={formik}
                  kindOfPerson={AdminUser.KindPerson.PJ}
                  label="Jurídica"
                />
              </div>
              {formik.touched.kindOfPerson && formik.errors.kindOfPerson ? (
                <div className="text-red-600 text-sm mt-2">
                  {formik.errors.kindOfPerson}
                </div>
              ) : null}
            </div>
          )}
          <div
            className={`${
              type === AdminUser.Type.END
                ? "col-span-2"
                : "col-span-2 md:col-span-1"
            }`}
          >
            <div className="flex items-center mb-2">
              <Label className="font-semibold" htmlFor="email" value="E-mail" />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">{formik.values.email}</p>
            ) : (
              <>
                <TextInput
                  id="email"
                  placeholder="requerente@email.com.br"
                  color={formik.errors.email ? "failure" : ""}
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.email}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div
            className={`${
              type === AdminUser.Type.END
                ? "col-span-2"
                : "col-span-2 md:col-span-1"
            }`}
          >
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="phone"
                value="Telefone/WhatsApp"
              />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">
                {formatPhoneNumber(formik.values.phone)}
              </p>
            ) : (
              <>
                <TextInput
                  id="phone"
                  name="phone"
                  color={formik.errors.phone ? "failure" : ""}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "phone",
                      formatPhoneNumber(e.target.value)
                    )
                  }}
                  maxLength={15}
                  placeholder="(99) 99999-9999"
                  value={formatPhoneNumber(formik.values.phone) || ""}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </>
            )}
          </div>
          {type === AdminUser.Type.END ? (
            <>
              <div className="col-span-2 md:col-span-1">
                <div className="flex items-center mb-2">
                  <Label
                    className="font-semibold"
                    htmlFor="kindOfPerson"
                    value="Tipo de Pessoa"
                  />
                </div>
                <div className="flex gap-4" role="group">
                  <KindOfPersonRadioWithType
                    formik={formik}
                    kindOfPerson={AdminUser.KindPerson.PF}
                    label="Física"
                    type={type}
                  />
                  <KindOfPersonRadioWithType
                    formik={formik}
                    kindOfPerson={AdminUser.KindPerson.PJ}
                    label="Jurídica"
                    type={type}
                  />
                </div>
                {formik.touched.kindOfPerson && formik.errors.kindOfPerson ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.kindOfPerson}
                  </div>
                ) : null}
              </div>
              <div className="col-span-2 md:col-span-1">
                <div className="flex items-center mb-2">
                  <Label
                    className="font-semibold"
                    htmlFor="kindOfPerson"
                    value="É advogado(a)?"
                  />
                </div>
                <div className="flex gap-4" role="group">
                  <div className="flex items-center gap-2">
                    <Radio
                      className="checked:bg-primary-600"
                      checked={formik.values.licenseNumber !== ""}
                      disabled={type === AdminUser.Type.END}
                    />
                    <Label
                      htmlFor="kindOfPerson"
                      className="text-xs font-normal text-gray-500"
                      color={formik.errors.status ? "failure" : ""}
                    >
                      Sim
                    </Label>
                  </div>
                  <div className="flex items-center gap-2">
                    <Radio
                      className="checked:bg-primary-600"
                      checked={formik.values.licenseNumber === ""}
                      disabled={type === AdminUser.Type.END}
                    />
                    <Label
                      htmlFor="kindOfPerson"
                      color={formik.errors.status ? "failure" : ""}
                      className="text-xs font-normal text-gray-500"
                    >
                      Não
                    </Label>
                  </div>
                </div>
                {formik.touched.kindOfPerson && formik.errors.kindOfPerson ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.kindOfPerson}
                  </div>
                ) : null}
              </div>
            </>
          ) : (
            <div className="col-span-2 md:col-span-1">
              <div className="flex items-center mb-2">
                <Label
                  className="font-semibold"
                  htmlFor="profile"
                  value="Perfil"
                />
              </div>
              <div className="flex gap-4" role="group">
                <div className="flex items-center gap-2">
                  <Radio
                    className="checked:bg-primary-600"
                    onChange={() =>
                      formik.setFieldValue(
                        "profile",
                        AdminUser.Profile.SPECIALIST
                      )
                    }
                    checked={
                      formik.values.profile === AdminUser.Profile.SPECIALIST
                    }
                  />
                  <Label
                    className="text-xs font-normal text-gray-500"
                    htmlFor="profile"
                    color={formik.errors.status ? "failure" : ""}
                  >
                    Especialista
                  </Label>
                </div>
                <div className="flex items-center gap-2">
                  <Radio
                    className="checked:bg-primary-600"
                    onChange={() =>
                      formik.setFieldValue(
                        "profile",
                        AdminUser.Profile.SECRETARY
                      )
                    }
                    checked={
                      formik.values.profile === AdminUser.Profile.SECRETARY
                    }
                  />
                  <Label
                    className="text-xs font-normal text-gray-500"
                    htmlFor="profile"
                    color={formik.errors.status ? "failure" : ""}
                  >
                    Responsável
                  </Label>
                </div>
              </div>
              {formik.touched.profile && formik.errors.profile ? (
                <div className="text-red-600 text-sm mt-2">
                  {formik.errors.profile}
                </div>
              ) : null}
            </div>
          )}
          {!(
            type === AdminUser.Type.END && formik.values.licenseNumber === ""
          ) && (
            <div
              className={`${
                type === AdminUser.Type.END
                  ? "col-span-2"
                  : "col-span-2 md:col-span-1"
              }`}
            >
              <div className="flex items-center mb-2">
                <Label
                  className="font-semibold"
                  htmlFor="licenseNumber"
                  value="OAB"
                />
              </div>
              {type === AdminUser.Type.END ? (
                <p className="text-sm font-normal">
                  {formik.values.licenseNumber}
                </p>
              ) : (
                <>
                  <TextInput
                    id="licenseNumber"
                    color={formik.errors.licenseNumber ? "failure" : ""}
                    {...formik.getFieldProps("licenseNumber")}
                  />
                  {formik.touched.licenseNumber &&
                  formik.errors.licenseNumber ? (
                    <div className="text-red-600 text-sm mt-2">
                      {formik.errors.licenseNumber}
                    </div>
                  ) : null}
                </>
              )}
            </div>
          )}
          <div className={"col-span-2 md:col-span-1"}>
            <Label
              htmlFor="address.postalCode"
              value="CEP"
              className="mb-2 block font-semibold"
            />
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">
                {formatPostalCode(formik.values.address?.postalCode)}
              </p>
            ) : (
              <>
                <TextInput
                  id="address.postalCode"
                  name="address.postalCode"
                  placeholder="00000-000"
                  maxLength={9}
                  color={formik.errors.address?.postalCode ? "failure" : ""}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "address.postalCode",
                      formatPostalCode(e.target.value)
                    )
                  }}
                  value={formatPostalCode(formik.values.address?.postalCode)}
                />
                {formik.touched.address?.postalCode &&
                formik.errors.address?.postalCode ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.address?.postalCode}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className={"col-span-2 md:col-span-1"}>
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="address.streetName"
                value="Endereço"
              />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">
                {formik.values.address?.streetName}
              </p>
            ) : (
              <>
                <TextInput
                  id="address.streetName"
                  color={formik.errors.address?.streetName ? "failure" : ""}
                  {...formik.getFieldProps("address.streetName")}
                  placeholder="Rua, Avenida, etc."
                />
                {formik.touched.address?.streetName &&
                formik.errors.address?.streetName ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.address?.streetName}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className={"col-span-2 md:col-span-1"}>
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="address.number"
                value="Nº"
              />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">
                {formik.values.address?.number}
              </p>
            ) : (
              <>
                <TextInput
                  id="address.number"
                  color={formik.errors.address?.number ? "failure" : ""}
                  {...formik.getFieldProps("address.number")}
                  placeholder="Número ou s/n"
                />
                {formik.touched.address?.number &&
                formik.errors.address?.number ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.address?.number}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className={"col-span-2 md:col-span-1"}>
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="address.complement"
                value="Complemento"
              />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">
                {formik.values.address?.complement}
              </p>
            ) : (
              <TextInput
                id="address.complement"
                {...formik.getFieldProps("address.complement")}
                placeholder="Apartamento, sala, conjunto, etc."
              />
            )}
          </div>
          <div className={"col-span-2 md:col-span-1"}>
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="address.neighborhood"
                value="Bairro"
              />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">
                {formik.values.address?.neighborhood}
              </p>
            ) : (
              <>
                <TextInput
                  id="address.neighborhood"
                  placeholder="Bairro"
                  color={formik.errors.address?.neighborhood ? "failure" : ""}
                  {...formik.getFieldProps("address.neighborhood")}
                />
                {formik.touched.address?.neighborhood &&
                formik.errors.address?.neighborhood ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.address?.neighborhood}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className={"col-span-2 md:col-span-1"}>
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="address.city"
                value="Cidade"
              />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">
                {formik.values.address?.city}
              </p>
            ) : (
              <>
                <TextInput
                  id="address.city"
                  color={formik.errors.address?.city ? "failure" : ""}
                  {...formik.getFieldProps("address.city")}
                />
                {formik.touched.address?.city && formik.errors.address?.city ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.address?.city}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className={"col-span-2 md:col-span-1"}>
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="address.state"
                value="Estado"
              />
            </div>
            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal">
                {
                  states.find(
                    (state) => state.sigla === formik.values.address?.state
                  )?.nome
                }
              </p>
            ) : (
              <>
                <Select
                  id="address.state"
                  theme={theme.customSelect}
                  color={formik.errors.address?.state ? "failure" : ""}
                  {...formik.getFieldProps("address.state")}
                >
                  <option disabled value="">
                    Selecione
                  </option>
                  {states.map((state) => (
                    <option
                      key={`admin_user_${state.sigla}`}
                      value={state.sigla}
                    >
                      {state.nome}
                    </option>
                  ))}
                </Select>
              </>
            )}
            {formik.touched.address?.state && formik.errors.address?.state ? (
              <div className="text-red-600 text-sm mt-2">
                {formik.errors.address?.state}
              </div>
            ) : null}
          </div>
          <div className={"col-span-2 md:col-span-1"}>
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="address.country"
                value="País"
              />
            </div>

            {type === AdminUser.Type.END ? (
              <p className="text-sm font-normal capitalize">
                {formik.values.address?.country?.toLowerCase()}
              </p>
            ) : (
              <>
                <TextInput
                  id="address.country"
                  {...formik.getFieldProps("address.country")}
                  disabled
                />
                {formik.touched.address?.country &&
                formik.errors.address?.country ? (
                  <div className="text-red-600 text-sm mt-2">
                    {formik.errors.address?.country}
                  </div>
                ) : null}
              </>
            )}
          </div>
          <div className="col-span-2">
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="status"
                value="Status"
              />
            </div>
            <div className="flex gap-4" role="group">
              <div className="flex items-center gap-2">
                <Radio
                  className="checked:bg-primary-600"
                  onChange={() =>
                    formik.setFieldValue("status", AdminUser.Status.ENABLE)
                  }
                  checked={formik.values.status === AdminUser.Status.ENABLE}
                  disabled={type === AdminUser.Type.END}
                />
                <Label
                  className="text-xs font-normal text-gray-500"
                  htmlFor="status"
                  color={formik.errors.status ? "failure" : ""}
                >
                  Ativo
                </Label>
              </div>
              <div className="flex items-center gap-2">
                <Radio
                  className="checked:bg-primary-600"
                  onChange={() =>
                    formik.setFieldValue("status", AdminUser.Status.DISABLE)
                  }
                  checked={formik.values.status === AdminUser.Status.DISABLE}
                  disabled={type === AdminUser.Type.END}
                />
                <Label
                  className="text-xs font-normal text-gray-500"
                  htmlFor="status"
                  color={formik.errors.status ? "failure" : ""}
                >
                  Inativo
                </Label>
              </div>
              {type === AdminUser.Type.END && (
                <div className="flex items-center gap-2">
                  <Radio
                    className="checked:bg-primary-600"
                    onChange={() =>
                      formik.setFieldValue("status", AdminUser.Status.DISABLE)
                    }
                    checked={formik.values.status === AdminUser.Status.DISABLE}
                    disabled={type === AdminUser.Type.END}
                  />
                  <Label
                    className="text-xs font-normal text-gray-500"
                    htmlFor="status"
                    color={formik.errors.status ? "failure" : ""}
                  >
                    Observador
                  </Label>
                </div>
              )}
            </div>
            {formik.touched.status && formik.errors.status ? (
              <div className="text-red-600 text-sm mt-2">
                {formik.errors.status}
              </div>
            ) : null}
          </div>
          <div className="flex md:col-span-2 mt-4 gap-4">
            <Button
              className="md:min-w-[100px] w-fit"
              type="submit"
              disabled={!formik.dirty}
              color="primary"
            >
              {loadingSubmission ? <BtnLoadingIcon /> : null}
              Salvar
            </Button>
            <Button
              color="gray"
              className="md:min-w-[100px] w-fit"
              onClick={() => {
                if (type === AdminUser.Type.END) {
                  return closeModal()
                }
                return setIsConfirmationModalOpen(true)
              }}
            >
              Cancelar
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={isConfirmationModalOpen}
        onRequestClose={() => setIsConfirmationModalOpen(false)}
        style={{ ...modalConfirmTheme(isMobile) }}
      >
        <ModalConfirm
          buttonInput={
            <div className="flex md:col-span-2 gap-4">
              <Button
                color="gray"
                className="w-fit text-sm font-medium"
                onClick={closeModal}
              >
                Sair sem salvar
              </Button>
              <Button
                color="gray"
                className="w-fit text-primary-700 text-sm font-medium border-primary-700"
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                Continuar editando
              </Button>
            </div>
          }
        />
      </Modal>
    </>
  )
}
