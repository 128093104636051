import AuthHelper from "@utils/helpers/auth.helper"
import axios from "axios"
import TokenStorage from "../data/token.storage"
import AuthService from "./auth.service"

const ERRORS = {
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  API_ERROR: "API_ERROR",
}

const axiosBase = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

axiosBase.interceptors.request.use(function (config) {
  const token = TokenStorage.getJWTToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

// Intercepta as respostas para lidar com erros de autenticação (401) e atualizar o token JWT automaticamente.
axiosBase.interceptors.response.use(
  (response) => response,
  async (error) => {
    const checkIsAuth = (url: string) => {
      if (url.endsWith("is_authenticated")) {
        return false
      }
      const regex = /\/auth\/([a-z_]+)/g
      return regex.test(url)
    }
    try {
      if (!checkIsAuth(error.request.responseURL)) {
        // Verifica se a resposta é um erro 401 (não autorizado).
        if (error.response && error.response.status === 401) {
          const refreshToken = TokenStorage.getJWTRefreshToken()

          if (refreshToken) {
            // Verifica se o token de atualização ainda é válido.
            const refreshTokenData = TokenStorage.parseJWTToken(refreshToken)
            const now = new Date().getTime()
            if (refreshTokenData.exp * 1000 < now) {
              // Se o token de atualização estiver expirado, lança um erro.
              throw new Error(ERRORS.TOKEN_EXPIRED)
            }

            // Atualiza o token JWT com um novo token de acesso.
            try {
              const newToken = await AuthService.refreshToken(refreshToken)
              const newAccessToken = newToken.accessToken
              const newRefreshToken = newToken.refreshToken
              TokenStorage.setJWTToken(
                newAccessToken.token,
                parseInt(newAccessToken.expiresIn)
              )
              TokenStorage.setJWTRefreshToken(
                newRefreshToken.token,
                parseInt(newRefreshToken.expiresIn)
              )

              // Atualiza o cabeçalho de autorização da solicitação original e tenta novamente.
              error.config.headers.Authorization = `Bearer ${newToken}`
              return axiosBase.request(error.config)
            } catch (error) {
              throw new Error(ERRORS.TOKEN_EXPIRED)
            }
          }
          throw new Error(ERRORS.TOKEN_EXPIRED)
        }
      }
      if (error.code === "ERR_NETWORK" || error.response.status === 500) {
        throw Error(ERRORS.API_ERROR)
      }
    } catch (error) {
      // Se ocorrer um erro ao atualizar o token JWT, limpa o armazenamento local e redireciona o usuário para a tela de login.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (error.message === ERRORS.API_ERROR) {
        if (
          // @ts-ignore
          error.response.status != undefined &&
          // @ts-ignore
          error.response.status >= 500
        ) {
          AuthHelper.redirectToErrorStatus()
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      } else if (error.message === ERRORS.TOKEN_EXPIRED) {
        TokenStorage.clearTokens()
        AuthHelper.redirectToLogin()
      }
    }

    // Se ocorrer outro erro, lança-o para o chamador lidar com ele.
    return Promise.reject(error)
  }
)

export default axiosBase
