import React from "react"

export default function PaperAirPlaneIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.71652 2.04311C8.65001 1.9104 8.54789 1.79882 8.42158 1.72084C8.29528 1.64286 8.14976 1.60156 8.00132 1.60156C7.85288 1.60156 7.70737 1.64286 7.58106 1.72084C7.45476 1.79882 7.35263 1.9104 7.28612 2.04311L1.68612 13.2431C1.61664 13.382 1.58907 13.5381 1.60678 13.6923C1.6245 13.8466 1.68673 13.9924 1.78588 14.1119C1.88503 14.2314 2.01682 14.3195 2.16517 14.3654C2.31352 14.4112 2.47201 14.413 2.62132 14.3703L6.62132 13.2271C6.7885 13.1793 6.93555 13.0783 7.04021 12.9394C7.14486 12.8006 7.20142 12.6314 7.20132 12.4575V8.80071C7.20132 8.58854 7.28561 8.38505 7.43564 8.23502C7.58567 8.08499 7.78915 8.00071 8.00132 8.00071C8.2135 8.00071 8.41698 8.08499 8.56701 8.23502C8.71704 8.38505 8.80132 8.58854 8.80132 8.80071V12.4575C8.80123 12.6314 8.85779 12.8006 8.96244 12.9394C9.06709 13.0783 9.21414 13.1793 9.38132 13.2271L13.3813 14.3695C13.5306 14.4123 13.6891 14.4106 13.8374 14.3649C13.9858 14.3191 14.1176 14.2311 14.2168 14.1117C14.3161 13.9923 14.3784 13.8466 14.3963 13.6924C14.4141 13.5381 14.3867 13.382 14.3173 13.2431L8.71732 2.04311H8.71652Z"
        fill="white"
      />
    </svg>
  )
}
