export class RoutesConstants {
  static page500 = "/500"
  static page404 = "/404"
  static signIn = "/signin"
  static verifyToken = "/verify-token"
  static verifyEmail = "/verify-email"
  static signUp = "/signup"
  static home = "/"
  static conciliations = "/conciliations"
  static conciliationsCreate = "/conciliations/create"
  static notifications = "/notifications"
  static payment = "/payment"
  static profile = "/profile"
  static pendencies = "/pendencies"
  static admin = "/admin"
  static users = "/users"
  static adminUsers = `${this.admin}${this.users}`
  static models = "/models"
  static adminModels = `${this.admin}${this.models}`
}
