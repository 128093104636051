import { ReactNode } from "react"

type SearchType = {
  searchInput?: ReactNode
  selectInput?: ReactNode
  radioInput?: ReactNode
  buttonInput?: ReactNode
}

export default function Search({
  searchInput,
  selectInput,
  radioInput,
  buttonInput,
}: SearchType) {
  return (
    <>
      <div className="flex flex-col max(820px):flex-col md:flex-row md:items-center md:border-b md:border-gray-200 md:pb-4 justify-between gap-4">
        <div className="flex flex-1 flex-col max(820px):flex-col md:flex-row max(820px):w-full">
          {searchInput || selectInput ? (
            <div className="flex gap-4">
              {searchInput ? searchInput : null}
              {selectInput ? (
                <div className="hidden max(820px):hidden md:flex">
                  {selectInput}
                </div>
              ) : null}
            </div>
          ) : null}
          {selectInput || buttonInput ? (
            <div className="flex mt-4 justify-between">
              {selectInput ? (
                <div className="flex max(820px):flex md:hidden">
                  {selectInput}
                </div>
              ) : null}
              {buttonInput ? (
                <div className="block max(820px):block md:hidden font-medium text-xs text-primary-800 whitespace-no-wrap">
                  {buttonInput}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {buttonInput ? (
          <div className="hidden max(820px):hidden text-xs font-medium text-primary-800 md:block mb-0 whitespace-no-wrap">
            {buttonInput}
          </div>
        ) : null}
      </div>
      {radioInput ? (
        <div className="flex mt-2 md:mt-0 gap-4 p-4">{radioInput}</div>
      ) : null}
    </>
  )
}
