import FullLoading from "@components/full-loading"
import ModalConfirm from "@components/modal-confirm"
import { modalConfirmTheme } from "@components/modal-confirm/style"
import { AbilityContext } from "@context/ability.provider"
import SectionPendingActions from "@features/conciliation/pages/details-updates/sections/pending-actions"
import SectionUpdates from "@features/conciliation/pages/details-updates/sections/updates"
import { useScreenDetect } from "@hooks/use-screen-detect"
import ConciliationDetailsService from "@services/conciliation-details.service"
import {
  ConciliationPendingAction,
  ConciliationUpdate,
} from "@type/conciliation.type"
import { Button, Label } from "flowbite-react"
import React, { useContext, useEffect, useState } from "react"
import { HiExclamation } from "react-icons/hi"
import Modal from "react-modal"
import { useParams } from "react-router-dom"
import { ConciliationErrors } from "../../types/conciliation-detail.type"

const initialState = {
  text: "",
  open: false,
  loading: false,
  error: false,
  attachments: [],
}

function ConciliationUpdates({
  setError,
}: {
  setError: React.Dispatch<React.SetStateAction<ConciliationErrors>>
}) {
  const { isMobile } = useScreenDetect()
  const ability = useContext(AbilityContext)
  const { conciliationNumber } = useParams()
  const [loading, setLoading] = useState(false)
  const [removeUpdateId, setRemoveUpdateId] = useState<number | undefined>(
    undefined
  )
  const [loadingPendingActions, setLoadingPendingActions] = useState(false)
  const [update, setUpdate] = useState<ConciliationUpdate.Data>(initialState)
  const [deleteReasonText, setDeleteReasonText] = useState("")
  const [conciliationUpdates, setConciliationUpdates] = useState<
    ConciliationUpdate.Item[]
  >([])
  const [pendingActions, setPendingActions] =
    useState<ConciliationPendingAction.Data>({
      items: [],
      updatedItems: [],
    })

  const fetchConciliationUpdates = async () => {
    setLoading(true)
    ConciliationDetailsService.findAllUpdates(conciliationNumber)
      .then((data) => setConciliationUpdates(data))
      .catch(() => setError((prev) => ({ ...prev, updates: true })))
      .finally(() => setLoading(false))
  }

  const fetchConciliationPendingActions = async () => {
    setLoading(true)
    ConciliationDetailsService.findAllPendingActions(conciliationNumber)
      .then((data) => setPendingActions((prev) => ({ ...prev, items: data })))
      .catch(() => setError((prev) => ({ ...prev, updates: true })))
      .finally(() => setLoadingPendingActions(false))
  }

  useEffect(() => {
    fetchConciliationUpdates()
  }, [])

  useEffect(() => {
    fetchConciliationPendingActions()
  }, [])

  const removeUpdate = () => {
    if (!removeUpdateId) {
      return setRemoveUpdateId(undefined)
    }

    ConciliationDetailsService.removeUpdate(removeUpdateId, conciliationNumber)
      .catch(() => setError((prev) => ({ ...prev, removeUpdate: true })))
      .then(() =>
        ConciliationDetailsService.findAllUpdates(conciliationNumber)
          .then((data) => setConciliationUpdates(data))
          .catch(() => setError((prev) => ({ ...prev, updates: true })))
          .finally(() => setUpdate((prev) => ({ ...prev, loading: false })))
      )
      .finally(() => setRemoveUpdateId(undefined))
  }

  return (
    <>
      {loading || loadingPendingActions ? (
        <FullLoading />
      ) : (
        <>
          <SectionPendingActions
            update={update}
            pendingActions={pendingActions}
            setPendingActions={setPendingActions}
          />
          <section
            className={pendingActions?.items.length > 0 ? `mt-8` : `mt-0`}
          >
            <SectionUpdates
              update={update}
              setUpdate={setUpdate}
              setRemoveUpdateId={setRemoveUpdateId}
              conciliationUpdates={conciliationUpdates}
              setConciliationUpdates={setConciliationUpdates}
              setError={setError}
            />
          </section>
        </>
      )}
      {ability.can("manage", "conciliations updates") && (
        <Modal
          isOpen={!!removeUpdateId}
          onRequestClose={() => setRemoveUpdateId(undefined)}
          style={modalConfirmTheme(isMobile)}
        >
          <ModalConfirm
            title={
              <div className="flex items-center text-gray-900 text-lg font-semibold border-b pb-4 mb-5">
                <HiExclamation />
                <h5>Tem certeza que deseja excluir a atualização?</h5>
              </div>
            }
            description={
              <>
                <Label
                  className="font-medium"
                  htmlFor="delete_reason"
                  value={"Se desejar, informe o motivo. "}
                />
                <textarea
                  name="delete_reason"
                  className={`my-3 px-3 min-h-[155px] disabled:text-gray-400 w-full rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900`}
                  value={deleteReasonText}
                  placeholder="Escreva sua observação aqui..."
                  onChange={(e) => setDeleteReasonText(e.target.value)}
                />
              </>
            }
            buttonInput={
              <div className="flex md:col-span-2 gap-4">
                <Button
                  color="primary"
                  className="w-fit text-sm font-medium"
                  onClick={removeUpdate}
                >
                  Confimar
                </Button>
                <Button
                  color="gray"
                  className="w-fit text-gray-700 text-sm font-medium"
                  onClick={() => {
                    setDeleteReasonText("")
                    setRemoveUpdateId(undefined)
                  }}
                >
                  Cancelar
                </Button>
              </div>
            }
          />
        </Modal>
      )}
    </>
  )
}

export default ConciliationUpdates
