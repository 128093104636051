export default function ChatIcon2() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M1.78149 4.00002C1.78149 3.57568 1.95007 3.16871 2.25012 2.86865C2.55018 2.5686 2.95715 2.40002 3.38149 2.40002H8.98149C9.40584 2.40002 9.81281 2.5686 10.1129 2.86865C10.4129 3.16871 10.5815 3.57568 10.5815 4.00002V7.20002C10.5815 7.62437 10.4129 8.03134 10.1129 8.33139C9.81281 8.63145 9.40584 8.80002 8.98149 8.80002H7.38149L4.98149 11.2V8.80002H3.38149C2.95715 8.80002 2.55018 8.63145 2.25012 8.33139C1.95007 8.03134 1.78149 7.62437 1.78149 7.20002V4.00002Z"
        fill="#31246D"
      />
      <path
        d="M12.1815 5.59998V7.19998C12.1815 8.04867 11.8444 8.8626 11.2442 9.46272C10.6441 10.0628 9.8302 10.4 8.9815 10.4H8.0439L6.6311 11.8136C6.8551 11.9328 7.1103 12 7.3815 12H8.9815L11.3815 14.4V12H12.9815C13.4058 12 13.8128 11.8314 14.1129 11.5313C14.4129 11.2313 14.5815 10.8243 14.5815 10.4V7.19998C14.5815 6.77563 14.4129 6.36866 14.1129 6.0686C13.8128 5.76855 13.4058 5.59998 12.9815 5.59998H12.1815Z"
        fill="#111928"
      />
    </svg>
  )
}
