import ModalConfirm from "@components/modal-confirm"
import { modalConfirmTheme } from "@components/modal-confirm/style"
import { useScreenDetect } from "@hooks/use-screen-detect"
import ConciliationBatchService from "@services/conciliation-batch.service"
import { Assignable } from "@type/conciliation.type"
import { Button, Checkbox, Label } from "flowbite-react"
import React, { useState } from "react"
import { HiUserAdd } from "react-icons/hi"
import Modal from "react-modal"

type ConciliationAssignableModalProps = {
  selectedItems: string[]
  setError: React.Dispatch<
    React.SetStateAction<{
      excel: boolean
      postAssignable: boolean
      notification: boolean
      completeProcess: boolean
      addUpdate: boolean
      isNotify: boolean
      fetchData: boolean
    }>
  >
  assignable: Assignable.Item[]
  isDefineResponsibleOpen: boolean
  setIsDefineResponsibleOpen: React.Dispatch<React.SetStateAction<boolean>>
  isDefineSpecialistOpen: boolean
  setIsDefineSpecialistOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function ConciliationAssignableModal({
  selectedItems,
  setError,
  assignable,
  isDefineResponsibleOpen,
  setIsDefineResponsibleOpen,
  isDefineSpecialistOpen,
  setIsDefineSpecialistOpen,
}: ConciliationAssignableModalProps) {
  const { isMobile } = useScreenDetect()
  const [responsibles, setResposibles] = useState<Assignable.Item[]>([])
  const [specialists, setSpecialists] = useState<Assignable.Item[]>([])

  const postAssignable = (type: Assignable.Type) => {
    const owners =
      type === Assignable.Type.SPECIALIST ? specialists : responsibles
    const newOwners = owners.map((owner) => ({
      id: owner.id,
      type: owner.type,
    }))
    ConciliationBatchService.postAssignable(selectedItems, newOwners)
      .catch(setError)
      .finally(() => {
        setIsDefineSpecialistOpen(false)
        setIsDefineResponsibleOpen(false)
      })
  }

  return (
    <Modal
      isOpen={isDefineResponsibleOpen || isDefineSpecialistOpen}
      onRequestClose={() => {
        if (isDefineSpecialistOpen) {
          return setIsDefineSpecialistOpen(false)
        }
        return setIsDefineResponsibleOpen(false)
      }}
      style={modalConfirmTheme(isMobile, "640px")}
    >
      <ModalConfirm
        title={
          <div className="flex items-center text-gray-900 text-lg font-semibold border-b pb-4 mb-5">
            <span className="mr-2">
              <HiUserAdd />
            </span>
            <h5>
              {isDefineSpecialistOpen
                ? "Selecione um especialista"
                : "Selecione um responsável"}
            </h5>
          </div>
        }
        description={
          <div className="flex flex-col gap-4 ml-6 mb-6">
            {assignable.length > 0 ? (
              assignable?.map((item) => (
                <div
                  key={`${item.id}_${item.name}_checkbox`}
                  className="cursor-pointer"
                >
                  <Checkbox
                    id={item.name}
                    value={item.id}
                    onClick={() => {
                      if (isDefineSpecialistOpen) {
                        setSpecialists((prev) => {
                          const prevResp = [...prev]
                          const _item = prevResp.find((i) => i.id === item.id)
                          if (_item) {
                            return prevResp.filter((i) => i.id !== item.id)
                          }
                          return [item, ...prev]
                        })
                      }
                      setResposibles((prev) => {
                        const prevResp = [...prev]
                        const _item = prevResp.find((i) => i.id === item.id)
                        if (_item) {
                          return prevResp.filter((i) => i.id !== item.id)
                        }
                        return [item, ...prev]
                      })
                    }}
                    className="mr-1.5 checked:bg-primary-600 cursor-pointer"
                  />
                  <Label className="cursor-pointer" htmlFor={item.name}>
                    {item.name}
                  </Label>
                </div>
              ))
            ) : (
              <div className="h-[24px]" />
            )}
          </div>
        }
        buttonInput={
          <div className="flex md:col-span-2 gap-4">
            <Button
              size="xs"
              color="primary"
              className="w-fit text-sm font-medium h-[34px]"
              disabled={
                (isDefineResponsibleOpen && responsibles.length === 0) ||
                (isDefineSpecialistOpen && specialists.length === 0)
              }
              onClick={() => {
                if (isDefineSpecialistOpen) {
                  return postAssignable(Assignable.Type.SPECIALIST)
                }
                return postAssignable(Assignable.Type.SECRETARY)
              }}
            >
              Enviar
            </Button>
            <Button
              size="xs"
              color="gray"
              className="w-fit text-primary-700 text-sm font-medium border-primary-700 h-[34px]"
              onClick={() => {
                if (isDefineSpecialistOpen) {
                  return setIsDefineSpecialistOpen(false)
                }
                return setIsDefineResponsibleOpen(false)
              }}
            >
              Cancelar
            </Button>
          </div>
        }
      />
    </Modal>
  )
}

export default ConciliationAssignableModal
