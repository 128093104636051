export const avatarColors = [
  "bg-gray-400",
  "bg-blue-400",
  "bg-green-400",
  "bg-red-400",
  "bg-yellow-400",
  "bg-purple-400",
  "bg-orange-400",
  "bg-teal-400",
  "bg-indigo-400",
  "bg-pink-400",
]

export const messagesColors = [
  "bg-gray-50",
  "bg-blue-50",
  "bg-green-50",
  "bg-red-50",
  "bg-yellow-50",
  "bg-purple-50",
  "bg-orange-50",
  "bg-teal-50",
  "bg-indigo-50",
  "bg-pink-50",
]
