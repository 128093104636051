import { useConciliationChatMessages } from "@hooks/use-conciliation-chat-messages"
import { useMemo } from "react"
import Avatar from "../avatar"

export default function ChatFirstMessage() {
  const { chatConciliationMessages } = useConciliationChatMessages()
  const messages = useMemo(
    () => chatConciliationMessages.filter((item) => item.status === "unread"),
    [chatConciliationMessages]
  )
  const lastUnreadMessage = messages[messages.length - 1]

  if (messages?.length <= 0) {
    return null
  }

  return (
    <div
      className={`flex w-[422px] h-[99px] p-4 border-1 rounded-lg border-gray-200 bg-white shadow-md z-10 fixed bottom-0 right-0 mb-24 mr-20`}
    >
      <div className="py-4 px-2 flex items-center w-full rounded-lg bg-gray-50 shadow-sm">
        <div className="w-8 h-8">
          <Avatar
            name={lastUnreadMessage?.sender?.name}
            bgColor={"bg-gray-300"}
            borderColor="border-gray-50"
            image={lastUnreadMessage?.sender?.avatar}
          />
        </div>
        <div className="ml-2 flex-col w-full">
          <p className="font-semibold text-sm text-gray-900">
            {lastUnreadMessage?.sender.name}
          </p>
          <p className="mt-0.5 text-sm text-gray-500">
            {lastUnreadMessage?.message}
          </p>
        </div>
      </div>
    </div>
  )
}
