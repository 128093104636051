import { ConciliationStatus } from "@type/conciliation-status.type"
import axios from "./base.service"
const CONCILIATION_STATUS_PATH = "/conciliation-status"
const ConciliationStatusService = {
  findAll: async (): Promise<ConciliationStatus[]> => {
    const response = await axios.get(CONCILIATION_STATUS_PATH)

    return response.data.data
  },
}

export default ConciliationStatusService
