import React from "react"

type LockClosedIconType = {
  width?: string
  height?: string
  color?: string
}

export default function LockClosedIcon({
  color = "#111928",
  height = "16",
  width = "15",
}: LockClosedIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.97448 7.14004V5.67679C3.97448 4.7066 4.35989 3.77614 5.04592 3.09011C5.73195 2.40408 6.6624 2.01868 7.6326 2.01868C8.60279 2.01868 9.53324 2.40408 10.2193 3.09011C10.9053 3.77614 11.2907 4.7066 11.2907 5.67679V7.14004C11.6788 7.14004 12.051 7.2942 12.3254 7.56862C12.5998 7.84303 12.754 8.21521 12.754 8.60329V12.2614C12.754 12.6495 12.5998 13.0217 12.3254 13.2961C12.051 13.5705 11.6788 13.7247 11.2907 13.7247H3.97448C3.5864 13.7247 3.21422 13.5705 2.93981 13.2961C2.66539 13.0217 2.51123 12.6495 2.51123 12.2614V8.60329C2.51123 8.21521 2.66539 7.84303 2.93981 7.56862C3.21422 7.2942 3.5864 7.14004 3.97448 7.14004ZM9.82747 5.67679V7.14004H5.43772V5.67679C5.43772 5.09468 5.66897 4.5364 6.08059 4.12479C6.49221 3.71317 7.05048 3.48192 7.6326 3.48192C8.21471 3.48192 8.77299 3.71317 9.1846 4.12479C9.59622 4.5364 9.82747 5.09468 9.82747 5.67679Z"
        fill={color}
      />
    </svg>
  )
}
