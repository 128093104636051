import React, { LegacyRef, forwardRef } from "react"
import InputMask, { Props, ReactInputMask } from "react-input-mask"

type TInputMaskCorrect = Omit<Props, "children"> & {
  children?: () => JSX.Element
}

const InputMaskCustom = forwardRef(
  (
    { children, ...props }: TInputMaskCorrect,
    ref: LegacyRef<ReactInputMask> | undefined
  ) => {
    const child = children as React.ReactNode
    return (
      <InputMask {...props} ref={ref}>
        {child}
      </InputMask>
    )
  }
)

InputMaskCustom.displayName = "InputMaskCustom"
export default InputMaskCustom
