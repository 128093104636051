import { AuthContextType } from "@type/auth-context.type"
import { SignupDto } from "@type/signup.type"
import { UserAuthenticated } from "@type/user.type"
import { ValidatorError } from "@type/validator-error.type"
import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
} from "react"
import { authProvider } from "../context/auth.provider"
import { AppAbility, updateAbility } from "../utils/ability"

const AuthContext = createContext<AuthContextType>(null!)

export function AuthProvider(props: PropsWithChildren) {
  const [user, setUser] = React.useState<UserAuthenticated | undefined>()

  useEffect(() => {
    const userAuthenticated = authProvider.getUserDecoded()
    if (userAuthenticated !== null && userAuthenticated !== undefined) {
      setUser(userAuthenticated)
    }
  }, [])

  const sendEmailToken = (
    username: string,
    callback: (error: boolean, message: string | null) => void
  ) => {
    return authProvider.requestToken(username, (error, message) => {
      callback(error, message)
    })
  }

  const signin = (token: string, callback: (error: boolean) => void) => {
    return authProvider.verifyToken(token, (error, userAuth) => {
      if (!error) {
        setUser(userAuth)
      }
      callback(error)
    })
  }

  const verifyEmail = (token: string, callback: (error: boolean) => void) => {
    return authProvider.verifyEmail(token, (error, userAuth) => {
      if (!error) {
        setUser(userAuth)
      }
      callback(error)
    })
  }

  const signout = (callback: VoidFunction) => {
    return authProvider.signout(() => {
      setUser(undefined)
      callback()
    })
  }
  const updateRules = (ability: AppAbility) => {
    const userAuthenticated = authProvider.getUserDecoded()
    if (userAuthenticated !== null && userAuthenticated !== undefined) {
      updateAbility(ability, userAuthenticated?.role)
    } else {
      updateAbility(ability, "user")
    }
  }

  const isAuthenticated = () => {
    return authProvider.isAuthenticated()
  }

  const checkAuthenticated = (callback: (error: boolean) => void) => {
    return authProvider.checkAuthenticated(callback)
  }

  const signup = (
    data: SignupDto,
    callback: (error: boolean, errors: Array<ValidatorError>) => void
  ) => {
    return authProvider.signup(data, callback)
  }

  const value = {
    user: user,
    sendEmailToken,
    signout,
    signin,
    isAuthenticated,
    checkAuthenticated,
    signup,
    verifyEmail,
    updateRules,
  }

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
