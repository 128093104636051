import { getInitials } from "@utils/helpers/getInitials.helper"

type AvatarProps = {
  image?: string
  name: string
  width?: string
  height?: string
  bgColor?: string
  withoutBorder?: boolean
  borderColor?: string
}

export default function Avatar({
  image,
  name,
  width = "w-8",
  height = "h-8",
  bgColor = "bg-gray-300",
  withoutBorder = false,
  borderColor = "border-white",
}: AvatarProps) {
  const border = withoutBorder ? "" : `border-2 ${borderColor}`
  return (
    <div className={`${width} ${height}`}>
      {image ? (
        <img
          className={`${width} ${height} ${border} rounded-full dark:border-gray-800`}
          src={image}
          alt={name}
        />
      ) : (
        <div
          className={`${width} ${height} ${border} rounded-full ${bgColor} items-center justify-center flex`}
        >
          <p className="font-medium text-xs text-center">{getInitials(name)}</p>
        </div>
      )}
    </div>
  )
}
