import {
  BtnLoadingIcon,
  CheckCircleIcon,
  ClipBoardCheckIcon,
  ClipIcon,
  ClockIcon,
  DocumentAddIcon,
  LockClosedIcon,
  PaperClipIcon,
  PencilDetailsIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserCircleIcon,
} from "@assets/icons"
import NoDataComponent from "@components/no-data"
import { AbilityContext } from "@context/ability.provider"
import { ConciliationErrors } from "@features/conciliation/types/conciliation-detail.type"
import { useRole } from "@hooks/use-role"
import AttachmentService from "@services/attachment.service"
import ConciliationDetailsService from "@services/conciliation-details.service"
import { ConciliationUpdate } from "@type/conciliation.type"
import { fileMapper } from "@utils/helpers/file.mapper"
import { formatDate } from "@utils/helpers/formatDate"
import { Button, Checkbox, Dropdown, FileInput, Label } from "flowbite-react"
import React, { useContext, useEffect, useMemo, useState } from "react"
import {
  HiArrowLeft,
  HiArrowRight,
  HiDotsVertical,
  HiPencil,
  HiTrash,
} from "react-icons/hi"
import { useParams } from "react-router"
import DatePicker from "tailwind-datepicker-react"
import { IOptions } from "tailwind-datepicker-react/types/Options"

type SectionUpdatesType = {
  update: ConciliationUpdate.Data
  setUpdate: React.Dispatch<React.SetStateAction<ConciliationUpdate.Data>>
  setRemoveUpdateId: React.Dispatch<React.SetStateAction<number | undefined>>
  setConciliationUpdates: React.Dispatch<
    React.SetStateAction<ConciliationUpdate.Item[]>
  >
  conciliationUpdates: ConciliationUpdate.Item[]
  setError: React.Dispatch<React.SetStateAction<ConciliationErrors>>
}

const initialState = {
  text: "",
  open: false,
  loading: false,
  error: false,
  attachments: [],
}

const CODE_VALUES: { [key: string]: JSX.Element } = {
  STARTED_THE_CASE: <ClipBoardCheckIcon height="16" width="16" />,
  DEFINED_RESPONSIBLE: <ClipBoardCheckIcon height="16" width="16" />,
  PROGRESSED: <CheckCircleIcon height="16" width="16" />,
  ATTACHED_DOCUMENT: <DocumentAddIcon height="16" width="16" />,
  NOTIFIED_REQUIRED: <UserCircleIcon height="16" width="16" />,
  EVALUATING_DOCUMENT: <ClockIcon height="16" width="16" />,
  CLOSED_THE_CASE: <LockClosedIcon height="16" width="16" />,
  EDITED_DOCUMENT: <PencilDetailsIcon />,
  ENTERED_PROCESS: <ShieldCheckIcon height="16" width="16" />,
}

const labels = {
  Specialist: "Especialistas",
  Secretary: "Secretaria",
  Claimant: "Requerentes",
  Required: "Partes requeridas",
  ThirdPart: "Terceiros",
}

function SectionUpdates({
  update,
  setUpdate,
  setRemoveUpdateId,
  setConciliationUpdates,
  conciliationUpdates,
  setError,
}: SectionUpdatesType) {
  const ability = useContext(AbilityContext)
  const { isUser } = useRole()
  const { conciliationNumber } = useParams()
  const [showDate, setShowDate] = useState<boolean>(false)
  const [date, setDate] = useState<Date>(new Date())
  const [responsibleList, setResponsibleList] = useState<string[]>([])
  const [editUpdate, setEditUpdate] = useState<
    ConciliationUpdate.ItemWithIndex | undefined
  >(undefined)
  const [updatesStatus, setUpdateStatus] = useState<
    ConciliationUpdate.Status[] | undefined
  >(undefined)
  const [updatesResponsible, setUpdateResponsible] = useState<
    ConciliationUpdate.Responsible | undefined
  >(undefined)

  useEffect(() => {
    if (!updatesStatus) {
      ConciliationDetailsService.findUpdatesStatus().then((data) =>
        setUpdateStatus(data)
      )
    }
  }, [updatesStatus])

  useEffect(() => {
    if (update.open && !updatesResponsible) {
      ConciliationDetailsService.findUpdatesResponsible().then((data) => {
        setUpdateResponsible(data)
      })
    }
  }, [update.open, updatesResponsible])

  const conciliationUpdatesUpdated = useMemo(() => {
    const array = conciliationUpdates
      .sort(
        (a, b) =>
          (new Date(a.dateTime).getTime() -
            new Date(b.dateTime).getTime()) as number
      )
      .reduce((acc, item, index) => {
        acc.push({ ...item, _index: index + 1 })
        return acc
      }, [] as ConciliationUpdate.ItemWithIndex[])
      .reverse()

    return array.reduce((acc, item) => {
      const dateKey = new Date(item.dateTime).toISOString().split("T")[0]
      if (!acc[dateKey]) {
        acc[dateKey] = []
      }
      acc[dateKey].push(item)
      return acc
    }, {} as ConciliationUpdate.GroupedData)
  }, [conciliationUpdates])

  const postUpdateText = async () => {
    setUpdate((prev) => ({ ...prev, loading: true }))

    if (update.text === "") {
      setUpdate((prev) => ({ ...prev, error: true }))
    }

    if (!update.error) {
      let attachs: Array<number> = []
      try {
        attachs = await AttachmentService.uploadAndGet(update.attachments)
      } catch (error) {
        console.log(error)
        setUpdate((prev) => ({ ...prev, loading: false }))
      }

      await ConciliationDetailsService.postUpdate(
        {
          attachments: attachs,
          updateMessage: update.text,
          date,
          responsible: responsibleList,
        },
        conciliationNumber
      )
        .then(() =>
          ConciliationDetailsService.findAllUpdates(conciliationNumber)
            .then((data) => setConciliationUpdates(data))
            .catch(() => setError((prev) => ({ ...prev, updates: true })))
            .finally(() => setUpdate((prev) => ({ ...prev, loading: false })))
        )
        .finally(() => setUpdate(initialState))
    }
  }

  const updateUpdate = async () => {
    if (!editUpdate) {
      return setEditUpdate(undefined)
    }
    let attachs: Array<number> = []

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _index, ...rest } = editUpdate

    try {
      attachs = await AttachmentService.uploadAndGet(rest.attachments)
    } catch (error) {
      console.log(error)
      setUpdate((prev) => ({ ...prev, loading: false }))
    }

    ConciliationDetailsService.putUpdate(
      editUpdate?.id,
      { ...rest, attachments: attachs },
      conciliationNumber
    )
      .then(() =>
        ConciliationDetailsService.findAllUpdates(conciliationNumber)
          .then((data) => {
            setConciliationUpdates(data)
            setEditUpdate(undefined)
          })
          .catch(() => setError((prev) => ({ ...prev, updates: true })))
          .finally(() => setUpdate((prev) => ({ ...prev, loading: false })))
      )
      .finally(() => setUpdate(initialState))
  }

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between mb-8 md:items-center">
        <p className="text-2xl text-gray-600 mb-4 md:mb-0 font-semibold">
          Atualizações
        </p>
        <Button
          color="gray"
          className="text-sm font-medium text-primary-800 mb-0 max-w-[200px] min-h-[37px] max-h-[37px]"
          onClick={() => setUpdate((prev) => ({ ...prev, open: !prev.open }))}
          disabled={update.open}
        >
          <span className="mr-1">
            <PlusIcon />
          </span>
          Adicionar atualização
        </Button>
      </div>
      <div
        className={`w-full h-[1px] bg-gray-200 ${
          update.open ? "mb-4" : "mb-8"
        } mt-4`}
      />
      {update.open && (
        <div
          className={`mb-8 flex flex-col gap-8 md:gap-4 p-4 pt-0 items-start rounded-lg bg-flowkit-white shadow-sm`}
        >
          <div className="flex-1 flex-col w-full">
            <Label
              className="font-medium"
              htmlFor="update_text"
              value={"Escreva abaixo a sua atualização."}
              color={update.error ? "failure" : ""}
            />
            <p className="mt-1 text-gray-500 font-normal text-sm leading-6">
              O histórico do processo será atualizado e todas as partes terão
              acesso a essa informação.
            </p>
            <textarea
              name="update_text"
              className={`mt-3 px-3 min-h-[155px] disabled:text-gray-400 w-full rounded-lg border ${
                update.error
                  ? "border-red-600 bg-red-50"
                  : "border-gray-300 bg-gray-50"
              } text-sm text-gray-900`}
              value={update.text}
              required
              placeholder="Escreva sua resposta aqui."
              onChange={(e) => {
                setUpdate((prev) => ({
                  ...prev,
                  error: false,
                  text: e.target.value,
                }))
              }}
            />
            {ability.can("manage", "conciliations updates") && (
              <div className="flex-col mt-2">
                <Label
                  className="font-medium"
                  htmlFor="responsible_people"
                  value={
                    "Caso deseje gerar uma ação pendente, selecione as pessoas responsáveis."
                  }
                  color={update.error ? "failure" : ""}
                />
                <div className="flex flex-col sm:flex-row md:flex-row sm:flex-wrap gap-4 sm:gap-6 mb-4 mt-2">
                  {updatesResponsible &&
                    Object.entries(updatesResponsible).map(([key, values]) => {
                      const currentValues = values?.map((val) => val.name)
                      // Check if all items in array B are in array A
                      const similarItems = currentValues.every((itemB) =>
                        responsibleList.includes(itemB)
                      )
                      return (
                        <div key={key}>
                          <Checkbox
                            id={key}
                            value={key}
                            checked={similarItems}
                            onClick={() =>
                              setResponsibleList((prev) => {
                                if (similarItems) {
                                  return prev.filter(
                                    (itemB) => !currentValues.includes(itemB)
                                  )
                                }
                                return prev.concat(currentValues)
                              })
                            }
                            className="mr-1.5 checked:bg-primary-600 hidden md:inline-block"
                          />
                          <Label className="text-gray-500" htmlFor={key}>
                            {labels[key as keyof typeof labels]}
                          </Label>
                          <div className="md:ml-5">
                            {values.map((val) => (
                              <div key={val.id}>
                                <Checkbox
                                  id={val.name}
                                  value={val.id}
                                  checked={responsibleList.includes(val.name)}
                                  onClick={() =>
                                    setResponsibleList((prev) => {
                                      const id = prev.find(
                                        (i) => i === val.name
                                      )
                                      if (id) {
                                        return prev.filter(
                                          (item) => item !== id
                                        )
                                      }
                                      return prev.concat(val.name)
                                    })
                                  }
                                  className="mr-1.5 checked:bg-primary-600"
                                />
                                <Label
                                  htmlFor={val.name}
                                  className="leading-none text-sm font-normal"
                                >
                                  {val.name}
                                </Label>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            )}
            {!isUser && (
              <>
                <Label
                  className="font-medium"
                  htmlFor="update_text"
                  value={"Informe o prazo final"}
                  color={update.error ? "failure" : ""}
                />
                <div className="md:w-[325px] h-[37px] w-full mt-2 relative">
                  <DatePicker
                    options={options}
                    value={date}
                    onChange={(selectedDate: Date) => {
                      setDate(selectedDate)
                    }}
                    show={showDate}
                    setShow={(state: boolean) => {
                      setShowDate(state)
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {Array.isArray(update.attachments) &&
          update.attachments.length > 0 ? (
            <div className="flex flex-col gap-2 w-full">
              {update.attachments.map((item, index) => {
                return (
                  <div
                    key={`${item.name}_${index}_${item.detail}`}
                    className="flex items-center"
                  >
                    <ClipIcon />
                    <p className="truncate text-primary-400 font-normal text-base leading-6">
                      {item.name}
                    </p>
                  </div>
                )
              })}
            </div>
          ) : null}
          <div className="flex flex-2 self-start items-bottom">
            <Button
              color="primary"
              className="md:min-w-[100px]"
              onClick={postUpdateText}
              disabled={update.loading || update.text === ""}
            >
              {update.loading ? <BtnLoadingIcon /> : null}
              Enviar
            </Button>
            <Button
              size="md"
              color="gray"
              className="text-sm h-[42px] ml-4 md:min-w-[100px]"
              onClick={() =>
                setUpdate((prev) => ({
                  ...prev,
                  open: !prev.open,
                  text: "",
                  attachments: [],
                  loading: false,
                  error: false,
                }))
              }
              disabled={update.loading}
            >
              Cancelar
            </Button>
            <div className="ml-6 md:ml-9 flex items-center justify-center cursor-pointer w-full">
              <label htmlFor="file" className="cursor-pointer">
                <PaperClipIcon />
              </label>
              <FileInput
                className="hidden"
                id="file"
                onChange={(e) => {
                  if (e.target.files !== null && e.target.files.length > 0) {
                    const filesArray = Array.from(e.target.files)
                    const newFilesInfo = fileMapper(filesArray)
                    setUpdate((prev) => ({
                      ...prev,
                      attachments: [...prev.attachments, ...newFilesInfo],
                    }))
                  }
                }}
                multiple
              />
            </div>
          </div>
        </div>
      )}
      {conciliationUpdates?.length > 0 ? (
        <div className="relative">
          <div className="absolute z-1 h-[calc(100%-15px)] w-[1px] bg-gray-200 mb-8 mt-4 ml-4" />
          {Object.entries(conciliationUpdatesUpdated).map(
            ([date, values], idx) => (
              <div
                key={`${date}_${idx}_conciliationUpdatesUpdated`}
                className={`relative flex flex-col ${
                  idx !== 0 ? "mt-8" : ""
                } z-99`}
              >
                <p
                  className={`${
                    idx === 0 ? "" : "mb-7"
                  } bg-white flex justify-between text-gray-900 text-lg font-semibold leading-27`}
                >
                  {date ? formatDate(date, "dd 'de' MMMM 'de' yyyy") : ""}
                </p>
                {idx === 0 && (
                  <div className="h-full bg-white text-white">text_needed</div>
                )}
                <div className="flex flex-col gap-10">
                  {values.map((value, index) => {
                    const isLastItem =
                      values.length === index + 1 &&
                      Object.keys(conciliationUpdatesUpdated).length === idx + 1

                    return (
                      <div
                        className="flex"
                        key={`${value.dateTime}_${index}_conciliationUpdatesUpdated_children`}
                      >
                        <div className="flex flex-col mr-3">
                          <div className="rounded-full border-3 border-white bg-primary-100 p-1 h-8 w-8 z-99 flex justify-center items-center">
                            <div className="h-8 w-8 flex justify-center items-center">
                              {
                                CODE_VALUES[
                                  updatesStatus?.find(
                                    (status) => status.code === value.subject
                                  )?.code ??
                                    ConciliationUpdate.StatusCode
                                      .DEFINED_RESPONSIBLE
                                ]
                              }
                            </div>
                          </div>
                          {isLastItem && <div className="h-full bg-white" />}
                        </div>
                        <div className="flex flex-1 flex-col rounded-md border border-gray-200 bg-white shadow-sm p-4">
                          <div className="flex flex-col md:flex-row justify-between">
                            <div className="flex flex-wrap items-center">
                              <p className="flex font-normal text-gray-500 text-sm mr-2.5">
                                <span className="mr-1">{value._index}.</span>
                                {value.author}
                              </p>
                              <div className="h-[28px] flex items-center">
                                <div className="p-2 flex justify-center items-center h-[22px] rounded-lg bg-primary-50">
                                  <p className="text-primary-800 text-center text-xs md:font-medium font-normal">
                                    {
                                      updatesStatus?.find(
                                        (status) =>
                                          status.code === value.subject
                                      )?.name
                                    }
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="flex items-start md:items-center justify-between md:justify-end text-gray-400 text-sm font-medium leading-21">
                              {value.dateTime
                                ? formatDate(
                                    value.dateTime,
                                    "dd-MM-yyyy às HH:mm"
                                  )
                                : ""}{" "}
                              {ability.can(
                                "manage",
                                "conciliations updates"
                              ) && (
                                <span className="ml-4">
                                  <Dropdown
                                    label={
                                      <HiDotsVertical
                                        size={20}
                                        className="text-gray-900"
                                      />
                                    }
                                    inline
                                    arrowIcon={false}
                                    className="w-[132px] text-sm font-normal rounded-md"
                                  >
                                    <Dropdown.Item
                                      className="text-gray-700 flex justify-center h-[37px] border-b border-gray-200"
                                      onClick={() => setEditUpdate(value)}
                                    >
                                      <HiPencil
                                        size={18}
                                        className="text-gray-500 mr-1"
                                      />
                                      Editar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      className="text-red-500 flex justify-center h-[37px]"
                                      onClick={() =>
                                        setRemoveUpdateId(value?.id)
                                      }
                                    >
                                      <HiTrash size={18} className="mr-1" />
                                      Excluir
                                    </Dropdown.Item>
                                  </Dropdown>
                                </span>
                              )}
                            </p>
                          </div>
                          {ability.can("manage", "conciliations updates") &&
                            value.description && (
                              <>
                                {editUpdate && editUpdate.id === value.id ? (
                                  <div className="w-full flex flex-col gap-4">
                                    <input
                                      className={`flex items-center mt-4 rounded-lg border p-3 w-full ${
                                        editUpdate?.description === ""
                                          ? "border-red-600 bg-red-50"
                                          : "border-gray-200 bg-gray-50"
                                      }`}
                                      value={editUpdate?.description}
                                      onChange={(e) => {
                                        setEditUpdate((prev) => {
                                          return {
                                            ...(prev as ConciliationUpdate.ItemWithIndex),
                                            description: e.target
                                              .value as string,
                                          }
                                        })
                                      }}
                                    />
                                    <div className="flex gap-4">
                                      <Button
                                        color="primary"
                                        size="xs"
                                        className="w-fit text-xs font-medium h-[34px]"
                                        onClick={updateUpdate}
                                        disabled={
                                          editUpdate?.description === ""
                                        }
                                      >
                                        Salvar
                                      </Button>
                                      <Button
                                        color="gray"
                                        size="xs"
                                        className="w-fit text-gray-700 text-xs font-medium h-[34px]"
                                        onClick={() => setEditUpdate(undefined)}
                                      >
                                        Cancelar
                                      </Button>
                                      <div className="flex items-center justify-center">
                                        <label
                                          htmlFor="file"
                                          className="cursor-pointer"
                                        >
                                          <PaperClipIcon />
                                        </label>
                                        <FileInput
                                          className="hidden"
                                          id="file"
                                          onChange={(e) => {
                                            if (
                                              e.target.files !== null &&
                                              e.target.files.length > 0
                                            ) {
                                              const filesArray = Array.from(
                                                e.target.files
                                              )
                                              const newFilesInfo =
                                                fileMapper(filesArray)
                                              setEditUpdate((prev) => {
                                                if (prev?.description) {
                                                  return {
                                                    ...prev,
                                                    attachments: newFilesInfo,
                                                  }
                                                }
                                                return prev
                                              })
                                            }
                                          }}
                                          multiple
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex items-center mt-4 rounded-lg border border-gray-200 bg-gray-50 p-3">
                                    <p className="text-gray-500 font-normal text-base">
                                      {value.description}
                                    </p>
                                  </div>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <NoDataComponent description="Nenhuma atualização por enquanto." />
      )}
    </>
  )
}

export default SectionUpdates

const options = {
  autoHide: true,
  todayBtn: true,
  clearBtn: true,
  clearBtnText: "Limpar",
  todayBtnText: "Hoje",
  theme: {
    background: "white",
    todayBtn: "bg-primary-700",
    clearBtn: "white",
    icons: "white",
    text: "white hover:text-white hover:bg-primary-700",
    disabledText: "black",
    input: "white h-[38px] border-gray-300 bg-gray-50",
    inputIcon: "white",
    selected: "bg-primary-700",
  },
  icons: {
    prev: () => (
      <span className="text-sm text-primary-700">
        <HiArrowLeft />
      </span>
    ),
    next: () => (
      <span className="text-sm text-primary-700">
        <HiArrowRight />{" "}
      </span>
    ),
  },
  defaultDate: new Date(),
  language: "pt-BR",
  weekDays: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"],
  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Data Inicial",
  inputDateFormatProp: {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  },
} as IOptions
