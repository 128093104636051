import { RoutesConstants as routes } from "@constants/routes"
import { useAuth } from "@hooks/use-auth"
import { Spinner } from "flowbite-react"
import { useContext, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { AbilityContext } from "../../../context/ability.provider"

function VerifyLoginPage() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { signin, updateRules } = useAuth()
  const ability = useContext(AbilityContext)

  useEffect(() => {
    const navigateToLogin = () => {
      navigate(routes.signIn)
    }
    const navigateToHome = () => {
      navigate(routes.home)
    }
    if (searchParams.has("token")) {
      signin(searchParams.get("token") ?? "", (error) => {
        if (!error) {
          updateRules(ability)
          navigateToHome()
        } else {
          navigateToLogin()
        }
      })
    } else {
      navigateToLogin()
    }
  }, [])

  return (
    <div className="flex flex-row items-center">
      <Spinner color="failure" />
      <h1 className="ml-2.5 text-xl font-bold text-gray-900 dark:text-white">
        Aguarde...
      </h1>
    </div>
  )
}

export default VerifyLoginPage
