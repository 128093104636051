import { initializeApp } from "firebase/app"
import { getMessaging, getToken, onMessage } from "firebase/messaging"
import firebaseConfig from "../constants/fcm-config"

const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const requestForToken = async (): Promise<string> =>
  new Promise((resolve, reject) => {
    getToken(messaging, {
      vapidKey: import.meta.env.VITE_FCM_REACT_APP_VAPID_KEY,
    })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken)
        } else {
          reject(
            "No registration token available. Request permission to generate one."
          )
        }
      })
      .catch((err) => {
        console.error(err)
        reject("An error occurred while retrieving token. ")
      })
  })

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
