function redirectToLogin() {
  const path = import.meta.env.VITE_BASE_APP ?? ""
  const loginUrl = `${window.location.origin}${path}/signin`
  window.location.href = loginUrl
}

function redirectToErrorStatus() {
  const path = import.meta.env.VITE_BASE_APP ?? ""
  const loginUrl = `${window.location.origin}${path}/500`
  window.location.href = loginUrl
}

export default {
  redirectToLogin,
  redirectToErrorStatus,
}
