import { RoutesConstants as routes } from "@constants/routes"
import { useRole } from "@hooks/use-role"
import { useScreenDetect } from "@hooks/use-screen-detect"
import ConciliationService from "@services/conciliation.service"
import { ConciliationStatus } from "@type/conciliation-status.type"
import { Conciliation } from "@type/conciliation.type"
import { format } from "date-fns"
import { pt } from "date-fns/locale"
import { Badge, Checkbox } from "flowbite-react"
import { Dispatch, ReactNode, SetStateAction } from "react"
import { HiSelector } from "react-icons/hi"
import { useNavigate } from "react-router-dom"

type ConciliationMainTableType = {
  selectedItems: string[]
  toggleSortingStatus: () => void
  toggleSortingStatusPayment: () => void
  conciliationStatusList: ConciliationStatus[]
  conciliationActionsDropdown: ReactNode
  toggleSortingCreatedDate: () => void
  conciliationSendMessage: ReactNode
  conciliationExport: ReactNode
  filteredData: Conciliation.Item[]
  onCheck: (item: Conciliation.Item) => void
  setError: Dispatch<
    SetStateAction<{
      excel: boolean
      postAssignable: boolean
      notification: boolean
      completeProcess: boolean
      addUpdate: boolean
      isNotify: boolean
      fetchData: boolean
      accessPermission: boolean
    }>
  >
}

function ConciliationMainTable({
  selectedItems,
  toggleSortingStatus,
  toggleSortingStatusPayment,
  conciliationActionsDropdown,
  toggleSortingCreatedDate,
  conciliationSendMessage,
  conciliationExport,
  filteredData,
  onCheck,
  setError,
}: ConciliationMainTableType) {
  const navigate = useNavigate()
  const { isMobile } = useScreenDetect()
  const { isAdmin, isSpecialist } = useRole()

  const goToConciliation = (conciliationNumber: string) => {
    ConciliationService.accessPermission(conciliationNumber)
      .then(() => navigate(`${routes.conciliations}/${conciliationNumber}`))
      .catch(() => setError((prev) => ({ ...prev, accessPermission: true })))
  }

  return (
    <table className="w-full text-sm text-left">
      <thead className="text-xs text-gray-500 uppercase bg-gray-50">
        <tr>
          {(isAdmin || isSpecialist) && (
            <th scope="col" className="h-[54px] px-6 py-3 whitespace-nowrap" />
          )}
          <th scope="col" className="h-[54px] px-6 py-3 whitespace-nowrap">
            {selectedItems.length === 0
              ? "Nº DO PROCESSO"
              : `${selectedItems.length} ${
                  selectedItems.length === 1
                    ? `processo selecionado`
                    : `processos selecionados`
                }`}
          </th>
          {(isAdmin || isSpecialist) && (
            <th scope="col" className="h-[54px] px-6 py-3 whitespace-nowrap">
              {(isMobile || selectedItems.length === 0) && (
                <span
                  className="flex items-center cursor-pointer"
                  onClick={toggleSortingStatusPayment}
                >
                  STATUS DE PAGAMENTO{" "}
                  <HiSelector className="ml-0.5" size={16} />
                </span>
              )}
            </th>
          )}
          <th scope="col" className="h-[54px] px-6 py-3 whitespace-nowrap">
            {(isMobile || selectedItems.length === 0) && "PARTE REQUERIDA"}
          </th>
          <th scope="col" className="h-[54px] px-6 py-3 whitespace-nowrap">
            {(isMobile || selectedItems.length === 0) && "REQUERENTE"}
          </th>
          <th scope="col" className="h-[54px] px-6 whitespace-nowrap">
            {isMobile || selectedItems.length === 0
              ? "ÚLTIMA ATUALIZAÇÃO"
              : conciliationActionsDropdown}
          </th>
          {isSpecialist && (
            <th scope="col" className="h-[54px] px-6 py-3 whitespace-nowrap">
              <span
                className="flex items-center cursor-pointer"
                onClick={toggleSortingStatus}
              >
                NOMEAÇÃO
                <HiSelector className="ml-0.5" size={16} />
              </span>
            </th>
          )}
          <th scope="col" className="h-[54px] px-6 py-3 whitespace-nowrap">
            {isMobile || selectedItems.length === 0 ? (
              <span
                className="flex items-center cursor-pointer"
                onClick={toggleSortingCreatedDate}
              >
                ENTRADA <HiSelector className="ml-0.5" size={16} />
              </span>
            ) : (
              conciliationSendMessage
            )}
          </th>
          <th scope="col" className="h-[54px] px-6 py-3 whitespace-nowrap">
            {isMobile || selectedItems.length === 0 ? (
              <span
                className="flex items-center cursor-pointer"
                onClick={toggleSortingStatus}
              >
                {isAdmin || isSpecialist ? "STATUS DO PROCESSO" : "STATUS"}{" "}
                <HiSelector className="ml-0.5" size={16} />
              </span>
            ) : (
              conciliationExport
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {filteredData?.map((item) => (
          <tr
            key={`conciliation_${item.conciliationNumber}_${item.createdAt}`}
            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
          >
            {(isAdmin || isSpecialist) && (
              <td className="h-[54px] w-[54px] px-6 py-4 text-gray-900">
                <Checkbox
                  className="checked:bg-primary-600 border cursor-pointer self-center"
                  checked={selectedItems.includes(item.conciliationNumber)}
                  onChange={() => onCheck(item)}
                />
              </td>
            )}
            <td
              className="h-[54px] px-6 py-4 text-gray-900 cursor-pointer"
              onClick={() => goToConciliation(item.conciliationNumber)}
            >
              {item.conciliationNumber}
            </td>
            {(isAdmin || isSpecialist) && (
              <td
                className="h-[54px] px-6 py-4"
                onClick={() => {
                  goToConciliation(item.conciliationNumber)
                }}
              >
                <Badge
                  color={item.statusPayment.color}
                  className="flex justify-center rounded-md w-fit whitespace-nowrap"
                >
                  {item.statusPayment.name}
                </Badge>
              </td>
            )}
            <td
              className="h-[54px] px-6 py-4 whitespace-nowrap text-gray-900"
              onClick={() => goToConciliation(item.conciliationNumber)}
            >
              {item.claimant}
            </td>
            <td
              className="h-[54px] px-6 py-4 whitespace-nowrap text-gray-900"
              onClick={() => goToConciliation(item.conciliationNumber)}
            >
              {item.defendant}
            </td>
            <td
              className="h-[54px] px-6 py-4 whitespace-nowrap text-gray-900"
              onClick={() => goToConciliation(item.conciliationNumber)}
            >
              {item.lastHistoryEntry}
            </td>
            {isSpecialist && (
              <td
                className="h-[54px] px-6 py-4 whitespace-nowrap text-gray-900"
                onClick={() => goToConciliation(item.conciliationNumber)}
              >
                {item.startDate
                  ? format(new Date(item.startDate), "dd/MM/yyyy HH:mm")
                  : ""}
              </td>
            )}
            <td
              className="h-[54px] px-6 py-4 whitespace-nowrap text-gray-900"
              onClick={() => goToConciliation(item.conciliationNumber)}
            >
              {item.createdAt
                ? format(new Date(item.createdAt), "dd/MM/yyyy HH:mm", {
                    locale: pt,
                  })
                : ""}
            </td>
            <td
              className="h-[54px] px-6 py-4"
              onClick={() => goToConciliation(item.conciliationNumber)}
            >
              <Badge
                color={item.status.color}
                className="flex justify-center rounded-md w-fit whitespace-nowrap"
              >
                {item.status.name}
              </Badge>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default ConciliationMainTable
