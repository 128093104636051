import axiosBase from "@services/base.service"
import { PERSON_TYPE, PROCESS_ROLES } from "@utils/helpers/constants"
import { formatCpfCnpj } from "@utils/helpers/formatCpfCnpj.helper"
import { cnpj as cnpjValidator, cpf as cpfValidator } from "cpf-cnpj-validator"
import { Button, Label, Radio } from "flowbite-react"
import { ErrorMessage, Field, FieldArray, Formik, getIn } from "formik"
import IMask from "imask"
import React, {
  ChangeEvent,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from "react"
import * as yup from "yup"
import { Claimant } from "../../types/conciliation.type"

interface TabProps {
  tab: string
  state: boolean
  tabToGo: number
}

interface CreateProcessFormProps {
  requerentesAnteriores: Claimant[]
  secondStep: boolean
  stepToGoInfo: TabProps
  formSubmit: (tab: string, state: boolean, tabToGo: number) => void
  requerentes: Claimant[]
  setRequerentes: React.Dispatch<React.SetStateAction<Claimant[]>>
  btnText: string
  sectionTitle: string
  primaryBtnText: string
  papelProcessoDefault: PROCESS_ROLES
}

const commonFieldsSchema = {
  tipoPessoa: yup.string().required("É necessário selecionar uma opção."),
  nomeCompleto: yup
    .string()
    .min(2, "Digite o nome completo")
    .required("É necessário preencher este campo para continuar.")
    .test(
      "test-invalid-full-name",
      "O campo deve conter apenas letras e espaços.",
      (fullName) => /^[\p{L}\s]+$/u.test(fullName)
    ),
  email: yup
    .string()
    .email("Digite um e-mail válido")
    .required("É necessário preencher este campo para continuar."),
  tel: yup
    .string()
    .matches(/(\(\d{2}\)\s)(\d{4,5}-\d{4})/g, "Digite um telefone válido")
    .required("É necessário preencher este campo para continuar."),
}

export default function CreateProcessForm({
  requerentesAnteriores,
  secondStep,
  stepToGoInfo,
  formSubmit,
  requerentes,
  setRequerentes,
  btnText,
  sectionTitle,
  primaryBtnText,
  papelProcessoDefault,
}: CreateProcessFormProps) {
  const cpfCnpjInputRef = useRef(null)
  const refPhone = useRef<HTMLInputElement | null>(null)
  const [papelProcesso, setPapelProcesso] = useState(papelProcessoDefault)

  const cpfCnpjSchema = yup
    .string()
    .required("É necessário preencher este campo para continuar.")
    .test(
      "test-invalid-cpf",
      "Algo parece estar errado. Por favor, verifique o número e tente novamente.",
      (cpfValue) =>
        cpfValue.length > 14
          ? cnpjValidator.isValid(cpfValue)
          : cpfValidator.isValid(cpfValue)
    )
    .test("test-same-cpf", "Este CPF já foi inserido", (cpfValue, context) => {
      const { path, createError } = context
      const allRequerentes = requerentes.concat(requerentesAnteriores)
      const duplicates = allRequerentes.filter(
        (requerente) => requerente.cpfCnpj === cpfValue
      )

      if (duplicates.length > 1) {
        return createError({
          path,
          message: "Este documento já está sendo utilizado neste formulário",
        })
      }
      return true
    })

  const validationSchema = yup.object({
    cpfCnpj: cpfCnpjSchema,
    oab: yup.string().test({
      name: "required-for-advogado",
      exclusive: true,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      test: (
        value: string | undefined,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        _context: yup.TestContext<yup.AnyObject>
      ) => {
        return papelProcesso === PROCESS_ROLES.Lawyer ? !!value : true
      },
      message: "É necessário preencher este campo para continuar.",
    }),
    papelProcesso: yup.string().required(),
    nomeCompleto: commonFieldsSchema.nomeCompleto,
    email: commonFieldsSchema.email,
    tel: commonFieldsSchema.tel,
    requerentes: yup.array().of(
      yup.object().shape({
        cpfCnpj: cpfCnpjSchema,
        ...commonFieldsSchema,
      })
    ),
  })

  const alternativeValidationSchema = yup.object({
    cpfCnpj: cpfCnpjSchema,
    ...commonFieldsSchema,
    requerentes: yup.array().of(
      yup.object().shape({
        cpfCnpj: cpfCnpjSchema,
        ...commonFieldsSchema,
      })
    ),
  })

  const handlePapelProcessoChange = (
    papelProcesso: PROCESS_ROLES
  ) => {
    if (
      requerentes &&
      requerentes[0] &&
      requerentes[0].oab &&
      papelProcesso !== PROCESS_ROLES.Lawyer
    ) {
      delete requerentes[0].oab
    }
    setPapelProcesso(papelProcesso)
    setRequerentes((prevRequerentes: Claimant[]) => {
      const novosRequerentes = [...prevRequerentes]
      for (let index = 0; index < novosRequerentes.length; index++) {
        novosRequerentes[index] = {
          ...novosRequerentes[index],
          papelProcesso,
        }
      }
      return novosRequerentes
    })
  }

  useEffect(() => {
    handleChange(0, { atributeName: "tipoPessoa", value: PERSON_TYPE.PF })
    handleChange(0, { atributeName: "papelProcesso", value: papelProcesso })
  }, [])

  const fillExistingUser = (
    cpfCnpj: string,
    index: number,
    setFieldValue?: (
      field: string,
      value: string,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (cpfCnpj.length === 11 || cpfCnpj.length === 14) {
      axiosBase
        .get(`/user/search?document=${formatCpfCnpj(cpfCnpj)}`)
        .then((response) => {
          const data = response.data.data
          setRequerentes((prevRequerentes: Claimant[]) => {
            const novosRequerentes = [...prevRequerentes]
            novosRequerentes[index] = {
              ...novosRequerentes[index],
              id: data.id,
              nomeCompleto: data.fullName,
              email: data.email,
              tel: data.phone,
            }
            return novosRequerentes
          })
          if (setFieldValue) {
            setFieldValue(
              index === 0
                ? "nomeCompleto"
                : `requerentes[${index - 1}].nomeCompleto`,
              data.fullName
            )
            setFieldValue(
              index === 0 ? "email" : `requerentes[${index - 1}].email`,
              data.email
            )
            setFieldValue(
              index === 0 ? "tel" : `requerentes[${index - 1}].tel`,
              data.phone
            )
          }
        })
        .catch(() => {
          setRequerentes((prevRequerentes: Claimant[]) => {
            const novosRequerentes = [...prevRequerentes]
            novosRequerentes[index] = {
              ...novosRequerentes[index],
              id: null,
            }
            return novosRequerentes
          })
        })
    }
  }

  const handleChange = (
    index: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: any,
    lawerCase?: boolean | null,
    setFieldValue?: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void
  ) => {
    if (event.target) {
      const { name, value, alt } = event.target

      if (lawerCase) {
        setRequerentes((prevRequerentes: Claimant[]) => {
          const novosRequerentes = [...prevRequerentes]
          novosRequerentes[index] = {
            ...novosRequerentes[index],
            oab: "",
          }
          return novosRequerentes
        })
      }

      let propertyName: string

      if (!alt) {
        propertyName = name
      } else {
        propertyName = alt
      }

      setRequerentes((prevRequerentes: Claimant[]) => {
        const novosRequerentes = [...prevRequerentes]
        novosRequerentes[index] = {
          ...novosRequerentes[index],
          [propertyName]: value,
        }
        return novosRequerentes
      })
    }

    if (
      (event && event.atributeName && event.atributeName == "tipoPessoa") ||
      event.atributeName == "papelProcesso"
    ) {
      setRequerentes((prevRequerentes: Claimant[]) => {
        const novosRequerentes = [...prevRequerentes]
        novosRequerentes[index] = {
          ...novosRequerentes[index],
          [event.atributeName]: event.value,
        }
        return novosRequerentes
      })
    }
    if (event.target && event.target.name.endsWith("cpfCnpj")) {
      const cpfCnpj = event.target.value.replace(/\D/g, "")
      fillExistingUser(cpfCnpj, index, setFieldValue)
    }
  }

  const adicionarRequerente = () => {
    setRequerentes([
      ...requerentes,
      {
        cpfCnpj: "",
        tipoPessoa: PERSON_TYPE.PF,
        nomeCompleto: "",
        email: "",
        tel: "",
        papelProcesso,
      },
    ])
  }

  const removerRequerente = (index: number) => {
    const novosRequerentes = [...requerentes]
    novosRequerentes.splice(index, 1)
    setRequerentes(novosRequerentes)
  }

  useEffect(() => {
    if (refPhone.current) {
      IMask(refPhone.current!, {
        mask: "(00) 00000-0000",
      })
    }
  }, [requerentes])

  return (
    <>
      <div className="flex flex-col gap-4 w-full">
        <Formik
          initialValues={
            secondStep
              ? {
                  id: undefined,
                  cpfCnpj: "",
                  oab: "",
                  papelProcesso: PROCESS_ROLES.Lawyer,
                  tipoPessoa: PERSON_TYPE.PF,
                  nomeCompleto: "",
                  email: "",
                  tel: "",
                  requerentes: [],
                }
              : {
                  id: undefined,
                  cpfCnpj: "",
                  nomeCompleto: "",
                  tipoPessoa: PERSON_TYPE.PF,
                  email: "",
                  tel: "",
                  requerentes: [],
                }
          }
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={
            secondStep ? validationSchema : alternativeValidationSchema
          }
          onSubmit={() => {
            formSubmit(
              stepToGoInfo.tab,
              stepToGoInfo.state,
              stepToGoInfo.tabToGo
            )
          }}
        >
          {({ errors, values, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {secondStep == true && (
                <>
                  <p className="text-xl font-bold	mt-5 font-sans leading-5 mb-8">
                    Dados do requerente
                  </p>
                  <Field
                    as="fieldset"
                    name="papelProcesso"
                    alt="papelProcesso"
                    className="flex max-w-md flex-col gap-4"
                    id="radio"
                  >
                    <legend className="mb-4 font-semibold">
                      Identifique o seu papel no processo
                    </legend>
                    <div className="flex items-center gap-2">
                      <input
                        className="checked:bg-primary-600"
                        type="radio"
                        id="advogado"
                        name="papelProcesso"
                        value={PROCESS_ROLES.Lawyer}
                        checked={papelProcesso === PROCESS_ROLES.Lawyer}
                        onChange={(e) => {
                          handleChange(0, e, true)
                          handlePapelProcessoChange(PROCESS_ROLES.Lawyer)
                          setFieldValue("papelProcesso", PROCESS_ROLES.Lawyer)
                        }}
                        required
                      />
                      <label htmlFor="advogado">Sou advogado(a)</label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        className="checked:bg-primary-600"
                        type="radio"
                        id="rep-requerente"
                        name="papelProcesso"
                        value={PROCESS_ROLES.Representative}
                        checked={papelProcesso === PROCESS_ROLES.Representative}
                        onChange={(e) => {
                          handleChange(0, e)
                          handlePapelProcessoChange(
                            PROCESS_ROLES.Representative
                          )
                          setFieldValue(
                            "papelProcesso",
                            PROCESS_ROLES.Representative
                          )
                        }}
                      />
                      <label htmlFor="rep-requerente">
                        Represento a parte requerente
                      </label>
                    </div>
                    <div className="flex items-center gap-2">
                      <input
                        className="checked:bg-primary-600"
                        type="radio"
                        id="sou-requerente"
                        name="papelProcesso"
                        value={PROCESS_ROLES.Claimant}
                        checked={papelProcesso === PROCESS_ROLES.Claimant}
                        onChange={(e) => {
                          handleChange(0, e)
                          handlePapelProcessoChange(PROCESS_ROLES.Claimant)
                          setFieldValue("papelProcesso", PROCESS_ROLES.Claimant)
                        }}
                      />
                      <label htmlFor="sou-requerente">
                        Sou requerente (meu nome está no contrato)
                      </label>
                    </div>
                  </Field>
                  {papelProcesso === PROCESS_ROLES.Lawyer && (
                    <div>
                      <div className="mb-2 block mt-4">
                        <Label htmlFor="oab" value="OAB" />
                      </div>
                      <Field
                        alt="oab"
                        name="oab"
                        className={`lg:w-[384px] w-full h-[42px] inline-flex items-center rounded-md border ${
                          errors && errors.oab
                            ? "border-red-500 bg-red-50"
                            : "border-gray-300 bg-gray-50"
                        } px-3 text-sm text-gray-900`}
                        type="text"
                        onChange={(
                          e: { target: { value: string } } | undefined
                        ) => {
                          setFieldValue && setFieldValue("oab", e?.target.value)
                          handleChange(0, e)
                        }}
                        value={
                          requerentes && requerentes[0] && requerentes[0].oab
                            ? requerentes[0].oab
                            : ""
                        }
                      />
                      {errors.oab && (
                        <ErrorMessage
                          component="div"
                          className="text-red-600 text-sm mt-2"
                          name="oab"
                        />
                      )}
                    </div>
                  )}
                  {papelProcesso !== PROCESS_ROLES.Claimant && (
                    <legend className="mb-4 mt-4 font-semibold">
                      Insira os dados do requerente que está no contrato
                    </legend>
                  )}
                </>
              )}
              <div
                className={`w-full flex lg:flex-row flex-col gap-8 ${
                  papelProcesso !== PROCESS_ROLES.Claimant ? "mt-0" : "mt-4"
                }`}
              >
                <div className="flex flex-col lg:w-2/4 w-full">
                  <div className="mb-2 block">
                    <Label
                      color={errors && errors.cpfCnpj ? "failure" : ""}
                      htmlFor="cpf-cnpj"
                      value="CPF/CNPJ"
                    />
                  </div>
                  <input
                    id="cpf-cnpj"
                    alt="cpfCnpj"
                    ref={cpfCnpjInputRef}
                    name="cpfCnpj"
                    className={`h-[42px] text-sm text-gray-900 inline-flex items-center rounded-md border ${
                      errors && errors.cpfCnpj
                        ? "border-red-500 bg-red-50"
                        : "border-gray-300 bg-gray-50"
                    }`}
                    type="text"
                    placeholder={"000.000.000-00"}
                    maxLength={18}
                    value={
                      (requerentes &&
                        requerentes[0] &&
                        requerentes[0].cpfCnpj &&
                        formatCpfCnpj(requerentes[0].cpfCnpj)) ||
                      undefined
                    }
                    onChange={(
                      e: ChangeEvent<HTMLInputElement> | undefined
                    ) => {
                      setFieldValue("cpfCnpj", e?.target.value)
                      handleChange(0, e, null, setFieldValue)
                      formatCpfCnpj(e?.target.value ?? "")
                    }}
                    onBlur={(
                      e: FocusEvent<HTMLInputElement, Element> | undefined
                    ) => {
                      handleChange(0, e, null, setFieldValue)
                    }}
                    onFocus={(e: FocusEvent<HTMLInputElement>) => {
                      handleChange(0, e, null, setFieldValue)
                    }}
                  />
                  {errors.cpfCnpj && (
                    <ErrorMessage
                      component="div"
                      className="text-red-600 text-sm"
                      name="cpfCnpj"
                    />
                  )}
                </div>
                <fieldset
                  className="flex flex-col lg:w-2/4 w-full gap-4 lg:mt-8 mb-2.5 lg:justify-center"
                  id="radio"
                >
                  <div className="flex gap-8 ">
                    <div className="flex items-center gap-2">
                      <Radio
                        id="pessoa-fisica"
                        name="tipoPessoa"
                        value={PERSON_TYPE.PF}
                        disabled={
                          requerentes.length > 0
                            ? Number.isInteger(requerentes[0].id) ||
                              requerentes[0].id === undefined
                            : true
                        }
                        className="checked:bg-primary-600"
                        checked={
                          requerentes &&
                          requerentes[0] &&
                          (requerentes[0].tipoPessoa === PERSON_TYPE.PF ||
                            requerentes[0]?.cpfCnpj?.length === 14)
                        }
                        onBlur={(e) => {
                          handleChange(0, e)
                        }}
                        onFocus={(e) => {
                          handleChange(0, e)
                        }}
                        onChange={(e) => {
                          handleChange(0, e)
                        }}
                      />
                      <Label htmlFor="pessoa-fisica">Pessoa física</Label>
                    </div>
                    <div className="flex items-center gap-2">
                      <Radio
                        id="pessoa-juridica"
                        name="tipoPessoa"
                        value="PJ"
                        className="checked:bg-primary-600"
                        disabled={
                          requerentes.length > 0
                            ? Number.isInteger(requerentes[0].id) ||
                              requerentes[0].id === undefined
                            : true
                        }
                        required
                        checked={
                          requerentes &&
                          requerentes[0] &&
                          (requerentes[0].tipoPessoa === "PJ" ||
                            requerentes[0]?.cpfCnpj?.length === 18)
                        }
                        onBlur={(e) => {
                          handleChange(0, e)
                        }}
                        onFocus={(e) => {
                          handleChange(0, e)
                        }}
                        onChange={(e) => {
                          handleChange(0, e)
                        }}
                      />
                      <Label htmlFor="pessoa-juridica">Pessoa jurídica</Label>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="flex gap-8 lg:flex-row flex-col">
                <div className="flex flex-col my-4 lg:w-4/4 w-full">
                  <div className="mb-2 block ">
                    <Label
                      htmlFor="nome-completo"
                      value="Nome completo"
                      color={errors && errors.nomeCompleto ? "failure" : ""}
                    />
                  </div>
                  <input
                    alt="nomeCompleto"
                    name="nomeCompleto"
                    id="nome-completo"
                    disabled={
                      papelProcesso === PROCESS_ROLES.Lawyer
                        ? false
                        : requerentes.length > 0
                        ? requerentes[0].id === undefined ||
                          Number.isInteger(requerentes[0].id)
                        : true
                    }
                    placeholder="Nome completo"
                    className={`h-[42px] inline-flex items-center rounded-md border ${
                      errors && errors.nomeCompleto
                        ? "border-red-500 bg-red-50"
                        : "border-gray-300 bg-gray-50 px-3 text-sm text-gray-900"
                    }`}
                    type="text"
                    value={
                      requerentes.length > 0 ? requerentes[0].nomeCompleto : ""
                    }
                    onChange={(e) => {
                      handleChange(0, e)
                      setFieldValue("nomeCompleto", e.target.value)
                    }}
                    onBlur={(e) => handleChange(0, e)}
                    onFocus={(e) => handleChange(0, e)}
                  />
                  {errors.nomeCompleto && (
                    <ErrorMessage
                      component="div"
                      className="text-red-600 text-sm"
                      name="nomeCompleto"
                    />
                  )}
                </div>
              </div>
              <div className="flex gap-8 lg:flex-row flex-col">
                <div className="flex flex-col lg:w-2/4 w-full">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="email"
                      value="E-mail"
                      color={errors && errors.email ? "failure" : ""}
                    />
                  </div>
                  <input
                    alt="email"
                    id="email"
                    name="email"
                    disabled={
                      papelProcesso === PROCESS_ROLES.Lawyer
                        ? false
                        : requerentes.length > 0
                        ? Number.isInteger(requerentes[0].id) ||
                          requerentes[0].id === undefined
                        : true
                    }
                    placeholder="requerente@email.com"
                    className={`h-[42px] inline-flex items-center rounded-md border ${
                      errors && errors.email
                        ? "border-red-500 bg-red-50"
                        : "border-gray-300 bg-gray-50 px-3 text-sm text-gray-900"
                    }`}
                    type="email"
                    value={requerentes.length > 0 ? requerentes[0].email : ""}
                    onChange={(e) => {
                      handleChange(0, e)
                      setFieldValue("email", e.target.value)
                    }}
                    onBlur={(e) => {
                      handleChange(0, e)
                    }}
                    onFocus={(e) => {
                      handleChange(0, e)
                    }}
                  />
                  {errors.email && (
                    <ErrorMessage
                      component="div"
                      className="text-red-600 text-sm"
                      name="email"
                    />
                  )}
                </div>
                <div className="flex flex-col lg:w-2/4 w-full">
                  <div className="mb-2 block">
                    <Label
                      htmlFor="tel"
                      value="Telefone/WhatsApp"
                      color={errors && errors.tel ? "failure" : ""}
                    />
                  </div>
                  <input
                    ref={(ref) => (refPhone.current = ref)}
                    alt="tel"
                    minLength={15}
                    id="tel"
                    name="tel"
                    disabled={
                      papelProcesso === PROCESS_ROLES.Lawyer
                        ? false
                        : requerentes.length > 0
                        ? Number.isInteger(requerentes[0].id) ||
                          requerentes[0].id === undefined
                        : true
                    }
                    className={`h-[42px] inline-flex items-center rounded-md border ${
                      errors && errors.tel
                        ? "border-red-500 bg-red-50"
                        : "border-gray-300 bg-gray-50 px-3 text-sm text-gray-900"
                    }`}
                    type="text"
                    value={requerentes.length > 0 ? requerentes[0].tel : ""}
                    onChange={(e) => {
                      handleChange(0, e)
                      setFieldValue("tel", e.target.value)
                    }}
                    onBlur={(e) => {
                      handleChange(0, e)
                    }}
                    onFocus={(e) => {
                      handleChange(0, e)
                    }}
                    placeholder="(DDD) + número"
                  />
                  {errors.tel && (
                    <ErrorMessage
                      component="div"
                      className="text-red-600 text-sm"
                      name="tel"
                    />
                  )}
                </div>
              </div>
              <div>
                <FieldArray name="requerentes">
                  {({ form, push, remove }) => (
                    <>
                      {form.values.requerentes.map(
                        (requerente: Claimant, index: number) => {
                          return (
                            <>
                              <div
                                key={`${
                                  requerente.id
                                }_${requerente.nomeCompleto?.trim()}_${index}`}
                                className="flex flex-col gap-4 relative"
                              >
                                <div className="flex w-full items-center">
                                  <legend className="mb-4 mt-8">
                                    {sectionTitle}
                                  </legend>
                                  <button
                                    type="button"
                                    className="text-sm not-italic font-medium bg-white hover:bg-gray-100 text-primary-800 mt-4 ml-2.5 rounded-lg border-gray-200 border py-2.5 px-5"
                                    onClick={() => {
                                      removerRequerente(index + 1)
                                      remove(index)
                                    }}
                                  >
                                    Excluir
                                  </button>
                                </div>
                                <div className="w-full flex gap-8 lg:flex-row flex-col">
                                  <div className="flex flex-col lg:w-2/4 w-full">
                                    <div className="mb-2 block">
                                      <Label
                                        htmlFor={`requerentes[${index}].cpfCnpj`}
                                        value="CPF/CNPJ"
                                        color={
                                          getIn(
                                            form.errors,
                                            `requerentes[${index}].cpfCnpj`
                                          )
                                            ? "failure"
                                            : ""
                                        }
                                      />
                                    </div>
                                    <input
                                      alt={`cpfCnpj`}
                                      id={`requerentes[${index}].cpfCnpj`}
                                      name={`requerentes[${index}].cpfCnpj`}
                                      className={`h-[42px] text-sm text-gray-900 inline-flex items-center rounded-md border ${
                                        getIn(
                                          form.errors,
                                          `requerentes[${index}].cpfCnpj`
                                        )
                                          ? "border-red-500 bg-red-50"
                                          : "border-gray-300 bg-gray-50"
                                      } `}
                                      type="text"
                                      onBlur={(e) => {
                                        handleChange(
                                          index + 1,
                                          e,
                                          null,
                                          setFieldValue
                                        )
                                      }}
                                      onFocus={(e) => {
                                        handleChange(
                                          index + 1,
                                          e,
                                          null,
                                          setFieldValue
                                        )
                                      }}
                                      value={
                                        (requerentes &&
                                          requerentes[index + 1] &&
                                          requerentes[index + 1].cpfCnpj &&
                                          formatCpfCnpj(
                                            requerentes[index + 1].cpfCnpj
                                          )) ||
                                        undefined
                                      }
                                      onChange={(e) => {
                                        handleChange(
                                          index + 1,
                                          e,
                                          null,
                                          setFieldValue
                                        )
                                        setFieldValue(
                                          `requerentes[${index}].cpfCnpj`,
                                          e.target.value
                                        )
                                      }}
                                      placeholder={"000.000.000-00"}
                                      maxLength={18}
                                    />
                                    {getIn(
                                      form.errors,
                                      `requerentes[${index}].cpfCnpj`
                                    ) && (
                                      <ErrorMessage
                                        name={`requerentes[${index}].cpfCnpj`}
                                        className="text-red-600 text-sm"
                                        component="div"
                                      />
                                    )}
                                  </div>
                                  <Field
                                    as="fieldset"
                                    className="flex flex-col lg:w-2/4 w-full gap-4 lg:mt-8 mb-2.5 lg:justify-center"
                                    id="radio"
                                    name={`requerentes[${index}].tipoPessoa`}
                                  >
                                    <div className="flex gap-8">
                                      <div className="flex items-center gap-2">
                                        <Radio
                                          id={`pessoa-fisica-${index + 1}`}
                                          value={PERSON_TYPE.PF}
                                          alt="tipoPessoa"
                                          disabled={
                                            requerentes[index + 1]
                                              ? Number.isInteger(
                                                  requerentes[index + 1].id
                                                ) ||
                                                requerentes[index + 1].id ===
                                                  undefined
                                              : true
                                          }
                                          className={`checked:bg-primary-600 ${
                                            getIn(
                                              form.errors,
                                              `requerentes[${index}].tipoPessoa`
                                            )
                                              ? "border-red-500 bg-red-50"
                                              : "border-gray-300 bg-gray-50"
                                          } `}
                                          onBlur={(e) => {
                                            handleChange(index + 1, e)
                                          }}
                                          onFocus={(e) => {
                                            handleChange(index + 1, e)
                                          }}
                                          onChange={(e) => {
                                            handleChange(index + 1, e)
                                          }}
                                          checked={
                                            requerentes &&
                                            requerentes[index + 1] &&
                                            requerentes[index + 1].cpfCnpj &&
                                            requerentes[
                                              index + 1
                                            ].cpfCnpj.replace(/[^\d]/g, "")
                                              .length <= 11
                                              ? true
                                              : false
                                          }
                                        />
                                        <Label
                                          htmlFor={`pessoa-fisica-${index + 1}`}
                                        >
                                          Pessoa física
                                        </Label>
                                      </div>
                                      <div className="flex items-center gap-2">
                                        <Radio
                                          alt="tipoPessoa"
                                          id={`pessoa-juridica-${index + 1}`}
                                          value="PJ"
                                          disabled={
                                            requerentes[index + 1]
                                              ? Number.isInteger(
                                                  requerentes[index + 1].id
                                                ) ||
                                                requerentes[index + 1].id ===
                                                  undefined
                                              : true
                                          }
                                          className={`checked:bg-primary-600 ${
                                            getIn(
                                              form.errors,
                                              `requerentes[${index}].tipoPessoa`
                                            )
                                              ? "border-red-500 bg-red-50"
                                              : "border-gray-300 bg-gray-50"
                                          } `}
                                          onChange={(e) => {
                                            handleChange(index + 1, e)
                                          }}
                                          onBlur={(e) => {
                                            handleChange(index + 1, e)
                                          }}
                                          onFocus={(e) => {
                                            handleChange(index + 1, e)
                                          }}
                                          checked={
                                            requerentes &&
                                            requerentes[index + 1] &&
                                            requerentes[index + 1].cpfCnpj &&
                                            requerentes[
                                              index + 1
                                            ].cpfCnpj.replace(/[^\d]/g, "")
                                              .length > 11
                                              ? true
                                              : false
                                          }
                                        />
                                        <Label
                                          htmlFor={`pessoa-juridica-${
                                            index + 1
                                          }`}
                                        >
                                          Pessoa jurídica
                                        </Label>
                                      </div>
                                    </div>
                                    {getIn(
                                      form.errors,
                                      `requerentes[${index}].tipoPessoa`
                                    ) && (
                                      <ErrorMessage
                                        name={`requerentes[${index}].tipoPessoa`}
                                        className="text-red-600 text-sm"
                                        component="div"
                                      />
                                    )}
                                  </Field>
                                </div>
                                <div className="flex gap-8 lg:flex-row flex-col">
                                  <div className="flex flex-col lg:w-4/4 w-full">
                                    <div className="mb-2 block">
                                      <Label
                                        htmlFor={`nome-completo-${index + 1}`}
                                        value="Nome completo"
                                        color={
                                          getIn(
                                            form.errors,
                                            `requerentes[${index}].nomeCompleto`
                                          )
                                            ? "failure"
                                            : ""
                                        }
                                      />
                                    </div>
                                    <input
                                      id={`nome-completo-${index + 1}`}
                                      name={`requerentes[${index}].nomeCompleto`}
                                      alt="nomeCompleto"
                                      disabled={
                                        requerentes[index + 1]
                                          ? Number.isInteger(
                                              requerentes[index + 1].id
                                            ) ||
                                            requerentes[index + 1].id ===
                                              undefined
                                          : true
                                      }
                                      placeholder="Nome completo"
                                      className={`h-[42px] inline-flex items-center rounded-md border ${
                                        getIn(
                                          form.errors,
                                          `requerentes[${index}].nomeCompleto`
                                        )
                                          ? "border-red-500 bg-red-50"
                                          : "border-gray-300 bg-gray-50"
                                      } px-3 text-sm text-gray-900`}
                                      type="text"
                                      onBlur={(e) => handleChange(index + 1, e)}
                                      onFocus={(e) =>
                                        handleChange(index + 1, e)
                                      }
                                      value={
                                        values.requerentes[index]
                                          ? values.requerentes[index][
                                              "nomeCompleto"
                                            ]
                                          : ""
                                      }
                                      onChange={(e) => {
                                        handleChange(index + 1, e)
                                        setFieldValue(
                                          `requerentes[${index}].nomeCompleto`,
                                          e.target.value
                                        )
                                      }}
                                    />
                                    {getIn(
                                      form.errors,
                                      `requerentes[${index}].nomeCompleto`
                                    ) && (
                                      <ErrorMessage
                                        name={`requerentes[${index}].nomeCompleto`}
                                        className="text-red-600 text-sm"
                                        component="div"
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="flex gap-8 lg:flex-row flex-col">
                                  <div className="flex flex-col lg:w-2/4 w-full">
                                    <div className="mb-2 block">
                                      <Label
                                        htmlFor={`email-${index + 1}`}
                                        value="E-mail"
                                        color={
                                          getIn(
                                            form.errors,
                                            `requerentes[${index}].email`
                                          )
                                            ? "failure"
                                            : ""
                                        }
                                      />
                                    </div>
                                    <input
                                      alt="email"
                                      id={`email-${index + 1}`}
                                      name={`requerentes[${index}].email`}
                                      placeholder="requerente@email.com"
                                      disabled={
                                        requerentes[index + 1]
                                          ? Number.isInteger(
                                              requerentes[index + 1].id
                                            ) ||
                                            requerentes[index + 1].id ===
                                              undefined
                                          : true
                                      }
                                      className={`h-[42px] inline-flex items-center rounded-md border ${
                                        getIn(
                                          form.errors,
                                          `requerentes[${index}].email`
                                        )
                                          ? "border-red-500 bg-red-50"
                                          : "border-gray-300 bg-gray-50"
                                      } px-3 text-sm text-gray-900`}
                                      type="text"
                                      onBlur={(e) => handleChange(index + 1, e)}
                                      onFocus={(e) =>
                                        handleChange(index + 1, e)
                                      }
                                      value={
                                        values.requerentes[index]
                                          ? values.requerentes[index]["email"]
                                          : ""
                                      }
                                      onChange={(e) => {
                                        handleChange(index + 1, e)
                                        setFieldValue(
                                          `requerentes[${index}].email`,
                                          e.target.value
                                        )
                                      }}
                                    />
                                    {getIn(
                                      form.errors,
                                      `requerentes[${index}].email`
                                    ) && (
                                      <ErrorMessage
                                        name={`requerentes[${index}].email`}
                                        className="text-red-600 text-sm"
                                        component="div"
                                      />
                                    )}
                                  </div>
                                  <div className="flex flex-col lg:w-2/4 w-full">
                                    <div className="mb-2 block">
                                      <Label
                                        htmlFor={`tel-${index + 1}`}
                                        value="Telefone/WhatsApp"
                                        color={
                                          getIn(
                                            form.errors,
                                            `requerentes[${index}].tel`
                                          )
                                            ? "failure"
                                            : ""
                                        }
                                      />
                                    </div>
                                    <input
                                      alt="tel"
                                      ref={(ref) => (refPhone.current = ref)}
                                      id={`tel-${index + 1}`}
                                      name={`requerentes[${index}].tel`}
                                      disabled={
                                        requerentes[index + 1]
                                          ? Number.isInteger(
                                              requerentes[index + 1].id
                                            ) ||
                                            requerentes[index + 1].id ===
                                              undefined
                                          : true
                                      }
                                      className={`h-[42px] inline-flex items-center rounded-md border ${
                                        getIn(
                                          form.errors,
                                          `requerentes[${index}].tel`
                                        )
                                          ? "border-red-500 bg-red-50"
                                          : "border-gray-300 bg-gray-50"
                                      } px-3 text-sm text-gray-900`}
                                      type="text"
                                      value={
                                        values.requerentes[index]
                                          ? values.requerentes[index]["tel"]
                                          : ""
                                      }
                                      onBlur={(e) => handleChange(index + 1, e)}
                                      onFocus={(e) =>
                                        handleChange(index + 1, e)
                                      }
                                      onChange={(e) => {
                                        handleChange(index + 1, e)
                                        setFieldValue(
                                          `requerentes[${index}].tel`,
                                          e.target.value
                                        )
                                      }}
                                      placeholder="(DDD) + número"
                                    />
                                    {getIn(
                                      form.errors,
                                      `requerentes[${index}].tel`
                                    ) && (
                                      <ErrorMessage
                                        name={`requerentes[${index}].tel`}
                                        className="text-red-600 text-sm"
                                        component="div"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          )
                        }
                      )}
                      <button
                        type="button"
                        className="mt-5 w-[210px] text-sm not-italic font-medium bg-white text-primary-800 rounded-lg border-gray-200 hover:bg-gray-100 border py-2 px-3"
                        onClick={() => {
                          adicionarRequerente()
                          push({
                            cpfCnpj: "",
                            tipoPessoa: PERSON_TYPE.PF,
                            nomeCompleto: "",
                            email: "",
                            tel: "",
                            papelProcesso,
                          })
                        }}
                      >
                        {btnText}
                      </button>
                    </>
                  )}
                </FieldArray>
              </div>
              <Button
                className="mt-14 min-w-[107px] w-auto"
                type="submit"
                color="primary"
              >
                {primaryBtnText}
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </>
  )
}
