import { DocFile } from "@features/conciliation/types/conciliation.type"
import { Button, FileInput, Label } from "flowbite-react"
import React from "react"

type ChatAttachmentProps = {
  attachFileOpen: boolean
  setAttachments: React.Dispatch<React.SetStateAction<DocFile[]>>
  attachments: DocFile[]
  errors: string[]
  setErrors: React.Dispatch<React.SetStateAction<string[]>>
  setAttachFileOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function ChatAttachment({
  attachFileOpen,
  setAttachments,
  attachments,
  errors,
  setErrors,
  setAttachFileOpen,
}: ChatAttachmentProps) {
  const handleDeleteFile = (index: number) => {
    setErrors((prev) => {
      const updatedErrors = [...prev]
      updatedErrors.splice(index, 1)
      return updatedErrors
    })
    setAttachments((prev) => {
      const updatedDocs = [...prev]
      updatedDocs.splice(index, 1)
      if (updatedDocs.length === 0) {
        setAttachFileOpen(false)
      }
      return updatedDocs
    })
  }

  const handleReplaceFile = (index: number) => {
    const hiddenFileInput = document.createElement("input")
    hiddenFileInput.type = "file"
    hiddenFileInput.addEventListener("change", (event) => {
      const newFile = (event.target as HTMLInputElement).files?.[0]
      if (newFile) {
        const newFilesInfo = {
          name: newFile.name,
          size: newFile.size,
          type: newFile.type,
          file: newFile,
          detail: newFile.name,
        }

        setAttachments((prev) => {
          const updatedDocs = [...prev]
          updatedDocs[index] = newFilesInfo
          return updatedDocs
        })
      }
    })
    hiddenFileInput.click()
  }

  return (
    <>
      {attachFileOpen && (
        <div className="shadow-md flex flex-col py-8 px-4 bg-white gap-4">
          <div
            className={`relative inline-block overflow-hidden ${
              attachments.length > 0 ? "mb-4" : ""
            }`}
          >
            <label htmlFor="file" className="flex cursor-pointer">
              <span className="h-[42px] text-sm rounded-tl-lg rounded-bl-lg text-white flex p-2.5 border-l border-t border-b border-gray-300 bg-primary-500">
                Procurar
              </span>
              <div className="h-[42px] text-sm px-4 py-3 w-full flex items-center rounded-tr-lg rounded-br-lg text-gray-900 border-r border-l border-t border-b border-gray-300 bg-gray-50">
                Nenhum arquivo encontrado.
              </div>
            </label>
            <FileInput
              className="hidden"
              id="file"
              onChange={(e) => {
                if (e.target.files !== null && e.target.files.length > 0) {
                  const filesArray = Array.from(e.target.files)
                  const newFilesInfo = filesArray.map((file) => ({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    detail: "",
                    file,
                  }))
                  setAttachments((prev) => [...prev, ...newFilesInfo])
                }
              }}
              multiple
            />
          </div>
          {Array.isArray(attachments)
            ? attachments.map((item, index) => {
                return (
                  <div
                    key={`${item.name}_${index}`}
                    className="flex flex-col mb-4"
                  >
                    <div className="flex">
                      <button
                        type="button"
                        className="h-[42px] text-sm rounded-tl-lg rounded-bl-lg text-white flex p-2.5 border-l border-t border-b border-gray-300 bg-primary-500"
                        onClick={() => handleReplaceFile(index)}
                      >
                        Procurar
                      </button>
                      <p className="h-[42px] text-sm px-4 py-3 w-full flex items-center rounded-tr-lg rounded-br-lg text-gray-900 border-r border-l border-t border-b border-gray-300 bg-gray-50">
                        {item.name}
                      </p>
                      <Button
                        size="md"
                        color="gray"
                        className="text-sm h-[42px] ml-4"
                        onClick={() => handleDeleteFile(index)}
                      >
                        Excluir
                      </Button>
                    </div>
                    <Label
                      className="my-2 font-medium"
                      htmlFor={`${item.name}_${index}`}
                      value="*Nome detalhado do arquivo"
                      color={
                        errors.find(
                          (error) => error === `${item.name}_${index}`
                        )
                          ? "failure"
                          : ""
                      }
                    />
                    <input
                      name={`${item.name}_${index}`}
                      type="text"
                      className={`disabled:text-gray-400 w-full rounded-lg border flex p-2.5 items-center gap-4 self-stretch ${
                        errors.find(
                          (error) => error === `${item.name}_${index}`
                        )
                          ? "border-red-600 bg-red-50"
                          : "border-gray-300 bg-gray-50"
                      } px-3 text-sm text-gray-900`}
                      value={item.detail}
                      required
                      onChange={(e) => {
                        setErrors((prev) => {
                          const updatedErrors = [...prev]
                          return updatedErrors.filter(
                            (err) => err !== `${item.name}_${index}`
                          )
                        })
                        setAttachments((prev) => {
                          const updatedDocs = [...prev]
                          updatedDocs[index] = {
                            ...updatedDocs[index],
                            detail: e.target.value,
                          }
                          return updatedDocs
                        })
                      }}
                    />
                  </div>
                )
              })
            : null}
        </div>
      )}
    </>
  )
}

export default ChatAttachment
