import axios from "axios"
import axiosBase from "./base.service"
import { DocFile } from "@features/conciliation/types/conciliation.type"

const ATTACHMENT_PATH = "/attachment"

const AttachmentService = {
  uploadUrl: async (
    file: File,
    fileName: string
  ): Promise<{ url: string; fileName: string }> => {
    const resp = await axiosBase.post(`${ATTACHMENT_PATH}/url-upload`, {
      fileName,
      name: file.name,
      mimeType: file.type,
    })
    return resp.data.data
  },
  post: async (
    name: string,
    type: string,
    detailName: string
  ): Promise<{ id: number; name: string; fileName: string }> => {
    const resp = await axiosBase.post(ATTACHMENT_PATH, {
      name: name,
      mimeType: type,
      fileName: detailName,
    })
    return resp.data.data
  },
  put: async (url: string, file: File): Promise<void> => {
    const config = {
      headers: {
        "Content-Type": file.type,
      },
    }

    const resp = await axios.put(url, file, config)
    return resp.data.data
  },
  delete: async (): Promise<void> => {
    await axiosBase.delete(`${ATTACHMENT_PATH}`)
  },

  uploadAndGet: async (
    attachments: DocFile[] | undefined
  ): Promise<number[]> => {
    if (!attachments || attachments.length === 0) return []

    const attachs: Array<number> = []

    for (let i = 0; i < attachments.length; i++) {
      const { url: resUrl, fileName } = await AttachmentService.uploadUrl(
        attachments[i].file,
        attachments[i].name
      )

      await AttachmentService.put(resUrl, attachments[i].file)

      const { id: fileId } = await AttachmentService.post(
        attachments[i].name,
        attachments[i].type,
        fileName
      )

      attachs.push(fileId)
    }
    return attachs
  },
}

export default AttachmentService
