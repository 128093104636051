import React from "react"

type DocumentAddIconType = {
  width?: string
  height?: string
  color?: string
}

export default function DocumentAddIcon({
  color = "#111928",
  height = "15",
  width = "15",
}: DocumentAddIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.70641 1.77942C4.31833 1.77942 3.94615 1.93358 3.67174 2.20799C3.39733 2.48241 3.24316 2.85459 3.24316 3.24267V12.0221C3.24316 12.4102 3.39733 12.7824 3.67174 13.0568C3.94615 13.3312 4.31833 13.4854 4.70641 13.4854H10.5594C10.9475 13.4854 11.3197 13.3312 11.5941 13.0568C11.8685 12.7824 12.0226 12.4102 12.0226 12.0221V5.74043C12.0226 5.35238 11.8683 4.98026 11.5939 4.70591L9.09615 2.20815C8.8218 1.93372 8.44968 1.7795 8.06164 1.77942H4.70641ZM8.36453 6.16916C8.36453 5.97512 8.28745 5.78903 8.15024 5.65182C8.01303 5.51462 7.82694 5.43754 7.63291 5.43754C7.43887 5.43754 7.25278 5.51462 7.11557 5.65182C6.97836 5.78903 6.90128 5.97512 6.90128 6.16916V7.63241H5.43803C5.244 7.63241 5.0579 7.70949 4.9207 7.84669C4.78349 7.9839 4.70641 8.16999 4.70641 8.36403C4.70641 8.55807 4.78349 8.74416 4.9207 8.88137C5.0579 9.01857 5.244 9.09565 5.43803 9.09565H6.90128V10.5589C6.90128 10.7529 6.97836 10.939 7.11557 11.0762C7.25278 11.2134 7.43887 11.2905 7.63291 11.2905C7.82694 11.2905 8.01303 11.2134 8.15024 11.0762C8.28745 10.939 8.36453 10.7529 8.36453 10.5589V9.09565H9.82778C10.0218 9.09565 10.2079 9.01857 10.3451 8.88137C10.4823 8.74416 10.5594 8.55807 10.5594 8.36403C10.5594 8.16999 10.4823 7.9839 10.3451 7.84669C10.2079 7.70949 10.0218 7.63241 9.82778 7.63241H8.36453V6.16916Z"
        fill={color}
      />
    </svg>
  )
}
