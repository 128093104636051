import React from "react"

type CheckCircleIconType = {
  width?: string
  height?: string
  color?: string
}

export default function CheckCircleIcon({
  color = "#111928",
  height = "15",
  width = "15",
}: CheckCircleIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.63277 13.4855C9.18508 13.4855 10.6738 12.8689 11.7715 11.7712C12.8691 10.6736 13.4858 9.18484 13.4858 7.63253C13.4858 6.08022 12.8691 4.59149 11.7715 3.49384C10.6738 2.39619 9.18508 1.77954 7.63277 1.77954C6.08046 1.77954 4.59173 2.39619 3.49409 3.49384C2.39644 4.59149 1.77979 6.08022 1.77979 7.63253C1.77979 9.18484 2.39644 10.6736 3.49409 11.7712C4.59173 12.8689 6.08046 13.4855 7.63277 13.4855ZM10.3449 6.68654C10.4782 6.54855 10.5519 6.36374 10.5502 6.17191C10.5486 5.98009 10.4716 5.79659 10.336 5.66094C10.2003 5.52529 10.0168 5.44834 9.82501 5.44668C9.63318 5.44501 9.44837 5.51875 9.31039 5.65202L6.90115 8.06126L5.95516 7.11527C5.81717 6.982 5.63236 6.90826 5.44054 6.90992C5.24871 6.91159 5.06521 6.98853 4.92956 7.12418C4.79391 7.25983 4.71696 7.44333 4.7153 7.63516C4.71363 7.82699 4.78737 8.0118 4.92064 8.14979L6.38389 9.61303C6.52109 9.75019 6.70715 9.82724 6.90115 9.82724C7.09515 9.82724 7.28121 9.75019 7.41841 9.61303L10.3449 6.68654Z"
        fill={color}
      />
    </svg>
  )
}
