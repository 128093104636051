import React from "react"
import { Modal } from "flowbite-react"

type ModalProps = {
  openModal: string | undefined
  setOpenModal: React.Dispatch<React.SetStateAction<string | undefined>>
  modalTitle: string
  optionTitle: string[]
  optionDescription: string[]
}

export default function ModalComponent(props: ModalProps) {
  return (
    <Modal
      show={props.openModal === "default"}
      onClose={() => props.setOpenModal(undefined)}
    >
      <Modal.Header className="dark:border-gray-600 border-b p-5">
        {props.modalTitle}
      </Modal.Header>
      <Modal.Body>
        <div className="">
          <p className="text-gray-500 font-bold mb-2">{props.optionTitle[0]}</p>
          <p className="text-base leading-relaxed text-gray-500 ">
            {props.optionDescription[0]}
          </p>
          <p className="text-gray-500 font-bold my-2">{props.optionTitle[1]}</p>
          <p className="text-base leading-relaxed text-gray-500 ">
            {props.optionDescription[1]}
          </p>
          <p className="text-gray-500 font-bold my-2">{props.optionTitle[2]}</p>
          <p className="text-base leading-relaxed text-gray-500 ">
            {props.optionDescription[2]}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  )
}
