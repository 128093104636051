import Error from "@components/error"
import FullLoading from "@components/full-loading"
import ToastComponent from "@components/toast"
import { ProfilePicService, ProfileService } from "@services/profile.service"
import { formatCpfCnpj } from "@utils/helpers/formatCpfCnpj.helper"
import { formatPhoneNumber } from "@utils/helpers/formatPhoneNumber.helper"
import { getInitials } from "@utils/helpers/getInitials.helper"
import { Button, Label } from "flowbite-react"
import { ErrorMessage, Field, Form, Formik } from "formik"
import IMask from "imask"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import * as yup from "yup"

export type IProfile = {
  avatarUrl: string
  fullName: string
  document: string
  personType?: "PF" | "PJ"
  email: string
  phone: string
}

const validationSchema = yup.object({
  phone: yup
    .string()
    .matches(/(\(\d{2}\)\s)(\d{4,5}-\d{4})/g, "Digite um telefone válido")
    .required("É necessário preencher este campo para continuar."),
})

function Profile() {
  const refPhone = useRef<HTMLInputElement | null>(null)
  const [isLoadingProfile, setIsLoadingProfile] = useState(false)
  const [errors, setErrors] = useState({
    update: false,
    remove: false,
    get: false,
  })
  const [imageError, setImageError] = useState(false)
  const [removeFile, setRemoveFile] = useState(false)
  const [selectedFile, setSelectedFile] = useState<FileList | undefined>(
    undefined
  )
  const [profile, setProfile] = useState<IProfile>({
    avatarUrl: "",
    document: "",
    email: "",
    fullName: "",
    phone: "",
  })

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    const files = event.target.files ?? ""
    if (files) {
      setSelectedFile(files)
      setImageError(false)
      setRemoveFile(false)
    } else {
      setSelectedFile(undefined)
    }
  }

  const onFormSubmitCreate = async (values: IProfile) => {
    setIsLoadingProfile(true)

    let avatarUrl = profile.avatarUrl

    if (selectedFile) {
      try {
        const file = selectedFile[0]

        const resUrl = await ProfilePicService.uploadUrl(file)

        if (!resUrl?.url) {
          setIsLoadingProfile(false)
          return
        }

        const urlForUpload = resUrl.url
        avatarUrl = urlForUpload

        await ProfilePicService.put(urlForUpload, file)

        await ProfilePicService.post(file, resUrl.fileName)

        fetchProfile()
      } catch (error) {
        setIsLoadingProfile(false)
      } finally {
        setIsLoadingProfile(false)
      }
    }

    const dataToUpdate = {
      avatarUrl: removeFile ? "" : avatarUrl,
      fullName: values.fullName,
      email: values.email,
      phone: values.phone,
    }

    await ProfileService.put(dataToUpdate)
      .then(() => fetchProfile())
      .catch((err) => {
        console.error("onFormSubmitCreate", err)
        setErrors((prev) => ({ ...prev, update: true }))
      })
      .finally(() => {
        setIsLoadingProfile(false)
        setSelectedFile(undefined)
        setRemoveFile(false)
      })
  }

  const fetchProfile = async () => {
    setIsLoadingProfile(true)

    ProfileService.get()
      .then((data) => {
        if (data.avatarUrl == "") {
          setImageError(true)
        }
        setProfile(data)
      })
      .catch(() => setErrors((prev) => ({ ...prev, get: true })))
      .finally(() => {
        setIsLoadingProfile(false)
        setRemoveFile(false)
      })
  }

  const handleRemoveAvatar = async (selectedFile?: File) => {
    if (!selectedFile) {
      await ProfilePicService.delete()
    } else {
      const dataToUpdate = {
        avatarUrl: "",
        fullName: profile.fullName,
        email: profile.email,
        phone: profile.phone,
      }
      await ProfileService.put(dataToUpdate).catch(() => {
        setErrors((prev) => ({ ...prev, remove: true }))
      })
    }
    await fetchProfile()
    setSelectedFile(undefined)
    setRemoveFile(true)
  }

  useEffect(() => {
    fetchProfile()
  }, [profile.phone])

  useEffect(() => {
    fetchProfile()
  }, [])

  useEffect(() => {
    if (refPhone.current) {
      IMask(refPhone.current!, {
        mask: "(00) 00000-0000",
      })
    }
  }, [profile])

  return isLoadingProfile ? (
    <FullLoading />
  ) : (
    <>
      {Object.values(errors).some((val) => val === true) && <ToastComponent />}
      <div className="flex flex-col w-full px-4 md:px-0 ">
        <h1 className="font-semibold mb-8 text-gray-900 text-3xl leading-none">
          Configurações da conta
        </h1>
        <div className="flex items-center md:bg-white md:shadow-md md:p-8 flex-col h-full min-h-[80vh]">
          {errors.get ? (
            <Error />
          ) : (
            <div className="max-w-screen-sm w-full">
              <h5 className="font-semibold mb-4 text-gray-900 text-xl leading-none">
                Informações gerais
              </h5>
              <p className="font-medium mb-2 text-gray-900 text-sm leading-relaxed">
                Adicione sua foto. Ela ficará visível apenas para pessoas do
                mesmo processo.
              </p>
              <div className="flex w-full mb-8 flex-col md:flex-row">
                <div className="mb-4 w-20 h-20 md:mb-0 md:w-24 md:h-20">
                  {imageError ? (
                    <div className="flex justify-center items-center w-full border-none h-full rounded-full bg-gray-300">
                      <p className="text-2xl tracking-widest">
                        {getInitials(profile.fullName)}
                      </p>
                    </div>
                  ) : (
                    <img
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile[0])
                          : profile.avatarUrl
                      }
                      alt={profile.fullName}
                      className="w-full border-none h-full rounded-full bg-primary-600"
                    />
                  )}
                </div>
                <div className="md:pl-4 text-gray-900 w-full">
                  <div className="flex relative overflow-hidden cursor-pointer">
                    <span className="text-sm rounded-tl-lg rounded-bl-lg text-white flex p-3 border-l border-t border-b border-gray-300 bg-gray-800">
                      Buscar
                    </span>
                    <input
                      type="file"
                      className="absolute inset-0 opacity-0 cursor-pointer w-full h-full left-0 top-0"
                      id="fileInput"
                      onChange={handleFileChange}
                    />
                    <div className="text-sm px-4 py-3 w-full flex items-center rounded-tr-lg rounded-br-lg text-gray-900 border-r border-l border-t border-b border-gray-300 bg-gray-50">
                      {!selectedFile
                        ? "Nenhum arquivo encontrado."
                        : selectedFile[0].name}
                    </div>
                  </div>
                  <p className="mb-1 mt-3 text-gray-500 font-normal text-xs leading-relaxed">
                    JPG, GIF or PNG. Max size of 800k
                  </p>
                  {!imageError && (
                    <Button
                      size="xs"
                      color="gray"
                      className="text-sm"
                      onClick={() => handleRemoveAvatar()}
                    >
                      Excluir
                    </Button>
                  )}
                </div>
              </div>
              <Formik
                initialValues={{
                  ...profile,
                  phone: formatPhoneNumber(profile.phone),
                }}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={(values) => onFormSubmitCreate(values)}
                validationSchema={validationSchema}
              >
                {({ errors, values, setFieldValue, handleSubmit, dirty }) => (
                  <Form
                    noValidate
                    className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mb-12 sm:mb-0"
                    onSubmit={(e) => {
                      e.preventDefault()
                      handleSubmit(e)
                    }}
                  >
                    <div className="md:col-span-2">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="fullName"
                          value="Nome completo"
                          color={errors.fullName ? "failure" : ""}
                        />
                      </div>
                      <Field
                        id="fullName"
                        name="fullName"
                        className={`disabled:text-gray-400 w-full rounded-lg border flex p-2.5 items-center gap-4 self-stretch ${
                          errors.fullName
                            ? "border-red-600 bg-red-50"
                            : "border-gray-300 bg-gray-50"
                        } px-3 text-sm text-gray-900`}
                        value={values.fullName}
                        placeholder="Nome Completo"
                        disabled
                      />
                      <ErrorMessage
                        component="div"
                        className="text-red-600 text-sm"
                        name="fullName"
                      />
                    </div>
                    <div className="block">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="document"
                          value="CPF"
                          color={errors.document ? "failure" : ""}
                        />
                      </div>
                      <Field
                        id="document"
                        name="document"
                        className={`disabled:text-gray-400 w-full rounded-lg border flex p-2.5 items-center gap-4 self-stretch ${
                          errors.document
                            ? "border-red-600 bg-red-50"
                            : "border-gray-300 bg-gray-50"
                        } px-3 text-sm text-gray-900`}
                        value={formatCpfCnpj(values.document) || undefined}
                        placeholder="000.000.000-00"
                        disabled
                      />
                      <ErrorMessage
                        component="div"
                        className="text-red-600 text-sm"
                        name="document"
                      />
                    </div>
                    <div className="block">
                      <div
                        className="flex items-center gap-4 h-full"
                        role="group"
                        aria-labelledby="my-radio-group"
                      >
                        <div>
                          <Field
                            className="checked:bg-primary-600"
                            type="radio"
                            name="personType"
                            value="PF"
                            disabled
                            checked={
                              values.document && values.document.length == 14
                            }
                          />
                          <label
                            htmlFor="personType"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Pessoa física
                          </label>
                        </div>
                        <div>
                          <Field
                            className="checked:bg-primary-600"
                            type="radio"
                            name="personType"
                            value="PJ"
                            disabled
                            checked={
                              values.document && values.document.length > 14
                            }
                          />
                          <label
                            htmlFor="personType"
                            className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            Pessoa jurídica
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="block">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="email"
                          value="Email"
                          color={errors.email ? "failure" : ""}
                        />
                      </div>
                      <Field
                        id="email"
                        name="email"
                        className={`disabled:text-gray-400 w-full rounded-lg border flex p-2.5 items-center gap-4 self-stretch ${
                          errors.email
                            ? "border-red-600 bg-red-50"
                            : "border-gray-300 bg-gray-50"
                        } px-3 text-sm text-gray-900`}
                        value={values.email}
                        placeholder="requerente@email.com.br"
                        disabled
                      />
                      <ErrorMessage
                        component="div"
                        className="text-red-600 text-sm"
                        name="email"
                      />
                    </div>
                    <div className="block">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="phone"
                          value="Telefone/WhatsApp"
                          color={errors.phone ? "failure" : ""}
                        />
                      </div>
                      <input
                        id="phone"
                        name="phone"
                        minLength={15}
                        ref={(ref) => (refPhone.current = ref)}
                        className={`w-full rounded-lg border flex p-2.5 items-center gap-4 self-stretch ${
                          errors.phone
                            ? "border-red-600 bg-red-50"
                            : "border-gray-300 bg-gray-50"
                        } px-3 text-sm text-gray-900`}
                        value={values.phone}
                        placeholder="(00) 00000-0000"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setFieldValue("phone", e.target.value)
                        }
                      />
                      <ErrorMessage
                        component="div"
                        className="text-red-600 text-sm"
                        name="phone"
                      />
                    </div>
                    <Button
                      disabled={!dirty && !selectedFile}
                      type="submit"
                      className="w-fit"
                      color="primary"
                    >
                      Salvar
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Profile
