import React from "react"

export default function PencilIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="hover:scale-100"
    >
      <path
        d="M13.586 3.58616C13.7705 3.39514 13.9912 3.24278 14.2352 3.13796C14.4792 3.03314 14.7416 2.97797 15.0072 2.97566C15.2728 2.97335 15.5361 3.02396 15.7819 3.12452C16.0277 3.22508 16.251 3.37359 16.4388 3.56137C16.6266 3.74916 16.7751 3.97246 16.8756 4.21825C16.9762 4.46405 17.0268 4.72741 17.0245 4.99296C17.0222 5.25852 16.967 5.52096 16.8622 5.76497C16.7574 6.00898 16.605 6.22967 16.414 6.41416L15.621 7.20716L12.793 4.37916L13.586 3.58616ZM11.379 5.79316L3 14.1722V17.0002H5.828L14.208 8.62116L11.378 5.79316H11.379Z"
        fill="#6B7280"
      />
    </svg>
  )
}
