import {
  BtnLoadingIcon,
  CheckCircleTwoIcon,
  CopyIcon,
  DocumentIcon,
  DownloadIcon,
  PdfIcon,
  PencilDetailsIcon,
  PictureIcon,
  PlusIcon,
} from "@assets/icons"
import FullLoading from "@components/full-loading"
import { modalConfirmTheme } from "@components/modal-confirm/style"
import NoDataComponent from "@components/no-data"
import { RoutesConstants as routes } from "@constants/routes"
import { ConciliationErrors } from "@features/conciliation/types/conciliation-detail.type"
import { useRole } from "@hooks/use-role"
import { useScreenDetect } from "@hooks/use-screen-detect"
import AdminModelService from "@services/admin.models.service"
import AttachmentService from "@services/attachment.service"
import ConciliationDetailsService from "@services/conciliation-details.service"
import { AdminModels } from "@type/admin.type"
import { Conciliation } from "@type/conciliation.type"
import { Constants } from "@utils/helpers/constants"
import { formatDate } from "@utils/helpers/formatDate"
import { Badge, Button, Label } from "flowbite-react"
import React, { useCallback, useEffect, useState } from "react"
import {
  HiDocumentText,
  HiEye,
  HiOutlineChevronRight,
  HiOutlineX,
  HiTrash,
} from "react-icons/hi"
import Modal from "react-modal"
import { useNavigate, useParams } from "react-router-dom"
import { DocFile } from "../../types/conciliation.type"

const maxFileSizeMB = 20

type ConciliationDocumentsProps = {
  setError: React.Dispatch<React.SetStateAction<ConciliationErrors>>
  setDocumentIds: React.Dispatch<React.SetStateAction<string[]>>
}

function ConciliationDocuments({
  setError,
  setDocumentIds,
}: ConciliationDocumentsProps) {
  const { isMobile } = useScreenDetect()
  const { isUser, isAdmin, isSecretary, isSpecialist } = useRole()
  const navigate = useNavigate()
  const { conciliationNumber } = useParams()
  const [loading, setLoading] = useState(false)
  const [showIcon, setShowIcon] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [attachments, setAttachments] = useState<DocFile[]>([])
  const [loadingDocument, setLoadingDocument] = useState<string[]>([])
  const [documents, setDocuments] = useState<Conciliation.Document[]>([])
  const [modalAddDocument, setModalAddDocument] = useState(false)
  const [models, setModels] = useState<AdminModels.Item[]>([])
  const [modelsLoading, setModelsLoading] = useState(false)

  const fetchConciliationDocuments = async () => {
    setLoading(true)
    ConciliationDetailsService.findAllDocuments(conciliationNumber)
      .then((data) => {
        if (isUser) {
          const filteredData = data?.filter(
            (item) => item.status !== AdminModels.Status.PUBLISH_INTERNAL
          )
          setDocumentIds(filteredData.map((item) => item.id))
          setDocuments(filteredData)
          return
        }
        setDocumentIds(data.map((item) => item.id))
        setDocuments(data)
      })
      .catch(() => setError((prev) => ({ ...prev, documents: true })))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    fetchConciliationDocuments()
  }, [])

  const handleDeleteFile = (index: number) => {
    setErrors((prev) => {
      const updatedErrors = [...prev]
      updatedErrors.splice(index, 1)
      return updatedErrors
    })
    setAttachments((prev) => {
      const updatedDocs = [...prev]
      updatedDocs.splice(index, 1)
      return updatedDocs
    })
  }

  const fetchModels = async () => {
    AdminModelService.findAllModels({ all: true })
      .then((data) => {
        setModels(data?.items)
      })
      .catch(() => setErrors((prev) => ({ ...prev, get: true })))
      .finally(() => setModelsLoading(false))
  }

  useEffect(() => {
    if (modalAddDocument) {
      fetchModels()
    }
  }, [modalAddDocument])

  const handleReplaceFile = (index: number) => {
    const hiddenFileInput = document.createElement("input")
    hiddenFileInput.type = "file"
    hiddenFileInput.addEventListener("change", (event) => {
      const newFile = (event.target as HTMLInputElement).files?.[0]
      if (newFile) {
        const newFilesInfo = {
          name: newFile.name,
          size: newFile.size,
          type: newFile.type,
          file: newFile,
          detail: newFile.name,
        }

        setAttachments((prev) => {
          const updatedDocs = [...prev]
          updatedDocs[index] = newFilesInfo
          return updatedDocs
        })
      }
    })
    hiddenFileInput.click()
  }

  const addFile = () => {
    if (isUser) {
      const uniqueFileName = Math.random().toString(36).slice(2)
      const file = new File([], `${uniqueFileName}.txt`, { type: "text/plain" })
      const newFile = {
        name: `${uniqueFileName}_uniqueFileName`,
        size: 12,
        type: "text/plain",
        detail: "",
        file,
      } as DocFile

      setAttachments((prev) => [...prev, newFile])
    } else {
      setModelsLoading(true)
      setModalAddDocument(true)
    }
  }

  const postFile = async (key: string) => {
    const data = attachments.find(
      (attachment, index) => `${attachment.name}_${index}` === key
    )

    setErrors((prev) => {
      const updatedErrors = [...prev]
      return updatedErrors.filter((err) => err !== key)
    })

    if (
      data &&
      (data.detail === "" || data.size > maxFileSizeMB * 1024 * 1024)
    ) {
      setErrors((prev) => {
        const updatedErrors = [...prev]
        return updatedErrors.concat(key)
      })
      return
    }

    setLoadingDocument((prev) => {
      const updatedDocs = [...prev]
      return updatedDocs.concat(key)
    })

    if (data) {
      try {
        const resUrl = await AttachmentService.uploadUrl(data.file, data.detail)

        if (!resUrl?.url) {
          setLoadingDocument((prev) => {
            const updatedDocs = [...prev]
            return updatedDocs.filter((doc) => doc !== key)
          })
          return
        }

        const urlForUpload = resUrl.url
        await AttachmentService.put(urlForUpload, data.file)
        const file = await AttachmentService.post(
          resUrl.fileName,
          data.file.type,
          data.detail
        )
        const resp = await ConciliationDetailsService.postDocument(
          {
            attachmentId: file.id,
          },
          conciliationNumber
        )

        if (resp) {
          await fetchConciliationDocuments()
          setAttachments((prev) => {
            const updatedAttachment = [...prev]
            return updatedAttachment.filter(
              (attachment, index) => `${attachment.name}_${index}` !== key
            )
          })
        }
      } catch (error) {
        setErrors((prev) => {
          const updatedErrors = [...prev]
          return updatedErrors.concat(key)
        })
      } finally {
        setLoadingDocument((prev) => {
          const updatedDocs = [...prev]
          return updatedDocs.filter((doc) => doc !== key)
        })
      }
    }
  }

  const handleCopyClick = (text: string) => {
    navigator.clipboard.writeText(text)
    setTimeout(() => {
      setShowIcon(false)
    }, 500)
  }

  const editModel = useCallback((id: string) => {
    navigate(
      `${routes.conciliations}/${conciliationNumber}${routes.models}/${id}`
    )
  }, [])

  const removeModel = useCallback((id: string) => {
    ConciliationDetailsService.removeDocument(
      conciliationNumber!,
      id?.toString()
    )
  }, [])

  const download = useCallback(async (item: Conciliation.Document) => {
    ConciliationDetailsService.downloadDocument(
      conciliationNumber!,
      item.id
    ).then((data) => window.open(data, "_blank"))
  }, [])

  // create task to finish implementation
  const showModel = useCallback((item: Conciliation.Document) => {
    alert("download: " + item?.downloadLink)
  }, [])

  const getStatusColor = (status: AdminModels.Status) => {
    const isPublishInternal = status === AdminModels.Status.PUBLISH_INTERNAL
    const isDraft = status === AdminModels.Status.DRAFT

    if (isPublishInternal && !isUser) {
      return "indigo"
    } else if (isDraft) {
      return "gray"
    }
    return undefined
  }

  const getStatusLabel = (status: AdminModels.Status) => {
    const isPublishInternal = status === AdminModels.Status.PUBLISH_INTERNAL
    const isDraft = status === AdminModels.Status.DRAFT

    if (isPublishInternal && !isUser) {
      return Constants.INTERNAL
    } else if (isDraft) {
      return Constants.DRAFT
    }
    return undefined
  }

  return (
    <>
      {loading ? (
        <FullLoading />
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:justify-between mb-8 md:items-center">
            <p className="text-2xl text-gray-600 font-semibold">
              Documentos do processo
            </p>
            <div className="max-w-sm w-auto md:mt-0 mt-3">
              <Button
                color="gray"
                size="sm"
                className="text-sm font-medium text-primary-800 min-h-[37px] max-h-[37px]"
                onClick={addFile}
              >
                <span className="mr-1">
                  <PlusIcon />
                </span>
                Adicionar documento
              </Button>
            </div>
          </div>
          <div className="w-full h-[1px] bg-gray-200 mb-8" />
          <div
            className={`flex flex-col ${attachments.length > 0 ? "gap-8" : ""}`}
          >
            {attachments?.map((attachment, index) => (
              <div
                key={`new_attachment_${attachment.name}_${index}`}
                className={`flex flex-col md:flex-row gap-8 md:gap-4`}
              >
                <div className="flex-1 flex-col">
                  <Label
                    className="w-full font-medium"
                    value="Carregue o arquivo"
                  />
                  <div className="mt-2 flex">
                    <button
                      onClick={() => handleReplaceFile(index)}
                      className="h-[42px] p-2.5 cursor-pointer text-sm rounded-tl-lg rounded-bl-lg text-white border-l border-t border-b border-gray-300 bg-primary-500"
                    >
                      Procurar
                    </button>
                    <input
                      name={`${attachment.name}_${index}`}
                      type="text"
                      className={`truncate rounded-tl-none rounded-bl-none rounded-tr-lg rounded-br-lg disabled:text-gray-400 w-full rounded-lg border flex p-2.5 items-center gap-4 self-stretch border-gray-300 bg-gray-50 px-3 text-sm text-gray-900`}
                      value={
                        attachment.name.includes("uniqueFileName")
                          ? "Nenhum arquivo encontrado."
                          : attachment.name
                      }
                      required
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <Label
                    className="font-medium"
                    htmlFor={`${attachment.name}_${index}`}
                    value="Nome detalhado do arquivo"
                    color={
                      errors.find(
                        (error) => error === `${attachment.name}_${index}`
                      )
                        ? "failure"
                        : ""
                    }
                  />
                  <input
                    name={`${attachment.name}_${index}`}
                    type="text"
                    className={`truncate mt-2 disabled:text-gray-400 w-full rounded-lg border flex p-2.5 items-center gap-4 self-stretch ${
                      errors.find(
                        (error) => error === `${attachment.name}_${index}`
                      )
                        ? "border-red-600 bg-red-50"
                        : "border-gray-300 bg-gray-50"
                    } px-3 text-sm text-gray-900`}
                    value={attachment.detail}
                    required
                    onChange={(e) => {
                      setErrors((prev) => {
                        const updatedErrors = [...prev]
                        return updatedErrors.filter(
                          (err) => err !== `${attachment.name}_${index}`
                        )
                      })
                      setAttachments((prev) => {
                        const updatedDocs = [...prev]
                        updatedDocs[0] = {
                          ...updatedDocs[0],
                          detail: e.target.value,
                        }
                        return updatedDocs
                      })
                    }}
                  />
                </div>
                <div className="flex flex-2 self-start md:self-end items-bottom">
                  <Button
                    className="md:min-w-[100px]"
                    color="primary"
                    onClick={() => postFile(`${attachment.name}_${index}`)}
                  >
                    {loadingDocument.find(
                      (doc) => doc === `${attachment.name}_${index}`
                    ) ? (
                      <BtnLoadingIcon />
                    ) : null}
                    Enviar
                  </Button>
                  <Button
                    size="md"
                    color="gray"
                    className="text-sm h-[42px] ml-4 md:min-w-[100px]"
                    onClick={() => handleDeleteFile(index)}
                    disabled={loadingDocument.length > 0}
                  >
                    Excluir
                  </Button>
                </div>
              </div>
            ))}
          </div>
          {documents?.length > 0 ? (
            <div
              className={`relative overflow-x-auto rounded-lg shadow-md ${
                attachments.length > 0 ? "mt-8" : ""
              }`}
            >
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 rounded-lg">
                <tbody>
                  {documents &&
                    documents?.map((item, index) => {
                      const isPDF = /\.pdf$/.test(item.fileName.toLowerCase())
                      const isImage =
                        /\.(jpe?g|png|gif|webp|svg|bmp|tiff|heic|heif|raw)$/i.test(
                          item.fileName.toLowerCase()
                        )
                      return (
                        <tr
                          key={`detail_document_${item.id}_${item.dateTime}_${index}`}
                          className="border-b border-gray-200 "
                        >
                          <td className="h-[54px] whitespace-nowrap px-4">
                            <div className="flex items-center">
                              <span className="flex w-[40px] h-[40px] mr-8 justify-center items-center rounded-lg bg-gray-100">
                                {isPDF ? (
                                  <PdfIcon />
                                ) : isImage ? (
                                  <PictureIcon />
                                ) : (
                                  <DocumentIcon />
                                )}
                              </span>
                              <p className="flex text-gray-900 text-base font-medium">
                                {item.fileName}
                                {(isAdmin || isSpecialist || isSecretary) &&
                                  item?.status &&
                                  item?.status !==
                                    AdminModels.Status.PUBLISH_ALL && (
                                    <Badge
                                      color={getStatusColor(item?.status)}
                                      className="ml-4 justify-center rounded-md w-fit whitespace-nowrap text-xs font-medium"
                                    >
                                      {getStatusLabel(item?.status)}
                                    </Badge>
                                  )}
                              </p>
                            </div>
                          </td>
                          <td className="h-[54px] whitespace-nowrap px-4">
                            <p className="text-gray-500 text-sm font-normal">
                              {item.sender}
                            </p>
                          </td>

                          <td className="h-[54px] whitespace-nowrap px-4 hover:bg-gray-50 transition duration-300 ease-in-out">
                            <div
                              className="relative group flex items-center transition-all cursor-pointer"
                              onClick={() => {
                                setShowIcon(true)
                                handleCopyClick(item.referenceId)
                              }}
                              onMouseLeave={() => setShowIcon(false)}
                            >
                              <span
                                className={`transition-opacity absolute left-0 flex items-center justify-center w-0 ${
                                  showIcon
                                    ? "opacity-0 invisible"
                                    : "opacity-100 visible"
                                } group-hover:w-auto group-hover:opacity-100 transition-all duration-300 ease-in-out`}
                              >
                                <CopyIcon />
                              </span>
                              <span
                                className={`transition-opacity cursor-pointer absolute left-0 flex items-center justify-center w-0 ${
                                  !showIcon
                                    ? "opacity-0 invisible"
                                    : "opacity-100 visible"
                                } group-hover:w-auto group-hover:opacity-100 transition-all duration-300 ease-in-out`}
                              >
                                <CheckCircleTwoIcon />
                              </span>
                              <p className="ml-4 px-4 text-gray-500 text-sm font-normal">
                                {item?.referenceId}
                              </p>
                            </div>
                          </td>
                          <td className="h-[54px] whitespace-nowrap px-4 ">
                            <p className="text-gray-500 text-sm font-normal">
                              {item?.dateTime ? formatDate(item?.dateTime) : ""}
                            </p>
                          </td>
                          <td className="h-[52px] whitespace-nowrap px-4">
                            <button
                              className="flex items-center cursor-pointer"
                              onClick={() => download(item)}
                            >
                              <span className="flex justify-center items-center mr-2">
                                <DownloadIcon />
                              </span>
                              <p className="text-primary-600 text-sm font-normal">
                                Baixar
                              </p>
                            </button>
                          </td>
                          {(isAdmin || isSpecialist || isSecretary) && (
                            <td className="flex gap-4 h-[52px] whitespace-nowrap px-4 justify-center">
                              <button
                                className="cursor-pointer"
                                onClick={() => showModel(item)}
                              >
                                <HiEye size={17} fill="#31246D" />
                              </button>
                              {item?.status === AdminModels.Status.DRAFT ? (
                                <>
                                  <button
                                    className="cursor-pointer"
                                    onClick={() => editModel(item.id)}
                                  >
                                    <PencilDetailsIcon color="#31246D" />
                                  </button>
                                  <button
                                    className="cursor-pointer"
                                    onClick={() => removeModel(item?.id)}
                                  >
                                    <HiTrash size={17} fill="#31246D" />
                                  </button>
                                </>
                              ) : isAdmin ? (
                                <>
                                  <button
                                    className="cursor-pointer"
                                    onClick={() => editModel(item.id)}
                                  >
                                    <PencilDetailsIcon color="#31246D" />
                                  </button>
                                  {item?.status !==
                                    AdminModels.Status.PUBLISH_ALL && (
                                    <button
                                      className="cursor-pointer"
                                      onClick={() => removeModel(item?.id)}
                                    >
                                      <HiTrash size={17} fill="#31246D" />
                                    </button>
                                  )}
                                </>
                              ) : null}
                            </td>
                          )}
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          ) : (
            <NoDataComponent description="Nenhum documento por enquanto." />
          )}
          <Modal
            isOpen={modalAddDocument && !modelsLoading}
            onRequestClose={() => setModalAddDocument(false)}
            style={modalConfirmTheme(isMobile, "640px")}
          >
            <div className="flex items-center justify-between border-b p-6">
              <div className="flex items-center">
                <HiDocumentText className="mr-2" color="gray-900" size={20} />
                <h2 className="text-lg font-semibold text-gray-900">
                  Selecione o modelo de documento
                </h2>
              </div>
              <HiOutlineX
                className="mr-2 cursor-pointer"
                color="gray-900"
                size={20}
                onClick={() => setModalAddDocument(false)}
              />
            </div>
            <div className="flex flex-col justify-between border-b py-6">
              {models?.map((item) => (
                <div
                  key={item.title}
                  onClick={() => editModel(item.id)}
                  className="flex cursor-pointer items-center justify-between px-6 py-4 border-b"
                >
                  <p className="text-gray-900">{item.title}</p>
                  <HiOutlineChevronRight
                    className="mr-2"
                    color="#6B7280"
                    size={20}
                  />
                </div>
              ))}
            </div>
          </Modal>
        </>
      )}
    </>
  )
}

export default ConciliationDocuments
