import FullSuccessModal from "@components/full-success-modal"
import { RoutesConstants as routes } from "@constants/routes"
import { useAuth } from "@hooks/use-auth"
import { Spinner } from "flowbite-react"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

function VerifyEmailPage() {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [verifiedEmail] = useState(false)
  const { verifyEmail } = useAuth()

  useEffect(() => {
    const navigateToLogin = () => {
      navigate(routes.signIn)
    }
    const navigateToHome = () => {
      navigate(routes.home)
    }
    if (searchParams.has("token")) {
      verifyEmail(searchParams.get("token") ?? "", (error) => {
        if (!error) {
          navigateToHome()
        } else {
          navigateToLogin()
        }
      })
    } else {
      navigateToLogin()
    }
  }, [])

  return (
    <div className="flex flex-row items-center relative">
      <Spinner color="failure" />
      <h1 className="ml-2.5 text-xl font-bold text-gray-900 dark:text-white">
        Aguarde...
      </h1>
      <FullSuccessModal
        title="E-mail verificado com sucesso!"
        description=""
        show={verifiedEmail}
      />
    </div>
  )
}

export default VerifyEmailPage
