import { ProcessActions } from "@utils/helpers/constants"
import { Dropdown } from "flowbite-react"
import {
  HiExclamation,
  HiMail,
  HiOutlineScale,
  HiPlusCircle,
  HiShieldCheck,
} from "react-icons/hi"

type ConciliationActionsDropdownType = {
  handleProcessActions: (item: string) => void
}

function ConciliationActionsDropdown({
  handleProcessActions,
}: ConciliationActionsDropdownType) {
  return (
    <Dropdown
      size="sm"
      color="secondary"
      outline={false}
      label="Ações no processo"
      dismissOnClick={false}
    >
      <Dropdown.Item
        onClick={() => handleProcessActions(ProcessActions.ADD_UPDATE)}
        className="text-sm font-normal"
      >
        <HiPlusCircle className="mr-1.5" size={18} />
        Adicionar atualização
      </Dropdown.Item>
      <Dropdown.Item
        className="text-sm font-normal"
        onClick={() => handleProcessActions(ProcessActions.NOTIFY_REQUIRED)}
      >
        <HiMail className="mr-1.5" size={18} /> Notificar requerido
      </Dropdown.Item>
      <Dropdown.Item
        className="text-sm font-normal"
        onClick={() => handleProcessActions(ProcessActions.DEFINE_RESPONSIBLE)}
      >
        <HiShieldCheck className="mr-1.5" size={18} />
        Definir responsável
      </Dropdown.Item>
      <Dropdown.Item
        className="text-sm font-normal"
        onClick={() => handleProcessActions(ProcessActions.DEFINE_SPECIALIST)}
      >
        <HiOutlineScale className="mr-1.5" size={18} /> Definir especialista
      </Dropdown.Item>
      <Dropdown.Item
        className="text-sm font-normal"
        onClick={() => handleProcessActions(ProcessActions.FINISH_PROCESS)}
      >
        <HiExclamation className="mr-1.5" size={18} /> Finalizar processo
      </Dropdown.Item>
    </Dropdown>
  )
}

export default ConciliationActionsDropdown
