import { MessageIcon, PaperAirPlaneIcon, PaperClipIcon } from "@assets/icons"
import Avatar from "@components/avatar"
import Error from "@components/error"
import { DocFile } from "@features/conciliation/types/conciliation.type"
import { useConciliationChatMessages } from "@hooks/use-conciliation-chat-messages"
import axiosBase from "@services/base.service"
import ConciliationChatMessageService from "@services/conciliation-chat-message.service"
import axios from "axios"
import { format } from "date-fns"
import { pt } from "date-fns/locale"
import { Button, Tooltip } from "flowbite-react"
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import ChatAttachment from "./chat-attachment"
import ChatMessage from "./chat-message"
import { avatarColors, messagesColors } from "./chat.colors"
import { Socket, io } from "socket.io-client"
import TokenStorage from "@data/token.storage"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ChatConciliation = forwardRef<any>((_, ref) => {
  const {
    chatConciliationMessages,
    setChatConciliationMessages,
    setChatConciliationMessagesError,
    chatConciliationMessagesError,
  } = useConciliationChatMessages()
  const { conciliationNumber } = useParams()
  const [errors, setErrors] = useState<string[]>([])
  const [attachments, setAttachments] = useState<DocFile[]>([])
  const [attachFileOpen, setAttachFileOpen] = useState<boolean>(false)
  const [chatMessage, setChatMessage] = useState("")
  const socketRef = useRef<Socket | null>(null)

  const messagesRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (chatConciliationMessages.length) {
      messagesRef.current?.lastElementChild?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      })
    }
  }, [chatConciliationMessages.length])

  useEffect(() => {
    socketRef.current = io(import.meta.env.VITE_SOCKETIO_URL, {
      reconnectionDelayMax: 10000,
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: `Bearer ${TokenStorage.getJWTToken()}`,
          },
        },
      },
    })

    socketRef.current.on("connect", () => {
      if (socketRef.current) {
        socketRef.current.emit("joinRoom", conciliationNumber)
      }
    })
    socketRef.current.on("message", () => {
      ConciliationChatMessageService.findAll(conciliationNumber)
        .then((data) => setChatConciliationMessages(data))
        .catch(() => setChatConciliationMessagesError(true))
    })
  }, [])

  const onChangeChatMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatMessage(e.target.value)
  }

  const sendChatMessage = async () => {
    const result = attachments.reduce((acc: string[], item, idx) => {
      if (item.detail === "") {
        acc.push(`${item.name}_${idx}`)
      }
      return acc
    }, [])
    setErrors(result)

    if (result.length === 0) {
      const attachs: Array<number> = []
      if (attachments.length > 0) {
        for (let i = 0; i < attachments.length; i++) {
          const resUrl = await axiosBase.post("/attachment/url-upload", {
            fileName: attachments[i].name,
            name: attachments[i].name,
            mimeType: attachments[i].type,
          })
          const urlForUpload = resUrl.data.data.url
          await axios.put(urlForUpload, attachments[i].file)
          const res = await axiosBase.post("/attachment", {
            fileName: resUrl.data.data.fileName,
            name: attachments[i].name,
            mimeType: attachments[i].type,
          })
          attachs.push(res.data.data.id)
        }
      }

      socketRef.current?.emit("message", {
        conciliationCode: conciliationNumber,
        message: chatMessage,
        attachmentItems: attachs,
      })
      setAttachments([])
      setChatMessage("")
      ConciliationChatMessageService.findAll(conciliationNumber)
        .then((data) => setChatConciliationMessages(data))
        .catch(() => setChatConciliationMessagesError(true))
    }
  }

  const participants = useMemo(() => {
    const participantsNames = chatConciliationMessages.map(
      ({ sender: { name } }) => name
    )
    const setParticipantsNames = [...new Set(participantsNames)]
    return setParticipantsNames.map((name, idx) => ({
      name,
      avatarBgcolor: avatarColors[idx],
      messageBgcolor: messagesColors[idx],
    }))
  }, [chatConciliationMessages])

  return (
    <div
      ref={ref}
      className="overflow-y-auto md:max-h-[70vh] shadow-md md:w-[610px]"
    >
      <div className="shadow-md mt-4 md:mt-0 flex flex-col md:flex-row md:items-center p-4 border-b border-gray-200 bg-gray-50">
        <h5 className="flex items-center uppercase text-gray-500 text-sm font-semibold">
          <span className="mr-4">
            <MessageIcon />
          </span>
          Mensagens
        </h5>
        <div className="mt-6 md:mt-0 md:ml-8 flex items-center">
          <p className="text-gray-500 text-sm font-normal">
            Participantes da conversa:
          </p>
          <div className="flex -space-x-4 ml-2">
            {!chatConciliationMessagesError &&
              participants.map((participant) => (
                <Tooltip
                  className="space-x-4"
                  content={participant.name}
                  style="dark"
                  key={`${participant.name}_tooltip`}
                >
                  <Avatar
                    key={`avatar_chat_${participant.name}`}
                    name={participant.name}
                    bgColor={participant.avatarBgcolor}
                    borderColor="border-gray-50"
                    image={
                      chatConciliationMessages.find(
                        ({ sender }) => participant.name === sender.name
                      )?.sender?.avatar
                    }
                  />
                </Tooltip>
              ))}
          </div>
        </div>
      </div>
      <div
        ref={messagesRef}
        className="shadow-md flex flex-col p-4 bg-white gap-4 md:max-h-[400px] min-h-[250px] md:min-h-[400px] overflow-y-scroll"
      >
        {chatConciliationMessagesError ? (
          <Error />
        ) : (
          <>
            {chatConciliationMessages?.map(
              ({ dateTime, message, id, sender, attachments }) => {
                const participant = participants.find(
                  ({ name }) => name === sender.name
                )
                return (
                  <ChatMessage
                    key={`message_chat_${id}_${dateTime}`}
                    name={sender.name}
                    description={message}
                    date={format(new Date(dateTime), "dd/MM/yyyy HH:mm", {
                      locale: pt,
                    })}
                    attachments={attachments}
                    bgColor={participant?.messageBgcolor}
                    avatar={
                      <Avatar
                        name={sender.name}
                        image={sender.avatar}
                        withoutBorder
                        bgColor={participant?.avatarBgcolor}
                      />
                    }
                  />
                )
              }
            )}
          </>
        )}
      </div>
      <div className="shadow-md flex flex-col pt-4 pb-4 px-4 border-t border-b border-gray-200 bg-gray-50">
        <textarea
          className="min-h-[126px] text-sm text-gray-500 py-0 px-0 focus:border-transparent border-transparent bg-gray-50 ring focus:ring-gray-50 ring-gray-50"
          placeholder="Escreva sua mensagem aqui..."
          value={chatMessage}
          onChange={onChangeChatMessage}
        />
      </div>
      <ChatAttachment
        errors={errors}
        setErrors={setErrors}
        attachments={attachments}
        attachFileOpen={attachFileOpen}
        setAttachments={setAttachments}
        setAttachFileOpen={setAttachFileOpen}
      />
      <div className="shadow-md px-4 flex items-center pt-4 pb-4 border-b border-gray-200 bg-gray-50 gap-4">
        <Button
          size="xs"
          color="primary"
          disabled={
            errors.length > 0 ||
            (attachFileOpen &&
              attachments.length >= 0 &&
              attachments.every((attachment) => attachment.detail === "")) ||
            (chatMessage === "" && attachments.length === 0)
          }
          className="h-[34px] py-1 w-fit rounded-md text-xs bg-primary-700 hover:bg-primary-900 px-3 justify-center items-center gap-2 md:gap-3"
          onClick={sendChatMessage}
        >
          <span className="mr-2">
            <PaperAirPlaneIcon />
          </span>
          Enviar Mensagem
        </Button>
        <button onClick={() => setAttachFileOpen((prev) => !prev)}>
          <PaperClipIcon />
        </button>
      </div>
    </div>
  )
})

ChatConciliation.displayName = "ChatConciliation"

export default ChatConciliation
