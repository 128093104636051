import FullSuccessModal from "@components/full-success-modal"
import { RoutesConstants as routes } from "@constants/routes"
import { EnvelopeIcon } from "@heroicons/react/24/solid"
import { useAuth } from "@hooks/use-auth"
import { Alert, Button, Label, Spinner, TextInput } from "flowbite-react"
import { Field, FieldProps, Form, Formik } from "formik"
import { useState } from "react"
import { Link } from "react-router-dom"
import * as Yup from "yup"

interface SigninFormValues {
  email: string
}

const SigninSchema = Yup.object().shape({
  email: Yup.string()
    .email(
      "Algo parece estar errado. Por favor, verifique o e-mail e tente novamente."
    )
    .required("É necessário preencher este campo para continuar."),
})

function SignInPage() {
  const initialValues: SigninFormValues = {
    email: "",
  }
  const [isLoadingLogin, setLoadingLogin] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showAlertError, setShowAlertError] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { sendEmailToken } = useAuth()
  return (
    <Formik
      validateOnBlur={true}
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={SigninSchema}
      onSubmit={(values) => {
        setLoadingLogin(true)
        setErrorMessage(null)
        sendEmailToken(values.email, (error, message) => {
          if (!error) {
            setShowSuccess(true)
          } else {
            setLoadingLogin(false)
            setShowAlertError(true)
            setErrorMessage(message)
          }
        })
      }}
    >
      {(formik) => {
        const { errors, touched } = formik
        return (
          <div className="relative">
            <FullSuccessModal
              title="Link enviado com sucesso!"
              description="Cheque seu e-mail para visualizar o link de acesso. Ele é mais seguro
                e não tem necessidade de senha."
              show={showSuccess}
            >
              <p className="mt-2.5">Isso pode levar alguns instantes.</p>
            </FullSuccessModal>
            <Form className="sm:w-full md:max-w-[560px] space-y-4 md:space-y-6 md:p-2 p-1">
              <h1 className="text-xl font-bold text-gray-900 dark:text-white flex items-center flex-row">
                <EnvelopeIcon className="h-5 w-5 inline mr-2.5" />
                Acesse sua conta
              </h1>
              {showAlertError && (
                <Alert
                  color="failure"
                  onDismiss={() => {
                    setShowAlertError(false)
                  }}
                >
                  <span>
                    {errorMessage
                      ? errorMessage
                      : "Ocorreu um erro, por favor tente novamente mais tarde."}
                  </span>
                </Alert>
              )}

              <Field name="email">
                {({ field }: FieldProps<string, SigninFormValues>) => (
                  <div>
                    <Label
                      htmlFor="email"
                      color={errors.email && touched.email ? "failure" : ""}
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Informe o e-mail cadastrado na plataforma que enviaremos
                      um link seguro de acesso sem a necessidade de senha.
                    </Label>
                    <TextInput
                      disabled={isLoadingLogin}
                      color={errors.email && touched.email ? "failure" : ""}
                      type="email"
                      helperText={errors.email}
                      id="email"
                      placeholder="Digite seu e-mail"
                      required
                      {...field}
                    />
                  </div>
                )}
              </Field>
              <Button
                color="primary"
                disabled={isLoadingLogin}
                className="w-full"
                type="submit"
              >
                {isLoadingLogin ? (
                  <>
                    <Spinner />
                    <span className="pl-3">Aguarde...</span>
                  </>
                ) : (
                  `Enviar link para o e-mail`
                )}
              </Button>
              <div className="text-sm font-light text-gray-500 dark:text-gray-400">
                Não tem uma conta?{" "}
                <Link
                  to={routes.signUp}
                  className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                >
                  Cadastre-se
                </Link>
              </div>
            </Form>
          </div>
        )
      }}
    </Formik>
  )
}

export default SignInPage
