import { DocFile } from "@features/conciliation/types/conciliation.type"
import { AdminModels } from "@type/admin.type"
import { PROCESS_ROLES } from "@utils/helpers/constants"
import { ConciliationStatus } from "./conciliation-status.type"

export namespace Conciliation {
  export type Item = {
    conciliationNumber: string
    claimant: string
    defendant: string
    lastHistoryEntry: string
    startDate?: Date
    status: ConciliationStatus
    statusPayment: ConciliationStatus
    createdAt: Date
  }

  export type GeneralInfo = {
    summary: string
    expectedOutcome: string
    amountClaim: number
    attachments: Array<{ id: number }>
    rite: number
    resolutionMethod: number
    hasClause: boolean
  }

  export type Applicant = {
    type: PROCESS_ROLES
    id?: number
    lawNumber?: string
    cpfCnpj?: string
    personType?: string
    fullName: string
    email?: string
    phone?: string
  }

  export type Payload = {
    generalInfo: GeneralInfo
    applicants: Applicant[]
    applicantsAccused: Applicant[]
  }

  export type Reason = {
    id: number
    description: string
    code: string
  }

  export type Status = {
    id: number
    name: string
    color: string
  }

  export type Paginated = {
    currentPage: number
    pages: number
    count: number
    items: Conciliation.Item[]
    itemsPerPage: number
  }

  export type Document = {
    id: string
    fileName: string
    size: number
    sender: string
    dateTime: Date
    referenceId: string
    status: AdminModels.Status
    downloadLink: string
  }

  export type DownloadDocument = {
    generalInfo: boolean
    history: boolean
    chat: boolean
    documentsId: string[]
  }
}

export namespace ConciliationPendingAction {
  export type Item = {
    conciliationCode: string
    description: string
    dueDate: Date | null
    id: number
    responsibleType: string
    status: string
    title: string
    urgent: boolean
  }

  export type UpdatedItem = {
    key: string
    open: boolean
    text: string
    error: boolean
    loading: boolean
    attachments: DocFile[]
  }

  export type Data = {
    items: Item[]
    updatedItems: UpdatedItem[]
  }

  export type Body = {
    updateMessage: string
    files: number[]
  }
}

export namespace ConciliationUpdate {
  export type Item = {
    id: number
    author: string
    subject: string
    description: string
    attachments: DocFile[]
    dateTime: Date
  }

  export type Put = {
    id: number
    description: string
    attachments: number[]
  }

  export type ItemWithIndex = Item & {
    _index: number
  }

  export type Status = {
    id: number
    name: string
    code: StatusCode
  }

  export type Responsible = {
    [key: string]: Array<{ id: number; name: string }>
  }

  export enum StatusCode {
    "DEFINED_RESPONSIBLE" = "DEFINED_RESPONSIBLE",
    "PROGRESSED" = "PROGRESSED",
    "ATTACHED_DOCUMENT" = "ATTACHED_DOCUMENT",
    "NOTIFIED_REQUIRED" = "NOTIFIED_REQUIRED",
    "EVALUATING_DOCUMENT" = "EVALUATING_DOCUMENT",
    "CLOSED_THE_CASE" = "CLOSED_THE_CASE",
    "EDITED_DOCUMENT" = "EDITED_DOCUMENT",
    "ENTERED_PROCESS" = "ENTERED_PROCESS",
  }

  export type Post = {
    updateMessage: string
    attachments: number[]
    responsible?: string[]
    date?: Date
  }

  export type GroupedData = {
    [date: string]: ItemWithIndex[]
  }

  export type Data = {
    text: string
    open: boolean
    loading: boolean
    error: boolean
    attachments: DocFile[]
  }
}

export namespace Assignable {
  export enum Type {
    "SPECIALIST" = "SPECIALIST",
    "SECRETARY" = "SECRETARY",
  }

  export type Item = {
    id: number
    name: string
    type: Assignable.Type
  }
}
