import { AdminModels } from "@type/admin.type"
import {
  Conciliation,
  ConciliationPendingAction,
  ConciliationUpdate,
} from "@type/conciliation.type"
import axiosBase from "./base.service"

const CONCILIATION_PATH = `/conciliation`

const ConciliationDetailsService = {
  find: async (conciliationCode: string | undefined) => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}`
    const response = await axiosBase.get(path)
    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  findAllDocuments: async (
    conciliationCode: string | undefined
  ): Promise<Conciliation.Document[]> => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}/docs`
    const response = await axiosBase.get(path)
    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  findAllUpdates: async (
    conciliationCode: string | undefined
  ): Promise<ConciliationUpdate.Item[]> => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}/updates`
    const response = await axiosBase.get(path)

    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  findUpdatesStatus: async (): Promise<ConciliationUpdate.Status[]> => {
    const path = `${CONCILIATION_PATH}/updates/status`
    const response = await axiosBase.get(path)

    if (!response.data) {
      throw new Error("Error getting conciliation updated status")
    }
    return response.data.data
  },
  findUpdatesResponsible: async (): Promise<ConciliationUpdate.Responsible> => {
    const path = `${CONCILIATION_PATH}/updates/responsible`
    const response = await axiosBase.get(path)

    if (!response.data) {
      throw new Error("Error getting conciliation updated status")
    }
    return response.data.data
  },
  postDocument: async (
    body: { attachmentId: number },
    conciliationCode: string | undefined
  ): Promise<Conciliation.Document[]> => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}/docs`
    const response = await axiosBase.post(path, body)
    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  findDocument: async (
    conciliationCode: string | undefined,
    docId: string | undefined
  ): Promise<AdminModels.Item> => {
    const PATH = `${CONCILIATION_PATH}/${conciliationCode}/docs/${docId}`
    const response = await axiosBase.get(PATH)
    if (!response.data) {
      throw new Error("Failed to get conciliation document. No data returned.")
    }
    return response.data.data
  },
  updateDocument: async (
    body: Omit<AdminModels.Item, "id">,
    conciliationCode: string | undefined,
    docId: string | undefined
  ): Promise<Conciliation.Document[]> => {
    const PATH = `${CONCILIATION_PATH}/${conciliationCode}/docs/${docId}`
    const response = await axiosBase.put(PATH, body)
    if (!response.data) {
      throw new Error(
        "Failed to update conciliation document. No data returned."
      )
    }
    return response.data.data
  },
  removeDocument: async (
    conciliationCode: string,
    docId: string
  ): Promise<Conciliation.Document[]> => {
    const PATH = `${CONCILIATION_PATH}/${conciliationCode}/docs/${docId}`
    const response = await axiosBase.delete(PATH)
    if (!response.data) {
      throw new Error(
        "Failed to delete conciliation document. No data returned."
      )
    }
    return response.data.data
  },
  postUpdate: async (
    body: ConciliationUpdate.Post,
    conciliationCode: string | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}/updates`
    const response = await axiosBase.post(path, body)

    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  putUpdate: async (
    id: number,
    body: ConciliationUpdate.Put,
    conciliationCode: string | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}/updates/${id}`
    const response = await axiosBase.put(path, body)

    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  removeUpdate: async (
    id: number,
    conciliationCode: string | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}/updates/${id}`
    const response = await axiosBase.delete(path)

    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  findAllPendingActions: async (
    conciliationCode: string | undefined
  ): Promise<ConciliationPendingAction.Item[]> => {
    const CONCILIATION_TASKS_PATH = `${CONCILIATION_PATH}/${conciliationCode}/tasks`
    const response = await axiosBase.get(CONCILIATION_TASKS_PATH)

    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  postPendingAction: async (
    body: ConciliationPendingAction.Body,
    conciliationCode?: string,
    taskId?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}/tasks/${taskId}`
    const response = await axiosBase.post(path, body)

    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  downloadConciliation: async (
    body: Conciliation.DownloadDocument,
    conciliationCode: string
  ): Promise<string> => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}/pdf/download`
    const response = await axiosBase.post(path, body)

    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  downloadDocument: async (
    conciliationCode: string | undefined,
    attachmentId: string | undefined
  ): Promise<string> => {
    const CONCILIATION_DETAILS_PATH = `conciliation/${conciliationCode}/attachment/${attachmentId}`
    const response = await axiosBase.get(CONCILIATION_DETAILS_PATH)
    if (!response.data) {
      throw new Error("Error getting conciliation details")
    }
    return response.data.data
  },
  notifyRequired: async (
    conciliationCode: string | undefined
  ): Promise<string> => {
    const path = `${CONCILIATION_PATH}/${conciliationCode}/notify`
    const response = await axiosBase.post(path)
    if (!response.data) {
      throw new Error("Error notifying conciliation")
    }
    return response.data.data
  },
}

export default ConciliationDetailsService
