import React from "react"

export default function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10.5C18 12.6217 17.1571 14.6566 15.6569 16.1569C14.1566 17.6571 12.1217 18.5 10 18.5C7.87827 18.5 5.84344 17.6571 4.34315 16.1569C2.84285 14.6566 2 12.6217 2 10.5C2 8.37827 2.84285 6.34344 4.34315 4.84315C5.84344 3.34285 7.87827 2.5 10 2.5C12.1217 2.5 14.1566 3.34285 15.6569 4.84315C17.1571 6.34344 18 8.37827 18 10.5ZM11 6.5C11 6.76522 10.8946 7.01957 10.7071 7.20711C10.5196 7.39464 10.2652 7.5 10 7.5C9.73478 7.5 9.48043 7.39464 9.29289 7.20711C9.10536 7.01957 9 6.76522 9 6.5C9 6.23478 9.10536 5.98043 9.29289 5.79289C9.48043 5.60536 9.73478 5.5 10 5.5C10.2652 5.5 10.5196 5.60536 10.7071 5.79289C10.8946 5.98043 11 6.23478 11 6.5ZM9 9.5C8.73478 9.5 8.48043 9.60536 8.29289 9.79289C8.10536 9.98043 8 10.2348 8 10.5C8 10.7652 8.10536 11.0196 8.29289 11.2071C8.48043 11.3946 8.73478 11.5 9 11.5V14.5C9 14.7652 9.10536 15.0196 9.29289 15.2071C9.48043 15.3946 9.73478 15.5 10 15.5H11C11.2652 15.5 11.5196 15.3946 11.7071 15.2071C11.8946 15.0196 12 14.7652 12 14.5C12 14.2348 11.8946 13.9804 11.7071 13.7929C11.5196 13.6054 11.2652 13.5 11 13.5V10.5C11 10.2348 10.8946 9.98043 10.7071 9.79289C10.5196 9.60536 10.2652 9.5 10 9.5H9Z"
        fill="#6B669B"
      />
    </svg>
  )
}
