import { SignupDto } from "@type/signup.type"
import { TokenResponse } from "@type/token.type"
import { ProfileUser } from "@type/user.type"
import TokenStorage from "../data/token.storage"
import axios from "./base.service"
const AuthService = {
  requestToken: async (username: string): Promise<void> => {
    await axios.post("/auth/request_token", {
      username,
    })
  },

  verifyToken: async (token: string): Promise<TokenResponse> => {
    const resp = await axios.post("/auth/verify", {
      token,
    })
    return resp.data.data
  },
  verifyEmail: async (token: string): Promise<TokenResponse> => {
    const resp = await axios.post("/signup/verify-email", {
      token,
    })
    return resp.data.data
  },

  signUp: async (data: SignupDto) => {
    await axios.post("/signup", data)
  },

  isAuthenticated: async () => {
    if (TokenStorage.getJWTToken() === null) {
      throw Error("Token not found")
    }
    await axios.get("/auth/is_authenticated")
  },

  refreshToken: async (refreshToken: string): Promise<TokenResponse> => {
    const resp = await axios.post("/auth/refresh", {
      refreshToken,
    })
    return resp.data.data
  },

  profile: async (): Promise<ProfileUser> => {
    const resp = await axios.get("/auth/profile")
    return resp.data.data
  },
}

export default AuthService
