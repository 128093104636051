import { Assignable } from "@type/conciliation.type"
import axiosBase from "./base.service"

export type Response = {
  message?: string
  error?: string
}

const CONCILIATION_BATCH_PATH = "/conciliation/batch"

const ConciliationBatchService = {
  postAssignable: async (
    conciliationsIds: string[],
    owners: { id: number; type: Assignable.Type }[]
  ): Promise<Response> => {
    const response = await axiosBase.post(`${CONCILIATION_BATCH_PATH}/assign`, {
      conciliations: conciliationsIds,
      owners,
    })
    return response.data.data
  },
  updateConciliation: async (
    conciliationsIds: string[],
    updateMessage: string,
    files: number[]
  ): Promise<Response> => {
    const response = await axiosBase.post(`${CONCILIATION_BATCH_PATH}/update`, {
      conciliations: conciliationsIds,
      updateMessage,
      files,
    })
    return response.data.data
  },
  exportExcel: async (conciliationsIds: string[]): Promise<Response> => {
    const response = await axiosBase.post(
      `${CONCILIATION_BATCH_PATH}/export/excel`,
      {
        conciliations: conciliationsIds,
      }
    )
    return response.data.data
  },
  postNotification: async (conciliationsIds: string[]): Promise<Response> => {
    const response = await axiosBase.post(`${CONCILIATION_BATCH_PATH}/notify`, {
      conciliations: conciliationsIds,
    })
    return response.data.data
  },
  completeProcess: async (
    conciliationsIds: string[],
    reason: string,
    observation: string
  ): Promise<Response> => {
    const response = await axiosBase.post(
      `${CONCILIATION_BATCH_PATH}/complete`,
      {
        conciliations: conciliationsIds,
        reason,
        observation,
      }
    )
    return response.data.data
  },
}

export default ConciliationBatchService
