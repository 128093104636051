import { BtnLoadingIcon, PaperClipIcon } from "@assets/icons"
import ReplyIcon from "@assets/images/reply.svg"
import PendingCard from "@components/pending-card"
import axiosBase from "@services/base.service"
import ConciliationDetailsService from "@services/conciliation-details.service"
import {
  ConciliationPendingAction,
  ConciliationUpdate,
} from "@type/conciliation.type"
import { Pendency } from "@type/pendency.type"
import { fileMapper } from "@utils/helpers/file.mapper"
import axios from "axios"
import { Button, FileInput } from "flowbite-react"
import { HiPaperClip } from "react-icons/hi"
import { useParams } from "react-router"

type SectionPendingActionsType = {
  pendingActions: ConciliationPendingAction.Data
  setPendingActions: React.Dispatch<
    React.SetStateAction<ConciliationPendingAction.Data>
  >
  update: ConciliationUpdate.Data
}

function SectionPendingActions({
  pendingActions,
  setPendingActions,
  update,
}: SectionPendingActionsType) {
  const { conciliationNumber } = useParams()

  if (pendingActions.items?.length === 0) {
    return null
  }

  const handleUpdatedItems = (
    prevItemCopy: ConciliationPendingAction.UpdatedItem[],
    updatedItemCopy: ConciliationPendingAction.UpdatedItem
  ) => {
    const idx = prevItemCopy.findIndex(
      (item) => item.key === updatedItemCopy.key
    )
    if (idx !== -1) {
      prevItemCopy[idx] = updatedItemCopy
    }
    return prevItemCopy
  }

  const updatePendingAction = async (
    updatedItem: ConciliationPendingAction.UpdatedItem
  ) => {
    setPendingActions((prev) => {
      const prevItemCopy = [...prev.updatedItems]
      const updatedItemCopy = { ...updatedItem }
      updatedItemCopy.loading = true
      const updatedItems = handleUpdatedItems(prevItemCopy, updatedItemCopy)
      return { ...prev, updatedItems }
    })

    if (updatedItem.text === "") {
      setPendingActions((prev) => {
        const prevItemCopy = [...prev.updatedItems]
        const updatedItemCopy = { ...updatedItem }
        updatedItemCopy.error = true
        const updatedItems = handleUpdatedItems(prevItemCopy, updatedItemCopy)
        return { ...prev, updatedItems }
      })
    }

    if (!updatedItem.error) {
      const attachs: Array<number> = []
      if (updatedItem.attachments.length > 0) {
        for (let i = 0; i < updatedItem.attachments.length; i++) {
          try {
            const resUrl = await axiosBase.post("/attachment/url-upload", {
              fileName: updatedItem.attachments[i].name,
              name: updatedItem.attachments[i].name,
              mimeType: updatedItem.attachments[i].type,
            })
            const urlForUpload = resUrl.data.data.url
            await axios.put(urlForUpload, updatedItem.attachments[i].file)
            const res = await axiosBase.post("/attachment", {
              fileName: resUrl.data.data.fileName,
              name: updatedItem.attachments[i].name,
              mimeType: updatedItem.attachments[i].type,
            })
            attachs.push(res.data.data.id)
          } catch (error) {
            setPendingActions((prev) => {
              const prevItemCopy = [...prev.updatedItems]
              const updatedItemCopy = { ...updatedItem }
              updatedItemCopy.loading = false
              const updatedItems = handleUpdatedItems(
                prevItemCopy,
                updatedItemCopy
              )
              return { ...prev, updatedItems }
            })
          }
        }
      }

      const [, , taskId] = updatedItem.key.split("|")

      await ConciliationDetailsService.postPendingAction(
        {
          files: attachs,
          updateMessage: update.text,
        },
        conciliationNumber,
        taskId
      )
        .then(() =>
          ConciliationDetailsService.findAllPendingActions(conciliationNumber)
            .then((data) =>
              setPendingActions((prev) => ({ ...prev, items: data }))
            )
            .finally(() =>
              setPendingActions((prev) => {
                const prevItemCopy = [...prev.updatedItems]
                const updatedItemCopy = { ...updatedItem }
                updatedItemCopy.loading = false
                const updatedItems = handleUpdatedItems(
                  prevItemCopy,
                  updatedItemCopy
                )
                return { ...prev, updatedItems }
              })
            )
        )
        .finally(() =>
          setPendingActions((prev) => ({ ...prev, updatedItems: [] }))
        )
    }
  }

  return (
    <section>
      <p className="text-2xl text-gray-600 font-semibold">Ações pendentes</p>
      <div className="w-full h-[1px] bg-gray-200 my-8" />
      {pendingActions.items.map((item) => {
        const title = item?.title?.trim()?.toLocaleLowerCase()
        const key = `${title}|${item.dueDate}|${item.id}`
        const updatedItem = pendingActions.updatedItems.find(
          (updateItem) => updateItem.key === key
        )
        return (
          <PendingCard
            key={key}
            description={item.description}
            title={item.title}
            bgColor={
              item.status === Pendency.Status.DONE
                ? "gray"
                : item.urgent
                ? "red"
                : "indigo"
            }
          >
            {updatedItem?.open ? (
              <div
                className={`flex flex-col gap-8 md:gap-4 items-start rounded-lg`}
              >
                <div className="flex-col w-full">
                  <textarea
                    name="update_text"
                    className={`mt-3 px-3 min-h-[155px] disabled:text-gray-400 w-full rounded-lg border ${
                      updatedItem.error
                        ? "border-red-600 bg-red-50"
                        : "border-gray-300 bg-gray-50"
                    } text-sm text-gray-900`}
                    value={updatedItem.text}
                    required
                    placeholder="Escreva sua resposta aqui."
                    onChange={(e) => {
                      setPendingActions((prev) => {
                        const prevItemCopy = [...prev.updatedItems]
                        const updatedItemCopy = { ...updatedItem }
                        updatedItemCopy.text = e.target.value
                        updatedItemCopy.error = false
                        const updatedItems = handleUpdatedItems(
                          prevItemCopy,
                          updatedItemCopy
                        )
                        return { ...prev, updatedItems }
                      })
                    }}
                  />
                </div>
                {Array.isArray(updatedItem.attachments) &&
                updatedItem.attachments.length > 0 ? (
                  <div className="flex flex-col gap-2 w-full">
                    {updatedItem.attachments.map((item, index) => {
                      return (
                        <div
                          key={`${item.name}_${index}`}
                          className="flex items-center"
                        >
                          <HiPaperClip
                            className="fill-primary-400 mr-1"
                            size={16}
                          />
                          <p className="truncate text-primary-400 font-normal text-base leading-6">
                            {item.name}
                          </p>
                        </div>
                      )
                    })}
                  </div>
                ) : null}
                <div className="flex flex-2 self-start items-center">
                  <Button
                    color="primary"
                    className="md:min-w-[100px]"
                    onClick={() => updatePendingAction(updatedItem)}
                    disabled={updatedItem.loading || updatedItem.text === ""}
                  >
                    {updatedItem.loading ? <BtnLoadingIcon /> : null}
                    Enviar
                  </Button>
                  <Button
                    size="md"
                    color="gray"
                    className="text-sm h-[42px] ml-4 md:min-w-[100px]"
                    onClick={() => {
                      setPendingActions((prev) => {
                        const prevItemCopy = [...prev.updatedItems]
                        const updatedItems = prevItemCopy.filter(
                          (item) => item.key !== updatedItem.key
                        )
                        return { ...prev, updatedItems }
                      })
                    }}
                  >
                    Cancelar
                  </Button>
                  <label
                    htmlFor="file"
                    className="ml-1 cursor-pointer flex py-2.5 px-5 justify-center items-center flex-1 rounded-lg"
                  >
                    <PaperClipIcon color="#1F2A37" />
                  </label>
                  <FileInput
                    id="file"
                    multiple
                    className="hidden"
                    onChange={(e) => {
                      if (
                        e.target.files !== null &&
                        e.target.files.length > 0
                      ) {
                        const filesArray = Array.from(e.target.files)
                        const newFilesInfo = fileMapper(filesArray)
                        setPendingActions((prev) => {
                          const prevItemCopy = [...prev.updatedItems]
                          const updatedItemCopy = { ...updatedItem }
                          const prevAttachments = [...updatedItem.attachments]
                          updatedItemCopy.attachments =
                            prevAttachments.concat(newFilesInfo)
                          const updatedItems = handleUpdatedItems(
                            prevItemCopy,
                            updatedItemCopy
                          )
                          return { ...prev, updatedItems }
                        })
                      }
                    }}
                  />
                </div>
              </div>
            ) : (
              <Button
                color="primary"
                size="xs"
                className="w-fit rounded-lg bg-primary-700 hover:bg-primary-900 px-3 min-h-[34px] justify-center items-center gap-2 md:gap-3 mt-3"
                onClick={() => {
                  setPendingActions((prev) => {
                    const prevItemCopy = [...prev.updatedItems]
                    const item = {
                      attachments: [],
                      error: false,
                      loading: false,
                      open: true,
                      text: "",
                      key,
                    }
                    const updatedItems = prevItemCopy.concat(item)
                    return { ...prev, updatedItems }
                  })
                }}
              >
                <img className="mr-2" src={ReplyIcon} alt="Reply Logo" />
                Responder
              </Button>
            )}
          </PendingCard>
        )
      })}
    </section>
  )
}

export default SectionPendingActions
