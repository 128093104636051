import { IProfile } from "@features/profile"
import axiosBase from "./base.service"
import axios from "axios"

const PROFILE_PATH = "/user-profile"

export type UpdateProfile = Pick<
  IProfile,
  "avatarUrl" | "fullName" | "email" | "phone"
>

const ProfileService = {
  get: async (): Promise<IProfile> => {
    const resp = await axiosBase.get(PROFILE_PATH)
    return resp.data.data
  },
  put: async (data: UpdateProfile): Promise<IProfile> => {
    const resp = await axiosBase.put(PROFILE_PATH, data)
    return resp.data.data
  },
}

const ProfilePicService = {
  uploadUrl: async (file: File): Promise<{ url: string; fileName: string }> => {
    const resp = await axiosBase.post(`${PROFILE_PATH}/url-upload`, {
      fileName: file.name,
      name: file.name,
      mimeType: file.type,
    })
    return resp.data.data
  },
  post: async (file: File, fileName: string): Promise<number> => {
    const resp = await axiosBase.post(PROFILE_PATH, {
      fileName: fileName,
      name: file.name,
      mimeType: file.type,
    })
    return resp.data.data
  },
  put: async (url: string, file: File): Promise<void> => {
    const config = {
      headers: {
        "Content-Type": file.type,
      },
    }

    const resp = await axios.put(url, file, config)
    return resp.data.data
  },
  delete: async (): Promise<void> => {
    await axiosBase.delete(`${PROFILE_PATH}`)
  },
}

export { ProfileService, ProfilePicService }
