import FullLoading from "@components/full-loading"
import ToastComponent from "@components/toast"
import { AbilityContext } from "@context/ability.provider"
import PaymentSection from "@features/payment/components/payment"
import ReceiveSection from "@features/payment/components/receive"
import { usePayments } from "@hooks/use-payments"
import { useRole } from "@hooks/use-role"
import { Tabs } from "flowbite-react"
import { useContext, useEffect, useMemo } from "react"

function PaymentMainPage() {
  const { isAdmin } = useRole()
  const ability = useContext(AbilityContext)
  const isReadPay = ability.can("read", "payments pay")
  const isReadReceive = ability.can("read", "payments receive")

  const {
    error,
    loading,
    activeTab,
    currentPage,
    paymentPage,
    receivePage,
    checkListDownload,
    fetchData,
    fetchStatus,
    setActiveTab,
    updateFilteredData,
    setCheckListDownload,
    setFilteredReceiveData,
    setFilteredPaymentData,
    paymentStatusId,
    processStatusId,
  } = usePayments()

  const sumPaymentAmount = useMemo(() => {
    if (!checkListDownload.length || !paymentPage?.items?.length) {
      return 0
    }

    return paymentPage.items.reduce((accumulator, element) => {
      return checkListDownload.includes(element?.id)
        ? accumulator + element.amount
        : accumulator
    }, 0)
  }, [checkListDownload, paymentPage?.items])

  const sumReceiveAmount = useMemo(() => {
    if (!checkListDownload.length || !receivePage?.items?.length) {
      return 0
    }

    return receivePage.items.reduce((accumulator, element) => {
      return checkListDownload.includes(element?.id)
        ? accumulator + element.amount
        : accumulator
    }, 0)
  }, [checkListDownload, receivePage?.items])

  useEffect(() => {
    fetchData(currentPage)
  }, [currentPage, activeTab, paymentStatusId, processStatusId])

  useEffect(() => {
    setCheckListDownload([])
  }, [activeTab])

  useEffect(() => {
    fetchStatus()
  }, [])

  useEffect(() => {
    updateFilteredData(paymentPage, 0, setFilteredPaymentData, sumPaymentAmount)
  }, [checkListDownload, sumPaymentAmount, paymentPage, activeTab])

  useEffect(() => {
    updateFilteredData(receivePage, 1, setFilteredReceiveData, sumReceiveAmount)
  }, [checkListDownload, sumReceiveAmount, receivePage, activeTab])

  return (
    <>
      {error.post && <ToastComponent />}
      <h1 className="text-3xl font-bold lg:ml-0 md:ml-0 sm:ml-8 ml-8 ">
        Pagamentos
      </h1>
      {isAdmin && (
        <Tabs.Group
          style="underline"
          className="mt-4 mb-0"
          onActiveTabChange={(tab) => {
            setActiveTab(tab)
          }}
        >
          <Tabs.Item active title="Pagar" />
          <Tabs.Item title="Receber" />
        </Tabs.Group>
      )}
      {loading ? (
        <FullLoading />
      ) : (
        <>
          {isAdmin &&
            (activeTab === 0 ? <PaymentSection /> : <ReceiveSection />)}
          {isReadReceive && !isAdmin && <ReceiveSection />}
          {isReadPay && !isAdmin && <PaymentSection />}
        </>
      )}
    </>
  )
}

export default PaymentMainPage
