import React from "react"

type PlusIconType = {
  width?: string
  height?: string
  color?: string
  isHovered?: boolean
  hoverColor?: string
}

export default function PlusIcon({
  color = "#221257",
  hoverColor = "#1A56DB",
  height = "17",
  width = "16",
  isHovered = false,
}: PlusIconType) {
  const _color = isHovered ? hoverColor : color

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path d="M8 8.5H4H8Z" fill={_color} />
      <path
        d="M8 4.5V8.5M8 8.5V12.5M8 8.5H12M8 8.5H4"
        stroke={_color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
