import { Toast } from "flowbite-react"
import { JSXElementConstructor, ReactElement, ReactFragment } from "react"
import { HiX } from "react-icons/hi"

export default function ToastComponent(props: {
  divIcon?: ReactElement<JSXElementConstructor<ReactFragment>>
  message?: string
}) {
  return (
    <Toast className="absolute top-0 right-0 mt-4 mr-8">
      {props.divIcon || (
        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
          <HiX className="h-5 w-5" />
        </div>
      )}
      <div className="ml-3 text-sm font-normal">
        {props.message || "O processo não pôde ser carregado corretamente"}
      </div>
      <Toast.Toggle />
    </Toast>
  )
}
