import { Checkbox, Dropdown, Label } from "flowbite-react"
import { useEffect, useState } from "react"

type Status = {
  id: number
  name: string
}

type CheckboxFilterProps = {
  statusList: Status[]
  onFilterChange: (selectedOptionIds: number[]) => void
  selectedIds?: number[]
}

function CheckboxFilter({
  statusList,
  onFilterChange,
  selectedIds = [],
}: CheckboxFilterProps) {
  const [selectedOptionIds, setSelectedOptionIds] =
    useState<number[]>(selectedIds)
  const handleCheckboxChange = (option: Status) => {
    const updatedSelectedOptions = selectedOptionIds.includes(option.id)
      ? selectedOptionIds.filter((item) => item !== option.id)
      : [...selectedOptionIds, option.id]

    setSelectedOptionIds(updatedSelectedOptions)
    onFilterChange(updatedSelectedOptions)
  }
  useEffect(() => {
    setSelectedOptionIds(selectedIds)
  }, [selectedIds])

  return (
    <div className="mt-2.5 flex flex-col">
      {statusList && statusList.length < 0
        ? null
        : statusList.map((option: Status) => (
            <Dropdown.Item
              key={`checkbox-filter-dropdown-item-${option.id}`}
              className="pl-1 rounded-2 cursor-default hover:bg-transparent"
            >
              <Checkbox
                id={option.id.toString()}
                className="checked:bg-primary-600 border-0 cursor-pointer"
                checked={selectedOptionIds.includes(option.id)}
                onChange={() => handleCheckboxChange(option)}
              />
              <Label
                htmlFor={option.id.toString()}
                className="ml-1 cursor-pointer"
              >
                {option.name}
              </Label>
            </Dropdown.Item>
          ))}
    </div>
  )
}

export default CheckboxFilter
