import PencilDetailsIcon from "@assets/icons/pencilDetails"
import { customTableTheme } from "@theme/custom"
import { AdminModels } from "@type/admin.type"
import { Flowbite, Table } from "flowbite-react"
import { HiSelector, HiTrash } from "react-icons/hi"

type AdminModelsTableType = {
  removeModel: (id: string) => void
  editModel: (id: string) => void
  toggleSortingOrder: () => void
  filtered: AdminModels.Item[]
}

function renderStatus(status: AdminModels.Status) {
  switch (status) {
    case AdminModels.Status.PUBLISH_ALL:
      return (
        <span className="inline-block px-2 py-1 justify-center items-center rounded-lg bg-gray-100 text-gray-900">
          Publicado para todos
        </span>
      )
    case AdminModels.Status.DRAFT:
      return (
        <span className="inline-block px-2 py-1 justify-center items-center rounded-lg bg-green-100 text-green-800 ">
          Rascunho
        </span>
      )
    case AdminModels.Status.PUBLISH_INTERNAL:
      return (
        <span className="inline-block px-2.5 py-1 justify-center items-center rounded-lg bg-gray-100 text-gray-900">
          Interno
        </span>
      )
  }
}

export default function AdminUsersTable({
  removeModel,
  editModel,
  toggleSortingOrder,
  filtered,
}: AdminModelsTableType) {
  return (
    <Flowbite theme={{ theme: customTableTheme }}>
      <Table className="px-4">
        <Table.Head>
          <Table.HeadCell className="uppercase text-gray-500 text-xs font-semibold w-[70%]">
            Título
          </Table.HeadCell>
          <Table.HeadCell className="uppercase text-gray-500 text-xs font-semibold w-[20%]">
            <span
              className="flex items-center cursor-pointer"
              onClick={toggleSortingOrder}
            >
              Status <HiSelector className="ml-0.5" size={16} />
            </span>
          </Table.HeadCell>
          <Table.HeadCell className="w-[10%]">
            <span className="sr-only">Editar</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {filtered.map((model) => (
            <Table.Row key={`${model.id}_${model.title}_model_admin`}>
              <Table.Cell className="text-gray-900 font-medium text-sm">
                {model.title}
              </Table.Cell>
              <Table.Cell className="text-xs font-normal">
                {renderStatus(model.status)}
              </Table.Cell>
              <Table.Cell>
                <div className="flex items-center gap-1 md:gap-2">
                  <button
                    className="cursor-pointer"
                    onClick={() => editModel(model.id)}
                  >
                    <PencilDetailsIcon />
                  </button>
                  <button
                    className="cursor-pointer"
                    onClick={() => removeModel(model.id)}
                  >
                    <HiTrash size={17} fill="gray-900" />
                  </button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Flowbite>
  )
}
