import { Notification } from "@type/notification.types"
import axios from "./base.service"

const NOTIFICATION_PATH = "/notification"
const NotificationService = {
  findAll: async (): Promise<Notification[]> => {
    const resp = await axios.get(`${NOTIFICATION_PATH}`)
    return resp.data.data
  },

  put: async (notificationId: number, status: string): Promise<void> => {
    await axios.put(`${NOTIFICATION_PATH}/${notificationId}`, { status })
  },
  sendToken: async (token: string): Promise<void> => {
    await axios.post(`${NOTIFICATION_PATH}/token`, { token })
  },
}

export default NotificationService
