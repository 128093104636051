import { BtnLoadingIcon } from "@assets/icons"
import { ConciliationErrors } from "@features/conciliation/types/conciliation-detail.type"
import { AdminUser } from "@type/admin.type"
import { formatCpfCnpj } from "@utils/helpers/formatCpfCnpj.helper"
import { formatPhoneNumber } from "@utils/helpers/formatPhoneNumber.helper"
import { formatPostalCode } from "@utils/helpers/formatPostalCode.helper"
import { states } from "@utils/helpers/states.list"
import { cpf as cpfValidator } from "cpf-cnpj-validator"
import { Button, Label, Radio, Select, TextInput } from "flowbite-react"
import { useFormik } from "formik"
import React, { useState } from "react"
import * as yup from "yup"
import * as theme from "./styles"

const validationSchema = yup
  .object({
    fullName: yup
      .string()
      .min(2, "Digite o nome completo")
      .required("É necessário preencher este campo para continuar.")
      .test(
        "test-invalid-full-name",
        "O campo deve conter apenas letras e espaços.",
        (fullName) => /^[\p{L}\s]+$/u.test(fullName)
      ),
    document: yup
      .string()
      .required("É necessário preencher este campo para continuar.")
      .test(
        "test-invalid-cpf-cnpj",
        "Algo parece estar errado. Por favor, verifique o número e tente novamente.",
        (cpfValue: string) => cpfValidator.isValid(cpfValue)
      ),
    kindOfPerson: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    email: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    phone: yup
      .string()
      .matches(/(\(\d{2}\)\s)(\d{4,5}-\d{4})/g, "Digite um telefone válido")
      .required("É necessário preencher este campo para continuar."),
    licenseNumber: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    profile: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    status: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    relatesTo: yup
      .string()
      .required("É necessário preencher este campo para continuar."),
    address: yup.object({
      postalCode: yup
        .string()
        .matches(/^\d{5}-\d{3}$/, "CEP deve estar no formato XXXXX-XXX")
        .required("É necessário preencher este campo para continuar."),
      streetName: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      number: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      complement: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      neighborhood: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      state: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      country: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
      city: yup
        .string()
        .required("É necessário preencher este campo para continuar."),
    }),
  })
  .required()

export type Form = yup.InferType<typeof validationSchema>

const initialState: Form = {
  status: "",
  relatesTo: "",
  profile: "",
  fullName: "",
  document: "",
  kindOfPerson: "",
  email: "",
  phone: "",
  licenseNumber: "",
  address: {
    postalCode: "",
    streetName: "",
    number: "",
    complement: "",
    neighborhood: "",
    state: "",
    country: "Brasil",
    city: "",
  },
}

type ConciliationDetailsFormProps = {
  type: string
  setModal: React.Dispatch<
    React.SetStateAction<{
      isDefineResponsible: boolean
      isDefineSpecialist: boolean
      isClaimants: boolean
      isRequireds: boolean
    }>
  >
  setErrors: React.Dispatch<React.SetStateAction<ConciliationErrors>>
}

function ConciliationDetailsForm(props: ConciliationDetailsFormProps) {
  const [profiles] = useState([{ id: 1, name: "Advogado" }])
  const [relatesToList] = useState([
    { id: 1, name: "RELATE A" },
    { id: 2, name: "RELATE B" },
  ])

  const onSubmit = (data: Form) => {
    if (props.type === "claimants") {
      console.log("claimants", data)
    }
    console.log("relates_to", data)
  }

  const formik = useFormik<Form>({
    initialValues: initialState,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => onSubmit(values),
  })

  const [loadingSubmission] = useState(false)

  const KindOfPersonRadioWithType = ({
    formik,
    kindOfPerson,
    label,
  }: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formik: any
    kindOfPerson: string
    label: string
    type?: string
  }) => {
    return (
      <div className="flex items-center gap-2">
        <Radio
          className="checked:bg-primary-600"
          onChange={() => formik.setFieldValue("kindOfPerson", kindOfPerson)}
          checked={formik.values.kindOfPerson === kindOfPerson}
        />
        <Label
          htmlFor="kindOfPerson"
          color={formik.errors.status ? "failure" : ""}
          className="text-xs font-normal text-gray-500"
        >
          {label}
        </Label>
      </div>
    )
  }

  return (
    <div className="mt-4 p-6 rounded-xl border border-gray-300 bg-white">
      <form
        className="w-full grid grid-cols-2 gap-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="col-span-2">
          <div className="flex gap-4" role="group">
            <div className="flex items-center gap-2">
              <Radio
                className="checked:bg-primary-600"
                onChange={() =>
                  formik.setFieldValue("status", AdminUser.Status.ENABLE)
                }
                checked={formik.values.status === AdminUser.Status.ENABLE}
              />
              <Label
                className="text-xs font-normal text-gray-500"
                htmlFor="status"
                color={formik.errors.status ? "failure" : ""}
              >
                Ativo
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                className="checked:bg-primary-600"
                onChange={() =>
                  formik.setFieldValue("status", AdminUser.Status.DISABLE)
                }
                checked={formik.values.status === AdminUser.Status.DISABLE}
              />
              <Label
                className="text-xs font-normal text-gray-500"
                htmlFor="status"
                color={formik.errors.status ? "failure" : ""}
              >
                Inativo
              </Label>
            </div>
            <div className="flex items-center gap-2">
              <Radio
                className="checked:bg-primary-600"
                onChange={() => formik.setFieldValue("status", "OBSERVER")}
                checked={formik.values.status === "OBSERVER"}
              />
              <Label
                className="text-xs font-normal text-gray-500"
                htmlFor="status"
                color={formik.errors.status ? "failure" : ""}
              >
                Observador
              </Label>
            </div>
          </div>
          {formik.touched.status && formik.errors.status ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.status}
            </div>
          ) : null}
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="status"
              value="Com quem essa pessoa está relacionada?"
            />
          </div>
          <Select
            id="relatesTo"
            theme={theme.customSelect}
            color={formik.errors.relatesTo ? "failure" : ""}
            {...formik.getFieldProps("relatesTo")}
          >
            <option disabled value="">
              Selecione
            </option>
            {relatesToList.map((item) => (
              <option key={`relates_to_${item.id}`} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
          {formik.touched.relatesTo && formik.errors.relatesTo ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.relatesTo}
            </div>
          ) : null}
        </div>
        <div className="col-span-2 md:col-span-1">
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="status"
              value="Tipo de perfil"
            />
          </div>
          <Select
            id="profile"
            theme={theme.customSelect}
            color={formik.errors.profile ? "failure" : ""}
            {...formik.getFieldProps("profile")}
          >
            <option disabled value="">
              Selecione
            </option>
            {profiles.map((item) => (
              <option key={`profile_${item.id}`} value={item.id}>
                {item.name}
              </option>
            ))}
          </Select>
          {formik.touched.profile && formik.errors.profile ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.profile}
            </div>
          ) : null}
        </div>
        <div
          className={`${
            formik.values.profile === "1" ? "md:col-span-1" : "md:col-span-2"
          } col-span-2 `}
        >
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="fullName"
              value="Nome completo"
            />
          </div>
          <TextInput
            id="fullName"
            placeholder="Nome Completo"
            color={formik.errors.fullName ? "failure" : ""}
            {...formik.getFieldProps("fullName")}
          />
          {formik.touched.fullName && formik.errors.fullName ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.fullName}
            </div>
          ) : null}
        </div>
        {formik.values.profile === "1" && (
          <div className={"col-span-2 md:col-span-1"}>
            <div className="flex items-center mb-2">
              <Label
                className="font-semibold"
                htmlFor="licenseNumber"
                value="OAB"
              />
            </div>
            <TextInput
              id="licenseNumber"
              color={formik.errors.licenseNumber ? "failure" : ""}
              {...formik.getFieldProps("licenseNumber")}
            />
            {formik.touched.licenseNumber && formik.errors.licenseNumber ? (
              <div className="text-red-600 text-sm mt-2">
                {formik.errors.licenseNumber}
              </div>
            ) : null}
          </div>
        )}
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="document"
              value="CPF/CNPJ"
            />
          </div>
          <TextInput
            id="document"
            name="document"
            color={formik.errors.document ? "failure" : ""}
            onChange={(e) => {
              formik.setFieldValue("document", e.target.value)
              formatCpfCnpj(e.target.value)
            }}
            maxLength={18}
            placeholder="Número de CPF/CNPJ"
            value={formatCpfCnpj(formik.values.document) || ""}
          />
          {formik.touched.document && formik.errors.document ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.document}
            </div>
          ) : null}
        </div>
        <div className="col-span-2 md:col-span-1 mt-8">
          <div className="flex gap-4" role="group">
            <KindOfPersonRadioWithType
              formik={formik}
              kindOfPerson={AdminUser.KindPerson.PF}
              label="Física"
            />
            <KindOfPersonRadioWithType
              formik={formik}
              kindOfPerson={AdminUser.KindPerson.PJ}
              label="Jurídica"
            />
          </div>
          {formik.touched.kindOfPerson && formik.errors.kindOfPerson ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.kindOfPerson}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label className="font-semibold" htmlFor="email" value="E-mail" />
          </div>
          <TextInput
            id="email"
            placeholder="requerente@email.com.br"
            color={formik.errors.email ? "failure" : ""}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.email}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="phone"
              value="Telefone/WhatsApp"
            />
          </div>
          <TextInput
            id="phone"
            name="phone"
            color={formik.errors.phone ? "failure" : ""}
            onChange={(e) => {
              formik.setFieldValue("phone", formatPhoneNumber(e.target.value))
            }}
            maxLength={15}
            placeholder="(99) 99999-9999"
            value={formatPhoneNumber(formik.values.phone) || ""}
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.phone}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <Label
            htmlFor="address.postalCode"
            value="CEP"
            className="mb-2 block font-semibold"
          />
          <TextInput
            id="address.postalCode"
            name="address.postalCode"
            placeholder="00000-000"
            maxLength={9}
            color={formik.errors.address?.postalCode ? "failure" : ""}
            onChange={(e) => {
              formik.setFieldValue(
                "address.postalCode",
                formatPostalCode(e.target.value)
              )
            }}
            value={formatPostalCode(formik.values.address?.postalCode)}
          />
          {formik.touched.address?.postalCode &&
          formik.errors.address?.postalCode ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.address?.postalCode}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="address.streetName"
              value="Endereço"
            />
          </div>
          <TextInput
            id="address.streetName"
            color={formik.errors.address?.streetName ? "failure" : ""}
            {...formik.getFieldProps("address.streetName")}
            placeholder="Rua, Avenida, etc."
          />
          {formik.touched.address?.streetName &&
          formik.errors.address?.streetName ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.address?.streetName}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="address.number"
              value="Nº"
            />
          </div>
          <TextInput
            id="address.number"
            color={formik.errors.address?.number ? "failure" : ""}
            {...formik.getFieldProps("address.number")}
            placeholder="Número ou s/n"
          />
          {formik.touched.address?.number && formik.errors.address?.number ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.address?.number}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="address.complement"
              value="Complemento"
            />
          </div>
          <TextInput
            id="address.complement"
            color={formik.errors.address?.complement ? "failure" : ""}
            {...formik.getFieldProps("address.complement")}
            placeholder="Apartamento, sala, conjunto, etc."
          />
          {formik.touched.address?.complement &&
          formik.errors.address?.complement ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.address?.complement}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="address.neighborhood"
              value="Bairro"
            />
          </div>
          <TextInput
            id="address.neighborhood"
            placeholder="Bairro"
            color={formik.errors.address?.neighborhood ? "failure" : ""}
            {...formik.getFieldProps("address.neighborhood")}
          />
          {formik.touched.address?.neighborhood &&
          formik.errors.address?.neighborhood ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.address?.neighborhood}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="address.city"
              value="Cidade"
            />
          </div>
          <TextInput
            id="address.city"
            color={formik.errors.address?.city ? "failure" : ""}
            {...formik.getFieldProps("address.city")}
          />
          {formik.touched.address?.city && formik.errors.address?.city ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.address?.city}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="address.state"
              value="Estado"
            />
          </div>
          <Select
            id="address.state"
            theme={theme.customSelect}
            color={formik.errors.address?.state ? "failure" : ""}
            {...formik.getFieldProps("address.state")}
          >
            <option disabled value="">
              Selecione
            </option>
            {states.map((state) => (
              <option key={`admin_user_${state.sigla}`} value={state.sigla}>
                {state.nome}
              </option>
            ))}
          </Select>
          {formik.touched.address?.state && formik.errors.address?.state ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.address?.state}
            </div>
          ) : null}
        </div>
        <div className={"col-span-2 md:col-span-1"}>
          <div className="flex items-center mb-2">
            <Label
              className="font-semibold"
              htmlFor="address.country"
              value="País"
            />
          </div>
          <TextInput
            id="address.country"
            {...formik.getFieldProps("address.country")}
            disabled
          />
          {formik.touched.address?.country && formik.errors.address?.country ? (
            <div className="text-red-600 text-sm mt-2">
              {formik.errors.address?.country}
            </div>
          ) : null}
        </div>
        <div className="flex md:col-span-2 mt-4 gap-4">
          <Button
            className="md:min-w-[100px] w-fit"
            type="submit"
            disabled={!formik.dirty}
            color="primary"
          >
            {loadingSubmission ? <BtnLoadingIcon /> : null}
            Salvar
          </Button>
          <Button
            color="gray"
            className="md:min-w-[100px] w-fit"
            onClick={() => {
              if (props.type === "claimants") {
                return props.setModal((prev) => ({
                  ...prev,
                  isClaimants: false,
                }))
              }
              return props.setModal((prev) => ({
                ...prev,
                isRequireds: false,
              }))
            }}
          >
            Cancelar
          </Button>
        </div>
      </form>
    </div>
  )
}

export default ConciliationDetailsForm
