type PencilDetailsIconType = {
  color?: string
}

export default function PencilDetailsIcon({
  color = "#111928",
}: PencilDetailsIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M10.8692 2.86883C11.0168 2.71602 11.1933 2.59412 11.3885 2.51027C11.5838 2.42642 11.7937 2.38228 12.0061 2.38043C12.2186 2.37859 12.4293 2.41907 12.6259 2.49952C12.8226 2.57997 13.0012 2.69877 13.1514 2.849C13.3017 2.99923 13.4205 3.17787 13.5009 3.37451C13.5814 3.57114 13.6218 3.78183 13.62 3.99427C13.6181 4.20672 13.574 4.41667 13.4902 4.61188C13.4063 4.80709 13.2844 4.98364 13.1316 5.13123L12.4972 5.76563L10.2348 3.50323L10.8692 2.86883ZM9.10359 4.63443L2.40039 11.3376V13.6H4.66279L11.3668 6.89683L9.10279 4.63443H9.10359Z"
        fill={color}
      />
    </svg>
  )
}
