import succeededDoneChecked from "@assets/animations/succeeded-done-checked.json"
import Lottie, { LottieRefCurrentProps } from "lottie-react"
import React, { PropsWithChildren, useEffect, useRef } from "react"
import "./index.css"

export interface FullSuccessModalProps extends PropsWithChildren {
  title: string
  description: string
  show: boolean
}

const FullSuccessModal: React.FC<FullSuccessModalProps> = (props) => {
  const showClass = !props.show ? "hidden" : ""
  const lottieRef = useRef<LottieRefCurrentProps>(null)
  useEffect(() => {
    if (props.show) {
      setTimeout(() => {
        lottieRef.current?.play()
      }, 200)
    }
  }, [props.show])
  return (
    <div
      className={`${showClass} absolute bg-white z-50 h-full w-full flex items-center justify-center full-success-modal`}
    >
      <div className="text-center flex flex-col items-center">
        <Lottie
          autoplay={false}
          lottieRef={lottieRef}
          className="h-10	w-10 justify-center"
          animationData={succeededDoneChecked}
          loop={false}
        />
        <h1 className="mt-2.5 text-xl font-bold text-gray-900 dark:text-white">
          {props.title}
        </h1>
        <p className="mt-2.5">{props.description}</p>
        {props.children}
      </div>
    </div>
  )
}

export default FullSuccessModal
