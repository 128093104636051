import { PaperClipIcon } from "@assets/icons"
import { modalConfirmTheme } from "@components/modal-confirm/style"
import { DocFile } from "@features/conciliation/types/conciliation.type"
import { useScreenDetect } from "@hooks/use-screen-detect"
import axiosBase from "@services/base.service"
import ConciliationBatchService from "@services/conciliation-batch.service"
import axios from "axios"
import { Button, FileInput } from "flowbite-react"
import React, { useState } from "react"
import { HiCheckCircle } from "react-icons/hi"
import Modal from "react-modal"

type ConciliationUpdateModalProps = {
  selectedItems: string[]
  isAddUpdateModalOpen: boolean
  setIsAddUpdateModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setError: React.Dispatch<
    React.SetStateAction<{
      excel: boolean
      postAssignable: boolean
      notification: boolean
      completeProcess: boolean
      addUpdate: boolean
      isNotify: boolean
      fetchData: boolean
    }>
  >
}

function ConciliationUpdateModal({
  selectedItems,
  isAddUpdateModalOpen,
  setIsAddUpdateModalOpen,
  setError,
}: ConciliationUpdateModalProps) {
  const { isMobile } = useScreenDetect()
  const [addUpdateText, setAddUpdateText] = useState("")
  const [attachments, setAttachments] = useState<DocFile[]>([])

  const updateConciliation = async () => {
    const attachs: Array<number> = []
    if (attachments.length > 0) {
      for (let i = 0; i < attachments.length; i++) {
        try {
          const resUrl = await axiosBase.post("/attachment/url-upload", {
            fileName: attachments[i].name,
            name: attachments[i].name,
            mimeType: attachments[i].type,
          })
          const urlForUpload = resUrl.data.data.url
          await axios.put(urlForUpload, attachments[i].file)
          const res = await axiosBase.post("/attachment", {
            fileName: resUrl.data.data.fileName,
            name: attachments[i].name,
            mimeType: attachments[i].type,
          })
          attachs.push(res.data.data.id)
        } catch (err) {
          console.error(err)
        }
      }
    }

    ConciliationBatchService.updateConciliation(
      selectedItems,
      addUpdateText,
      attachs
    )
      .catch(setError)
      .finally(() => {
        setAttachments([])
        setAddUpdateText("")
        setIsAddUpdateModalOpen(false)
      })
  }

  return (
    <Modal
      isOpen={isAddUpdateModalOpen}
      onRequestClose={() => setIsAddUpdateModalOpen(false)}
      style={modalConfirmTheme(isMobile, "640px")}
    >
      <div className="p-5">
        <h5 className="flex items-center text-gray-900 text-lg font-semibold border-b pb-4 mb-5">
          <HiCheckCircle className="mr-2" />
          Adicionar atualização
        </h5>
        <p className="text-sm text-gray-900 font-semibold">
          Escreva abaixo a sua atualização.
        </p>
        <p className="text-sm text-gray-500 font-normal my-4">
          O histórico dos processos será atualizado e todas as partes terão
          acesso a essa informação.
        </p>
        <textarea
          className="min-h-[126px] p-1 px-2 w-full text-sm text-gray-500 rounded-lg border border-gray-300 bg-gray-50"
          placeholder="Escreva sua atualização aqui..."
          value={addUpdateText}
          onChange={(e) => setAddUpdateText(e.target.value)}
        />
        <FileInput
          className="hidden"
          id="file"
          onChange={(e) => {
            if (e.target.files !== null && e.target.files.length > 0) {
              const filesArray = Array.from(e.target.files)
              const newFilesInfo = filesArray.map((file) => ({
                name: file.name,
                size: file.size,
                type: file.type,
                detail: "",
                file,
              }))
              setAttachments((prev) => [...prev, ...newFilesInfo])
            }
          }}
          multiple
        />
        {attachments?.map((item, i) => (
          <p
            className="text-sm text-primary-600 mt-1"
            key={`${item.name}_${i}`}
          >
            {item.name}
          </p>
        ))}
        <div className="flex md:col-span-2 gap-4 mt-5 items-center">
          <Button
            size="xs"
            color="primary"
            className="w-fit text-sm font-medium h-[34px]"
            onClick={updateConciliation}
            disabled={attachments.length === 0 && addUpdateText === ""}
          >
            Enviar
          </Button>
          <Button
            size="xs"
            color="gray"
            className="w-fit text-primary-700 text-sm font-medium border-primary-700 h-[34px]"
            onClick={() => {
              setAttachments([])
              setAddUpdateText("")
              setIsAddUpdateModalOpen(false)
            }}
          >
            Cancelar
          </Button>
          <label htmlFor="file" className="ml-2">
            <PaperClipIcon />
          </label>
        </div>
      </div>
    </Modal>
  )
}

export default ConciliationUpdateModal
