import React from "react"

export default function TrashCan() {
  return (
    <svg
      className="mx-auto mb-4 h-14 w-14 text-gray-400 dark:text-gray-200"
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8001 4.40039C19.3916 4.40061 18.9913 4.51453 18.6439 4.72941C18.2966 4.94428 18.0159 5.25161 17.8333 5.61699L16.2405 8.80039H8.8001C8.21662 8.80039 7.65704 9.03218 7.24446 9.44476C6.83188 9.85734 6.6001 10.4169 6.6001 11.0004C6.6001 11.5839 6.83188 12.1434 7.24446 12.556C7.65704 12.9686 8.21662 13.2004 8.8001 13.2004V35.2004C8.8001 36.3673 9.26367 37.4865 10.0888 38.3117C10.914 39.1368 12.0331 39.6004 13.2001 39.6004H30.8001C31.9671 39.6004 33.0862 39.1368 33.9114 38.3117C34.7365 37.4865 35.2001 36.3673 35.2001 35.2004V13.2004C35.7836 13.2004 36.3432 12.9686 36.7557 12.556C37.1683 12.1434 37.4001 11.5839 37.4001 11.0004C37.4001 10.4169 37.1683 9.85734 36.7557 9.44476C36.3432 9.03218 35.7836 8.80039 35.2001 8.80039H27.7597L26.1669 5.61699C25.9843 5.25161 25.7036 4.94428 25.3563 4.72941C25.0089 4.51453 24.6086 4.40061 24.2001 4.40039H19.8001ZM15.4001 17.6004C15.4001 17.0169 15.6319 16.4573 16.0445 16.0448C16.457 15.6322 17.0166 15.4004 17.6001 15.4004C18.1836 15.4004 18.7432 15.6322 19.1557 16.0448C19.5683 16.4573 19.8001 17.0169 19.8001 17.6004V30.8004C19.8001 31.3839 19.5683 31.9434 19.1557 32.356C18.7432 32.7686 18.1836 33.0004 17.6001 33.0004C17.0166 33.0004 16.457 32.7686 16.0445 32.356C15.6319 31.9434 15.4001 31.3839 15.4001 30.8004V17.6004ZM26.4001 15.4004C25.8166 15.4004 25.257 15.6322 24.8445 16.0448C24.4319 16.4573 24.2001 17.0169 24.2001 17.6004V30.8004C24.2001 31.3839 24.4319 31.9434 24.8445 32.356C25.257 32.7686 25.8166 33.0004 26.4001 33.0004C26.9836 33.0004 27.5432 32.7686 27.9557 32.356C28.3683 31.9434 28.6001 31.3839 28.6001 30.8004V17.6004C28.6001 17.0169 28.3683 16.4573 27.9557 16.0448C27.5432 15.6322 26.9836 15.4004 26.4001 15.4004Z"
        fill="#9CA3AF"
      />
    </svg>
  )
}
