import axios from "./base.service"
const PERSON_PATH = "/person"
const PersonService = {
  findNameByCpf: async (cpf: string): Promise<string | boolean> => {
    try {
      const resp = await axios.get(`${PERSON_PATH}/${cpf}`)
      return resp.data.data
    } catch (error) {
      return false
    }
  },
}

export default PersonService
