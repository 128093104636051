import {
  ScrollDirection,
  ScrollDirectionContextType,
} from "@type/scroll-direction-context.type"
import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"

const ScrollDirectionContext = createContext<ScrollDirectionContextType>(null!)

export function ScrollDirectionProvider(props: PropsWithChildren) {
  const scrollableDivRef = useRef<HTMLDivElement | null>(null)
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirection | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (scrollableDivRef.current) {
        const scrollTop = scrollableDivRef.current.scrollTop

        if (
          scrollTop >
          parseInt(scrollableDivRef.current.dataset.prevScrollTop || "0")
        ) {
          setScrollDirection(ScrollDirection.down)
        } else if (
          scrollTop <
          parseInt(scrollableDivRef.current.dataset.prevScrollTop || "0")
        ) {
          setScrollDirection(ScrollDirection.up)
        }

        scrollableDivRef.current.dataset.prevScrollTop = scrollTop.toString()
      }
    }

    if (scrollableDivRef.current) {
      scrollableDivRef.current.addEventListener("scroll", handleScroll)
      scrollableDivRef.current.dataset.prevScrollTop = "0"
    }

    return () => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  const value = { scrollableDivRef, scrollDirection }

  return (
    <ScrollDirectionContext.Provider value={value}>
      {props.children}
    </ScrollDirectionContext.Provider>
  )
}

export function useScrollDirection() {
  return useContext(ScrollDirectionContext)
}
