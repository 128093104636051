import { ConciliationStatus } from "@type/conciliation-status.type"
import { Conciliation } from "@type/conciliation.type"

export namespace Payment {
  export type PaymentStatus = {
    id: number
    name: string
    code: PaymentStatusCode
    color: string
  }

  export enum PaymentStatusCode {
    "PAID" = "PAID",
    "AWAITING_PAYMENT" = "AWAITING_PAYMENT",
    "OVERDUE" = "OVERDUE",
  }

  export type Item = {
    id: number
    status: PaymentStatus
    amount: number
    conciliation: ConciliationPayment
    dueDate?: string
  }

  export type ConciliationPayment = {
    code: string
    defendant: string
    startDate?: Date
    specialist?: string
    status?: Conciliation.Status
  }

  export type Paginated = {
    currentPage: number
    pages: number
    count: number
    items: Item[]
    itemsPerPage: number
  }
}

export interface PaymentContextType {
  updateFilteredData: (
    page: Payment.Paginated | undefined,
    tab: number,
    setFilteredData: React.Dispatch<React.SetStateAction<Payment.Item[]>>,
    sumAmount: number
  ) => void
  onNavigatePage: (toPage: number) => void
  onCheck: (item: Payment.Item) => void
  isSemiReceiveChecked: () => boolean
  isSemiPaymentChecked: () => boolean
  checkAllReceive: () => void
  checkAllPayment: () => void
  fetchStatus: () => Promise<void>
  fetchRequest: () => Promise<void>
  fetchData: (page: number) => Promise<void>
  handleFilterProcessStatus: (selectedOptions: number[]) => void
  handleFilterPaymentStatus: (selectedOptions: number[]) => void
  error: {
    post: boolean
    get: boolean
  }
  setActiveTab: React.Dispatch<React.SetStateAction<number>>
  loading: boolean
  currentPage: number
  paymentPage: Payment.Paginated | undefined
  receivePage: Payment.Paginated | undefined
  checkListDownload: number[]
  activeTab: number
  filteredPaymentData: Payment.Item[]
  filteredReceiveData: Payment.Item[]
  processStatusList: ConciliationStatus[]
  processStatusId: number[]
  setCheckListDownload: React.Dispatch<React.SetStateAction<number[]>>
  paymentStatusList: Payment.PaymentStatus[]
  paymentStatusId: number[]
  setFilteredPaymentData: React.Dispatch<React.SetStateAction<Payment.Item[]>>
  setFilteredReceiveData: React.Dispatch<React.SetStateAction<Payment.Item[]>>
}
