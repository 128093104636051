import React from "react"

type PdfIconType = {
  size?: string
  color?: string
}
export default function PdfIcon({
  color = "#233876",
  size = "20",
}: PdfIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <g clipPath="url(#clip0_2_323)">
        <path
          d="M0 2.5C0 1.12109 1.12109 0 2.5 0H8.75V5C8.75 5.69141 9.30859 6.25 10 6.25H15V11.875H6.875C5.49609 11.875 4.375 12.9961 4.375 14.375V20H2.5C1.12109 20 0 18.8789 0 17.5V2.5ZM15 5H10V0L15 5ZM6.875 13.75H8.125C9.33203 13.75 10.3125 14.7305 10.3125 15.9375C10.3125 17.1445 9.33203 18.125 8.125 18.125H7.5V19.375C7.5 19.7188 7.21875 20 6.875 20C6.53125 20 6.25 19.7188 6.25 19.375V17.5V14.375C6.25 14.0312 6.53125 13.75 6.875 13.75ZM8.125 16.875C8.64453 16.875 9.0625 16.457 9.0625 15.9375C9.0625 15.418 8.64453 15 8.125 15H7.5V16.875H8.125ZM11.875 13.75H13.125C14.1602 13.75 15 14.5898 15 15.625V18.125C15 19.1602 14.1602 20 13.125 20H11.875C11.5312 20 11.25 19.7188 11.25 19.375V14.375C11.25 14.0312 11.5312 13.75 11.875 13.75ZM13.125 18.75C13.4688 18.75 13.75 18.4688 13.75 18.125V15.625C13.75 15.2812 13.4688 15 13.125 15H12.5V18.75H13.125ZM16.25 14.375C16.25 14.0312 16.5312 13.75 16.875 13.75H18.75C19.0938 13.75 19.375 14.0312 19.375 14.375C19.375 14.7188 19.0938 15 18.75 15H17.5V16.25H18.75C19.0938 16.25 19.375 16.5312 19.375 16.875C19.375 17.2188 19.0938 17.5 18.75 17.5H17.5V19.375C17.5 19.7188 17.2188 20 16.875 20C16.5312 20 16.25 19.7188 16.25 19.375V16.875V14.375Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_323">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
