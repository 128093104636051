import { HiCalendar } from "react-icons/hi"

type DateInputProps = {
  disabled?: boolean
  onClick?: () => void
  onChange?: () => void
  placeholder: string
  style?: string
  value: string
  containerStyle?: string
}

function DateInput({
  disabled,
  onClick,
  onChange,
  placeholder,
  style,
  containerStyle,
  value,
}: DateInputProps) {
  return (
    <div className={`relative ${containerStyle}`}>
      <input
        className={`${style} h-[37px] pl-8 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5 focus:outline-none`}
        placeholder={placeholder}
        disabled={disabled}
        onClick={onClick}
        onChange={onChange}
        defaultValue={value}
      />
      <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
        <HiCalendar color="#6B7280" />
      </span>
    </div>
  )
}

export default DateInput
