import { Payment } from "@type/payment.types"
import currencyFormatter from "currency-formatter"

export const paymentStatus = (
  status: string,
  statusList: Payment.PaymentStatus[]
) => {
  const selectedStatus = statusList.find((payment) => payment.code === status)

  if (selectedStatus) {
    return selectedStatus
  }

  return statusList.find((payment) => payment.code === "AWAITING_PAYMENT")
}

export const formatPrice = (price: number) => {
  return currencyFormatter.format(price, { locale: "pt-BR" })
}
