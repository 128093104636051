import React from "react"

export default function PaperClipIcon({ color }: { color?: string }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M7.19922 3.60078C6.48313 3.60078 5.79638 3.88524 5.29003 4.39159C4.78368 4.89794 4.49922 5.5847 4.49922 6.30078V9.90078C4.49922 11.0943 4.97332 12.2388 5.81724 13.0828C6.66115 13.9267 7.80574 14.4008 8.99922 14.4008C10.1927 14.4008 11.3373 13.9267 12.1812 13.0828C13.0251 12.2388 13.4992 11.0943 13.4992 9.90078V6.30078C13.4992 6.06209 13.594 5.83317 13.7628 5.66439C13.9316 5.4956 14.1605 5.40078 14.3992 5.40078C14.6379 5.40078 14.8668 5.4956 15.0356 5.66439C15.2044 5.83317 15.2992 6.06209 15.2992 6.30078V9.90078C15.2992 10.7281 15.1363 11.5473 14.8197 12.3117C14.5031 13.076 14.039 13.7705 13.454 14.3556C12.869 14.9406 12.1745 15.4046 11.4101 15.7212C10.6458 16.0378 9.82655 16.2008 8.99922 16.2008C8.17189 16.2008 7.35266 16.0378 6.58831 15.7212C5.82396 15.4046 5.12946 14.9406 4.54445 14.3556C3.95944 13.7705 3.49538 13.076 3.17878 12.3117C2.86217 11.5473 2.69922 10.7281 2.69922 9.90078V6.30078C2.69922 5.10731 3.17332 3.96271 4.01724 3.1188C4.86115 2.27489 6.00574 1.80078 7.19922 1.80078C8.39269 1.80078 9.53729 2.27489 10.3812 3.1188C11.2251 3.96271 11.6992 5.10731 11.6992 6.30078V9.90078C11.6992 10.6169 11.4148 11.3036 10.9084 11.81C10.4021 12.3163 9.7153 12.6008 8.99922 12.6008C8.28313 12.6008 7.59638 12.3163 7.09003 11.81C6.58368 11.3036 6.29922 10.6169 6.29922 9.90078V6.30078C6.29922 6.06209 6.39404 5.83317 6.56282 5.66439C6.73161 5.4956 6.96052 5.40078 7.19922 5.40078C7.43791 5.40078 7.66683 5.4956 7.83561 5.66439C8.0044 5.83317 8.09922 6.06209 8.09922 6.30078V9.90078C8.09922 10.1395 8.19404 10.3684 8.36282 10.5372C8.53161 10.706 8.76052 10.8008 8.99922 10.8008C9.23791 10.8008 9.46683 10.706 9.63561 10.5372C9.8044 10.3684 9.89922 10.1395 9.89922 9.90078V6.30078C9.89922 5.5847 9.61476 4.89794 9.10841 4.39159C8.60206 3.88524 7.9153 3.60078 7.19922 3.60078Z"
        fill={color ?? "#6B7280"}
      />
    </svg>
  )
}
