import React from "react"

type DownloadIconType = {
  size?: string
  color?: string
}
export default function DownloadIcon({
  color = "#31246D",
  size = "16",
}: DownloadIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.39999 13.6C2.39999 13.3878 2.48428 13.1843 2.63431 13.0343C2.78434 12.8843 2.98782 12.8 3.19999 12.8H12.8C13.0122 12.8 13.2157 12.8843 13.3657 13.0343C13.5157 13.1843 13.6 13.3878 13.6 13.6C13.6 13.8121 13.5157 14.0156 13.3657 14.1657C13.2157 14.3157 13.0122 14.4 12.8 14.4H3.19999C2.98782 14.4 2.78434 14.3157 2.63431 14.1657C2.48428 14.0156 2.39999 13.8121 2.39999 13.6ZM5.03439 7.43438C5.18442 7.2844 5.38786 7.20015 5.59999 7.20015C5.81213 7.20015 6.01557 7.2844 6.16559 7.43438L7.19999 8.46878V2.39998C7.19999 2.1878 7.28428 1.98432 7.43431 1.83429C7.58434 1.68426 7.78782 1.59998 7.99999 1.59998C8.21217 1.59998 8.41565 1.68426 8.56568 1.83429C8.71571 1.98432 8.79999 2.1878 8.79999 2.39998V8.46878L9.83439 7.43438C9.90819 7.35797 9.99647 7.29702 10.0941 7.25509C10.1917 7.21317 10.2967 7.1911 10.4029 7.19017C10.5091 7.18925 10.6144 7.20949 10.7128 7.24972C10.8111 7.28994 10.9004 7.34935 10.9755 7.42446C11.0506 7.49957 11.11 7.58889 11.1503 7.68721C11.1905 7.78553 11.2107 7.89087 11.2098 7.9971C11.2089 8.10332 11.1868 8.2083 11.1449 8.3059C11.1029 8.4035 11.042 8.49178 10.9656 8.56558L8.56559 10.9656C8.41557 11.1156 8.21213 11.1998 7.99999 11.1998C7.78786 11.1998 7.58442 11.1156 7.43439 10.9656L5.03439 8.56558C4.88442 8.41555 4.80016 8.21211 4.80016 7.99998C4.80016 7.78784 4.88442 7.5844 5.03439 7.43438Z"
        fill={color}
      />
    </svg>
  )
}
