import { PaperClipIcon } from "@assets/icons"
import { ConciliationChatMessageAttachment } from "@type/conciliation-chat-message.type"
import { ReactNode } from "react"

type ChatMessageProps = {
  name: string
  description: string
  date: string
  avatar: ReactNode
  bgColor?: string
  attachments: ConciliationChatMessageAttachment[]
}

function ChatMessage({
  name,
  description,
  date,
  avatar,
  bgColor = "bg-gray-50",
  attachments,
}: ChatMessageProps) {
  return (
    <div className={`shadow-md flex p-4 ${bgColor} rounded-md`}>
      <div>{avatar}</div>
      <div className={`flex flex-col ${bgColor} ml-2 text-sm`}>
        <h5 className="font-semibold mb-1">{name}</h5>
        <p className="text-gray-500 leading-6 mb-3">{description}</p>
        {attachments.length > 0 && (
          <div>
            <p className="text-gray-500 leading-6 mb-3">Seguem os anexos</p>
            {attachments?.map((attachment) => (
              // pending download the file
              <a
                target="_blank"
                key={`chat_message_${attachment.fileName}_${attachment.id}`}
                className="text-primary-400 text-sm leading-6 mb-3 flex items-center cursor-pointer"
              >
                <span className="mr-1">
                  <PaperClipIcon color="#504888" />
                </span>
                {attachment.fileName}
              </a>
            ))}
          </div>
        )}
        <p className="text-gray-400 text-xs">{date}</p>
      </div>
    </div>
  )
}

export default ChatMessage
