import {
  ConciliationChatMessage,
  ConciliationChatMessageContextType,
} from "@type/conciliation-chat-message.type"
import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { useScreenDetect } from "./use-screen-detect"

const ConciliationChatMessagesContext =
  createContext<ConciliationChatMessageContextType>(null!)

export function ConciliationChatMessagesProvider(props: PropsWithChildren) {
  const topRef = useRef<HTMLDivElement | null>(null)
  const [chatStates, setChatStates] = useState({
    mobileOpen: false,
    desktopOpen: false,
  })

  const chatRefs = {
    mobile: useRef<HTMLDivElement | null>(null),
    desktop: useRef<HTMLDivElement | null>(null),
  }

  const [chatConciliationMessagesError, setChatConciliationMessagesError] =
    useState<boolean>(false)
  const [chatConciliationMessages, setChatConciliationMessages] = useState<
    ConciliationChatMessage[]
  >([])

  const { isMobile } = useScreenDetect()

  useEffect(() => {
    if (!isMobile) {
      setChatStates((prevState) => ({ ...prevState, mobileOpen: false }))
    }
  }, [isMobile])

  const executeScroll = useCallback(() => {
    if (isMobile && chatStates.mobileOpen) {
      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: "smooth" })
      }
    }

    setChatStates((prevState) => ({
      ...prevState,
      desktopOpen: !isMobile ? !prevState.desktopOpen : false,
      mobileOpen: isMobile ? !prevState.mobileOpen : false,
    }))

    setTimeout(
      () => {
        if (isMobile && chatRefs.mobile.current && !chatStates.mobileOpen) {
          chatRefs.mobile.current.scrollIntoView({
            behavior: "smooth",
          })
        }
      },
      isMobile ? 100 : 0
    )
  }, [isMobile, chatRefs.mobile, chatStates.mobileOpen, topRef.current])

  const value = {
    chatStates,
    chatRefs,
    chatConciliationMessagesError,
    chatConciliationMessages,
    setChatStates,
    setChatConciliationMessages,
    setChatConciliationMessagesError,
    executeScroll,
    topRef,
  }

  return (
    <ConciliationChatMessagesContext.Provider value={value}>
      {props.children}
    </ConciliationChatMessagesContext.Provider>
  )
}

export function useConciliationChatMessages() {
  return useContext(ConciliationChatMessagesContext)
}
