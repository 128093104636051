import ReplyIcon from "@assets/images/reply.svg"
import Error from "@components/error"
import FullLoading from "@components/full-loading"
import NoDataComponent from "@components/no-data"
import PendingCard from "@components/pending-card"
import { RoutesConstants as routes } from "@constants/routes"
import { useRole } from "@hooks/use-role"
import PendencyService from "@services/pendency.service"
import { Pendency } from "@type/pendency.type"
import { Button, Tabs } from "flowbite-react"
import { useEffect, useState } from "react"
import { HiCheckCircle } from "react-icons/hi"
import { useNavigate } from "react-router-dom"
import { getButtonClasses } from "./style"
type IList = { filter: (arg0: Pendency.Item) => boolean; title: string }

const responsibleTypeLabels = {
  [Pendency.ResponsibleType.MINE]: "Minhas",
  [Pendency.ResponsibleType.SECRETARY]: "Secretaria",
  [Pendency.ResponsibleType.SPECIALIST]: "Especialistas",
}

const list: IList[] = [
  {
    title: "Todas",
    filter: (item: Pendency.Item) => item.status === Pendency.Status.TODO,
  },
  {
    title: "Urgentes",
    filter: (item: Pendency.Item) =>
      item.status === Pendency.Status.TODO && item.urgent === true,
  },
  {
    title: "Concluídas",
    filter: (item: Pendency.Item) => item.status === Pendency.Status.DONE,
  },
]

function getTabLabelWithCount(tab: IList, itemCount: number) {
  return `${tab.title} (${itemCount})`
}

function Pendencies() {
  const navigate = useNavigate()
  const { isSpecialist, isSecretary } = useRole()
  const [error, setError] = useState(false)
  const [isLoadingPending, setLoadingPending] = useState(false)
  const [pendencies, setPendencies] = useState<Pendency.Item[]>([])
  const [responsibleType, setResponsibleType] =
    useState<Pendency.ResponsibleType>(Pendency.ResponsibleType.MINE)

  const handleResponsibleType = (value: Pendency.ResponsibleType) => {
    setResponsibleType(value)
  }

  const getPendencies = async () => {
    setLoadingPending(true)
    PendencyService.findAll()
      .then((data) => setPendencies(data))
      .catch(() => setError(true))
      .finally(() => setLoadingPending(false))
  }

  useEffect(() => {
    getPendencies()
  }, [])

  const isUserAuthorized = isSpecialist || isSecretary

  return isLoadingPending ? (
    <FullLoading />
  ) : (
    <>
      <div className="px-4 md:px-0">
        <h1
          className={`${
            isUserAuthorized ? "mb-8" : "mb-4"
          } text-gray-900 font-bold font-plus-jakarta-sans text-3xl leading-none`}
        >
          Pendências
        </h1>
        {!isUserAuthorized && (
          <div className="flex justify-start items-center gap-4 mb-8">
            {Object.values(Pendency.ResponsibleType).map((type) => (
              <button
                key={`button_${type}`}
                onClick={() => handleResponsibleType(type)}
                className={getButtonClasses(responsibleType === type)}
              >
                {responsibleTypeLabels[type]}
              </button>
            ))}
          </div>
        )}
        <div className="rounded-md bg-white shadow-md flex p-4 flex-col items-start min-h-[75vh]">
          <Tabs.Group
            style="underline"
            aria-label="Tabs with underline"
            className="border-none w-full"
          >
            {list.map((tab) => {
              const filteredPendencies = pendencies.filter((item) => {
                if (!isUserAuthorized) {
                  return (
                    item.responsibleType === responsibleType && tab.filter(item)
                  )
                }

                return tab.filter(item)
              })

              return (
                <Tabs.Item
                  key={`tab_${tab.title}`}
                  title={getTabLabelWithCount(tab, filteredPendencies.length)}
                  className="hover:border-none hover:text-gray-500 text-sm py-0 my-0"
                >
                  {error ? (
                    <Error />
                  ) : filteredPendencies.length > 0 ? (
                    filteredPendencies.map((item) => (
                      <PendingCard
                        key={`pending_card_${item.conciliationCode}`}
                        title={item.title}
                        description={item.description}
                        bgColor={
                          item.status === Pendency.Status.DONE
                            ? "gray"
                            : item.urgent
                            ? "red"
                            : "indigo"
                        }
                        isDone={item.status === Pendency.Status.DONE}
                      >
                        {!(item.status === Pendency.Status.DONE) ? (
                          <Button
                            size="xs"
                            color="primary"
                            className="w-fit rounded-lg bg-primary-700 hover:bg-primary-900 px-3 justify-center items-center gap-2 md:gap-3 mt-3 min-h-[34px] max-h-[34px]"
                            onClick={() => {
                              navigate(
                                `${routes.conciliations}/${item.conciliationCode}`
                              )
                            }}
                          >
                            <img
                              className="mr-2"
                              src={ReplyIcon}
                              alt="Reply Logo"
                            />
                            Responder
                          </Button>
                        ) : null}
                      </PendingCard>
                    ))
                  ) : (
                    <NoDataComponent
                      icon={<HiCheckCircle size={24} fill="#160042" />}
                      description={
                        <p className="text-sm text-center text-primary-900">
                          Tudo em dia! <br />
                          Quando você tiver alguma pendência, ela será exibida
                          aqui.
                        </p>
                      }
                    />
                  )}
                </Tabs.Item>
              )
            })}
          </Tabs.Group>
        </div>
      </div>
    </>
  )
}

export default Pendencies
