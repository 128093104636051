import logoFull from "@assets/images/logo-tagline-negativo.svg"
import React, { HTMLProps } from "react"
import "./index.css"

interface BrandImageProps extends HTMLProps<HTMLImageElement> {}
const BrandImage: React.FC<BrandImageProps> = (props) => {
  const logo = props.src ?? logoFull
  return <img {...props} src={logo} alt="Arbitralis" />
}

export default BrandImage
