import React from "react"

type ShieldCheckIconType = {
  width?: string
  height?: string
  color?: string
}

export default function ShieldCheckIcon({
  color = "#111928",
  height = "15",
  width = "15",
}: ShieldCheckIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.90123 3.70868C4.01938 3.68234 6.05599 2.88814 7.63277 1.47357C9.20945 2.8884 11.2461 3.68287 13.3643 3.70941C13.4448 4.18497 13.4858 4.67516 13.4858 5.17339C13.4858 8.99612 11.0421 12.2482 7.63277 13.4532C4.22341 12.2475 1.77979 8.99539 1.77979 5.17266C1.77979 4.67369 1.82149 4.18497 1.90123 3.70868ZM10.3449 6.42154C10.4782 6.28356 10.5519 6.09875 10.5502 5.90692C10.5486 5.71509 10.4716 5.53159 10.336 5.39594C10.2003 5.26029 10.0168 5.18335 9.82501 5.18168C9.63318 5.18001 9.44837 5.25375 9.31039 5.38703L6.90115 7.79626L5.95516 6.85027C5.81717 6.717 5.63236 6.64326 5.44054 6.64493C5.24871 6.64659 5.06521 6.72354 4.92956 6.85919C4.79391 6.99483 4.71696 7.17833 4.7153 7.37016C4.71363 7.56199 4.78737 7.7468 4.92064 7.88479L6.38389 9.34804C6.52109 9.48519 6.70715 9.56225 6.90115 9.56225C7.09515 9.56225 7.28121 9.48519 7.41841 9.34804L10.3449 6.42154Z"
        fill={color}
      />
    </svg>
  )
}
