import { AdminUser } from "@type/admin.type"
import axiosBase from "./base.service"
const USERS_PATH = "/users"

const AdminService = {
  findAllUsers: async ({
    profile,
    search,
    status,
    type,
    page,
  }: AdminUser.Params): Promise<AdminUser.PaginatedAdminUsers> => {
    const response = await axiosBase.get(USERS_PATH, {
      params: {
        type,
        status,
        profile,
        search,
        page,
      },
    })
    if (!response.data) {
      throw new Error("Error getting users details")
    }
    return response.data.data
  },
  findUser: async (id: string): Promise<AdminUser.Item> => {
    const response = await axiosBase.get(`${USERS_PATH}/${id}`)
    if (!response.data) {
      throw new Error("Error getting user details")
    }
    return response.data.data
  },
  updateUser: async (
    id: string,
    fullName: string,
    document: string,
    type: AdminUser.Type,
    email: string,
    phone: string
  ): Promise<AdminUser.Item> => {
    const response = await axiosBase.put(`${USERS_PATH}/${id}`, {
      fullName,
      document,
      type,
      email,
      phone,
    })
    if (!response.data) {
      throw new Error("Error updating user")
    }
    return response.data.data
  },
  postUser: async (
    body: Omit<AdminUser.Item, "id">
  ): Promise<AdminUser.Item> => {
    const response = await axiosBase.post(`${USERS_PATH}`, body)
    if (!response.data) {
      throw new Error("Error creating user")
    }
    return response.data.data
  },
  putUser: async (
    body: Omit<AdminUser.Item, "id">,
    id: string
  ): Promise<AdminUser.Item> => {
    const response = await axiosBase.put(`${USERS_PATH}/${id}`, body)
    if (!response.data) {
      throw new Error("Error updating user")
    }
    return response.data.data
  },
}

export default AdminService
