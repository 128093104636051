import { useAuth } from "@hooks/use-auth"
import { Roles } from "@utils/ability"

export function useRole() {
  const { user } = useAuth()
  return {
    isAdmin: user?.role === Roles.admin,
    isSecretary: user?.role === Roles.secretary,
    isSpecialist: user?.role === Roles.specialist,
    isUser: user?.role === Roles.user,
  }
}
