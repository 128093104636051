import { ReactNode } from "react"
import { HiFlag } from "react-icons/hi"

type NoDataComponentProps = {
  description?: string | ReactNode
  icon?: ReactNode
}

const NoDataComponent = ({ description, icon }: NoDataComponentProps) => {
  return (
    <div className="py-16 w-full flex justify-center items-center flex-col">
      <div className="flex items-center justify-center bg-primary-100 h-[48px] w-[48px] rounded-full mb-4">
        {icon || <HiFlag fill="#160042" />}
      </div>
      {typeof description === "string" ? (
        <p className="text-sm text-center text-primary-900 font-medium line-h[2rem]">
          {description || "Nenhuma atualização por enquanto."}
        </p>
      ) : (
        description
      )}
    </div>
  )
}

export default NoDataComponent
