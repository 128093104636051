import DOMPurify from "dompurify"

export function escapeHtml(unsafe: string) {
  return unsafe
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;")
}

export function addSpanTags(inputString: string): string {
  const replacedString = inputString.replace(
    /<span.*?>({{(.*?)}})<\/span>/g,
    "$1"
  )
  const finalString = replacedString.replace(
    /{{(.*?)}}/g,
    (s: unknown, p1: string) =>
      '<span style="color: blue">{{' + escapeHtml(p1) + "}}</span>"
  )
  return finalString
}

export function sanitizeAndSaveContent(html: string) {
  const sanitizedHTML = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: [
      "p",
      "br",
      "b",
      "i",
      "u",
      "ul",
      "ol",
      "li",
      "a",
      "h1",
      "h2",
      "img",
      "span",
    ],
    ALLOWED_ATTR: ["href", "src"],
  })
  return sanitizedHTML
}
