import React, { useEffect, useState } from "react"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { Navbar } from "flowbite-react"
import logoSmall from "@assets/images/logo-brand.svg"
import BrandImage from "@components/brand-image"
import FullLoading from "@components/full-loading"
import { useAuth } from "@hooks/use-auth"
import { MAIN_WEBSITE_LINK } from "@constants/links"

const AuthLayout: React.FC = () => {
  const { isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const [showLoading, setShowLoading] = useState(true)
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/")
    } else {
      setTimeout(() => {
        setShowLoading(false)
      }, 2000)
    }
  }, [])
  return showLoading ? (
    <FullLoading />
  ) : (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="grid lg:h-screen lg:grid-cols-2">
          <div className="flex md:hidden border-b">
            <Navbar>
              <Navbar.Brand>
                <BrandImage src={logoSmall} className="mr-4 h-8" />
              </Navbar.Brand>
            </Navbar>
          </div>
          <div className="items-center justify-center px-4 py-6 bg-primary-600 lg:py-0 sm:px-0 hidden sm:hidden md:flex lg:hidden">
            <div className="max-w-md xl:max-w-xl">
              <BrandImage className="h-20 mr-2" />
            </div>
          </div>
          <div className="flex items-center justify-center px-1 md:px-4 py-6 lg:py-0 sm:px-0">
            <Outlet />
          </div>
          <div className="items-center justify-center px-4 py-6 bg-primary-600 lg:py-0 sm:px-0 hidden lg:flex">
            <div className="max-w-md xl:max-w-xl">
              <Link to={MAIN_WEBSITE_LINK} target="_blank">
                <BrandImage className="h-20 mr-2" />
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AuthLayout
