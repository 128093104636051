import PencilDetailsIcon from "@assets/icons/pencilDetails"
import { customTableTheme } from "@theme/custom"
import { AdminUser } from "@type/admin.type"
import { Flowbite, Table } from "flowbite-react"
import { HiEye, HiSelector } from "react-icons/hi"

type AdminUsersTableType = {
  getUser: (id: string) => void
  toggleSortingOrder: () => void
  filtered: AdminUser.Item[]
  type: AdminUser.Type
}

export default function AdminUsersTable({
  getUser,
  toggleSortingOrder,
  filtered,
  type,
}: AdminUsersTableType) {
  return (
    <Flowbite theme={{ theme: customTableTheme }}>
      <Table className="px-4">
        <Table.Head className="uppercase text-gray-500 text-xs font-semibold">
          <Table.HeadCell>ID</Table.HeadCell>
          <Table.HeadCell>Nome</Table.HeadCell>
          <Table.HeadCell>Perfil</Table.HeadCell>
          <Table.HeadCell className="min-w-[150px]">
            Tipo de Pessoa
          </Table.HeadCell>
          <Table.HeadCell>E-mail</Table.HeadCell>
          <Table.HeadCell>
            <span
              className="flex items-center cursor-pointer"
              onClick={toggleSortingOrder}
            >
              Status <HiSelector className="ml-0.5" size={16} />
            </span>
          </Table.HeadCell>
          <Table.HeadCell>
            <span className="sr-only">Editar</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body className="divide-y">
          {filtered.map((user) => (
            <Table.Row key={`${user.id}_${user.email}_user_admin`}>
              <Table.Cell className="text-gray-900 font-medium text-sm">
                {user.id}
              </Table.Cell>
              <Table.Cell className="text-gray-900 font-medium text-sm whitespace-no-wrap min-w-[150px]">
                {user.fullName}
              </Table.Cell>
              <Table.Cell className="text-gray-900 font-medium text-sm">
                {user.profile == AdminUser.Profile.SECRETARY
                  ? "Responsável"
                  : "Especialista"}
              </Table.Cell>
              <Table.Cell className="text-gray-900 font-medium text-sm">
                {user.kindOfPerson == AdminUser.KindPerson.PF
                  ? "Física"
                  : "Jurídica"}
              </Table.Cell>
              <Table.Cell className="text-gray-900 font-medium text-sm">
                {user.email}
              </Table.Cell>
              <Table.Cell className="text-xs font-medium">
                {user.status === AdminUser.Status.ENABLE ? (
                  <span className="inline-block px-2 py-1 justify-center items-center rounded-lg bg-green-100 text-green-800">
                    Ativo
                  </span>
                ) : (
                  <span className="inline-block px-2.5 py-1 justify-center items-center rounded-lg bg-gray-100 text-gray-900">
                    Inativo
                  </span>
                )}
              </Table.Cell>
              <Table.Cell>
                {type === AdminUser.Type.INTERNAL ? (
                  <button
                    className="cursor-pointer"
                    onClick={() => getUser(user.id)}
                  >
                    <PencilDetailsIcon />
                  </button>
                ) : (
                  <button
                    className="cursor-pointer"
                    onClick={() => getUser(user.id)}
                  >
                    <HiEye fill="gray-900" />
                  </button>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Flowbite>
  )
}
