export enum NotificationStatus {
  READ = "read",
  UNREAD = "unread",
}

export type Notification = {
  notificationId: number
  agentAvatar: string
  agentName: string
  body: string
  status: NotificationStatus
  createdAt: string
}

export interface NotificationContextType {
  errors: {
    get: boolean
    put: boolean
  }
  notifications: Notification[] | undefined
  changeNotificationStatus: (
    notificationId: number,
    status: NotificationStatus
  ) => void
}
