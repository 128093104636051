import type { CustomFlowbiteTheme } from "flowbite-react"

export const customTableTheme: CustomFlowbiteTheme = {
  table: {
    root: {
      base: "w-full text-left text-sm text-gray-500 dark:text-gray-400",
      wrapper: "relative overflow-x-auto",
    },
    head: {
      base: "h-[54px] group/head text-xs uppercase text-gray-700 dark:text-gray-400",
      cell: {
        base: "h-[54px] bg-gray-50 dark:bg-gray-700 px-4",
      },
    },
    row: {
      hovered: "hover:bg-gray-50 dark:hover:bg-gray-600",
      striped:
        "odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700",
    },
  },
}

export const modalFormTheme = (isMobile: boolean) => ({
  overlay: {
    zIndex: 9999,
    background: "rgba(0, 0, 0, 0.20)",
  },
  content: {
    zIndex: 9999,
    top: "0",
    right: "0",
    left: "auto",
    bottom: "auto",
    width: isMobile ? "80vw" : "716px",
    height: "100%",
    margin: "0",
    padding: "20px",
    border: "none",
    borderRadius: "0px",
  },
})

export const customButton: CustomFlowbiteTheme = {
  button: {
    base: "h-[34px] bg-primary-700 focus:ring-primary-300 group flex items-center justify-center p-0.5 text-center font-medium focus:z-10",
    color: {
      primary:
        "text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800",
      secondary: "border bg-white hover:bg-gray-100 sm:w-full w-full",
    },

    outline: {
      on: "transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit",
    },
    size: {
      md: "text-sm px-3 py-2",
    },
  },
}
