import { useNavigate } from "react-router-dom"
import Image from "../../assets/images/404.png"
import Logo from "../../assets/images/logo.svg"
const { VITE_CONTACT_WHATSAPP } = import.meta.env

const Page404 = () => {
  const navigate = useNavigate()

  return (
    <div className="bg-gray-50 h-full w-full">
      <nav className="h-[106px] w-full p-8">
        <img
          className="h-[42px] cursor-pointer"
          src={Logo}
          alt="Arbitralis Logo"
          onClick={() => navigate("/")}
        />
      </nav>
      <div className="w-full flex items-center justify-center">
        <div className="w-full bg-white flex lg:items-center lg:justify-center mx-8">
          <div className="flex flex-col lg:flex-row px-12 md:px-8 xl:px-0 py-12 md:py-8 lg:py-24 lg:max-w-[1280px]">
            <div className="flex-1 md:min-w-[610px] ">
              <h5 className="text-primary-600 text-lg md:text-2xl font-bold leading-9 tracking-tight mb-2">
                Erro 404
              </h5>
              <h1 className="md:max-w-[610px] text-gray-900 text-2xl md:text-4xl font-extrabold mb-8 md:leading-[3.375rem]">
                Desculpe, não conseguimos encontrar essa página.
              </h1>
              <p className="text-gray-600 text-sm font-normal leading-[1.3rem]">
                Pode ser que o sistema esteja temporariamente indisponível ou
                que o link seja inválido. <br />
                Por favor, entre em contato conosco caso o erro continue.
              </p>
              <div className="mt-8 text-gray-500 text-sm font-medium leading-5 flex gap-4">
                <a
                  className="cursor-pointer underline"
                  onClick={() => navigate("/")}
                >
                  Página inicial
                </a>
                <a
                  className="cursor-pointer underline"
                  target="_blank"
                  href={VITE_CONTACT_WHATSAPP}
                  rel="noreferrer"
                >
                  Canal de atendimento
                </a>
              </div>
            </div>
            <div className="hidden md:flex w-full max-w-[250px] lg:max-w-[468px] lg:mx-auto">
              <img
                className="w-full h-auto"
                src={Image}
                alt="Duas pessoas, um homem e uma mulher, segurando plugs de energia, prontos para conectar e canalizar seu poder coletivo."
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page404
