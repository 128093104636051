import { DownloadIcon, SearchIcon } from "@assets/icons"
import { CardComponent } from "@components/card"
import CheckboxFilter from "@components/checkbox-filter"
import ErrorComponent from "@components/error"
import ModalConfirm from "@components/modal-confirm"
import { modalConfirmTheme } from "@components/modal-confirm/style"
import NoDataComponent from "@components/no-data"
import { Pagination } from "@components/pagination"
import ToastComponent from "@components/toast"
import ConciliationActionsDropdown from "@features/conciliation/pages/main/components/actions.dropdown"
import ConciliationAssignableModal from "@features/conciliation/pages/main/components/assignable.modal"
import ConciliationCompleteProcessModal from "@features/conciliation/pages/main/components/complete.process.modal"
import DateInput from "@features/conciliation/pages/main/components/date.input"
import ConciliationExport from "@features/conciliation/pages/main/components/export"
import ConciliationSendMessage from "@features/conciliation/pages/main/components/send.message"
import ConciliationMainTable from "@features/conciliation/pages/main/components/table"
import ConciliationUpdateModal from "@features/conciliation/pages/main/components/update.modal"
import { useAuth } from "@hooks/use-auth"
import { useRole } from "@hooks/use-role"
import { useScreenDetect } from "@hooks/use-screen-detect"
import ConciliationBatchService from "@services/conciliation-batch.service"
import ConciliationStatusService from "@services/conciliation-status.service"
import ConciliationService from "@services/conciliation.service"
import { Assignable, Conciliation } from "@type/conciliation.type"
import { ProcessActions } from "@utils/helpers/constants"
import { Button, Dropdown, Label, Radio } from "flowbite-react"
import React, { useCallback, useEffect, useState } from "react"
import {
  HiArrowLeft,
  HiArrowRight,
  HiFilter,
  HiLockClosed,
} from "react-icons/hi"
import Modal from "react-modal"
import DatePicker from "tailwind-datepicker-react"
import { IOptions } from "tailwind-datepicker-react/types/Options"
const { VITE_CONTACT_WHATSAPP } = import.meta.env

type AscendingProps = { status: number; date: boolean; statusPayment: number }

type DateFilterProps = {
  processEntryStartDate?: Date
  processEntryEndDate?: Date
  specialistAppointmentStartDate?: Date
  specialistAppointmentEndDate?: Date
}

function ConciliationMainPage() {
  const { user } = useAuth()
  const { isMobile } = useScreenDetect()
  const [ascending, setAscending] = useState<AscendingProps>({
    status: 0,
    statusPayment: 0,
    date: false,
  })
  const [conciliationPage, setConciliationPage] =
    useState<Conciliation.Paginated>()
  const [conciliationStatusList, setConciliationStatusList] = useState<
    Conciliation.Status[]
  >([])
  const [assignable, setAssignable] = useState<Assignable.Item[]>([])
  const [initialState, setInitialState] = useState<Conciliation.Item[]>([])
  const [filteredData, setFilteredData] = useState<Conciliation.Item[]>([])
  const [loading, setLoading] = useState(false)
  const [date, setDate] = useState<DateFilterProps>(defaultDate)
  const [show, setShow] = useState(defaultShowDate)
  const [modal, setModal] = useState(defaultModal)
  const [error, setError] = useState(defaultError)
  const [selectedItems, setSelectedItems] = useState<string[]>([])
  const [conciliationStatusId, setConciliationStatusId] = useState<number[]>([])
  const [conciliationSearch, setConciliationSearch] = useState<string>("")
  const [allFilter, setAllFilter] = useState<string>("All")
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { isAdmin, isSpecialist } = useRole()

  const fetchData = async (page: number) => {
    setLoading(true)
    await ConciliationService.findAll(
      page,
      allFilter,
      conciliationStatusId.length > 0 ? conciliationStatusId : undefined,
      conciliationSearch ?? undefined,
      date.processEntryStartDate ?? undefined,
      date.processEntryEndDate ?? undefined,
      date.specialistAppointmentStartDate ?? undefined,
      date.specialistAppointmentEndDate ?? undefined
    )
      .then((data) => {
        setConciliationPage(data)
        setFilteredData(data?.items)
        setInitialState(data?.items)
        setError({ ...defaultError, fetchData: false })
      })
      .catch(() => setError({ ...defaultError, fetchData: true }))
      .finally(() => setLoading(false))
  }

  const handleFilterChange = useCallback((selectedOptions: number[]) => {
    setConciliationStatusId(selectedOptions)
  }, [])

  const fetchConciliationStatus = async () => {
    const resp = await ConciliationStatusService.findAll()
    setConciliationStatusList(resp)
  }

  const onChangeSearch = useCallback((search: string) => {
    setConciliationSearch(search)
  }, [])

  useEffect(() => {
    fetchData(currentPage)
  }, [allFilter, conciliationStatusId])

  useEffect(() => {
    if (
      (date.processEntryStartDate && date.processEntryEndDate) ||
      (date.specialistAppointmentStartDate && date.specialistAppointmentEndDate)
    ) {
      fetchData(currentPage)
    }
  }, [
    date.processEntryStartDate,
    date.processEntryEndDate,
    date.specialistAppointmentStartDate,
    date.specialistAppointmentEndDate,
  ])

  useEffect(() => {
    fetchConciliationStatus()
  }, [])

  const onNavigatePage = useCallback((toPage: number) => {
    setCurrentPage(toPage)
    fetchData(toPage)
  }, [])

  const onFilterRadioValueChange = (ev: React.FormEvent<HTMLInputElement>) => {
    setAllFilter(ev.currentTarget.value)
  }

  const onCheck = (item: Conciliation.Item) => {
    if (selectedItems.includes(item.conciliationNumber)) {
      return setSelectedItems((prev) =>
        prev.filter((x) => x !== item.conciliationNumber)
      )
    }
    return setSelectedItems((prev) => [...prev, item.conciliationNumber])
  }

  const exportTable = () => {
    ConciliationBatchService.exportExcel(selectedItems)
      .catch(() => setError((prev) => ({ ...prev, excel: true })))
      .finally(() => setModal({ ...defaultModal, isConfirmationModal: false }))
    // TBD: implement open the excel URL in a new tab
  }

  const sendMessage = async () => {
    // TBD: implement to send messages
  }

  const toggleSortingCreatedDate = useCallback(() => {
    setFilteredData((prev) => {
      const sortedEntries = [...prev].sort((a, b) => {
        const sortOrder = ascending.date ? 1 : -1
        return (
          sortOrder *
          (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
        )
      })
      setAscending((prev) => ({ ...prev, date: !prev.date }))
      return sortedEntries
    })
  }, [ascending.date])

  const toggleSortingStatus = () => {
    setFilteredData((prev) => {
      if (ascending.status === 2) {
        setAscending((prev) => ({ ...prev, status: 0 }))
        return initialState
      }

      const sortedEntries = [...prev].sort((a, b) => {
        const sortOrder = ascending.status === 0 ? -1 : 1
        return sortOrder * a.status.name.localeCompare(b.status.name)
      })

      setAscending((prev) => ({ ...prev, status: prev.status + 1 }))
      return sortedEntries
    })
  }

  const toggleSortingStatusPayment = () => {
    setFilteredData((prev) => {
      if (ascending.statusPayment === 2) {
        setAscending((prev) => ({ ...prev, statusPayment: 0 }))
        return initialState
      }

      const sortedEntries = [...prev].sort((a, b) => {
        const sortOrder = ascending.statusPayment === 0 ? -1 : 1
        return (
          sortOrder * a.statusPayment.name.localeCompare(b.statusPayment.name)
        )
      })

      setAscending((prev) => ({
        ...prev,
        statusPayment: prev.statusPayment + 1,
      }))
      return sortedEntries
    })
  }

  const postNotifyRequired = () => {
    ConciliationBatchService.postNotification(selectedItems)
      .catch(() => setError((prev) => ({ ...prev, notification: true })))
      .finally(() => setModal(defaultModal))
  }

  const handleProcessActions = useCallback((option: string) => {
    if (option === ProcessActions.ADD_UPDATE) {
      setModal({ ...defaultModal, isAddUpdateModal: true })
    }

    if (option === ProcessActions.NOTIFY_REQUIRED) {
      setModal({ ...defaultModal, isNotify: true })
    }

    if (option === ProcessActions.DEFINE_RESPONSIBLE) {
      setAssignable([])
      ConciliationService.getAssignable(Assignable.Type.SECRETARY).then(
        (data) => setAssignable(data)
      )
      setModal({ ...defaultModal, isDefineResponsible: true })
    }

    if (option === ProcessActions.DEFINE_SPECIALIST) {
      setAssignable([])
      ConciliationService.getAssignable(Assignable.Type.SPECIALIST).then(
        (data) => setAssignable(data)
      )
      setModal({ ...defaultModal, isDefineSpecialist: true })
    }

    if (option === ProcessActions.FINISH_PROCESS) {
      setModal({ ...defaultModal, isFinishProcess: true })
    }
  }, [])

  const completeProcess = (
    reason: string,
    observation: string,
    handleFinally: () => void
  ) => {
    ConciliationBatchService.completeProcess(selectedItems, reason, observation)
      .catch(() => setError({ ...defaultError, completeProcess: true }))
      .finally(() => handleFinally())
  }

  return (
    <>
      {Object.entries(error)
        .filter(([key]) => key !== "accessPermission")
        .some(([, err]) => Boolean(err)) && <ToastComponent />}
      <h1 className="text-3xl font-bold lg:ml-0 md:ml-0 sm:ml-8 ml-8 ">
        Meus Processos
      </h1>
      <CardComponent
        header={
          <div className="mb-4">
            <div className="flex md:flex-row flex-col h-full md:items-end gap-4 md:flex-wrap">
              <div className="min-w-full relative md:w-2/5 md:min-w-[375px] max-w-[375px]">
                <div className="w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <SearchIcon className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                  </div>
                  <input
                    onChange={(e) => onChangeSearch(e.target.value)}
                    type="search"
                    value={conciliationSearch}
                    className="h-[37px] block w-full p-2.5 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Busque por nº do processo, CPF ou nome"
                  />
                </div>
                <button
                  disabled={conciliationSearch === ""}
                  onClick={() => fetchData(currentPage)}
                  className="h-[37px] absolute md:flex items-center hidden top-0 right-0 p-2.5 text-sm font-medium text-white bg-primary-700 rounded-r-lg border border-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  <SearchIcon className="w-5 h-5" />
                  <span className="sr-only">Buscar</span>
                </button>
              </div>
              <button
                type="submit"
                className="block md:mt-4 md:hidden p-2.5 text-sm text-white bg-primary-700 rounded border border-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Buscar
              </button>
              <div className="flex min-w-[160px]">
                <Dropdown
                  color="secondary"
                  outline={false}
                  label="Filtrar por status"
                  dismissOnClick={false}
                  className="p-2.5 pt-4"
                  size="sm"
                >
                  <Label className="flex">Filtrar por status</Label>
                  <CheckboxFilter
                    statusList={conciliationStatusList}
                    selectedIds={conciliationStatusId}
                    onFilterChange={handleFilterChange}
                  />
                </Dropdown>
              </div>
              {(isAdmin || isSpecialist) && (
                <div className="flex gap-4 flex-col md:flex-row">
                  <div>
                    <p className="text-sm text-gray-600 font-medium mb-2">
                      Entrada do processo na câmara
                    </p>
                    <div className="flex gap-4">
                      <div className="md:w-[160px] h-[37px] w-full">
                        {!date.processEntryStartDate &&
                        !show.processEntryStartDate ? (
                          <DateInput
                            value=""
                            placeholder="Data inicial"
                            onClick={() => {
                              setShow((prev) => ({
                                ...prev,
                                processEntryStartDate: true,
                              }))
                            }}
                          />
                        ) : (
                          <DatePicker
                            options={options}
                            show={show.processEntryStartDate}
                            value={date.processEntryStartDate}
                            setShow={(state: boolean) => {
                              setShow((prev) => ({
                                ...prev,
                                processEntryStartDate: state,
                              }))
                            }}
                            onChange={(selectedDate: Date) => {
                              setDate((prev) => ({
                                ...prev,
                                processEntryStartDate: selectedDate,
                              }))
                              setShow((prev) => ({
                                ...prev,
                                processEntryStartDate: false,
                                processEntryEndDate: true,
                              }))
                            }}
                          />
                        )}
                      </div>
                      <div className="md:w-[160px] h-[37px] w-full">
                        {!date.processEntryEndDate &&
                        !show.processEntryEndDate ? (
                          <DateInput
                            value=""
                            disabled={!date.processEntryStartDate}
                            placeholder="Data final"
                            onClick={() => {
                              setShow((prev) => ({
                                ...prev,
                                processEntryEndDate: true,
                              }))
                            }}
                            style={
                              !date.processEntryStartDate
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }
                            containerStyle={
                              !date.processEntryStartDate
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }
                          />
                        ) : (
                          <DatePicker
                            show={show.processEntryEndDate}
                            value={date.processEntryEndDate}
                            options={{
                              ...options,
                              minDate: date.processEntryStartDate,
                            }}
                            setShow={(state: boolean) => {
                              if (!date.processEntryStartDate) {
                                return
                              }
                              setShow((prev) => ({
                                ...prev,
                                processEntryEndDate: state,
                              }))
                            }}
                            onChange={(selectedDate: Date) => {
                              setDate((prev) => ({
                                ...prev,
                                processEntryEndDate: selectedDate,
                              }))
                              setShow((prev) => ({
                                ...prev,
                                processEntryStartDate: false,
                                processEntryEndDate: false,
                              }))
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className="text-sm text-gray-600 font-medium mb-2">
                      Nomeação do especialista
                    </p>
                    <div className="flex gap-4">
                      <div className="md:w-[160px] h-[37px] w-full">
                        {!date.specialistAppointmentStartDate &&
                        !show.specialistAppointmentStartDate ? (
                          <DateInput
                            value=""
                            placeholder="Data inicial"
                            onClick={() => {
                              setShow((prev) => ({
                                ...prev,
                                specialistAppointmentStartDate: true,
                              }))
                            }}
                          />
                        ) : (
                          <DatePicker
                            options={options}
                            show={show.specialistAppointmentStartDate}
                            value={date.specialistAppointmentStartDate}
                            setShow={(state: boolean) => {
                              setShow((prev) => ({
                                ...prev,
                                specialistAppointmentStartDate: state,
                              }))
                            }}
                            onChange={(selectedDate: Date) => {
                              setDate((prev) => ({
                                ...prev,
                                specialistAppointmentStartDate: selectedDate,
                              }))
                              setShow((prev) => ({
                                ...prev,
                                specialistAppointmentEndDate: true,
                              }))
                            }}
                          />
                        )}
                      </div>
                      <div
                        className={`md:w-[160px] h-[37px] w-full cursor-pointer`}
                      >
                        {!date.specialistAppointmentEndDate &&
                        !show.specialistAppointmentEndDate ? (
                          <DateInput
                            value=""
                            disabled={!date.specialistAppointmentStartDate}
                            placeholder="Data final"
                            onClick={() => {
                              setShow((prev) => ({
                                ...prev,
                                specialistAppointmentEndDate: true,
                              }))
                            }}
                            style={
                              !date.specialistAppointmentStartDate
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }
                            containerStyle={
                              !date.specialistAppointmentStartDate
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }
                          />
                        ) : (
                          <DatePicker
                            options={{
                              ...options,
                              minDate: date.specialistAppointmentStartDate,
                            }}
                            show={show.specialistAppointmentEndDate}
                            value={date.specialistAppointmentEndDate}
                            setShow={(state: boolean) => {
                              if (!date.specialistAppointmentStartDate) {
                                return
                              }
                              setShow((prev) => ({
                                ...prev,
                                specialistAppointmentEndDate: state,
                              }))
                            }}
                            onChange={(selectedDate: Date) => {
                              setDate((prev) => ({
                                ...prev,
                                specialistAppointmentEndDate: selectedDate,
                              }))
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
            <div className="flex px-4 mb-4 md:mb-0">
              <div className="flex items-center gap-2">
                <span className="text-sm">Mostrar</span>
              </div>
              <div className="flex items-center gap-2 ml-4">
                <Radio
                  className="checked:bg-primary-600"
                  radioGroup="filter"
                  value="All"
                  name="filter"
                  id="filter-all"
                  onChange={onFilterRadioValueChange}
                  checked={allFilter === "All" || !allFilter}
                />
                <Label htmlFor="filter-all">Tudo</Label>
              </div>
              <div className="flex items-center gap-2 ml-4">
                <Radio
                  className="checked:bg-primary-600"
                  name="filter"
                  id="filter-action-pending"
                  radioGroup="filter"
                  onChange={onFilterRadioValueChange}
                  checked={allFilter === "ActionPending"}
                  value="ActionPending"
                />
                <Label htmlFor="filter-action-pending">Pendentes de ação</Label>
              </div>
            </div>
            {(isAdmin || isSpecialist) && (
              <>
                <div className="md:hidden mb-4">
                  <ConciliationActionsDropdown
                    handleProcessActions={handleProcessActions}
                  />
                </div>
                <div className="flex justify-between md:hidden mb-4 md:mb-0">
                  <ConciliationSendMessage sendMessage={sendMessage} />
                  <ConciliationExport
                    exportTable={() =>
                      setModal({ ...defaultModal, isConfirmationModal: true })
                    }
                  />
                </div>
              </>
            )}
          </div>
        }
        body={
          error?.fetchData ? (
            <>
              <hr className="h-px my-4 bg-gray-200 border-0" />
              <ErrorComponent />
            </>
          ) : (
            <>
              {loading ? null : filteredData?.length !== 0 ? (
                <ConciliationMainTable
                  onCheck={onCheck}
                  setError={setError}
                  filteredData={filteredData}
                  selectedItems={selectedItems}
                  toggleSortingStatus={toggleSortingStatus}
                  toggleSortingStatusPayment={toggleSortingStatusPayment}
                  conciliationStatusList={conciliationStatusList}
                  toggleSortingCreatedDate={toggleSortingCreatedDate}
                  conciliationSendMessage={
                    <ConciliationSendMessage sendMessage={sendMessage} />
                  }
                  conciliationExport={
                    <ConciliationExport
                      exportTable={() =>
                        setModal({ ...defaultModal, isConfirmationModal: true })
                      }
                    />
                  }
                  conciliationActionsDropdown={
                    <ConciliationActionsDropdown
                      handleProcessActions={handleProcessActions}
                    />
                  }
                />
              ) : (
                <NoDataComponent
                  icon={<HiFilter size={24} fill="#160042" />}
                  description={
                    <p className="text-sm text-center text-primary-900">
                      Nenhum resultado para o filtro que você aplicou. <br />
                      Tente novamente usando filtros de pesquisa diferentes.
                    </p>
                  }
                />
              )}
            </>
          )
        }
        footer={
          <>
            {filteredData?.length !== 0 && (
              <Pagination
                onNavigatePage={onNavigatePage}
                currentPage={currentPage}
                currentTotal={conciliationPage?.items.length ?? 0}
                itemsPerPage={conciliationPage?.itemsPerPage ?? 10}
                pages={conciliationPage?.pages ?? 0}
                total={conciliationPage?.count ?? 0}
              />
            )}
          </>
        }
      />
      <Modal
        isOpen={error.accessPermission}
        onRequestClose={() =>
          setError((prev) => ({ ...prev, accessPermission: false }))
        }
        style={modalConfirmTheme(isMobile, "640px")}
      >
        <ModalConfirm
          title={
            <h5 className="text-gray-900 text-lg font-semibold border-b pb-4 mb-5 flex items-center gap-2">
              <HiLockClosed />
              Processo vinculado ao seu CPF
            </h5>
          }
          description={
            <div className="text-base font-normal text-gray-700 mb-5">
              <p>
                Para garantir a segurança e o sigilo de todas as partes
                envolvidas, a visualização deste processo está temporariamente
                restrita.
              </p>
              <p className="my-4 font-bold leading-150">
                Para acessá-lo, por favor verifique o link enviado ao seu e-mail{" "}
                {user?.email}.
              </p>
              <p>
                Se você não recebeu o e-mail ou não usa mais este endereço, por
                favor, entre em contato conosco.
              </p>
            </div>
          }
          buttonInput={
            <Button
              as="a"
              size="sm"
              color="gray"
              className="w-fit text-gray-700 text-sm font-normal border-primary-700 h-[41px]"
              href={VITE_CONTACT_WHATSAPP}
              target="_blank"
              rel="noopener noreferrer"
            >
              Fale conosco
            </Button>
          }
        />
      </Modal>
      {isAdmin && (
        <>
          <Modal
            isOpen={modal.isConfirmationModal}
            onRequestClose={() => setModal(defaultModal)}
            style={modalConfirmTheme(isMobile, "640px")}
          >
            <ModalConfirm
              title={
                <div className="flex items-center text-gray-900 border-b pb-4 mb-5">
                  <span className="mr-2">
                    <DownloadIcon />
                  </span>
                  <h2 className="text-lg font-semibold">
                    Deseja exportar todos os processos selecionados?
                  </h2>
                </div>
              }
              description={
                <>
                  <p className="text-gray-900 text-sm font-semibold">
                    Ao confirmar, o arquivo será enviado para o e-mail{" "}
                    <span className="text-primary-500">{user?.email}</span>.
                  </p>
                  <p className="text-gray-900 text-sm font-semibold mb-5">
                    Isso pode levar alguns minutos.
                  </p>
                </>
              }
              buttonInput={
                <div className="flex md:col-span-2 gap-4">
                  <Button
                    size="xs"
                    color="primary"
                    className="w-fit text-sm font-medium h-[34px]"
                    onClick={exportTable}
                  >
                    Confirmar
                  </Button>
                  <Button
                    size="xs"
                    color="gray"
                    className="w-fit text-primary-700 text-sm font-medium border-primary-700 h-[34px]"
                    onClick={() => setModal(defaultModal)}
                  >
                    Cancelar
                  </Button>
                </div>
              }
            />
          </Modal>
          <Modal
            isOpen={modal.isNotify}
            onRequestClose={() => setModal(defaultModal)}
            style={modalConfirmTheme(isMobile, "640px")}
          >
            <ModalConfirm
              title={"Notificar partes requeridas"}
              description="Enviaremos a notificação por e-mail e WhatsApp para todos as partes requeridas."
              buttonInput={
                <div className="flex md:col-span-2 gap-4">
                  <Button
                    size="xs"
                    color="primary"
                    className="w-fit text-sm font-medium h-[34px]"
                    onClick={postNotifyRequired}
                  >
                    Enviar
                  </Button>
                  <Button
                    size="xs"
                    color="gray"
                    className="w-fit text-primary-700 text-sm font-medium border-primary-700 h-[34px]"
                    onClick={() => setModal(defaultModal)}
                  >
                    Cancelar
                  </Button>
                </div>
              }
            />
          </Modal>
          <ConciliationUpdateModal
            selectedItems={selectedItems}
            isAddUpdateModalOpen={modal.isAddUpdateModal}
            setIsAddUpdateModalOpen={() => setModal(defaultModal)}
            setError={() => setError({ ...defaultError, addUpdate: true })}
          />
          <ConciliationAssignableModal
            assignable={assignable}
            selectedItems={selectedItems}
            isDefineResponsibleOpen={modal.isDefineResponsible}
            setIsDefineResponsibleOpen={() => setModal(defaultModal)}
            isDefineSpecialistOpen={modal.isDefineSpecialist}
            setIsDefineSpecialistOpen={() => setModal(defaultModal)}
            setError={() => setError({ ...defaultError, postAssignable: true })}
          />
          <ConciliationCompleteProcessModal
            onComplete={completeProcess}
            isFinishProcessOpen={modal.isFinishProcess}
            setIsFinishProcessOpen={() => setModal(defaultModal)}
          />
        </>
      )}
    </>
  )
}

export default ConciliationMainPage

const options = {
  autoHide: true,
  todayBtn: true,
  clearBtn: true,
  clearBtnText: "Limpar",
  todayBtnText: "Hoje",
  theme: {
    background: "white",
    todayBtn: "bg-primary-700",
    clearBtn: "white",
    icons: "white",
    text: "white hover:text-white hover:bg-primary-700",
    disabledText: "black",
    input: "white h-[38px] border-gray-300 bg-gray-50",
    inputIcon: "white",
    selected: "bg-primary-700",
  },
  icons: {
    prev: () => (
      <span className="text-sm text-primary-700">
        <HiArrowLeft />
      </span>
    ),
    next: () => (
      <span className="text-sm text-primary-700">
        <HiArrowRight />{" "}
      </span>
    ),
  },
  defaultDate: new Date(),
  language: "pt-BR",
  weekDays: ["Seg", "Ter", "Qua", "Qui", "Sex", "Sab", "Dom"],
  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Data Inicial",
  inputDateFormatProp: {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  },
} as IOptions

const defaultModal = {
  isConfirmationModal: false,
  isAddUpdateModal: false,
  isDefineResponsible: false,
  isDefineSpecialist: false,
  isFinishProcess: false,
  isNotify: false,
}

const defaultError = {
  excel: false,
  postAssignable: false,
  notification: false,
  completeProcess: false,
  addUpdate: false,
  isNotify: false,
  fetchData: false,
  accessPermission: false,
}

const defaultDate = {
  processEntryStartDate: undefined,
  processEntryEndDate: undefined,
  specialistAppointmentStartDate: undefined,
  specialistAppointmentEndDate: undefined,
}

const defaultShowDate = {
  processEntryStartDate: false,
  processEntryEndDate: false,
  specialistAppointmentStartDate: false,
  specialistAppointmentEndDate: false,
}
