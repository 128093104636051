export function formatCpfCnpj(
  number: string,
  type?: "cpf" | "cnpj"
): string | null {
  if (!number) return null

  const digitsOnly = number.replace(/\D/g, "")

  if (type === "cpf") {
    return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
  }

  if (type === "cnpj") {
    return digitsOnly.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    )
  }

  if (number.length > 14) {
    return digitsOnly.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      "$1.$2.$3/$4-$5"
    )
  } else {
    return digitsOnly.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
  }
}
