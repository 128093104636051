import { DownloadIcon } from "@assets/icons"

type ConciliationExportProps = {
  exportTable: () => void
}

function ConciliationExport({ exportTable }: ConciliationExportProps) {
  return (
    <button
      onClick={exportTable}
      className="text-primary-600 text-sm font-normal flex items-center gap-1"
    >
      <DownloadIcon />
      Exportar
    </button>
  )
}

export default ConciliationExport
