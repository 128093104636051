export const formatDate = (
  date: string | Date,
  format = "dd/MM/yyyy HH:mm"
) => {
  const dateObject = new Date(date)

  const formatOptions = {
    dd: dateObject.getDate().toString().padStart(2, "0"),
    MM: (dateObject.getMonth() + 1).toString().padStart(2, "0"),
    MMMM: new Intl.DateTimeFormat("pt", { month: "long" }).format(dateObject),
    yyyy: dateObject.getFullYear().toString(),
    HH: dateObject.getHours().toString().padStart(2, "0"),
    mm: dateObject.getMinutes().toString().padStart(2, "0"),
  }

  if (format === "dd 'de' MMMM 'de' yyyy") {
    return `${formatOptions.dd} de ${formatOptions.MMMM} de ${formatOptions.yyyy}`
  }

  const formattedDate = format.replace(
    /(dd|MM|yyyy|HH|mm)/g,
    (match) => formatOptions[match as keyof typeof formatOptions]
  )

  return formattedDate.replace(/-/g, "/")
}
