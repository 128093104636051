import {
  Applicant,
  ConciliationParts,
  Data,
} from "@features/conciliation/types/conciliation-detail.type"
import { EyeIcon } from "@heroicons/react/24/solid"

export default function ConciliationPartsRender({ data }: Data) {
  return data && data.length > 0 ? (
    <>
      {data.map((applicant: Applicant | ConciliationParts, idx: number) => (
        <div key={`${applicant.name}_${applicant.document}_${idx}`}>
          <p className="text-sm text-gray-600 mt-6 mb-2 uppercase">
            <strong>{applicant.name}</strong>
          </p>
          <p className="mb-2">E-mail: {applicant.mail}</p>
          <p className="mb-2">Telefone: {applicant.phone}</p>
          {applicant.document ? (
            <p className="mb-2.5">CPF: {applicant.document}</p>
          ) : null}
          {applicant &&
            applicant.hits &&
            applicant.hits.map((hit, index: number) => (
              <div className="mt-2" key={`${hit.date}_${hit.ip}_${index}`}>
                <div className="flex items-center gap-2">
                  <EyeIcon className="text-gray-400" width={20} height={20} />
                  <p>Acessos</p>
                </div>
                <p>{hit.date}</p>
                <p>{hit.ip}</p>
              </div>
            ))}
        </div>
      ))}
    </>
  ) : null
}
