import { RoutesConstants as routes } from "@constants/routes"
import AdminModelMainPage from "@features/admin/models/admin-model"
import AdminModelsMainPage from "@features/admin/models/admin-models"
import AdminUsersMainPage from "@features/admin/users/admin-users"
import CreateProcess from "@features/conciliation/pages/create/create.process"
import ConciliationDocumentsUpdate from "@features/conciliation/pages/details-documents-update/conciliation.documents"
import ConciliationDetails from "@features/conciliation/pages/details/conciliation.details"
import ConciliationMainPage from "@features/conciliation/pages/main/index.page"
import NotificationsMainPage from "@features/notifications/pages/main/index.page"
import PaymentMainPage from "@features/payment"
import Pendencies from "@features/pendencies"
import Profile from "@features/profile"
import { ConciliationChatMessagesProvider } from "@hooks/use-conciliation-chat-messages"
import { PaymentsProvider } from "@hooks/use-payments"
import AuthLayout from "@layouts/auth.layout"
import DefaultLayout from "@layouts/default.layout"
import Page404 from "@pages/404"
import Page500 from "@pages/500"
import SignInPage from "@pages/authentication/signin/signin.page"
import SignUpPage from "@pages/authentication/signup/signup.page"
import VerifyEmailPage from "@pages/authentication/verify-email/verify-email.page"
import VerifyLoginPage from "@pages/authentication/verify/verify.page"
import { Actions, Subjects } from "@utils/ability"
import { useContext } from "react"
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"
import { AbilityContext } from "./context/ability.provider"
import * as Sentry from "@sentry/react"
import React from "react"

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  debug: import.meta.env.VITE_SENTRY_ENVIRONMENT === "development",
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // 1.0 Registra todas as transacoes, 0.0 registra nenhum.
  tracesSampleRate: Number(
    import.meta.env.VITE_SENTRY_TRANSACTION_SAMPLE_RATE ?? "0.05"
  ),

  tracePropagationTargets: [
    "localhost",
    import.meta.env.VITE_SENTRY_PROPAGATION_URL ?? "",
  ],

  replaysSessionSampleRate: Number(
    import.meta.env.VITE_SENTRY_REPLAY_ERROR_RATE ?? "0.01"
  ),
  replaysOnErrorSampleRate: Number(
    import.meta.env.VITE_SENTRY_REPLAY_SAMPLE_RATE ?? "0.05"
  ),
})

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function App() {
  const ability = useContext(AbilityContext)
  const PermissionRoute = ({
    action,
    subject,
  }: {
    action: string
    subject: string
  }) => {
    return ability.can(action as Actions, subject as Subjects) ? (
      <Outlet />
    ) : (
      <Navigate to="/404" />
    )
  }

  return (
    <SentryRoutes>
      <Route element={<AuthLayout />}>
        <Route path={routes.signIn} element={<SignInPage />} />
        <Route path={routes.verifyToken} element={<VerifyLoginPage />} />
        <Route path={routes.verifyEmail} element={<VerifyEmailPage />} />
        <Route path={routes.signUp} element={<SignUpPage />} />
      </Route>

      <Route element={<DefaultLayout />}>
        <Route path={routes.home} element={<ConciliationMainPage />} />
        <Route path={routes.conciliations} element={<ConciliationMainPage />} />
        <Route path={routes.conciliationsCreate} element={<CreateProcess />} />
        <Route
          path={`${routes.conciliations}/:conciliationNumber`}
          element={
            <ConciliationChatMessagesProvider>
              <ConciliationDetails />
            </ConciliationChatMessagesProvider>
          }
        />
        <Route
          path={`${routes.conciliations}/:conciliationNumber${routes.models}/:modelId`}
          element={<ConciliationDocumentsUpdate />}
        />
        <Route
          path={routes.notifications}
          element={<NotificationsMainPage />}
        />
        <Route
          path={routes.payment}
          element={
            <PaymentsProvider>
              <PaymentMainPage />
            </PaymentsProvider>
          }
        />
        <Route path={routes.profile} element={<Profile />} />
        <Route element={<PermissionRoute action="read" subject="tasks" />}>
          <Route path={routes.pendencies} element={<Pendencies />} />
        </Route>
        <Route
          element={<PermissionRoute action="manage" subject="admin users" />}
        >
          <Route path={routes.adminUsers} element={<AdminUsersMainPage />} />
        </Route>
        <Route
          element={
            <PermissionRoute action="manage" subject="admin document models" />
          }
        >
          <Route path={routes.adminModels} element={<AdminModelsMainPage />} />
          <Route
            path={`${routes.adminModels}/:modelId`}
            element={<AdminModelMainPage />}
          />
        </Route>
      </Route>
      <Route path={routes.page500} element={<Page500 />} />
      <Route path="*" element={<Page404 />} />
    </SentryRoutes>
  )
}

export default App
