import { CustomFlowbiteTheme, Select } from "flowbite-react"
import React, { ChangeEvent } from "react"

type SelectFilterThemeType = CustomFlowbiteTheme["select"]

type SelectFilterOptionType = { label: string; value: string }

type SelectFilterType = {
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  value: string
  options: SelectFilterOptionType[]
  theme?: SelectFilterThemeType
}

const SelectFilter = ({
  onChange,
  value,
  options,
  theme,
}: SelectFilterType) => {
  return (
    <Select theme={theme || customSelect} onChange={onChange} value={value}>
      {options.map((option) => (
        <option key={`select_filter_${option.label}`} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  )
}

export default SelectFilter

export const customSelect: CustomFlowbiteTheme["select"] = {
  base: "h-[37px]",

  field: {
    base: "h-[37px]",
    select: {
      base: "h-[37px] !py-0",
    },
  },
}
