import CheckboxSemiIcon from "@assets/images/Checkbox.svg"
import { CardComponent } from "@components/card"
import CheckboxFilter from "@components/checkbox-filter"
import ErrorComponent from "@components/error"
import NoDataComponent from "@components/no-data"
import { Pagination } from "@components/pagination"
import { notPaid, usePayments } from "@hooks/use-payments"
import { useRole } from "@hooks/use-role"
import { Payment } from "@type/payment.types"
import { formatPrice, paymentStatus } from "@utils/payment-status.validator"
import { format } from "date-fns"
import { pt } from "date-fns/locale"
import { Badge, Button, Checkbox, Dropdown, Label } from "flowbite-react"

const specialistCustomLabels = {
  [`${Payment.PaymentStatusCode.PAID}`]: "Recebido",
  [`${Payment.PaymentStatusCode.AWAITING_PAYMENT}`]: "À receber",
  [`${Payment.PaymentStatusCode.OVERDUE}`]: "Vencido",
}

function ReceiveSection() {
  const { isAdmin } = useRole()
  const {
    onNavigatePage,
    onCheck,
    isSemiReceiveChecked,
    checkAllReceive,
    handleFilterPaymentStatus,
    fetchRequest,
    paymentStatusList,
    paymentStatusId,
    currentPage,
    receivePage,
    checkListDownload,
    setCheckListDownload,
    filteredReceiveData,
    error,
    processStatusList,
    handleFilterProcessStatus,
    processStatusId,
  } = usePayments()
  return (
    <CardComponent
      header={
        <form>
          <div className="md:flex-row flex-col">
            <div className="md:ml-0 gap-4 md:gap-6 md:mt-0 h-auto w-full flex justify-between flex-wrap">
              <div className="flex gap-4 md:gap-6 flex-wrap">
                <Dropdown
                  color="secondary"
                  label="Status do processo"
                  dismissOnClick={false}
                  className="p-2.5 pt-3.5 md:ml-6 text-sm font-medium"
                  size="sm"
                >
                  <Label>Status do processo</Label>
                  <CheckboxFilter
                    statusList={processStatusList || []}
                    onFilterChange={handleFilterProcessStatus}
                    selectedIds={processStatusId}
                  />
                </Dropdown>
                <Dropdown
                  color="secondary"
                  label={"Status de pagamento"}
                  dismissOnClick={false}
                  className="p-2.5 pt-3.5 md:ml-6 text-sm font-medium"
                  size="sm"
                >
                  <Label>Status de pagamento</Label>
                  <CheckboxFilter
                    statusList={
                      isAdmin
                        ? paymentStatusList?.map((item) => ({
                            ...item,
                            name: specialistCustomLabels[
                              item?.code as keyof typeof specialistCustomLabels
                            ],
                          }))
                        : paymentStatusList
                    }
                    onFilterChange={handleFilterPaymentStatus}
                    selectedIds={paymentStatusId}
                  />
                </Dropdown>
              </div>
              <Button
                outline={true}
                size="sm"
                disabled={!checkListDownload.length}
                className="px-4 dark:text-gray-400 disabled:bg-gray-400 disabled:opacity-100 whitespace-nowrap min-h-[37px] max-h-[37px]"
                color="primary"
                onClick={fetchRequest}
              >
                Gerar boleto
              </Button>
            </div>
          </div>
          <hr className="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
        </form>
      }
      body={
        error?.get ? (
          <ErrorComponent />
        ) : (
          <>
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-gray-500 text-xs font-semibold uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="h-[54px] px-6 py-3 w-10 inline-center"
                  >
                    {!isSemiReceiveChecked() ? (
                      <Checkbox
                        className="checked:bg-primary-600 border-0 cursor-pointer self-center"
                        onChange={checkAllReceive}
                      />
                    ) : (
                      <button
                        className="checked:bg-primary-600 border-0 cursor-pointer self-center"
                        onClick={() => setCheckListDownload([])}
                      >
                        <img src={CheckboxSemiIcon} />
                      </button>
                    )}
                  </th>
                  <th
                    scope="col"
                    className="h-[54px] px-6 py-3 whitespace-nowrap"
                  >
                    Nº DO PROCESSO
                  </th>

                  <th
                    scope="col"
                    className="h-[54px] px-6 py-3 whitespace-nowrap"
                  >
                    REQUERENTE
                  </th>

                  <th
                    scope="col"
                    className="h-[54px] px-6 py-3 whitespace-nowrap"
                  >
                    ESPECIALISTA
                  </th>
                  <th
                    scope="col"
                    className="h-[54px] px-6 py-3 whitespace-nowrap"
                  >
                    VENCIMENTO
                  </th>
                  <th scope="col" className="h-[54px] px-6 py-3">
                    STATUS DO PROCESSO
                  </th>

                  <th scope="col" className={`h-[54px] px-6 py-3`}>
                    STATUS DE PAGAMENTO
                  </th>
                  <th scope="col" className="h-[54px] px-6 py-3 w-[250px]">
                    VALOR
                  </th>
                </tr>
              </thead>
              {filteredReceiveData?.length > 0 ? (
                <tbody>
                  {filteredReceiveData?.map((item) => {
                    const status = paymentStatus(
                      item.status?.code,
                      paymentStatusList
                    )
                    return (
                      <tr
                        key={item.id}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        <td className="h-[54px] px-6">
                          {notPaid(item) ? (
                            <Checkbox
                              className="checked:bg-primary-600 border cursor-pointer self-center"
                              checked={checkListDownload.includes(item.id)}
                              disabled={!notPaid(item)}
                              onChange={() => onCheck(item)}
                            />
                          ) : (
                            <div />
                          )}
                        </td>
                        <td className="h-[54px] px-6 text-gray-900">
                          {item.id === -1 ? (
                            <strong className="text-sm font-medium">
                              {item?.conciliation?.code}
                            </strong>
                          ) : (
                            item?.conciliation.code
                          )}
                        </td>
                        <td className="h-[54px] px-6 text-gray-900">
                          {item.conciliation.defendant}
                        </td>
                        <td className="h-[54px] px-6 text-gray-900">
                          {item.conciliation.specialist}
                        </td>
                        <td className="h-[54px] px-6 w-[250px] whitespace-nowrap text-gray-900">
                          {item?.dueDate
                            ? format(
                                new Date(item?.dueDate),
                                "dd/MM/yyyy HH:mm",
                                { locale: pt }
                              )
                            : ""}
                        </td>
                        <td className="h-[54px] px-6">
                          {item?.id !== -1 ? (
                            <Badge
                              size="xs"
                              color={item?.conciliation?.status?.color}
                              className={`flex justify-center rounded-md w-fit whitespace-nowrap`}
                            >
                              {item?.conciliation?.status?.name}
                            </Badge>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className="h-[54px] px-6">
                          {item?.id !== -1 ? (
                            <Badge
                              size="xs"
                              color={status?.color}
                              className={`flex justify-center rounded-md w-fit whitespace-nowrap`}
                            >
                              {status?.name}
                            </Badge>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className="h-[54px] px-6 text-gray-900">
                          {item?.id === -1 ? (
                            <strong className="text-lg">
                              {formatPrice(item.amount)}
                            </strong>
                          ) : (
                            formatPrice(item.amount)
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              ) : null}
            </table>
            {filteredReceiveData?.length === 0 && (
              <NoDataComponent description="Nenhuma fatura foi gerada ainda." />
            )}
          </>
        )
      }
      footer={
        <Pagination
          onNavigatePage={onNavigatePage}
          currentPage={currentPage}
          currentTotal={receivePage?.items.length ?? 0}
          itemsPerPage={receivePage?.itemsPerPage ?? 10}
          pages={receivePage?.pages ?? 0}
          total={receivePage?.count ?? 0}
        />
      }
    />
  )
}

export default ReceiveSection
