import { JWTTokenPayload } from "@type/token.type"

const TokenStorage = {
  AUTH_TOKEN: "AUTH_TOKEN",
  AUTH_TOKEN_TTL: "AUTH_TOKEN_TTL",
  AUTH_REFRESH_TOKEN: "AUTH_REFRESH_TOKEN",
  AUTH_REFRESH_TOKEN_TTL: "AUTH_REFRESH_TOKEN_TTL",

  getJWTToken(): string | null {
    return localStorage.getItem(this.AUTH_TOKEN)
  },

  getJWTRefreshToken(): string | null {
    return localStorage.getItem(this.AUTH_REFRESH_TOKEN)
  },

  getJWTRefreshTokenTTL(): string | null {
    return localStorage.getItem(this.AUTH_REFRESH_TOKEN_TTL)
  },

  setJWTToken(token: string, ttl: number): void {
    localStorage.setItem(this.AUTH_TOKEN, token)
    localStorage.setItem(this.AUTH_TOKEN_TTL, ttl.toString())
  },

  setJWTRefreshToken(refreshToken: string, ttl: number): void {
    localStorage.setItem(this.AUTH_REFRESH_TOKEN, refreshToken)
    localStorage.setItem(this.AUTH_REFRESH_TOKEN_TTL, ttl.toString())
  },

  parseJWTToken(token: string): JWTTokenPayload {
    const payloadBase64Url = token.split(".")[1]
    const payloadBase64 = payloadBase64Url.replace("-", "+").replace("_", "/")
    const payload = JSON.parse(atob(payloadBase64))
    return payload
  },

  clearTokens(): void {
    localStorage.removeItem(this.AUTH_TOKEN)
    localStorage.removeItem(this.AUTH_REFRESH_TOKEN)
    localStorage.removeItem(this.AUTH_TOKEN_TTL)
    localStorage.removeItem(this.AUTH_REFRESH_TOKEN_TTL)
  },
}

export default TokenStorage
