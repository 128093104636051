import { AbilityBuilder, AbilityClass, PureAbility } from "@casl/ability"

export type Actions = "manage" | "create" | "read" | "update" | "delete"
export type Subjects =
  | "all"
  | "conciliations"
  | "conciliations documents"
  | "conciliations updates"
  | "payments receive"
  | "payments pay"
  | "admin users"
  | "admin document models"
  | "tasks"

export enum Roles {
  "user" = "user",
  "secretary" = "secretary",
  "specialist" = "specialist",
  "admin" = "admin",
}

export type AppAbility = PureAbility<[Actions, Subjects]>
export const AppAbility = PureAbility as AbilityClass<AppAbility>

export default function defineRulesFor(role: Roles) {
  const { can, rules } = new AbilityBuilder(AppAbility)

  can(["read"], "payments pay")
  if (role === Roles.admin) {
    can(["manage"], "admin users")
    can(["manage"], "admin document models")
    can(["manage", "read", "create", "update"], "conciliations")
    can(
      ["manage", "read", "create", "update", "delete"],
      "conciliations documents"
    )
    can(["manage", "read", "create", "update"], "conciliations updates")
    can(["manage", "read", "create", "update", "delete"], "payments receive")
    can(["manage", "read", "create", "update", "delete"], "payments pay")
    can(["manage", "read"], "tasks")
  } else if (role === Roles.secretary) {
    can(["read", "create", "update"], "conciliations")
    can(["read", "create", "update", "delete"], "conciliations documents")
    can(["read", "create", "update"], "conciliations updates")
    can(["read"], "tasks")
  } else if (role === Roles.specialist) {
    can(["read", "create"], "conciliations")
    can(["read", "create"], "conciliations documents")
    can(["read", "create", "update"], "conciliations updates")
    can(["read"], "tasks")
  } else {
    can(["read"], "payments pay")
    can(["read", "create"], "conciliations")
    can(["read", "create"], "conciliations documents")
    can(["read", "update", "create"], "conciliations updates")
  }

  return rules
}

type SubjectType = { type: Subjects }

export function buildAbilityFor(role: Roles): AppAbility {
  return new AppAbility(defineRulesFor(role), {
    detectSubjectType: (object: SubjectType) => object.type,
  })
}

export function updateAbility(ability: AppAbility, role: string) {
  const rules = defineRulesFor(role as Roles)
  ability.update(rules)
}
