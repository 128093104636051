import { AbilityContext } from "@context/ability.provider"
import { AuthProvider } from "@hooks/use-auth"
import { NotificationsProvider } from "@hooks/use-notifications"
import { ScrollDirectionProvider } from "@hooks/use-scroll-direction"
import { flowbiteTheme } from "@theme/base"
import { Roles, buildAbilityFor } from "@utils/ability"
import { Flowbite } from "flowbite-react"
import React, { ComponentProps, ComponentType, ReactNode } from "react"
import { BrowserRouter } from "react-router-dom"

const ability = buildAbilityFor(Roles.user)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Providers = [ComponentType<any>, ComponentProps<any>?][]

const combineProviders = <T extends ReactNode>(
  providers: Providers
): React.FC<{ children: T }> => {
  const CombinedProvider: React.FC<{ children: T }> = ({ children }) => {
    return providers.reduceRight(
      (AccumulatedProviders, [Provider, props = {}]) => {
        return <Provider {...props}>{AccumulatedProviders}</Provider>
      },
      <>{children}</>
    )
  }
  return CombinedProvider
}

export const AllProviders = combineProviders([
  [BrowserRouter],
  [Flowbite, { theme: { theme: flowbiteTheme } }],
  [AbilityContext.Provider, { value: ability }],
  [AuthProvider],
  [NotificationsProvider],
  [ScrollDirectionProvider],
])
