import { ConciliationChatMessage } from "@type/conciliation-chat-message.type"
import axiosBase from "./base.service"

type ConciliationChatMessagePostService = {
  message: string
  attachments?: number[]
}

const ConciliationChatMessageService = {
  findAll: async (
    conciliationCode?: string
  ): Promise<ConciliationChatMessage[]> => {
    const CONCILIATION_PATH = `/conciliation/${conciliationCode}/messages`
    const response = await axiosBase.get(CONCILIATION_PATH)
    if (!response.data) {
      throw new Error("Error getting conciliation chat message details")
    }
    return response.data.data
  },
  post: async ({
    message,
    attachments, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }: ConciliationChatMessagePostService): Promise<any> => {
    const CONCILIATION_PATH = `/conciliation/messages`
    const response = await axiosBase.post(CONCILIATION_PATH, {
      message,
      attachments,
    })
    return response.data.data
  },
}

export default ConciliationChatMessageService
