import { ChatIcon2 } from "@assets/icons"

type ConciliationSendMessageProps = {
  sendMessage: () => Promise<void>
}

function ConciliationSendMessage({
  sendMessage,
}: ConciliationSendMessageProps) {
  return (
    <button
      onClick={sendMessage}
      className="text-primary-600 text-sm font-normal flex items-center gap-1"
    >
      <ChatIcon2 />
      Enviar mensagem
    </button>
  )
}

export default ConciliationSendMessage
