import { modalConfirmTheme } from "@components/modal-confirm/style"
import { useScreenDetect } from "@hooks/use-screen-detect"
import ConciliationService from "@services/conciliation.service"
import { Conciliation } from "@type/conciliation.type"
import { Button, Label, Select } from "flowbite-react"
import { useEffect, useState } from "react"
import { HiExclamation } from "react-icons/hi"
import Modal from "react-modal"

type ConciliationCompleteProcessModalProps = {
  onComplete: (
    reason: string,
    observation: string,
    handleFinally: () => void
  ) => void
  isFinishProcessOpen: boolean
  setIsFinishProcessOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function ConciliationCompleteProcessModal({
  onComplete,
  isFinishProcessOpen,
  setIsFinishProcessOpen,
}: ConciliationCompleteProcessModalProps) {
  const { isMobile } = useScreenDetect()
  const [reason, setReason] = useState("")
  const [observation, setObservation] = useState("")
  const [reasons, setReasons] = useState<Conciliation.Reason[] | undefined>(
    undefined
  )

  useEffect(() => {
    if (!reasons) {
      ConciliationService.getReasons().then((data) => setReasons(data))
    }
  }, [reasons])

  return (
    <Modal
      isOpen={isFinishProcessOpen}
      onRequestClose={() => {
        setReason("")
        setObservation("")
        setIsFinishProcessOpen(false)
      }}
      style={modalConfirmTheme(isMobile, "640px")}
    >
      <div className="p-5">
        <h5 className="flex items-center text-gray-900 text-lg font-semibold border-b pb-4 mb-5">
          <HiExclamation className="mr-2" />
          Finalizar processo
        </h5>
        <Label htmlFor="reason" className="text-sm font-semibold">
          Informe o motivo
        </Label>
        <Select
          id="reason"
          className="mb-4 mt-1"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Selecione o motivo"
        >
          <option value="">Selecione o motivo</option>
          {reasons?.map((option) => (
            <option
              key={`select_filter_${option.description}`}
              value={option.id}
            >
              {option.description}
            </option>
          ))}
        </Select>
        <Label htmlFor="observation" className="text-sm font-semibold">
          Observação
        </Label>
        <textarea
          id="observation"
          className="mt-1 min-h-[126px] p-1 px-2 w-full text-sm text-gray-500 rounded-lg border border-gray-300 bg-gray-50"
          placeholder="Essa observação ficará visível na aba de ’Atualizações’ do processo para todas as partes envolvidas terem acesso."
          value={observation}
          onChange={(e) => setObservation(e.target.value)}
        />
        <div className="flex md:col-span-2 gap-4 mt-5 items-center">
          <Button
            size="xs"
            color="primary"
            className="w-fit text-sm font-medium h-[34px]"
            onClick={() =>
              onComplete(reason, observation, () => {
                setIsFinishProcessOpen(false)
                setReason("")
                setObservation("")
              })
            }
            disabled={reason === ""}
          >
            Enviar
          </Button>
          <Button
            size="xs"
            color="gray"
            className="w-fit text-primary-700 text-sm font-medium border-primary-700 h-[34px]"
            onClick={() => {
              setReason("")
              setObservation("")
              setIsFinishProcessOpen(false)
            }}
          >
            Cancelar
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ConciliationCompleteProcessModal
