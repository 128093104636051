import React from "react"

export default function ChatIcon() {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_901_5217)">
        <circle cx="40" cy="36" r="34.5" fill="#BCF0DA" stroke="#E5E7EB" />
        <path
          d="M28.8008 28.9992C28.8008 28.2566 29.0958 27.5444 29.6209 27.0193C30.146 26.4942 30.8582 26.1992 31.6008 26.1992H41.4008C42.1434 26.1992 42.8556 26.4942 43.3807 27.0193C43.9058 27.5444 44.2008 28.2566 44.2008 28.9992V34.5992C44.2008 35.3418 43.9058 36.054 43.3807 36.5791C42.8556 37.1042 42.1434 37.3992 41.4008 37.3992H38.6008L34.4008 41.5992V37.3992H31.6008C30.8582 37.3992 30.146 37.1042 29.6209 36.5791C29.0958 36.054 28.8008 35.3418 28.8008 34.5992V28.9992Z"
          fill="#6B7280"
        />
        <path
          d="M46.9984 31.8008V34.6008C46.9984 36.086 46.4084 37.5104 45.3582 38.5606C44.308 39.6108 42.8836 40.2008 41.3984 40.2008H39.7576L37.2852 42.6746C37.6772 42.8832 38.1238 43.0008 38.5984 43.0008H41.3984L45.5984 47.2008V43.0008H48.3984C49.141 43.0008 49.8532 42.7058 50.3783 42.1807C50.9034 41.6556 51.1984 40.9434 51.1984 40.2008V34.6008C51.1984 33.8582 50.9034 33.146 50.3783 32.6209C49.8532 32.0958 49.141 31.8008 48.3984 31.8008H46.9984Z"
          fill="#111928"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_901_5217"
          x="0"
          y="0"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_901_5217"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_901_5217"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="1"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_901_5217"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_901_5217"
            result="effect2_dropShadow_901_5217"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_901_5217"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
