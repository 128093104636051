import React from "react"

export default function MessageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M2.39844 5.99961C2.39844 5.36309 2.65129 4.75264 3.10138 4.30255C3.55147 3.85247 4.16192 3.59961 4.79844 3.59961H13.1984C13.835 3.59961 14.4454 3.85247 14.8955 4.30255C15.3456 4.75264 15.5984 5.36309 15.5984 5.99961V10.7996C15.5984 11.4361 15.3456 12.0466 14.8955 12.4967C14.4454 12.9468 13.835 13.1996 13.1984 13.1996H10.7984L7.19844 16.7996V13.1996H4.79844C4.16192 13.1996 3.55147 12.9468 3.10138 12.4967C2.65129 12.0466 2.39844 11.4361 2.39844 10.7996V5.99961Z"
        fill="#6B7280"
      />
      <path
        d="M18.0014 8.40039V10.8004C18.0014 12.0734 17.4957 13.2943 16.5955 14.1945C15.6953 15.0947 14.4744 15.6004 13.2014 15.6004H11.795L9.67578 17.7208C10.0118 17.8996 10.3946 18.0004 10.8014 18.0004H13.2014L16.8014 21.6004V18.0004H19.2014C19.8379 18.0004 20.4483 17.7475 20.8984 17.2974C21.3485 16.8474 21.6014 16.2369 21.6014 15.6004V10.8004C21.6014 10.1639 21.3485 9.55342 20.8984 9.10333C20.4483 8.65325 19.8379 8.40039 19.2014 8.40039H18.0014Z"
        fill="#111928"
      />
    </svg>
  )
}
