import { Label, Radio } from "flowbite-react"
import React, { ChangeEvent } from "react"

type RadioFilterOptionType = { label: string; value: string; name: string }

type RadioFilterType = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
  options: RadioFilterOptionType[]
}

const RadioFilter = ({ onChange, value, options }: RadioFilterType) => {
  return (
    <>
      {options.map((option) => (
        <div
          key={`radio_filter_${option.value}`}
          className="flex items-center gap-2"
        >
          <Radio
            className="checked:bg-primary-600"
            id={option.name}
            name={option.name}
            value={option.value}
            checked={value === option.value}
            onChange={onChange}
          />
          <Label
            htmlFor={option.name}
            className="text-gray-900 font-medium text-sm"
          >
            {option.label}
          </Label>
        </div>
      ))}
    </>
  )
}

export default RadioFilter
