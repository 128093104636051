import CheckIcon from "@assets/images/check.svg"
import { Card as FlowbiteCard } from "flowbite-react"
import React, { PropsWithChildren } from "react"

interface IPendingCard extends PropsWithChildren<object> {
  title: string
  description: string
  isDone?: boolean
  bgColor: keyof typeof colors
}

const customCardTheme = (bgColor: string) => {
  return {
    root: {
      base: `flex rounded-lg lg:p-0 border ${bgColor} border-gray-200 lg:w-full lg:m-0 lg:p-4 lg:mt-8 lg:pb-4 lg:pt-4 p-4`,
    },
  }
}

const colors = {
  indigo: "bg-indigo-50",
  red: "bg-red-100",
  gray: "bg-gray-50",
}

const PendingCard: React.FC<IPendingCard> = ({
  title,
  description,
  isDone,
  bgColor,
  children,
}) => {
  return (
    <FlowbiteCard
      className={`shadow-sm mt-8`}
      theme={customCardTheme(colors[bgColor])}
    >
      <div
        className={`flex ${isDone ? "flex-row" : "flex-col"} justify-between`}
      >
        <div className="flex items-center">
          {isDone ? (
            <div className="mr-2 w-9 h-8 bg-gray-300 rounded-md flex items-center justify-center">
              <img className="w-5 h-5" src={CheckIcon} alt="Check Logo" />
            </div>
          ) : null}
          <h5
            className={`${
              isDone
                ? "text-gray-500 text-sm font-normal leading-6"
                : "text-gray-900 text-sm font-semibold leading-6"
            }`}
          >
            {title}
          </h5>
        </div>
        <p className="text-gray-500 text-sm font-normal leading-6">
          {description}
        </p>
        {children}
      </div>
    </FlowbiteCard>
  )
}

export default PendingCard
