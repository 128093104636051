import { AllProviders } from "@context/combine.provider"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"
import { Roles, buildAbilityFor } from "./utils/ability"

type WindowWithAbility = {
  ability: ReturnType<typeof buildAbilityFor>
}

if (process.env.NODE_ENV !== "production") {
  // expose ability to play around with it in devtools
  const win = window as unknown as WindowWithAbility
  win.ability = buildAbilityFor(Roles.user)
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <AllProviders>
      <App />
    </AllProviders>
  </React.StrictMode>
)
