import { BtnMessageIcon, ChatIcon } from "@assets/icons"

type ChatButtonType = {
  onClick: () => void
  isOpen: boolean
  buttonProps?: string
}

function ChatButton({ onClick, isOpen, buttonProps }: ChatButtonType) {
  return (
    <button
      className={`z-10 fixed bottom-0 right-0 m-4 ${buttonProps}`}
      onClick={onClick}
    >
      {isOpen ? <BtnMessageIcon /> : <ChatIcon />}
    </button>
  )
}

export default ChatButton
