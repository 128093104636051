import { Pendency } from "@type/pendency.type"
import axiosBase from "./base.service"

const PENDENCY_PATH = "/conciliation-tasks"

const PendencyService = {
  findAll: async (): Promise<Pendency.Item[]> => {
    const resp = await axiosBase.get(`${PENDENCY_PATH}`)
    return resp.data.data
  },
}

export default PendencyService
