import { AdminModels } from "../types/admin.type"
import axiosBase from "./base.service"

const VARIABLE_CODES_PATH = "/document-variable-codes"
const MODELS_PATH = "/document-models"

const AdminModelService = {
  getVariableCodes: async (): Promise<AdminModels.VariableCodes[]> => {
    const response = await axiosBase.get(`${VARIABLE_CODES_PATH}`)
    if (!response.data) {
      throw new Error("Error getting variable codes")
    }
    return response.data.data
  },
  findModel: async (id: string): Promise<AdminModels.Item> => {
    const response = await axiosBase.get(`${MODELS_PATH}/${id}`)
    if (!response.data) {
      throw new Error("Error getting model")
    }
    return response.data.data
  },
  createModel: async (
    body: Omit<AdminModels.Item, "id">
  ): Promise<AdminModels.Item> => {
    const response = await axiosBase.post(`${MODELS_PATH}`, body)
    if (!response.data) {
      throw new Error("Error creating model")
    }
    return response.data
  },
  updateModel: async (
    id: string,
    body: Omit<AdminModels.Item, "id">
  ): Promise<AdminModels.Item> => {
    const response = await axiosBase.put(`${MODELS_PATH}/${id}`, body)
    if (!response.data) {
      throw new Error("Error updating model")
    }
    return response.data.data
  },
  removeModel: async (id: string): Promise<AdminModels.Item> => {
    const response = await axiosBase.delete(`${MODELS_PATH}/${id}`)
    if (!response.data) {
      throw new Error("Error deleting model")
    }
    return response.data.data
  },
  findAllModels: async (
    params?: AdminModels.Params
  ): Promise<AdminModels.PaginatedAdminModels> => {
    const response = await axiosBase.get(MODELS_PATH, {
      params: {
        status: params?.status,
        search: params?.search,
        page: params?.page,
        all: params?.page,
      },
    })
    if (!response.data) {
      throw new Error("Error getting users details")
    }
    return response.data.data
  },
}

export default AdminModelService
