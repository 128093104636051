export function formatPhoneNumber(phoneNumber: string) {
  const cleaned: string = phoneNumber.replace(/\D/g, "")

  const match: RegExpMatchArray | null = cleaned.match(
    /^(\d{2})(\d{5})(\d{4})$/
  )

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return phoneNumber
}
