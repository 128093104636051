import ChatButton from "@components/chat-button"
import ChatFirstMessage from "@components/chat-first-message"
import Error from "@components/error"
import ModalConfirm from "@components/modal-confirm"
import { modalConfirmTheme } from "@components/modal-confirm/style"
import ToastComponent from "@components/toast"
import { RoutesConstants as routes } from "@constants/routes"
import ConciliationInformation from "@features/conciliation/pages/details-information/details.information"
import ConciliationDetailsForm from "@features/conciliation/pages/details/conciliation.details.form"
import ConciliationAssignableModal from "@features/conciliation/pages/main/components/assignable.modal"
import ConciliationCompleteProcessModal from "@features/conciliation/pages/main/components/complete.process.modal"
import {
  ConciliationDetail,
  ConciliationErrors,
} from "@features/conciliation/types/conciliation-detail.type"
import { ArrowDownTrayIcon } from "@heroicons/react/24/solid"
import { useConciliationChatMessages } from "@hooks/use-conciliation-chat-messages"
import { useRole } from "@hooks/use-role"
import { useScreenDetect } from "@hooks/use-screen-detect"
import ConciliationBatchService from "@services/conciliation-batch.service"
import ConciliationChatMessageService from "@services/conciliation-chat-message.service"
import ConciliationDetailsService from "@services/conciliation-details.service"
import ConciliationService from "@services/conciliation.service"
import { Assignable } from "@type/conciliation.type"
import { ProcessActions } from "@utils/helpers/constants"
import { Badge, Breadcrumb, Button, Card, Tabs, TabsRef } from "flowbite-react"
import { useCallback, useEffect, useRef, useState } from "react"
import {
  HiClock,
  HiDocumentText,
  HiExclamation,
  HiInformationCircle,
  HiNewspaper,
  HiOutlinePlusSm,
} from "react-icons/hi"
import { useInView } from "react-intersection-observer"
import Modal from "react-modal"
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import ChatConciliation from "../../components/chat-conciliation"
import ConciliationDocuments from "../details-documents/conciliation.documents"
import ConciliationUpdates from "../details-updates/conciliation.updates"

const tabsKeys = {
  0: "information",
  1: "documents",
  2: "updates",
}

const defaultErrors: ConciliationErrors = {
  information: false,
  documents: false,
  updates: false,
  download: false,
  finish: false,
  removeUpdate: false,
  postAssignable: false,
}

const defaultModal = {
  isDefineResponsible: false,
  isDefineSpecialist: false,
  isClaimants: false,
  isRequireds: false,
}

function ConciliationDetails() {
  const navigate = useNavigate()
  const { isAdmin, isSecretary } = useRole()
  const [conciliationData, setConciliationData] =
    useState<ConciliationDetail | null>()
  const { ref, inView } = useInView()
  const { isMobile } = useScreenDetect()
  const { conciliationNumber } = useParams()
  const [, setSearchParams] = useSearchParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tabValue = searchParams.get("tab")
  const [errors, setErrors] = useState<ConciliationErrors>(defaultErrors)
  const [openNotifyModal, setOpenNotifyModal] = useState(false)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [documentsIds, setDocumentIds] = useState<string[]>([])
  const tabsRef = useRef<TabsRef>(null)
  const [modal, setModal] = useState(defaultModal)
  const [assignable, setAssignable] = useState<Assignable.Item[]>([])

  useEffect(() => {
    if (conciliationNumber) {
      const verifyPermission = () => {
        ConciliationService.accessPermission(conciliationNumber).catch(() =>
          navigate("/")
        )
      }
      verifyPermission()
    }
  }, [conciliationNumber])

  const {
    topRef,
    chatRefs,
    chatStates,
    setChatConciliationMessages,
    setChatConciliationMessagesError,
    executeScroll,
    setChatStates,
  } = useConciliationChatMessages()

  useEffect(() => {
    ConciliationDetailsService.find(conciliationNumber)
      .then((data) => setConciliationData(data))
      .catch(() =>
        setErrors((prev) => ({
          ...prev,
          information: true,
        }))
      )
  }, [])

  useEffect(() => {
    ConciliationChatMessageService.findAll(conciliationNumber)
      .then((data) => setChatConciliationMessages(data))
      .catch(() => setChatConciliationMessagesError(true))
  }, [])

  const downloadConciliation = () => {
    ConciliationDetailsService.downloadConciliation(
      {
        documentsId: documentsIds,
        chat: true,
        generalInfo: true,
        history: true,
      },
      conciliationData!.code
    ).then((data) => window.open(data, "_blank", "noreferrer"))
  }

  useEffect(() => {
    if (inView && isMobile) {
      setChatStates((prevState) => ({
        ...prevState,
        mobileOpen: true,
        desktopOpen: false,
      }))
    } else if (isMobile) {
      setChatStates((prevState) => ({
        ...prevState,
        mobileOpen: false,
        desktopOpen: false,
      }))
    }
  }, [inView, isMobile])

  const handleActiveTab = (tab: number) => {
    setActiveTab(tab)
    setSearchParams({ tab: tabsKeys[tab as keyof typeof tabsKeys] })
  }

  useEffect(() => {
    if (tabValue === tabsKeys[1]) {
      handleActiveTab(1)
    } else if (tabValue === tabsKeys[2]) {
      handleActiveTab(2)
    } else {
      handleActiveTab(0)
    }
  }, [tabValue])

  const [openModalFinishConciliation, setOpenModalFinishConciliation] =
    useState(false)

  const finishConciliation = (
    reason: string,
    observation: string,
    handleFinally: () => void
  ) => {
    if (conciliationNumber) {
      return ConciliationBatchService.completeProcess(
        [conciliationNumber],
        reason,
        observation
      )
        .catch(() => setErrors((prev) => ({ ...prev, finish: true })))
        .finally(() => handleFinally())
    }
  }

  const handleProcessActions = useCallback((option: string) => {
    if (option === ProcessActions.DEFINE_RESPONSIBLE) {
      setAssignable([])
      ConciliationService.getAssignable(Assignable.Type.SECRETARY).then(
        (data) => setAssignable(data)
      )
      setModal({ ...defaultModal, isDefineResponsible: true })
    }

    if (option === ProcessActions.DEFINE_SPECIALIST) {
      setAssignable([])
      ConciliationService.getAssignable(Assignable.Type.SPECIALIST).then(
        (data) => setAssignable(data)
      )
      setModal({ ...defaultModal, isDefineSpecialist: true })
    }
  }, [])

  const handleIncludeRelatedParties = useCallback((option: string) => {
    if (option === "claimants") {
      setModal({ ...defaultModal, isClaimants: true })
    }

    if (option === "requested_party") {
      setModal({ ...defaultModal, isRequireds: true })
    }
  }, [])

  const postNotifyRequired = () => {
    setOpenNotifyModal(false)
    ConciliationDetailsService.notifyRequired(conciliationNumber)
      .then(() => {
        // TODO: Implementar snackbar para avisar
      })
      .catch(() => {
        // TODO: Implementar snackbar para avisar
      })
  }

  return conciliationData ? (
    <>
      {Object.values(errors).some((err) => Boolean(err)) && <ToastComponent />}
      <div ref={topRef} className="flex flex-col">
        <div className="flex justify-between">
          <div className="flex lg:flex-row md:flex-row sm:flex-col flex-col">
            <h1 className="text-3xl font-bold lg:mx-0 md:mx-0 sm:mx-8 mx-8">
              Detalhes do processo
            </h1>
            <Badge
              color={conciliationData && conciliationData.color}
              className="w-fit lg:ml-3 md:ml-3 sm:ml-7 ml-7 mt-2 flex justify-center rounded-md whitespace-nowrap"
            >
              {conciliationData && conciliationData.status}
            </Badge>
          </div>
          <Breadcrumb
            aria-label="Default breadcrumb example"
            className="items-center lg:flex hidden"
          >
            <Breadcrumb.Item
              className="cursor-pointer"
              onClick={() => navigate(routes.conciliations)}
              icon={HiNewspaper}
            >
              <p>Processos</p>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Detalhes</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="flex mt-3 lg:mx-0 md:mx-0 sm:mx-8 mx-8 lg:mb-0 lg:flex-row md:flex-row sm:flex-col flex-col">
          <p className="text-gray-500">
            Código: {conciliationData && conciliationData.code}
          </p>

          <p
            onClick={downloadConciliation}
            className="flex items-center gap-1 lg:ml-3 md:ml-3 lg:mt-0 md:mt-0 sm:mt-2 mt-2 text-primary-600 hover:cursor-pointer"
          >
            <ArrowDownTrayIcon width={16} height={16} />
            Baixar processo
          </p>
        </div>
        {(isAdmin || isSecretary) && (
          <div className="flex flex-wrap mt-3 lg:mx-0 md:mx-0 sm:mx-8 mx-8 gap-4 justify-between">
            <Button onClick={() => setOpenNotifyModal(true)} color="gray">
              Notificar requerido
            </Button>{" "}
            <Button
              onClick={() => setOpenModalFinishConciliation(true)}
              color="gray"
            >
              <HiExclamation className="mr-2 h-5 w-5" />
              Finalizar processo
            </Button>
          </div>
        )}
      </div>
      <Card className="relative">
        {conciliationData.hasChat && (
          <>
            <ChatButton
              onClick={() => executeScroll(activeTab)}
              isOpen={Object.values(chatStates).some((value) => value === true)}
            />
            <div
              onClick={() => executeScroll(activeTab)}
              className={`cursor-pointer hidden md:${
                Object.values(chatStates).some((value) => value === true)
                  ? "hidden"
                  : "block"
              }`}
            >
              <ChatFirstMessage />
            </div>
          </>
        )}
        {conciliationData.hasChat && chatStates.desktopOpen && (
          <div className="z-1000 md:top-[19.5vh] md:fixed right-[5vw] absolute hidden md:col-span-2 md:block max-h-[813px] max-w-[610px]">
            <ChatConciliation ref={chatRefs.desktop} />
          </div>
        )}
        <div className="mx-4 md:mx-0">
          <Tabs.Group
            style="underline"
            ref={tabsRef}
            onActiveTabChange={(tab) => handleActiveTab(tab)}
            className="z-1"
          >
            <Tabs.Item
              active={activeTab === 0}
              icon={HiInformationCircle}
              title="Informações"
            >
              <div className="md:grid md:grid-cols-5 md:gap-12 mt-6">
                <div
                  className={`${
                    chatStates.desktopOpen ? "col-span-3" : "col-span-5"
                  }`}
                >
                  {errors.information ? (
                    <Error />
                  ) : (
                    <ConciliationInformation
                      chatStates={chatStates}
                      conciliationData={conciliationData}
                      includeClaimants={
                        <>
                          {(isAdmin || isSecretary) && !modal.isClaimants && (
                            <Button
                              color="gray"
                              size="xs"
                              className="ml-2 h-[34px] text-xs font-medium text-gray-800"
                              onClick={() =>
                                handleIncludeRelatedParties("claimants")
                              }
                            >
                              <HiOutlinePlusSm className="mr-1" size={14} />
                              Incluir
                            </Button>
                          )}
                        </>
                      }
                      claimantsModal={
                        <>
                          {modal.isClaimants && (
                            <ConciliationDetailsForm
                              type="claimants"
                              setModal={setModal}
                              setErrors={setErrors}
                            />
                          )}
                        </>
                      }
                      includeRequireds={
                        <>
                          {(isAdmin || isSecretary) && !modal.isRequireds && (
                            <Button
                              color="gray"
                              size="xs"
                              className="ml-2 h-[34px] text-xs font-medium text-gray-800"
                              onClick={() =>
                                handleIncludeRelatedParties("requested_party")
                              }
                            >
                              <HiOutlinePlusSm className="mr-1" size={14} />
                              Incluir
                            </Button>
                          )}
                        </>
                      }
                      requiredsModal={
                        <>
                          {modal.isRequireds && (
                            <ConciliationDetailsForm
                              type="requested_party"
                              setModal={setModal}
                              setErrors={setErrors}
                            />
                          )}
                        </>
                      }
                      includeDefineResponsible={
                        <>
                          {(isAdmin || isSecretary) && (
                            <Button
                              color="gray"
                              size="xs"
                              className="ml-2 h-[34px] text-xs font-medium text-gray-800"
                              onClick={() =>
                                handleProcessActions(
                                  ProcessActions.DEFINE_RESPONSIBLE
                                )
                              }
                            >
                              <HiOutlinePlusSm className="mr-1" size={14} />
                              Incluir
                            </Button>
                          )}
                        </>
                      }
                      includeSpecialist={
                        <>
                          {(isAdmin || isSecretary) && (
                            <Button
                              color="gray"
                              size="xs"
                              className="ml-2 h-[34px] text-xs font-medium text-gray-800"
                              onClick={() =>
                                handleProcessActions(
                                  ProcessActions.DEFINE_SPECIALIST
                                )
                              }
                            >
                              <HiOutlinePlusSm className="mr-1" size={14} />
                              Incluir
                            </Button>
                          )}
                        </>
                      }
                    />
                  )}
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item
              active={activeTab === 1}
              title="Documentos"
              icon={HiDocumentText}
            >
              <div className="md:grid md:grid-cols-5 md:gap-12 mt-6">
                <div
                  className={`${
                    chatStates.desktopOpen ? "col-span-3" : "col-span-5"
                  }`}
                >
                  {errors.documents ? (
                    <Error />
                  ) : (
                    <ConciliationDocuments
                      setError={setErrors}
                      setDocumentIds={setDocumentIds}
                    />
                  )}
                </div>
              </div>
            </Tabs.Item>
            <Tabs.Item
              active={activeTab === 2}
              icon={HiClock}
              title="Atualizações"
            >
              <div className="md:grid md:grid-cols-5 md:gap-12 mt-6">
                <div
                  className={`${
                    chatStates.desktopOpen ? "col-span-3" : "col-span-5"
                  }`}
                >
                  {errors.updates ? (
                    <Error />
                  ) : (
                    <ConciliationUpdates setError={setErrors} />
                  )}
                </div>
              </div>
            </Tabs.Item>
          </Tabs.Group>
        </div>
      </Card>
      {conciliationData.hasChat && (
        <div ref={ref}>
          <div ref={chatRefs.mobile} className={`block md:hidden`}>
            <ChatConciliation />
          </div>
        </div>
      )}
      <ConciliationCompleteProcessModal
        onComplete={finishConciliation}
        isFinishProcessOpen={openModalFinishConciliation}
        setIsFinishProcessOpen={() => setOpenModalFinishConciliation(false)}
      />
      <Modal
        isOpen={openNotifyModal}
        onRequestClose={() => setOpenNotifyModal(false)}
        style={modalConfirmTheme(isMobile, "640px")}
      >
        <ModalConfirm
          title={"Notificar a parte requerida"}
          description="Enviaremos a notificação por e-mail e WhatsApp para todos as partes requeridas."
          buttonInput={
            <div className="flex md:col-span-2 gap-4">
              <Button
                size="xs"
                color="primary"
                className="w-fit text-sm font-medium h-[34px]"
                onClick={postNotifyRequired}
              >
                Enviar
              </Button>
              <Button
                size="xs"
                color="gray"
                className="w-fit text-primary-700 text-sm font-medium border-primary-700 h-[34px]"
                onClick={() => setOpenNotifyModal(false)}
              >
                Cancelar
              </Button>
            </div>
          }
        />
      </Modal>
      {conciliationNumber &&
        (modal.isDefineResponsible || modal.isDefineSpecialist) && (
          <ConciliationAssignableModal
            assignable={assignable}
            selectedItems={[conciliationNumber]}
            isDefineResponsibleOpen={modal.isDefineResponsible}
            setIsDefineResponsibleOpen={() => setModal(defaultModal)}
            isDefineSpecialistOpen={modal.isDefineSpecialist}
            setIsDefineSpecialistOpen={() => setModal(defaultModal)}
            setError={() =>
              setErrors({ ...defaultErrors, postAssignable: true })
            }
          />
        )}
    </>
  ) : (
    <></>
  )
}

export default ConciliationDetails
