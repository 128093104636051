const {
  VITE_FCM_API_KEY,
  VITE_FCM_AUTH_DOMAIN,
  VITE_FCM_DATABASE_URL,
  VITE_FCM_PROJECT_ID,
  VITE_FCM_STORAGE_BUCKET,
  VITE_FCM_MESSAGING_SENDER_ID,
  VITE_FCM_APP_ID,
} = import.meta.env

const firebaseConfig = {
  apiKey: VITE_FCM_API_KEY,
  authDomain: VITE_FCM_AUTH_DOMAIN,
  databaseURL: VITE_FCM_DATABASE_URL,
  projectId: VITE_FCM_PROJECT_ID,
  storageBucket: VITE_FCM_STORAGE_BUCKET,
  messagingSenderId: VITE_FCM_MESSAGING_SENDER_ID,
  appId: VITE_FCM_APP_ID,
}
export default firebaseConfig
