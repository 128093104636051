export const getButtonClasses = (isActive: boolean) => {
  return `px-3 py-2 text-xs font-medium text-center hover:bg-gray-300 ${
    isActive ? "text-white" : "text-gray-800"
  } ${isActive ? "hover:text-white" : "hover:text-gray-800"}
  ${isActive ? "bg-primary-700" : "bg-gray-100"} rounded-full ${
    isActive ? "hover:bg-primary-700" : "hover:bg-gray-100"
  } focus:outline-none focus:ring-gray-100 ${
    isActive ? "dark:bg-primary-700" : "dark:bg-gray-100"
  } ${
    isActive ? "dark:hover:bg-primary-700" : "dark:hover:bg-gray-100"
  } dark:focus:ring-gray-100`
}
