import {
  Button,
  CustomFlowbiteTheme,
  Flowbite,
  TextInput,
} from "flowbite-react"
import { ChangeEvent } from "react"
import { HiOutlineSearch } from "react-icons/hi"

type SearchInputType = {
  handleSearchText: (event: ChangeEvent<HTMLInputElement>) => void
  searchText: string
  handleSearch: () => void
  theme?: CustomFlowbiteTheme
  placeholder: string
}

export default function SearchInput({
  handleSearchText,
  searchText,
  handleSearch,
  placeholder,
  theme,
}: SearchInputType) {
  return (
    <div className="flex w-full max(820px):w-full md:w-auto">
      <Flowbite theme={{ theme: theme || customButton }}>
        <TextInput
          icon={HiOutlineSearch}
          placeholder={placeholder}
          onChange={handleSearchText}
          value={searchText}
        />

        <Button
          color="primary"
          className="rounded-tl-none rounded-bl-none"
          onClick={handleSearch}
        >
          Buscar
        </Button>
      </Flowbite>
    </div>
  )
}

const customButton: CustomFlowbiteTheme = {
  textInput: {
    base: "flex h-[37px] w-full",
    field: {
      base: "relative md:min-w-[300px] h-[37px] w-full max(820px):w-full",
      input: {
        base: "pl-8 flex w-full !rounded-tr-none !rounded-br-none border disabled:cursor-not-allowed disabled:opacity-50 focus:outline-none focus-visible:gray-100 focus:border-gray-300 h-[37px]",
      },
      icon: {
        svg: "h-4 w-4",
      },
    },
  },
  button: {
    base: "w-[71px] h-[37px] bg-primary-700 focus:ring-primary-300 group flex items-center justify-center text-center font-medium focus:z-10",
    color: {
      primary:
        "text-white bg-primary-700 hover:bg-primary-800 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800",
      secondary: "border bg-white hover:bg-gray-100 sm:w-full w-full",
    },
    outline: {
      on: "transition-all duration-75 ease-in group-hover:bg-opacity-0 group-hover:text-inherit",
    },
  },
}
