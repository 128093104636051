export default function ExclamationCircleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1 12C22.1 14.5461 21.0886 16.9879 19.2882 18.7882C17.4879 20.5886 15.0461 21.6 12.5 21.6C9.95395 21.6 7.51215 20.5886 5.7118 18.7882C3.91145 16.9879 2.90002 14.5461 2.90002 12C2.90002 9.45392 3.91145 7.01212 5.7118 5.21177C7.51215 3.41142 9.95395 2.39999 12.5 2.39999C15.0461 2.39999 17.4879 3.41142 19.2882 5.21177C21.0886 7.01212 22.1 9.45392 22.1 12ZM13.7 16.8C13.7 17.1183 13.5736 17.4235 13.3486 17.6485C13.1235 17.8736 12.8183 18 12.5 18C12.1818 18 11.8765 17.8736 11.6515 17.6485C11.4265 17.4235 11.3 17.1183 11.3 16.8C11.3 16.4817 11.4265 16.1765 11.6515 15.9515C11.8765 15.7264 12.1818 15.6 12.5 15.6C12.8183 15.6 13.1235 15.7264 13.3486 15.9515C13.5736 16.1765 13.7 16.4817 13.7 16.8ZM12.5 5.99999C12.1818 5.99999 11.8765 6.12642 11.6515 6.35147C11.4265 6.57651 11.3 6.88173 11.3 7.19999V12C11.3 12.3183 11.4265 12.6235 11.6515 12.8485C11.8765 13.0736 12.1818 13.2 12.5 13.2C12.8183 13.2 13.1235 13.0736 13.3486 12.8485C13.5736 12.6235 13.7 12.3183 13.7 12V7.19999C13.7 6.88173 13.5736 6.57651 13.3486 6.35147C13.1235 6.12642 12.8183 5.99999 12.5 5.99999Z"
        fill="#771D1D"
      />
    </svg>
  )
}
