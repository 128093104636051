import { ValidatorError } from "@type/validator-error.type"

export const mapToValidateErrors = (
  errors: Array<string>
): Array<ValidatorError> => {
  const validatorErrors = new Array<ValidatorError>()
  for (let index = 0; index < errors.length; index++) {
    const element = errors[index]
    const regex = /^([a-zA-Z]+)\s(.*)$/
    const matchers = element.match(regex)
    if (matchers && matchers?.length > 0) {
      validatorErrors.push({
        property: matchers[1],
        message: matchers[2],
      })
    }
  }
  return validatorErrors
}
