import React from "react"

type PictureIconType = {
  width?: string
  height?: string
  color?: string
}
export default function PictureIcon({
  color = "#233876",
  height = "20",
  width = "15",
}: PictureIconType) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
    >
      <g clipPath="url(#clip0_2_328)">
        <path
          d="M2.5 0C1.12109 0 0 1.12109 0 2.5V17.5C0 18.8789 1.12109 20 2.5 20H12.5C13.8789 20 15 18.8789 15 17.5V6.25H10C9.30859 6.25 8.75 5.69141 8.75 5V0H2.5ZM10 0V5H15L10 0ZM2.5 10C2.5 9.66848 2.6317 9.35054 2.86612 9.11612C3.10054 8.8817 3.41848 8.75 3.75 8.75C4.08152 8.75 4.39946 8.8817 4.63388 9.11612C4.8683 9.35054 5 9.66848 5 10C5 10.3315 4.8683 10.6495 4.63388 10.8839C4.39946 11.1183 4.08152 11.25 3.75 11.25C3.41848 11.25 3.10054 11.1183 2.86612 10.8839C2.6317 10.6495 2.5 10.3315 2.5 10ZM8.4375 11.25C8.64453 11.25 8.83594 11.3516 8.95312 11.5195L12.3906 16.5195C12.5234 16.7109 12.5352 16.9609 12.4297 17.1641C12.3242 17.3672 12.1094 17.5 11.875 17.5H8.4375H6.875H5H3.125C2.89844 17.5 2.69141 17.3789 2.58203 17.1836C2.47266 16.9883 2.47266 16.7461 2.58984 16.5547L4.46484 13.4297C4.57812 13.2422 4.78125 13.125 5 13.125C5.21875 13.125 5.42188 13.2383 5.53516 13.4297L6.03516 14.2656L7.92188 11.5234C8.03906 11.3555 8.23047 11.2539 8.4375 11.2539V11.25Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2_328">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
