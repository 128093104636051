export namespace Pendency {
  export enum Status {
    "TODO" = "TODO",
    "DONE" = "DONE",
  }

  export enum ResponsibleType {
    "MINE" = "MINE",
    "SECRETARY" = "SECRETARY",
    "SPECIALIST" = "SPECIALIST",
  }

  export type Item = {
    title: string
    description: string
    dueDate: string
    conciliationCode: string
    id: number
    status: Status
    responsibleType: ResponsibleType
    urgent: boolean
  }
}
